import React, { FC, useState } from "react";
import "./style.scss";
import Button from "shared/ui/button";
import CheckIcon from "../../../../assets/icon-check.svg";
import { OrderBy } from "entities/market";
import { SortData } from "static/data";
import { useTranslation } from "react-i18next";

type PropTypeRow = {
  sort: string;
  onClick: () => void;
  active: boolean;
};

export const SortRow: FC<PropTypeRow> = ({ sort, onClick, active }) => {
  const { t } = useTranslation("app");

  return (
    <div
      onClick={onClick}
      className={active == true ? "sort-row-active sort-row" : "sort-row"}
    >
      <div>
        <p>{t(sort)}</p>
      </div>
      {active && <img src={CheckIcon} alt="CheckIcon" />}
    </div>
  );
};

type PropType = {
  onClose: () => void;
  assetType: any;
  sort: any;
  onSort: (v: any) => void;
  style?: React.CSSProperties;
};

export const SortModal: React.FC<PropType> = ({
  onClose,
  sort,
  onSort,
  assetType,
  style,
}) => {
  const { t } = useTranslation("app");
  const [sortBy, setSortBy] = useState(sort?.sortBy || "");
  const [orderBy, setOrderBy] = useState<OrderBy>(sort?.orderBy || "asc");

  const handleSort = (
    activeSortConfig: string,
    sortDescendingConfig: OrderBy
  ) => {
    onSort({
      sortBy: activeSortConfig,
      orderBy: sortDescendingConfig,
    });

    onClose();
  };
  const sortExists = SortData[String(assetType)];
  const defaultSortConfig = sortBy === "";

  if (!sortExists) return <></>;

  return (
    <div className="bottom-sheet-modal-wrapper" style={style}>
      <div onClick={onClose} className="outlet-sort-modal"></div>

      <div className="sort-modal">
        <div className="sort-modal-header">
          <p>{t("sort")}</p>
          <span onClick={() => setOrderBy(orderBy === "asc" ? "desc" : "asc")}>
            {orderBy === "desc" ? t("descending") : t("ascending")}
          </span>
        </div>

        {SortData[String(assetType)].map((v, i) => (
          <SortRow
            key={i}
            sort={v.text}
            active={sortBy === v.by}
            onClick={() => setSortBy(v.by)}
          />
        ))}

        {defaultSortConfig && (
          <Button
            onClick={() => {
              handleSort(sortBy, orderBy);
            }}
            children={sortBy !== "" ? t("apply") : t("cancel")}
            variant={sortBy !== "" ? "dark" : "noBorder"}
          />
        )}

        {!defaultSortConfig && (
          <div className="sort-configs-btns">
            <Button
              onClick={() => {
                handleSort(sortBy, orderBy);
              }}
              children={sortBy !== "" ? t("apply") : t("cancel")}
              variant={sortBy !== "" ? "dark" : "noBorder"}
            />
            <Button
              className="reset-sort-config"
              onClick={() => {
                handleSort("", "asc");
              }}
              children={t("reset")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
