import { IUserProfileInfo } from "entities/onboard/model/onboardModel";

export const MapProfileInfoFromUser = (info: {
  areInvestWithSavings: boolean;
  birthDate: string;
  citizen: string;
  gender: string;
  haveExperience: boolean;
  hopeToAchieve: string;
  whatDoForLiving: string;
}): IUserProfileInfo => {
  if (info) {
    const [day, month, year] = info.birthDate.split(".");
    return {
      gender: info.gender,
      birthDate: { day, month, year },
      citizen: info.citizen,
      hopeToAchieve: info.hopeToAchieve,
      haveExperience: info.haveExperience,
      whatDoForLiving: info.whatDoForLiving,
      areInvestWithSavings: info.areInvestWithSavings,
    };
  } else {
    return {};
  }
};
