import React, { FC, useCallback, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { PersonalInfoEditCard } from "../components/personal-info-edit-card";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import Modal from "widgets/auth/modal";
import { toast } from "react-toastify";
import { accountStore } from "entities/account";

export const SecurityPage = () => {
  const [delModal, setDelModal] = useState(false);
  const { user } = useStore(sessionModel.sessionStore);
  const { t } = useTranslation("app");
  const { t: err } = useTranslation("errors");
  const navigate = useNavigate();
  const { requestDataDeletion } = useStore(accountStore);

  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };

  const changePassword = () => {
    navigate(PATH_LIST.changePassword);
  };

  const changePIN = () => {
    navigate(PATH_LIST.changePIN);
  };

  const RequestToDelete = async (pinCode: string) => {
    setDelModal(false);
    try {
      await requestDataDeletion(err, pinCode);
    } catch (error) {
      toast.error("Server Error");
    } finally {
      navigate(PATH_LIST.account);
    }
  };

  return (
    <div className="personal-info">
      <div className="personal-info-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("security")}</p>
      </div>
      <div className="personal-info-main">
        <PersonalInfoEditCard
          onEdit={() => changePassword()}
          name={t("changePassword")}
          security={true}
        />
        <PersonalInfoEditCard
          onEdit={() => changePIN()}
          name={t("changePinCode")}
          security={true}
        />
        <PersonalInfoEditCard
          onEdit={() => setDelModal(true)}
          name={t("delMyAcc")}
          security={true}
          deletion={true}
        />
      </div>

      {delModal === true && (
        <Modal
          customModal="modalLogOut"
          onClick={RequestToDelete}
          onHide={() => setDelModal(false)}
          title={t("confirmAccDel")}
          text={t("proceedWithAccDel")}
          btnText={t("confirm")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          // isLoading={load}
          // dealError={dealError}
        />
      )}
    </div>
  );
};
