import React from "react";
import LogoL from "../../../../assets/LogoLetter.svg";
import { FC } from "react";
import "./style.scss";

type LogoTitle = {
  title: string;
};

const LogoAndTitle: FC<LogoTitle> = ({ title }) => {
  return (
    <div className="logoAndTitle">
      <a href="/">
        <img src={LogoL} alt="LogoLetter" />
      </a>
      <p>{title}</p>
    </div>
  );
};

export default LogoAndTitle;
