import { useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import FilterSectionRow from "../components/filter-section-row";
import HorizontalScroll from "../components/horizontal-scroll";
import { TabBtn } from "../components/tab-btn";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import FilterCard from "../components/ready-filters";
// import { FilterType } from "../components/ready-filters";
import { EditFilterCard } from "../components/edit-filter-card";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { useStore } from "zustand";
import {
  FilterType,
  IFilter,
  IStocksFilterData,
  marketStore,
  useSelectFilters,
} from "entities/market";
import NotFoundContainer from "shared/ui/NotFoundContainer";
import { useTranslation } from "react-i18next";
import { CreateFilterModal } from "../components/create-filter-modal";

export const FilterPage = () => {
  const { t } = useTranslation("app");
  const [activeTab, setActiveTab] = useState("ReadyFilters");
  const [activeTabBond, setActiveTabBond] = useState("ReadyFilters");
  const [editFilter, setEditFilter] = useState<null | IFilter>(null);
  const [editFilterModal, setEditFilterModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);

  const { isLoading, loadFilters, updateFilter, removeFilter } =
    useStore(marketStore);

  const stockFilters = useSelectFilters(FilterType.Stock, "user");
  const stockReadyFilters = useSelectFilters(FilterType.Stock, "admin");
  const bondFilters = useSelectFilters(FilterType.Bond, "user");
  const bondReadyFilters = useSelectFilters(FilterType.Bond, "admin");

  // useEffect(() => {
  //   if (!stockReadyFilters.length && stockFilters.length > 0) {
  //     setActiveTab("My");
  //   }
  // }, [stockFilters, stockReadyFilters]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.market);
  };
  const createStockFilter = () => {
    marketStore.setState({
      createdFilter: null,
      filterAssetsCount: 0,
      filterAssets: [],
      filterSortConfigs: null,
    });
    navigate(PATH_LIST.createStockFilter);
  };
  const createBondFilter = () => {
    marketStore.setState({
      createdFilter: null,
      filterAssetsCount: 0,
      filterAssets: [],
      filterSortConfigs: null,
    });
    navigate(PATH_LIST.createBondFilter);
  };

  useBlockScrollbar(!!editFilter, [editFilter]);

  const handleTabClick = (text: string) => {
    setActiveTab(text);
  };
  const handleBondTabClick = (text: string) => {
    setActiveTabBond(text);
  };

  useEffect(() => {
    loadFilters();
  }, []);

  const onUpdate = async (name: string) => {
    if (editFilter && !isNaN(editFilter.id)) {
      await updateFilter(editFilter.id, {
        name,
        type: editFilter.type,
        data: editFilter.data as IStocksFilterData,
      });
      loadFilters();
      setRenameModal(false);
    } else {
      console.error("Invalid filter ID");
    }
  };

  return (
    <div className="filter">
      <div className="filter-header">
        <img onClick={handleNavigate} src={GoBack} alt="Go Back" />
        <p>{t("filter")}</p>
      </div>
      <div className="filter-main">
        <FilterSectionRow name="Stocks" onClick={() => createStockFilter()} />
        <HorizontalScroll className="tab-bar-filter">
          <TabBtn
            text={t("readyFilters")}
            active={activeTab === "ReadyFilters"}
            onClick={() => handleTabClick("ReadyFilters")}
          />
          <TabBtn
            text={t("myFilters")}
            active={activeTab === "My"}
            onClick={() => handleTabClick("My")}
          />
        </HorizontalScroll>
        <div className="filter-cards">
          {activeTab === "ReadyFilters" ? (
            <NotFoundContainer
              animation
              condition={!!stockReadyFilters.length}
              isLoading={isLoading}
            >
              <Swiper
                style={{ width: "100%", padding: "16px 24px" }}
                wrapperClass="filter-ready-box"
                spaceBetween={24}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
              >
                {stockReadyFilters.map((f, i) => (
                  <SwiperSlide key={i}>
                    <FilterCard
                      //@ts-ignore
                      type="StockFilter"
                      filter={f}
                      onOpen={() => {
                        marketStore.setState({
                          createdFilter: f,
                          filterSortConfigs: null,
                        });
                        navigate(PATH_LIST.filterReview);
                      }}
                      // type={FilterType.StockFilter}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </NotFoundContainer>
          ) : (
            <NotFoundContainer
              animation
              condition={!!stockFilters.length}
              isLoading={isLoading}
            >
              <HorizontalScroll className="filter-my-box">
                {stockFilters.map((f, i) => (
                  <FilterCard
                    key={i}
                    filter={f}
                    myFilter
                    onMyFilterEdit={() => {
                      setEditFilter(f);
                      setEditFilterModal(true);
                    }}
                    onOpen={() => {
                      marketStore.setState({
                        createdFilter: f,
                        filterSortConfigs: null,
                      });
                      navigate(PATH_LIST.filterReview);
                    }}
                  />
                ))}
              </HorizontalScroll>
            </NotFoundContainer>
          )}
        </div>

        <FilterSectionRow name="Bonds" onClick={() => createBondFilter()} />
        <HorizontalScroll className="tab-bar-filter">
          <TabBtn
            text={t("readyFilters")}
            active={activeTabBond === "ReadyFilters"}
            onClick={() => handleBondTabClick("ReadyFilters")}
          />
          <TabBtn
            text={t("myFilters")}
            active={activeTabBond === "My"}
            onClick={() => handleBondTabClick("My")}
          />
        </HorizontalScroll>
        <div className="filter-cards">
          {activeTabBond === "ReadyFilters" ? (
            <NotFoundContainer
              animation
              condition={!!bondReadyFilters.length}
              isLoading={isLoading}
            >
              <Swiper
                style={{ width: "100%", padding: "16px 24px" }}
                wrapperClass="filter-ready-box"
                spaceBetween={24}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
              >
                {bondReadyFilters.map((f, i) => (
                  <SwiperSlide key={i}>
                    <FilterCard
                      //@ts-ignore
                      type="BondFilter"
                      filter={f}
                      onOpen={() => {
                        marketStore.setState({ createdFilter: f });
                        navigate(PATH_LIST.filterReview);
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </NotFoundContainer>
          ) : (
            <NotFoundContainer
              animation
              condition={!!bondFilters.length}
              isLoading={isLoading}
            >
              <HorizontalScroll className="filter-my-box">
                {bondFilters.map((f, i) => (
                  <FilterCard
                    key={i}
                    filter={f}
                    myFilter
                    onMyFilterEdit={() => {
                      setEditFilter(f);
                      setEditFilterModal(true);
                    }}
                    onOpen={() => {
                      marketStore.setState({ createdFilter: f });
                      navigate(PATH_LIST.filterReview);
                    }}
                  />
                ))}
              </HorizontalScroll>
            </NotFoundContainer>
          )}
        </div>
      </div>

      {editFilterModal && (
        <EditFilterCard
          onRemoveClick={() => {
            setEditFilterModal(false);
            removeFilter(editFilter!.id);
          }}
          onRenameClick={() => {
            setEditFilterModal(false);
            setRenameModal(true);
          }}
          onClose={() => setEditFilterModal(false)}
        />
      )}

      {renameModal && (
        <CreateFilterModal
          title={t("rename")}
          value={editFilter?.name || ""}
          onHide={() => setRenameModal(false)}
          onSubmit={onUpdate}
          isLoading={isLoading}
          renameFilter
        />
      )}
    </div>
  );
};
