import React, { useState, useRef, useEffect, FC } from "react";
import "./voice.scss";
import Play from "../../../../../assets/PlayAudio.svg";
import Pause from "../../../../../assets/PauseAudio.svg";
import moment from "moment";

interface IChat {
  sender: string;
  customer: string;
  text: string;
  file: string | null;
  createdAt: string;
  isLast: boolean;

  src: string;
}

const formatTime = (time: number | null): string => {
  try {
    if (time === null) return "";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  } catch (error) {
    return "";
  }
};

const ChatVoiceMessage: FC<IChat> = ({ src, sender, createdAt, isLast }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    const audio = audioRef.current;
    const updateProgress = () => {
      if (audio) {
        const percentage = (audio.currentTime / audio.duration) * 100;
        setProgress(percentage);
        setCurrentTime(audio.currentTime);
      }
    };
    const handleEnded = () => {
      setIsPlaying(false);
      setProgress(0);
    };
    const handleLoadedMetadata = () => {
      if (audio) {
        setDuration(audio.duration || null);
      }
    };
    if (audio) {
      audio.addEventListener("timeupdate", updateProgress);
      audio.addEventListener("ended", handleEnded);
      audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    }
    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", updateProgress);
        audio.removeEventListener("ended", handleEnded);
        audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      }
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;

    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }

      setIsPlaying(!isPlaying);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const audio = audioRef.current;
    const targetTime =
      (parseFloat(e.target.value) / 100) * (audio?.duration ?? 0);

    if (audio) {
      audio.currentTime = targetTime;
      setProgress(parseFloat(e.target.value));
      setCurrentTime(targetTime);
    }
  };

  return (
    <div
      className={`voice-message-container voice-message-${sender}-container ${
        isLast ? "last-msg" : ""
      }`}
    >
      <audio ref={audioRef} src={src} />

      <button onClick={togglePlayPause}>
        <img
          // className={!isPlaying ? "voice-message-stoped" : undefined}
          src={isPlaying ? Pause : Play}
          alt="play/pause"
        />
      </button>

      <div className="timeline-range-Vmessage">
        <input
          type="range"
          value={progress}
          step="0.01"
          onChange={handleSeek}
        />

        <div className="timeline-Vmessage">
          <div>
            <span>{formatTime(currentTime)}</span>
            {formatTime(duration) ? (
              <>
                <span>/</span>
                <span>{formatTime(duration)}</span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <p className="voice-created-at">
        {moment(new Date(createdAt)).format("HH:mm")}
      </p>
    </div>
  );
};

export default ChatVoiceMessage;
