import React, { FC, useEffect, useState } from "react";
import "./file.scss";
import moment from "moment";
import { getChatFileUrl } from "helpers/functions";

interface IChat {
  file: string;
  sender: string;
  customer: string;
  createdAt: string;
  isLast: boolean;
}

const ChatFileMessage: FC<IChat> = ({ file, sender, createdAt, isLast }) => {
  const fileUrl = file;
  const [fileSize, setFileSize] = useState<string | null>(null);
  const [fileType, setFileType] = useState<any | null>(null);

  useEffect(() => {
    const fetchFileMetadata = async () => {
      try {
        const response = await fetch(fileUrl);
        const size = response.headers.get("content-length");
        const type = response.headers.get("content-type");

        const kb = (size as unknown as number) / 1024;
        const mb = kb / 1024;
        const finalSize =
          mb > 0 ? +mb.toFixed(2) + " MB" : +kb.toFixed(2) + " KB";

        setFileType(type);
        setFileSize(size ? finalSize : "");
      } catch (error) {
        console.error("Error fetching file metadata:", error);
        setFileType("");
        setFileSize("");
      }
    };

    fetchFileMetadata();
  }, [fileUrl]);

  const fileName = fileUrl.split("/").pop() || "";

  const getFileLabel = (type: string | null) => {
    switch (type) {
      case "application/zip":
        return "ZIP";
      case "video/mp4":
        return "MP4";
      case "application/msword":
        return "Word";
      case "application/pdf":
        return "PDF";
      case "image/jpeg":
      case "image/jpg":
        return "JPEG";
      case "image/svg+xml":
        return "SVG";
      default:
        return "File";
    }
  };
  const fileTypeLabel = getFileLabel(fileType);

  const fileNameDisplay =
    fileName.length > 10 ? `${fileName.slice(0, 10)}...` : fileName;

  const handleDownload = async () => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div
      onClick={handleDownload}
      className={`message-file message-${sender}-file ${
        isLast ? "last-msg" : ""
      }`}
    >
      <div className="file-type">{fileTypeLabel}</div>
      <div className="file-info">
        <p>{fileNameDisplay}</p>
        <span>{fileSize}</span>
      </div>
      <span className="created-at">
        {moment(new Date(createdAt)).format("HH:mm")}
      </span>
    </div>
  );
};

export default ChatFileMessage;
