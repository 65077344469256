import React, { FC } from "react";
import "./style.scss";

type PropsType = {
  date: string;
  price: string;
  year: string;
  percent: string;
  showYear: boolean;
  annuallyYield: string;
  yearString: string;
};
const DividentsDetails: FC<PropsType> = ({
  date,
  price,
  year,
  percent,
  showYear,
  annuallyYield,
  yearString,
}) => {

  return (
    <>
      <div className="dividents-details-row">
        {showYear && yearString && (
          <div className="dividents-header">
            <p>{yearString}</p>

            <span>{annuallyYield}</span>
          </div>
        )}
        <div className="header-DD">
          <p>{date}</p>
          <p>{price}</p>
        </div>
        <div className="footer-DD">
          <p>{year}</p>
          <p>{percent}</p>
        </div>
      </div>
    </>
  );
};

export default DividentsDetails;
