import React, { useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { EditRatesCard } from "../components/edit-rates-card";
import { dashboardStore } from "entities/dashboard";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";

export const EditRatesPage: React.FC = () => {
  const navigate = useNavigate();
  const { user_rates, updateRates } = useStore(dashboardStore);
  const [dropdown, setDropdown] = useState<string | null>(null);
  const [rates, setRates] = useState<any[]>(user_rates);
  const { t } = useTranslation("app");

  const resetDropdown = () => {
    setDropdown(null);
  };
  const handleDropdown = (index: number, type: "left" | "right") => () => {
    const id = `${index}-${type}`;
    if (dropdown === id) {
      setDropdown(null);
    } else {
      setDropdown(id);
    }
  };

  const handleNavigate = () => {
    navigate(PATH_LIST.home);
  };

  useEffect(() => {
    // this function update rates only in local storage!!
    updateRates(rates);
    // in future we should update in db
  }, [rates]);

  return (
    <div className="edit-rates">
      <div onClick={resetDropdown} className="edit-rates-header">
        <img onClick={handleNavigate} src={GoBack} alt="Go Back" />
        <p>{t("editRates")}</p>
      </div>

      {user_rates.map((rate, i) => (
        <EditRatesCard
          key={i}
          reset={resetDropdown}
          onChange={(l, r) =>
            setRates(
              rates.map((v, j) =>
                i === j ? { price_asset: l, trade_asset: r } : v
              )
            )
          }
          priceAsset={rate.price_asset}
          tradeAsset={rate.trade_asset}
          handleFirstDropdown={handleDropdown(i, "left")}
          handleSecondDropdown={handleDropdown(i, "right")}
          dropdownFF={dropdown === `${i}-${"left"}`}
          dropdownFS={dropdown === `${i}-${"right"}`}
        />
      ))}
    </div>
  );
};
