import React from "react";
import "./style.scss";
import Icon from "../../../../assets/PDF.svg";
import { sircapApi } from "shared/api/sircap";

type PropsType = {
  file: string;
  name: string;
  userId: string;
};

const ReportItem: React.FC<PropsType> = ({ name, file, userId }) => {
  return (
    <div
      onClick={() => {
        window.open(`${sircapApi.baseUrl}/reports/${userId}/${file}`, "_blank");
      }}
      className="report-item-container"
    >
      <span>{name}</span>

      <img className="report-download-btn" src={Icon} alt="" />
    </div>
  );
};

export default ReportItem;
