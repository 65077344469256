import React, { FC, useCallback, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import ReplenishmentDetails from "../components/replenishment-details";
import Currency from "../components/deposit-currency";
import { CurrencyModal } from "../components/currency-modal";
import Button from "shared/ui/button";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import Modal from "widgets/auth/modal";
import { accountStore, useWithdrawalInfo } from "entities/account";
import { useStore } from "zustand";
import Loading from "widgets/loading";
import { CURRENCY_FLAGS } from "static/currencies";
import {
  useUserContract,
  useUserName,
} from "entities/session/model/sessionModel";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { dashboardStore } from "entities/dashboard";

export const WithdrawalPage: FC = () => {
  const navigate = useNavigate();
  const [currencyModal, setCurrencyModal] = useState<
    null | "bank" | "currency"
  >(null);
  const [modalWithdrawal, setModalWithdrawal] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState<number>();
  const userName = useUserName();
  const contract = useUserContract();

  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("app");

  const { user_deposit_accounts, loadUserAccounts } = useStore(dashboardStore);

  const {
    isLoading,
    requestWithdrawal,
    selectedWithdrawCurrency,
    selectCurrency,
    withdrawInfo: accounts,
    getWithdrawInfo,
  } = useStore(accountStore);

  const [selectedBank, setSelectedBank] = useState<number | null>(null);
  const [accountNumber, setAccountNumber] = useState<string>("");
  const withdrawInfo = useWithdrawalInfo(
    selectedWithdrawCurrency,
    selectedBank
  );
  const currencies = new Set(accounts?.map((v) => v.currency.code));

  const handleNavigate = () => {
    if (isLoading) return;
    navigate(PATH_LIST.account);
  };

  useEffect(() => {
    getWithdrawInfo();
    loadUserAccounts();
  }, []);

  useEffect(() => {
    setAccountNumber(withdrawInfo?.account_number || "");
  }, [withdrawInfo]);

  const handleWithdrawal = () => {
    if (isNaN(withdrawalAmount as number)) return;
    const amount = +(withdrawalAmount || 0);
    requestWithdrawal(
      {
        amount,
        currency: selectedWithdrawCurrency,
        account_number: accountNumber,
        info_source: selectedBank!,
      },
      (resp: any) => {
        if (resp.error) {
          toast.error(err(String(resp.error.code)));
        } else {
          setModalWithdrawal(true);
        }
      }
    );
  };

  const getUserAccount = useCallback(() => {
    const acc = user_deposit_accounts.find(
      (acc) => acc.currency.code === selectedWithdrawCurrency
    );
    if (acc) {
      return `${+acc.amount.toFixed(2)} ${acc.currency.symbol}`;
    } else {
      return "0";
    }
  }, [user_deposit_accounts, selectedWithdrawCurrency]);

  const getUserAccountAmount = useCallback(() => {
    const acc = user_deposit_accounts.find(
      (acc) => acc.currency.code === selectedWithdrawCurrency
    );
    if (acc) {
      return acc.amount;
    } else {
      return 0;
    }
  }, [user_deposit_accounts, selectedWithdrawCurrency]);

  useBlockScrollbar(!!currencyModal, [currencyModal]);

  if (!withdrawInfo || isLoading) {
    return (
      <div className="withdraw deposit">
        <div className="deposit-header">
          <img
            className="go-back-portfolio"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <p>{t("withdrawal")}</p>
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <div className="not-found-coming-soon">
            <p>{t("noData")}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="withdraw deposit">
      <div className="deposit-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("withdrawal")}</p>
      </div>
      <div className="deposit-main">
        <ReplenishmentDetails
          nameOfDetail={t("fullName")}
          detail={userName || ""}
        />
        <Currency
          image={CURRENCY_FLAGS[selectedWithdrawCurrency]}
          currency={selectedWithdrawCurrency}
          onClick={() => setCurrencyModal("currency")}
        />
        <Currency
          image={withdrawInfo.info_source.image}
          onClick={() => setCurrencyModal("bank")}
          bank={withdrawInfo.info_source.name}
        />
        {/* <ReplenishmentDetails
          nameOfDetail={t("accountNumber")}
          detail={withdrawInfo.account_number || ""}
        /> */}

        <div className="withdrawal-amount">
          <label htmlFor="number">{t("accountNumber")}</label>
          <input
            id="number"
            type="number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(String(e.target.value))}
          />
        </div>

        <div className="withdrawal-amount">
          <label htmlFor="number">{t("withdrawalAmount")}</label>
          <input
            // className="wrong-value"
            id="number"
            type="number"
            value={withdrawalAmount}
            min={0}
            max={getUserAccountAmount()}
            onChange={(e) => {
              const strValue = String(e.target.value);
              const value = +strValue || "";
              setWithdrawalAmount(value as number);
            }}
          />
          <span style={{ textAlign: "left" }}>
            {t("available")}: {getUserAccount()}
          </span>
        </div>
      </div>

      <div className="btn-wrapper">
        <Button
          children={t("withdraw")}
          variant="dark"
          onClick={handleWithdrawal}
          disabled={
            !withdrawalAmount ||
            +(withdrawalAmount || 0) > getUserAccountAmount() ||
            isLoading
          }
        />
      </div>

      {currencyModal && (
        <CurrencyModal
          type={currencyModal}
          onClose={() => setCurrencyModal(null)}
          onCurrencyChange={
            currencyModal === "bank"
              ? setSelectedBank
              : (v) => {
                  setSelectedBank(null);
                  selectCurrency("withdraw")(v);
                }
          }
          selectedCurrency={selectedWithdrawCurrency}
          selectedBank={selectedBank}
          currencies={Array.from(currencies)}
          banks={
            currencyModal === "bank"
              ? accounts
                  .filter((d) => d.currency.code === selectedWithdrawCurrency)
                  .map((d) => d.info_source)
              : undefined
          }
        />
      )}

      {modalWithdrawal && (
        <Modal
          customModal="modalWithdrawal"
          onClick={() => {
            setModalWithdrawal(false);
            navigate(PATH_LIST.account);
          }}
          onHide={() => {
            setModalWithdrawal(false);
            navigate(PATH_LIST.account);
          }}
          title={t("withdrawalProcessing")}
          text={t("requestBeingProcessed")}
          btnText={t("ok")}
          showSecondBtn={false}
          customOutlet="customOutlet customWithdrawalOutlet"
        />
      )}
    </div>
  );
};
