import React, { useState, FC } from "react";
import "./style.scss";

export const PasswordShowHide: FC<any> = (props) => {
  const { field, form, placeholder, autoFocus, autoComplete } = props;
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const hasError = form.touched[field.name] && form.errors[field.name];

  return (
    <div className="input-container">
      <input
        type={showHidePassword ? "text" : "password"}
        {...field}
        className={hasError ? "input-error input-field" : "input-field"}
        placeholder={placeholder}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
      />
      {field.value && (
        <i onClick={() => changeShowHidePassword(!showHidePassword)}>
          {showHidePassword ? "Hide" : "Show"}
        </i>
      )}
    </div>
  );
};
