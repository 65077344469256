import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "shared/ui/form-field";
import Button from "shared/ui/button";
import { useTranslation } from "react-i18next";
import { useSchema } from "helpers/functions";

const Schema = (t: any) =>
  Yup.object().shape({
    companyName: Yup.string().required(t("companyNameRequired")),
  });

type PropsType = {
  initialValues: { companyName: string };
  onSubmit: (companyName: string) => void;
};
export const CompanyNameForm: React.FC<PropsType> = ({
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation("onboarding-auth");
  const schema = useSchema(Schema);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit(values.companyName);
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty } = formik;
        return (
          <Form>
            <FormField
              id="companyName"
              label={t("companyName")}
              type="text"
              placeholder={t("enterCompanyName")}
              isInvalid={!!(errors.companyName && touched.companyName)}
            />

            <Button
              type="submit"
              className="registerNameBtn companyNameBtn"
              variant={"dark"}
              disabled={!isValid}
            >
              {t("continue")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
