import React from "react";
import "./style.scss";

const BargainingAlert: React.FC<{
  variant: "info" | "danger" | "warning";
  text: string;
}> = ({ variant = "info", text }) => {
  return (
    <div className={"bargaining-alert" + " " + variant}>
      <span className={variant}>{text}</span>
    </div>
  );
};

export default BargainingAlert;
