import React, { FC, useState } from "react";
import "./style.scss";
import Armenian from "../../../../assets/currencies/AMD.svg";
import English from "../../../../assets/currencies/USD.svg";
import Russian from "../../../../assets/currencies/RUB.svg";
import CheckIcon from "../../../../assets/icon-check.svg";
import { useTranslation } from "react-i18next";

type PropType = {
  language: "Armenian" | "English" | "Russian";
  onChange?: () => void;
  onLangClick: () => void;
  isChecked: boolean;
  languageImage: "Armenian" | "English" | "Russian";
};

export const LanguageRow: FC<PropType> = ({
  language,
  languageImage,
  onChange,
  onLangClick,
  isChecked,
}) => {
  const languageImages = {
    Armenian,
    English,
    Russian,
  };

  return (
    <div onClick={onLangClick} onChange={onChange} className="language-row">
      <div>
        <img
          src={languageImages[languageImage]}
          alt={`${languageImages}-icon`}
        />
        <p>{language}</p>
      </div>

      {isChecked == true && <img src={CheckIcon} alt="CheckIcon" />}
    </div>
  );
};
