import React, { FC } from "react";
import "./style.scss";
import Ascending from "../../../../assets/SortAscendingIcon.svg";
import Descending from "../../../../assets/SortDescendingIcon.svg";

type PropsType = {
  name: string;
  config: string;
  img: string;
};
const SortConfig: FC<PropsType> = ({ name, config }) => {
  return (
    <>
      <p>{name}</p>
      <div className="sort-config-option">
        <p>{config.charAt(0).toUpperCase() + config.slice(1)}</p>

        <img
          src={config === "Ascending" ? Ascending : Descending}
          alt="Sort Icon"
        />
      </div>
    </>
  );
};

export default SortConfig;
