import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import Close from "../../../assets/Close.svg";
import Clock from "../../../assets/Clock.svg";
import EmptySearch from "../../../assets/EmptySearch.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import Loading from "widgets/loading";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";

export const HistorySearchPage = () => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string>("");
  const [history, setHistory] = useState<string[]>([]);
  const [activeBtn, setActiveBtn] = useState("Exchange");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [debouncedValue] = useDebounce(inputValue, 700);

  const { isLoading, searchAssets, search, clearSearchAssets } =
    useStore(marketStore);

  const handleNavigate = () => {
    clearSearchAssets();
    navigate(PATH_LIST.history);
  };
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };
  useEffect(() => {
    if (inputValue && inputValue.length > 1) {
      // setHistory((prevHistory) => {
      //   const newHistory = [inputValue, ...prevHistory];
      //   if (newHistory.length > 5) {
      //     newHistory.pop();
      //   }
      //   return newHistory;
      // });
      // search(inputValue);
    }
  }, [debouncedValue]);

  const handleHistoryClick = (search: string) => {
    setInputValue(search);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="history-search">
      <div className="history-search-header">
        <img onClick={handleNavigate} src={GoBack} alt="Go Back" />
        <p>{t("search")}</p>
      </div>
      <div className="history-search-main">
        <form>
          <input
            ref={inputRef}
            onChange={handleInputChange}
            value={inputValue}
            type="text"
            autoFocus={true}
          />
          <img onClick={() => setInputValue("")} src={Close} alt="Close" />
        </form>
        {history.length > 0 && inputValue.length == 0 && (
          <div className="history">
            <div className="history-header">
              <span>{t("recentSearches")}</span>
              <p
                onClick={() => {
                  setHistory([]);
                }}
              >
                {t("clearAll")}
              </p>
            </div>
            {history.map((search, index) => (
              <div
                onClick={() => handleHistoryClick(search)}
                className="history-row"
                key={index}
              >
                <img src={Clock} alt="Clock" />
                <span>{search}</span>
              </div>
            ))}
          </div>
        )}
        {isLoading && <Loading />}
        <div className="search-query-container">
          <div className="query-type">
            <button
              className={activeBtn == "Exchange" ? "active-btn" : ""}
              onClick={() => setActiveBtn("Exchange")}
            >
              {t("exchange")}
            </button>
            <button
              className={activeBtn == "OverCounter" ? "active-btn" : ""}
              onClick={() => setActiveBtn("OverCounter")}
            >
              {t("overTheCounter")}
            </button>
          </div>
          <div className="empty-search">
            <img src={EmptySearch} alt="Empty-Search" />
            <p>{t("transactionsNotFound")}</p>
            <span>{t("tryDifferentQuery")}</span>
          </div>
          {/* {Object.entries(searchAssets).length > 0 && ( 
             {Object.entries(searchAssets)
              .sort((a, b) => a[1].length - b[1].length)
              .map(([key, list]) => (
                <React.Fragment key={key}>
                  <span className="history-part-title">
                    {AssetTypes[+key as unknown as AssetType]}
                  </span>
                  {list.map((asset: any) => (
                    <MarketAsset
                      key={asset.id}
                      asset={asset}
                      showGains={false}
                      gains={"0"}
                      gainsPercent={"0"}
                      onClick={() =>
                        navigateToAsset(asset.type, asset.symbol, navigate)
                      }
                    />
                  ))}
                </React.Fragment>
              ))}  )
              } */}
        </div>
      </div>
    </div>
  );
};
