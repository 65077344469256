import React, { FC } from "react";
import "./style.scss";
import BE1 from "assets/BackgroundElTop.png";
import BE2 from "assets/BackgroundElBottom.png";
import GoBackButton from "shared/ui/back-button";
import GoBack from "../../../../assets/GoBack.svg";
import OpenCameraButton from "widgets/open-camera-btn";

import PassportEx from "../../../../assets/PassportEx.svg";
import IdCardEx from "../../../../assets/IdCardEx.svg";
import { useTranslation } from "react-i18next";

type PropType = {
  onClose: any;
  onOpen: any;
  type: "idCard" | "idCardBack" | "passport";
};

const PersonalDocumentModal: FC<PropType> = ({ type, onClose, onOpen }) => {
  const { t } = useTranslation("onboarding-auth");

  return (
    <div className="modalDocType">
      <div className="goBack" onClick={onClose}>
        <div>
          <img src={GoBack} alt="Go Back" />
        </div>
        <p className="backText">{t("back")}</p>
      </div>

      <div
        className={`${
          type === "passport" ? "" : "examplePhotoBoxID"
        } examplePhotoBox`}
      >
        <img
          src={type === "passport" ? PassportEx : IdCardEx}
          alt="DocumentExample"
        />
      </div>

      <div className="textPDM">
        <p>
          {type === "passport"
            ? t("takePhotoOfPassport")
            : t("takePhotoOfIDCard")}
        </p>
        <span>
          {type === "passport"
            ? t("alignDocumentFrameCapture")
            : t("alignIDCardFrameCapture")}
        </span>
      </div>

      <OpenCameraButton onOpen={onOpen} />

      <img
        className="backgroundElement backgroundElementTop"
        src={BE1}
        alt="BackgroundElTop"
      />
      <img
        className="backgroundElement backgroundElementBottom"
        src={BE2}
        alt="BackgroundElBottom"
      />
    </div>
  );
};

export default PersonalDocumentModal;
