import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

type PropsType = {
  symbol: string;
};
const TV_TechicalAnalysisWidget: React.FC<PropsType> = ({ symbol }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      interval: "1M",
      width: "100%",
      isTransparent: true,
      height: 350,
      symbol,
      showIntervalTabs: false,
      displayMode: "single",
      locale: i18n.language,
      colorTheme: "dark",
    });

    const el = document!.getElementById("tradingview-widget-container-ta");
    if (el) el.appendChild(script);

    return () => {
      const el = document!.getElementById("tradingview-widget-container-ta");
      if (el) el.innerHTML = "";
    };
  }, []);

  return (
    <div id="tradingview-widget-container-ta" style={{ width: "100%" }}>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TV_TechicalAnalysisWidget;
