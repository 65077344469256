import React, { FC, useState } from "react";
import "./style.scss";
import Camera from "../../../../assets/icon-camera.svg";
import Image from "../../../../assets/icon-image.svg";
import File from "../../../../assets/icon-file.svg";
import { useTranslation } from "react-i18next";

type PropType = {
  onClose: () => void;
  onCameraClick: () => void;
  onGalleryClick: () => void;
  onFileUploadClick: () => void;
};

export const SendFileModal: FC<PropType> = ({
  onClose,
  onCameraClick,
  onGalleryClick,
  onFileUploadClick,
}) => {
  const { t } = useTranslation("app");
  return (
    <div
      className="bottom-sheet-modal-wrapper"
      style={{
        justifyContent: "center",
      }}
    >
      <div onClick={onClose} className="outlet-edit-profil-img"></div>
      <div className="edit-profil-img chat-send-file">
        <div onClick={onCameraClick} className="edit-profile-row">
          <img src={Camera} alt="icon" />
          <p>{t("takePhoto")}</p>
        </div>
        <div onClick={onGalleryClick} className="edit-profile-row">
          <img src={Image} alt="icon" />
          <p>{t("uploadPicture")}</p>
        </div>
        <div onClick={onFileUploadClick} className="edit-profile-row">
          <img src={File} alt="icon" />
          <p>{t("uploadFile")}</p>
        </div>
      </div>
    </div>
  );
};
