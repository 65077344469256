import { marketStore } from "entities/market";
import { Socket, io } from "socket.io-client";
import { createStore } from "zustand";

const baseUrl = process.env.REACT_APP_RT_SOCKET as string;

interface IOStore {
  socket: Socket;
  connected: boolean;
  list: Record<string, null | { bid: number; ask: number }>;
  chartSubscribes: Map<
    string,
    { handlers: Array<{ id: string; callback: any }> }
  >;

  listen: () => void;
  subscribe: (ticker: string) => void;
  unsubscribe: (ticker: string) => void;
}
const ioRTStore = createStore<IOStore>()((set, get) => ({
  socket: io(baseUrl, {
    autoConnect: true,
    transports: ["websocket"],
    reconnection: true,
    reconnectionDelay: 1500,
    reconnectionDelayMax: 2000,
  }),
  connected: false,
  list: {},
  chartSubscribes: new Map(),

  listen: () => {
    get()
      .socket.off("ticker")
      .on("ticker", (data) => {
        const { ticker, bid, ask } = JSON.parse(data);
        set({ list: { ...get().list, [ticker]: { bid, ask } } });
        const subscribes = get().chartSubscribes.get(ticker);
        subscribes?.handlers?.forEach((h) =>
          h.callback({
            time: +new Date(),
            open: +bid.toFixed(4),
            high: +(ask * 1.002).toFixed(4),
            low: +(bid * 0.999).toFixed(4),
            close: +ask.toFixed(4),
            volume: 0,
          })
        );
        marketStore
          .getState()
          .setAssetRealTimePrice(ticker, { bidPrice: bid, askPrice: ask });
      });
  },
  subscribe: (ticker: string) => {
    set({ list: { ...get().list, [ticker]: null } });
    get().socket.emit("subscribe", ticker);
  },
  unsubscribe: (ticker: string) => {
    set({ list: { ...get().list, [ticker]: null } });
    get().socket.emit("unsubscribe", ticker);
  },
}));

ioRTStore.getState().socket.on("connect", function () {
  console.log("RT_SOCKET: Connected");
  ioRTStore.setState({ connected: true });
  ioRTStore.getState().listen();
});
ioRTStore.getState().socket.on("disconnect", function () {
  console.log("RT_SOCKET: Disconnected");
  ioRTStore.setState({ connected: false });
  ioRTStore.getState().socket.off("ticker");
});

export { ioRTStore };
