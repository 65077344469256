import React, { FC, useState } from "react";
import "./style.scss";
import Close from "../../../../assets/Close.svg";
import { AddAccCurrencyRow } from "../add-acc-currency-checkbox";
import { dashboardStore, useCurrencies } from "entities/dashboard";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";

type PropType = {
  onClose: () => void;
};

export const AddAccountCurrency: FC<PropType> = ({ onClose }) => {
  const currencies = useCurrencies();
  const { user_accounts_show_config, updateAccountsShowConfig } =
    useStore(dashboardStore);
  const [config, setConfig] = useState(user_accounts_show_config);
  const { t } = useTranslation("app");

  const close = () => {
    // update should be request in api for save config in db
    updateAccountsShowConfig(config);
    onClose();
  };

  return (
    <div className="bottom-sheet-modal-wrapper">
      <div onClick={close} className="outlet-add-account-currency"></div>

      <div className="add-account-currency">
        <div className="add-account-currency-header">
          <p>{t("addOtherCurrency")}</p>
          <img onClick={close} src={Close} alt="Close" />
        </div>
        <AddAccCurrencyRow
          customClass="firstRowMargin"
          isChecked
          htmlForId="amd"
          currency="AMD"
        />
        {currencies
          .filter((c) => c.code !== "AMD")
          .map((c) => (
            <AddAccCurrencyRow
              key={c.id}
              onChange={() => {
                setConfig({ ...config, [c.code]: !config[c.code] });
              }}
              isChecked={config[c.code]}
              htmlForId={c.code}
              currency={c.code}
            />
          ))}
      </div>
    </div>
  );
};
// const getUserAccount = useCallback(() => {
//   return Object.entries(user_accounts_show_config)
//     .filter(([key, value]) => !!value)
//     .map(([key]) => {
//       const acc = user_accounts.find(
//         (acc) => acc.account_type === AccountType.DepositAccount
//       );
//       return {
//         symbol: key,
//         amount: acc?.cash?.find((a) => a.symbol === key)?.amount || 0,
//       };
//     });
// }, [user_accounts, user_accounts_show_config]);
