import React, { useState, useEffect } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import Button from "shared/ui/button";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { RegisterEnum, onboardModel } from "entities/onboard";
import { PATH_LIST } from "shared/lib/react-router";
import { useNavigate } from "react-router-dom";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { onboardStore } from "entities/onboard/model/onboardModel";
import Modal from "widgets/auth/modal";
import { toast } from "react-toastify";
import { sessionModel } from "entities/session";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const RegisterPhonePage: React.FC = () => {
  const registerType = onboardModel.useRegisterType();
  const { phoneNumber, setPhoneNumber, clear } = useStore(onboardStore);
  const [phone, setPhone] = useState<string>(phoneNumber || "");
  const isValid: boolean = isPhoneValid(phone);
  const { i18n } = useTranslation("errors");
  const { t } = useTranslation("onboarding-auth");
  const { t: y } = useTranslation("yup");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    if (!registerType) navigate(PATH_LIST.chooseAccType);
    setLink(PATH_LIST.chooseAccType);

    return () => setLink("");
  }, []);

  const onRegister = async () => {
    setIsSubmitting(true);
    const clearPhone = phone.replace(/\D/g, "");

    const resp = await sircapApi.onboarding.regPhone({
      phone: clearPhone,
      entity: registerType as RegisterEnum,
      language: i18n.language,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.UserIsExists:
          setShowModal(true);
          break;

        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        default:
          break;
      }
    } else {
      setPhoneNumber(clearPhone);
      const { id } = resp.data.data;
      onboardModel.onboardStore.getState().setId(id);

      navigate(PATH_LIST.verifyPhone);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="registerAccount">
      <GoBackButton link={PATH_LIST.chooseAccType} />
      <p>
        {registerType === RegisterEnum.Business
          ? t("registerBusinessAccount")
          : t("registerIndividualAccount")}
      </p>
      <span>{t("enterPhone")}</span>

      <form
        className="phoneForm"
        onSubmit={(e) => {
          e.preventDefault();

          if (isValid) {
            onRegister();
          }
        }}
      >
        <label>{t("phoneNumber")}</label>

        <PhoneInput
          defaultCountry={"am"}
          value={phone}
          onChange={(v: string) => setPhone(v)}
          inputProps={{ autoFocus: true, value: phone }}
        />

        {!isValid && <p className="validation">{y("phoneNotValid")}</p>}
        <Button
          className="phoneNumberBtn"
          children={t("getStarted")}
          variant="dark"
          disabled={!isValid || isSubmitting}
          type="submit"
        />
      </form>

      {showModal && (
        <Modal
          title={t("accExists")}
          text={t("accWhitPNExists")}
          btnText={t("logIn")}
          showSecondBtn={false}
          onClick={() => {
            // setShowModal(false);
            navigate(PATH_LIST.login);
          }}
          onHide={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
