import { DependencyList, useEffect } from "react";

export const useBlockScrollbar = (statement: boolean, deps: DependencyList) => {
  useEffect(() => {
    if (statement) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, deps);
};

export const useBlockScrollbarFromIndividuallyWrapped = (
  statement: boolean,
  wrapper: string,
  deps: DependencyList,
) => {
  useEffect(() => {
    if (statement) {
      document.getElementById(wrapper)?.classList.add("disable-scroll");
    } else {
      document.getElementById(wrapper)?.classList.remove("disable-scroll");
    }
  }, deps);
};
