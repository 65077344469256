import { IAsset } from "entities/market";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sircapApi } from "shared/api/sircap";
import { PATH_LIST } from "shared/lib/react-router";
import { G10_CURRENCIES } from "static/currencies";
import { AssetType, OperationType } from "static/types";
import * as Yup from "yup";
import numeral from "numeral";

numeral.register("locale", "custom", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T",
  },
  ordinal: function (number) {
    var b = number % 10;
    return ~~((number % 100) / 10) === 1
      ? "th"
      : b === 1
      ? "st"
      : b === 2
      ? "nd"
      : b === 3
      ? "rd"
      : "th";
  },
  currency: {
    symbol: "$",
  },
});
numeral.locale("custom");

export const b64toBlob = (
  srcData: string,
  contentType = "",
  sliceSize = 512
) => {
  try {
    const base64Data = srcData.split(",")[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  } catch (error) {}
};

export const blobToFile = (blob: Blob, filename: string): File => {
  return new File([blob], filename, { type: blob.type });
};

export const useSchema = (Schema: (t: any) => any) => {
  const { i18n, t } = useTranslation("yup");
  const schema = useMemo(() => Schema(t), [i18n.language]);

  return schema;
};

export function formatPrice(x: number, fixed: number = 2) {
  return x
    ? x.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1,")
    : "";
}

export function formatMarketCap(x: number) {
  return x ? numeral(x).format("0.0a") : "";
}

export const getAvatarUrl = (file: string) => {
  return `${sircapApi.baseUrl}/uploads/avatar/${file}`;
};

export const getChatFileUrl = (room: number, file: string) => {
  return `${sircapApi.baseUrl}/uploads/chat/${room}/${file}`;
};

export const getContractUrl = (file: string) => {
  return `${sircapApi.baseUrl}/documents/contracts/${file}`;
};

export const getStoryImgUrl = (fileName: any) => {
  return `${sircapApi.baseUrl}/gallery/${fileName}`;
};

export const getAssetIconUrl = (symbol: string) => {
  return `https://financialmodelingprep.com/image-stock/${symbol}.png`;
};

export const showNotification = (text: string) => {
  toast.info(text, {
    className: "notification-modal",
    bodyClassName: "notification-modal-text",
    position: "bottom-center",
    icon: false,
    hideProgressBar: true,
  });
};

export const navigateToAsset = (
  type: number,
  asset_id: any,
  navigate: any,
  operation: string = ""
) => {
  switch (type) {
    case AssetType.Stock:
    case AssetType.Fond:
      navigate(PATH_LIST.stock(asset_id));
      break;
    case AssetType.ETF:
      navigate(PATH_LIST.stock(asset_id) + "?isEtf=true");
      break;

    case AssetType.Currency:
      navigate(PATH_LIST.currency(asset_id));
      break;

    case AssetType.Bond:
      navigate(PATH_LIST.bond(asset_id));
      break;

    case AssetType.EuroBond:
      navigate(PATH_LIST.bond(asset_id) + "?isEuro=true");
      break;

    case AssetType.StructuredProduct:
      navigate(PATH_LIST.strategyInvest(asset_id, operation) + "");
      break;

    default:
      break;
  }
};

export const getCommission = (
  user_tariff: any,
  type: number,
  symbol: string,
  amount: number,
  operation: string,
  minimal_commission: number,
  with_sirius: boolean,
  country: string = "",
  is_etf: boolean = false
) => {
  if (!user_tariff) return [0, 0, 0];
  if (with_sirius) return [0, 0, 0];

  const tariff = user_tariff;

  let commision: number = 0;
  let additional: number = 0;
  switch (type) {
    case AssetType.ETF:
    case AssetType.Stock:
      commision = tariff.exBonds;
      // additional = 1;
      break;
    case AssetType.Currency:
      if (G10_CURRENCIES.includes(symbol)) {
        commision = tariff.g10Currencies;
      } else {
        commision = tariff.regCurrencies;
      }
      break;
    case AssetType.Fond:
      commision = tariff.exBonds;
      break;
    case AssetType.Bond:
      if (country === "ARM") {
        commision = tariff.govBonds;
      } else {
        commision = tariff.exBonds;
        // additional = 1;
      }
      break;
    case AssetType.EuroBond:
      commision = tariff.otc_euroBonds;
      break;
    case AssetType.ADR_GDR:
      commision = tariff.otc_ADRs;
      break;
    case AssetType.StructuredProduct:
      if (is_etf) {
        commision = tariff.indexStrategies;
      } else {
        commision = tariff.individualStrategy;
      }
      break;
    default:
      break;
  }

  let totalCommission = +((amount / 100) * commision + additional).toFixed(2);
  if (minimal_commission) {
    totalCommission =
      totalCommission < minimal_commission
        ? minimal_commission
        : totalCommission;
  }
  // let totalAmount: number;
  // if (operation === OperationType.Sell) {
  //   totalAmount = amount - totalCommission;
  // } else {
  //   totalAmount = amount + totalCommission;
  // }
  // return [commision, additional, totalAmount];
  const totalAmount =
    operation === OperationType.Sell ? totalCommission * -1 : totalCommission;
  return [commision, additional, totalAmount];
};

export const getRatePrice = (all_rates: any[], rate: any) => {
  const rateExists = all_rates.find(
    (r) =>
      r.price_asset.code === rate.price_asset &&
      r.trade_asset.code === rate.trade_asset
  );

  let price = null;
  if (rateExists) {
    price = +rateExists.price.toFixed(4);
  } else {
    const rateSwapExists = all_rates.find(
      (r) =>
        r.price_asset.code === rate.trade_asset &&
        r.trade_asset.code === rate.price_asset
    );
    if (rateSwapExists) {
      price = +(1 / rateSwapExists.price).toFixed(4);
    }
  }

  return price;
};

export const convertTo =
  (all_rates: any[], currency: string) =>
  (item: { symbol: string; amount: number }) => {
    const { symbol, amount } = item;

    if (symbol === currency) {
      return amount;
    } else {
      const price = getRatePrice(all_rates, {
        trade_asset: currency,
        price_asset: symbol,
      });
      // console.log(symbol, currency, price);
      if (price) {
        return amount / price;
      } else {
        return 0;
      }
    }
  };

export const getCurrSymbol = (code: string) => {
  const symbols: Record<string, string> = {
    AMD: "֏",
    USD: "$",
    EUR: "€",
    RUB: "₽",
    AED: "د.إ",
    CNY: "¥",
    GBP: "£",
  };

  return symbols[code];
};

export const isBondMatured = (bond?: IAsset | null) => {
  if (bond && bond.data) {
    const format = "DD.MM.YYYY";
    const maturityDate = moment(bond.data.maturity, format);
    if (maturityDate.isBefore(moment().add(7, "day"))) {
      return true;
    }
  }
  return false;
};

export type MultiLangValueType = Record<string, string>;

export const getMLValue = (field: MultiLangValueType, locale = "en") => {
  return field[locale] || field[Object.keys(field)[0]] || "";
};

export const getFromLotsText = (lots: number) => {
  return lots === 1 ? `1 lot` : `${lots} lots`;
};

export const getStrategyImgUrl = (strategy: string, fileName: any) => {
  return `${sircapApi.baseUrl}/gallery/strategy/${strategy}/${fileName}`;
};
