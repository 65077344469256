import React, { useEffect, useState } from "react";
import "./style.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Home from "../../../assets/Home.svg";
import HomeActive from "../../../assets/HomeActive.svg";
import History from "../../../assets/History.svg";
import HistoryActive from "../../../assets/HistoryActive.svg";
import WhatToBuy from "../../../assets/WhatToBuy.svg";
import WhatToBuyActive from "../../../assets/WhatToBuyActive.svg";
import Market from "../../../assets/Market.svg";
import MarketActive from "../../../assets/MarketActive.svg";
import Chat from "../../../assets/Chat.svg";
import ChatActive from "../../../assets/ChatActive.svg";
import Account from "../../../assets/Account.svg";
import AccountActive from "../../../assets/AccountActive.svg";
import LogoApp from "../../../assets/LogoApp.svg";
import MaleUser from "../../../assets/MaleUser.svg";
import FemaleUser from "../../../assets/FemaleUser.svg";
import BusinessUser from "../../../assets/BusinessUser.svg";

import { PATH_LIST, PATH_PAGE } from "shared/lib/react-router";
import Modal from "widgets/auth/modal";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { marketStore } from "entities/market";
import { dashboardStore } from "entities/dashboard";
import { ContactsModal } from "pages/app/components/contacts-modal";
import NotificationIcon from "pages/app/components/notification-icon";
import { generalSocketStore } from "services/general.socket";
import { useTranslation } from "react-i18next";
import { sessionStore } from "entities/session/model/sessionModel";
import { getAvatarUrl } from "helpers/functions";
import { EntityType } from "static/types";

const deviceId = "";

export const AppLayout: React.FC = () => {
  const { user, entity, updateUserAvatar } = useStore(sessionStore);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("app");
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const { setPrevPathDeal } = useStore(dashboardStore);

  useEffect(() => {
    if (user?.language) {
      if (user.language !== i18n.language) {
        i18n.changeLanguage(user.language);
        const html = document.getElementsByTagName("html");
        html[0].setAttribute("lang", user.language);
      }
    }
  }, [user?.language, i18n?.language]);

  useEffect(() => {
    setPreviousPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (previousPath?.includes("/buy") || previousPath?.includes("/sell")) {
      setPrevPathDeal(true);
    } else {
      setPrevPathDeal(false);
    }
  }, [previousPath]);

  const [activePage, setActivePage] = useState(
    localStorage.getItem("activePage") || "Home"
  );
  const [modalLogOut, setModalLogOut] = useState(false);
  const [modalContacts, setModalContacts] = useState(false);

  const {
    logout,
    requestNotificationToken,
    signContractModal,
    setSignContractModal,
    inactiveAccountModal,
    setInactiveAccountModal,
  } = useStore(sessionModel.sessionStore);
  const {
    loadTariffs,
    newAssetDeal,
    updateAssetDeal,
    loadExchanges,
    filterStockIndustries,
    filterSectors,
    filterCountries,
    checkCurrencyAsset,
    loadFavorites,
  } = useStore(marketStore);
  const { loadAllRates } = useStore(dashboardStore);

  const { connected, connect, disconnect, socket, addRoom, checkChat } =
    useStore(generalSocketStore);

  useEffect(() => {
    requestNotificationToken();
  }, []);

  useEffect(() => {
    loadFavorites();
    filterStockIndustries();
    filterCountries();
    filterSectors();
    loadExchanges();
    loadTariffs();
    checkChat();
    connect();

    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (connected) {
      socket
        .off("chat-new-message")
        .off("new-order")
        .off("update-order")
        .off("update-rates")
        .off("user-accounts");
      socket
        .on("user-accounts", ({ accounts }) =>
          dashboardStore.setState({ user_deposit_accounts: accounts[0] })
        )
        .on("chat-new-message", (r) => addRoom(r.room))
        .on("new-order", ({ order }) => newAssetDeal(order))
        .on("update-order", ({ order }) => updateAssetDeal(order))
        .on("update-rates", () => {
          loadAllRates();
          checkCurrencyAsset();
        });
    }
  }, [connected]);

  useEffect(() => {
    localStorage.setItem("activePage", activePage);
  }, [activePage]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes(PATH_PAGE.app.home.root)) {
      setActivePage("Home");
    } else if (currentPath.includes(PATH_PAGE.app.whatToBuy.root)) {
      setActivePage("WhatToBuy");
    } else if (currentPath.includes(PATH_PAGE.app.market.root)) {
      setActivePage("Market");
    } else if (currentPath.includes(PATH_PAGE.app.chat.root)) {
      setActivePage("Chat");
    } else if (currentPath.includes(PATH_PAGE.app.account.root)) {
      setActivePage("Account");
    }
  }, [location]);

  const handlePageChange = (pageName: string, path: string) => {
    setActivePage(pageName);
    navigate(path);
  };

  const renderHeaderItem = () => {
    const pageName = activePage;

    const getUserDefaultImage = () => {
      if (user) {
        if (entity === EntityType.Individual) {
          const gender = user.profileInfo.gender;
          if (gender === "female") {
            return FemaleUser;
          }
        } else {
          return BusinessUser;
        }
      }

      return MaleUser;
    };

    return (
      <>
        {activePage === "Home" ? (
          // <img src={LogoApp} alt="Logo" />
          <div className="layout-home-header">
            <img src={LogoApp} alt="Logo" />
            {user?.avatar ? (
              <img
                onClick={() => {
                  navigate(PATH_LIST.account);
                }}
                className="layout-home-header-profile-img"
                src={getAvatarUrl(user.avatar)}
                alt="User"
              />
            ) : (
              <img
                onClick={() => {
                  navigate(PATH_LIST.account);
                }}
                className="layout-home-header-profile-img"
                src={getUserDefaultImage()}
                alt="User icon"
              />
            )}
          </div>
        ) : activePage === "WhatToBuy" ? (
          <p>{t("whatToBuy")}</p>
        ) : activePage === "Account" ? (
          <div className="account-nav">
            <p>{t("account")}</p>
            <span onClick={() => setModalLogOut(true)}>{t("logOut")}</span>
          </div>
        ) : activePage === "Chat" ? (
          <div className="account-nav chat-nav">
            <p>{t("chat")}</p>
            <span onClick={() => setModalContacts(true)}>{t("contacts")}</span>
          </div>
        ) : (
          <p>{t(pageName.toLocaleLowerCase())}</p>
        )}
      </>
    );
  };

  const RenderNavItem = (
    pageName: string,
    whatToBuy: string,
    image: string,
    activeImage: string,
    path: string
  ) => {
    // const [isImageLoaded, setIsImageLoaded] = useState(false);
    // useEffect(() => {
    //   console.log("prev" + isImageLoaded);
    // }, [isImageLoaded]);

    const isActive = activePage === pageName;
    return (
      <div
        className={
          isActive
            ? "active-page"
            : pageName == "Chat"
            ? "chat__page_container"
            : ""
        }
        onClick={() => handlePageChange(pageName, path)}
      >
        <img
          src={isActive ? activeImage : image}
          alt={pageName}
          // onLoad={() => {
          //   setIsImageLoaded(true);
          //   console.log(isImageLoaded + pageName);
          //   console.log("- - -");
          // }}
        />
        {/* && isImageLoaded */}
        {/* {isActive && !whatToBuy ? (
          <span>{activePage}</span>
        ) : isActive && whatToBuy ? (
          <span>{whatToBuy}</span>
        ) : (
          ""
        )} */}
        <span
          style={{
            color: isActive ? "#f8009e" : "#F9F9F9",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "14px",
            textAlign: "center",
          }}
        >
          {whatToBuy ? whatToBuy : pageName}
        </span>

        {!isActive && pageName == "Chat" && (
          <NotificationIcon navIcon type="all" />
        )}
      </div>
    );
  };

  useBlockScrollbar(modalLogOut, [modalLogOut]);

  const onLogout = () => {
    logout();
    setModalLogOut(false);
    navigate(PATH_LIST.login);
  };

  return (
    <div className={"app-layout"}>
      <div className="app-header">{renderHeaderItem()}</div>
      <Outlet />
      {modalLogOut === true && (
        <Modal
          customModal="modal-log-out"
          onClick={onLogout}
          onClickSecondBtn={() => setModalLogOut(false)}
          onHide={() => setModalLogOut(false)}
          closeIcon={false}
          title={t("logOut")}
          text={t("sureToԼogOut")}
          btnText={t("logOut")}
          btnText2={t("cancel")}
          showSecondBtn={true}
          customOutlet="customOutlet"
        />
      )}
      {modalContacts === true && (
        <ContactsModal onClose={() => setModalContacts(false)} />
      )}
      {inactiveAccountModal && (
        <Modal
          title={t("accountIsNotActive")}
          text={t("pleaseWaitUntilActivate")}
          btnText={t("ok")}
          onClick={() => setInactiveAccountModal(false)}
          showSecondBtn={false}
          onHide={() => setInactiveAccountModal(false)}
        />
      )}
      {signContractModal && (
        <Modal
          title={t("contractIsNotSigned")}
          text={t("signToMakeTransactions")}
          btnText={t("signContract")}
          onClick={() => {
            navigate(PATH_LIST.signUserContract);
            setSignContractModal(false);
          }}
          showSecondBtn={false}
          onHide={() => setSignContractModal(false)}
          closeIcon={false}
        />
      )}

      <nav className="app-navbar">
        {RenderNavItem("Home", "", Home, HomeActive, PATH_LIST.home)}
        {RenderNavItem(
          "Market",
          "",
          WhatToBuy,
          WhatToBuyActive,
          PATH_LIST.market
        )}
        {RenderNavItem(
          "WhatToBuy",
          "What to buy",
          Market,
          MarketActive,
          PATH_LIST.whatToBuy
        )}
        {RenderNavItem("Chat", "", Chat, ChatActive, PATH_LIST.chat)}
        {/* {RenderNavItem(
          "History",
          "",
          History,
          HistoryActive,
          PATH_LIST.account
        )} */}
      </nav>
    </div>
  );
};
