import React from "react";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.css";
import "./style.scss";
import { onboardModel } from "entities/onboard";
import { RegisterEnum } from "entities/onboard/model/onboardModel";
import { useTranslation } from "react-i18next";

type PropsType = {
  percent: number;
  mobileInfo: string;
};
export const ProgressLine: React.FC<PropsType> = ({ percent, mobileInfo }) => {
  const registerType = onboardModel.useRegisterType();
  const { t } = useTranslation("onboarding-auth");

  return (
    <div
      className={
        registerType === RegisterEnum.Individual
          ? "progress progressLarg"
          : "progress"
      }
    >
      <div className="progressText">
        <p className="activeText">{t("createAccount")}</p>
        {registerType === RegisterEnum.Individual && (
          <p className={percent >= 42 ? "activeText" : "passiveText"}>
            {t("profileInfo")}
          </p>
        )}
        <p className={percent >= 86 ? "activeText" : "passiveText"}>
          {registerType === RegisterEnum.Individual
            ? t("verifyAccount")
            : t("signContract")}
        </p>
      </div>
      <Progress.Line
        className="progress-line"
        percent={percent}
        strokeColor="#F8009E"
        status="active"
        showInfo={false}
      />
      <Progress.Circle
        className="progress-circle"
        percent={percent}
        strokeColor="#F8009E"
        status="active"
        showInfo={false}
      />
      <span className="mobileInfo">{mobileInfo}</span>
    </div>
  );
};
