import React, { FC } from "react";
import "./style.scss";

type PropsType = {
  name: string;
  code: string;
  image: string;
};

const ExchangeSource: FC<PropsType> = ({ name, code, image }) => {
  return (
    <div className="exchange-source-container">
      <div className="source-name-wrapper">
        <div className="source-name">{name}</div>
      </div>
      <div className="exchange-source">
        <p className={!image ? "imgLess" : ""}>{code}</p>
        {image && <img src={image} alt="img" />}
      </div>
    </div>
  );
};

export default ExchangeSource;
