import React, { useState, useRef, useEffect } from "react";
import "./style.scss";
import MaleUser from "../../../assets/MaleUser.svg";
import FemaleUser from "../../../assets/FemaleUser.svg";
import BusinessUser from "../../../assets/BusinessUser.svg";
import EditImg from "../../../assets/EditImg.svg";
import UserIcon from "../../../assets/icon-user.svg";
import PasswordIcon from "../../../assets/icon-unlock.svg";
import PromoCode from "../../../assets/icon-promocode.svg";
import LanguageIcon from "../../../assets/icon-globe.svg";
import WithdrawalIcon from "../../../assets/icon-arrow-up-circle.svg";
import DepositIcon from "../../../assets/icon-arrow-down-circle.svg";
import HistoryIcon from "../../../assets/icon-clock.svg";
import TextDocIcon from "../../../assets/icon-file.svg";
import IconCamera from "../../../assets/IconCamera.png";
import { UserStatus } from "../components/account-status";
import { AccountOptionCard } from "../components/account-option-card";
import { EditProfilePhoto } from "../components/edit-profile-photo";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { LanguageModal } from "../components/language-modal";
import { useStore } from "zustand";
import { blobToFile, getAvatarUrl, showNotification } from "helpers/functions";
import { accountStore } from "entities/account";
import { useTranslation } from "react-i18next";
import TakePhotoModal from "pages/onboarding/components/take-photo-modal";
import { DocumentType, EntityType } from "static/types";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { sessionStore, useUserName } from "entities/session/model/sessionModel";
import { sessionModel } from "entities/session";
import { PromoCodeModal } from "../components/promo-code-modal";

export const AccountPage = () => {
  const { inactiveAccountModal } = useStore(sessionModel.sessionStore);
  const [editImgModal, setEditImgModal] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [promoCodeModal, setPromoCodeModal] = useState(false);
  const [video, setVideo] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { user, entity, updateUserAvatar } = useStore(sessionStore);
  const username = useUserName();

  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("app");
  const { uploadAvatar, removeAvatar } = useStore(accountStore);

  const openFileSelector = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      e.target.value = "";
      uploadAvatar(file, err, updateUserAvatar);
    }
  };
  const closeVideo = () => {
    setVideo(false);

    if (videoRef.current) {
      const mediaStream = videoRef.current.srcObject as MediaStream;
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }

      videoRef.current.srcObject = null;
    }
  };

  useBlockScrollbar(video || editImgModal || languageModal || promoCodeModal, [
    video,
    editImgModal,
    languageModal,
    promoCodeModal,
  ]);

  const navigate = useNavigate();

  const navigatePersonalInfo = () => {
    navigate(PATH_LIST.personal);
  };
  const navigateSecurity = () => {
    navigate(PATH_LIST.security);
  };
  const navigateDeposit = () => {
    navigate(PATH_LIST.deposit);
  };
  const navigateWithdrawal = () => {
    navigate(PATH_LIST.withdrawal);
  };
  const navigateHistory = () => {
    navigate(PATH_LIST.history);
  };
  const navigateBrokerage = () => {
    navigate(PATH_LIST.brokerageReport);
  };
  const navigateTax = () => {
    navigate(PATH_LIST.taxReport);
  };

  const onUploadAvatar = (image: any) => {
    uploadAvatar(blobToFile(image, `AVATAR.jpg`), err, updateUserAvatar);
  };

  const getUserDefaultImage = () => {
    if (user) {
      if (entity === EntityType.Individual) {
        const gender = user.profileInfo.gender;
        if (gender === "female") {
          return FemaleUser;
        }
      } else {
        return BusinessUser;
      }
    }

    return MaleUser;
  };

  return (
    <div className="account">
      <div className="user-info">
        <div onClick={() => setEditImgModal(true)} className="user-image">
          {user?.avatar ? (
            <img
              className="profile-img profile-img-active"
              src={getAvatarUrl(user.avatar)}
              alt="User"
            />
          ) : (
            <img
              className="profile-img"
              src={getUserDefaultImage()}
              alt="User icon"
            />
          )}
          <img className="editImg" src={EditImg} alt="Edit Image" />
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
        <p>{username}</p>
        <UserStatus status={user?.tariff} />
      </div>
      {inactiveAccountModal && (
        <p className="acc-verification-status">{t("yourAccIsNotVerified")}</p>
      )}
      <div className="account-options">
        <p className="part-title">{t("account")}</p>

        <AccountOptionCard
          onClick={() => navigatePersonalInfo()}
          image={UserIcon}
          option={t("personalInfo")}
        />
        <AccountOptionCard
          onClick={() => navigate(PATH_LIST.signUserContract)}
          image={TextDocIcon}
          option={t("contract")}
        />

        <AccountOptionCard
          onClick={() => setPromoCodeModal(true)}
          image={PromoCode}
          option={t("promoCode")}
        />

        <AccountOptionCard
          onClick={() => navigateSecurity()}
          image={PasswordIcon}
          option={t("security")}
        />

        <AccountOptionCard
          onClick={() => navigate(PATH_LIST.tariffs)}
          image={TextDocIcon}
          option={t("myTariffs")}
        />

        <AccountOptionCard
          onClick={() => setLanguageModal(true)}
          image={LanguageIcon}
          option={t("language")}
        />

        <p className="part-title">{t("deposit&Withdrawal")}</p>

        <AccountOptionCard
          onClick={() => navigateDeposit()}
          image={DepositIcon}
          option={t("deposit")}
        />
        <AccountOptionCard
          onClick={() => navigateWithdrawal()}
          image={WithdrawalIcon}
          option={t("withdrawal")}
        />

        <p className="part-title">{t("reports")}</p>
        <AccountOptionCard
          onClick={() => navigateHistory()}
          image={HistoryIcon}
          option={t("history")}
        />
        <AccountOptionCard
          onClick={() => navigateBrokerage()}
          image={TextDocIcon}
          option={t("brokerageReports")}
        />
        {/* <AccountOptionCard
          onClick={() => navigateTax()}
          image={TextDocIcon}
          option={t("taxReports")}
        /> */}
      </div>

      {editImgModal === true && (
        <EditProfilePhoto
          onGalleryClick={() => {
            openFileSelector();
            setEditImgModal(false);
          }}
          onCameraClick={() => {
            setVideo(true);
            setEditImgModal(false);
          }}
          onRemoveClick={() => {
            removeAvatar(err, updateUserAvatar);
            setEditImgModal(false);
          }}
          remove={!!user?.avatar}
          onClose={() => setEditImgModal(false)}
        />
      )}
      {languageModal === true && (
        <LanguageModal
          onClose={() => {
            setTimeout(() => {
              setLanguageModal(false);
            }, 200);
          }}
        />
      )}

      {promoCodeModal === true && (
        <PromoCodeModal
          onClose={() => {
            setTimeout(() => {
              setPromoCodeModal(false);
            }, 200);
          }}
        />
      )}
      {video === true && (
        <TakePhotoModal
          className="acc-take-photo"
          onOpen={(image: string) => {
            onUploadAvatar(image);
            closeVideo();
          }}
          onClose={closeVideo}
          type={DocumentType.Avatar}
        ></TakePhotoModal>
      )}
    </div>
  );
};
