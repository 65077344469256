import React, { FC, useRef, useState } from "react";

type PropsType = {
  children: any;
  className: string;
};

const HorizontalScroll: FC<PropsType> = ({ children, className }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setDragging(true);
    setStartX(e.pageX - containerRef.current!.offsetLeft);
    setScrollLeft(containerRef.current!.scrollLeft);
  };
  const handleMouseUp = () => {
    setDragging(false);
    containerRef.current!.style.transition = "transform 0.8s ease";
  };
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!dragging) return;
    const x = e.pageX - containerRef.current!.offsetLeft;
    const walk = (x - startX) * 1.7;
    containerRef.current!.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseMove={handleMouseMove}
      className={className}
    >
      {children}
    </div>
  );
};

export default HorizontalScroll;
