import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import { CreateFilterModal } from "../components/create-filter-modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { MarketAsset } from "../components/market-asset";
import { navigateToAsset } from "helpers/functions";
import { AssetType } from "static/types";
import { useStore } from "zustand";
import { FilterType, IStocksFilterData, marketStore } from "entities/market";
import { SortModal } from "../components/sort-modal";
import { SortData } from "static/data";
import SortConfig from "../components/sort";
import { useTranslation } from "react-i18next";

const AssetsContainer: React.FC<{ type: FilterType }> = ({ type }) => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const limit = 50;

  const {
    isLoading,
    filterAssets,
    filterAssetsCount,
    loadAssetsByFilters,
    loadBondsByFilters,
    filterSortConfigs,
  } = useStore(marketStore);

  const onLoadAssets = useCallback(async () => {
    if (type === "bond") {
      await loadBondsByFilters({
        page,
        limit,
        ...(filterSortConfigs
          ? {
              sortBy: filterSortConfigs.sortBy,
              orderBy: filterSortConfigs.orderBy,
            }
          : {}),
      });
    } else {
      await loadAssetsByFilters({
        page,
        limit,
        ...(filterSortConfigs
          ? {
              sortBy: filterSortConfigs.sortBy,
              orderBy: filterSortConfigs.orderBy,
            }
          : {}),
      });
    }
  }, [page, filterSortConfigs]);

  useEffect(() => {
    setPage(0);
  }, [type]);

  useEffect(() => {
    onLoadAssets();
  }, [onLoadAssets, page]);

  if (isLoading) {
    return <p className="assets-loading">{t("loading")}</p>;
  }

  if (!filterAssets.length) {
    return (
      <div className="not-found-coming-soon">
        <p>{t("notFound")}</p>
      </div>
    );
  }

  const currItemsLength = (page + 1) * limit;

  return (
    <div style={{ width: "100%" }}>
      <InfiniteScroll
        dataLength={currItemsLength}
        next={() => {
          setPage(page + 1);
        }}
        hasMore={currItemsLength < filterAssetsCount}
        scrollThreshold={0.9}
        scrollableTarget={"scrollable-container"}
        loader={<p className="assets-loading">{t("loading")}</p>}
      >
        {filterAssets.map((asset) => (
          <MarketAsset
            key={asset.id}
            onClick={() => {
              navigateToAsset(asset.type, asset.id, navigate);
            }}
            asset={asset}
            gains={asset.change}
            gainsPercent={asset.changesPercentage}
            showGains={[
              AssetType.Stock,
              AssetType.ETF,
              AssetType.Fond,
            ].includes(asset.type)}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export const FilterReviewPage = () => {
  const { t } = useTranslation("app");
  const [modal, setModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const navigate = useNavigate();

  const {
    isLoading,
    filterAssetsCount,
    createFilter,
    updateFilter,
    createdFilter,
    filterSortConfigs,
    setFilterSortConfigs,
  } = useStore(marketStore);

  const handleNavigate = () => {
    navigate(-1);
  };

  const onCreate = async (name: string) => {
    await createFilter({
      name,
      type: createdFilter?.type || FilterType.Stock,
      data: createdFilter?.data as IStocksFilterData,
    });

    navigate(PATH_LIST.filter);
  };

  const onSave = async () => {
    if (createdFilter && createdFilter.id) {
      await updateFilter(createdFilter.id, {
        name: createdFilter.name!,
        type: createdFilter.type!,
        data: createdFilter.data!,
      });

      navigate(PATH_LIST.filter);
    } else {
      console.error("Invalid filter ID");
    }
  };

  const SortBtn = () => {
    return (
      <Button
        onClick={() => setSortModal(true)}
        children={
          filterSortConfigs?.sortBy ? (
            <SortConfig
              name={t(
                SortData[
                  createdFilter?.type === FilterType.Bond
                    ? AssetType.Bond
                    : AssetType.Stock
                ].find((a) => a.by === filterSortConfigs.sortBy)?.text || ""
              )}
              config={
                filterSortConfigs.orderBy === "asc"
                  ? t("ascending")
                  : t("descending")
              }
              img={
                filterSortConfigs.orderBy === "asc" ? "Ascending" : "Descending"
              }
            />
          ) : (
            t("sort")
          )
        }
        className={
          filterSortConfigs?.sortBy
            ? "market-sort-btn sorted-configs"
            : "market-sort-btn sorted-configs-empty"
        }
        variant="dark"
      />
    );
  };

  return (
    <div className="filter-review">
      <div className="filter-review-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p style={{ width: "60%" }}>
          {createdFilter?.id ? createdFilter?.name : t("newFilter")}
        </p>
      </div>
      {!isLoading && (
        <p className="result-quantity">
          {filterAssetsCount} {t("results")}
        </p>
      )}
      <div className="filter-review-main" id="scrollable-container">
        {/* @ts-ignore */}
        {createdFilter && <AssetsContainer type={createdFilter.type} />}
      </div>

      {createdFilter?.id && !createdFilter?.user_id ? (
        <div className="btn-wrapper">{SortBtn()}</div>
      ) : createdFilter?.id ? (
        <div className="btn-wrapper" style={{ gap: 12 }}>
          {SortBtn()}
          <Button
            type="submit"
            onClick={() => {
              if (createdFilter?.changed) {
                onSave();
              } else {
                if (createdFilter.type === FilterType.Stock) {
                  navigate(PATH_LIST.createStockFilter);
                } else {
                  navigate(PATH_LIST.createBondFilter);
                }
              }
            }}
            variant="dark"
            className="costumBtn filterReviewEditSaveBtn"
          >
            {t(createdFilter?.changed ? "save" : "edit")}
          </Button>
        </div>
      ) : (
        <div className="btn-wrapper">
          <Button
            type="submit"
            onClick={() => setModal(true)}
            variant="dark"
            className="costumBtn"
          >
            {t("create")}
          </Button>
        </div>
      )}
      {modal && (
        <CreateFilterModal
          title={t("createNewFilter")}
          onHide={() => setModal(false)}
          onSubmit={onCreate}
          text={t("saveFilterToFollow")}
          isLoading={isLoading}
        />
      )}
      {sortModal && (
        <SortModal
          style={{ justifyContent: "center" }}
          assetType={
            createdFilter?.type === FilterType.Bond
              ? AssetType.Bond
              : AssetType.Stock
          }
          sort={filterSortConfigs}
          onClose={() => setSortModal(false)}
          onSort={(v) => setFilterSortConfigs(v)}
        />
      )}
    </div>
  );
};
