import React, { FC, useState } from "react";
import "./style.scss";

type PropsType = {
  range: string | number;
  active: boolean;
  onClick: () => void;
};
const ChartRangeBtn: FC<PropsType> = ({ range, active, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={
        !active ? "chart-range-btn" : "chart-range-btn chart-range-btn-active"
      }
    >
      {range}
    </button>
  );
};

export default ChartRangeBtn;
