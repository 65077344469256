import React, { useEffect, useState } from "react";
import "./style.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ProgressLine } from "widgets/progress-line";
import { OnBoardPathList, PATH_LIST, PATH_PAGE } from "shared/lib/react-router";
import { onboardModel, ProfileInfoStep, RegisterStep } from "entities/onboard";
import CameraAccess from "./CameraAccess";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";

export const OnBoardingLayout = () => {
  const registerType = onboardModel.useRegisterType();
  const registerStep = onboardModel.useRegisterStep();
  const infoStep = onboardModel.useProfileInfoStep();
  const { id } = useStore(onboardModel.onboardStore);
  const [showProgress, setShowProgress] = useState(false);
  const [percent, setPercent] = useState(0);
  const [percentStep, setPercentStep] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const rootPath = PATH_PAGE.onboarding.root;
      if (
        location.pathname === `/${rootPath}` ||
        location.pathname === `/${rootPath}/`
      ) {
        navigate(PATH_LIST.chooseAccType);
      }
      // @ts-ignore
      const obj = Object.keys(OnBoardPathList[registerType]);
      setPercentStep(`${obj.indexOf(location.pathname)}/${obj.length - 1}`);
      // @ts-ignore
      const pathPercent = OnBoardPathList[registerType][location.pathname];
      if (pathPercent === null) {
        setShowProgress(false);
      } else {
        setShowProgress(true);
        setPercent(pathPercent);
      }
    } catch (error) {
      // console.error(error);
    }
  }, [location]);

  return (
    <div
      className={`${
        location.pathname !== PATH_LIST.accountHasBeenCreated &&
        location.pathname !== PATH_LIST.chooseAccType &&
        " onboarding-layout "
      } 
      ${
        location.pathname === PATH_LIST.accountHasBeenCreated ||
        location.pathname === PATH_LIST.pinCode ||
        location.pathname === PATH_LIST.chooseAccType
          ? " onboardingLayoutAccCreated "
          : ""
      }
       ${
         location.pathname === PATH_LIST.termsAndConditions &&
         " onboarding-layout-terms "
       }`}
    >
      {showProgress &&
        location.pathname !== PATH_LIST.accountHasBeenCreated &&
        location.pathname !== PATH_LIST.pinCode && (
          <ProgressLine percent={percent} mobileInfo={percentStep} />
        )}

      <Outlet />

      <CameraAccess />
    </div>
  );
};
