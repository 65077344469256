import React, { FC, useEffect, useRef, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";

type PropsType = {
  label: string;
  value: any;
  onChange?: any;
  symbol?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  textType?: boolean;
  dropdown?: any;
};

const RangeSliderInput: FC<PropsType> = ({
  label,
  value,
  onChange,
  symbol,
  min,
  max,
  disabled,
  textType,
  dropdown,
}) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation("app");

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="range-slider-input">
      <label htmlFor={label}>{t(label.toLowerCase())}</label>
      {!textType ? (
        <input
          id={label}
          type="number"
          value={value}
          max={max ? max : 100}
          min={min ? min : 0}
          onChange={onChange}
          disabled={disabled}
        />
      ) : (
        <input
          id={label}
          type="text"
          value={value}
          onChange={onChange}
          readOnly={disabled}
          className={disabled ? "readonly-range-input" : ""}
          onClick={() => (dropdown ? setActive(true) : undefined)}
        />
      )}
      {symbol && <p>{symbol}</p>}

      {dropdown && active && (
        <div ref={dropdownRef} className="market-cap-dropdown-wrapper">
          <div onClick={() => setActive(false)} className="market-cap-dropdown">
            {dropdown}
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeSliderInput;
