import React from "react";
import "./style.scss";

const ChatTypingSimulation = () => {
  return (
    <div className="chat__typing-animation">
      <span className="chat__typing-animation-dot">.</span>
      <span className="chat__typing-animation-dot">.</span>
      <span className="chat__typing-animation-dot">.</span>
    </div>
  );
};

export default ChatTypingSimulation;
