import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import Search from "../../../assets/SearchMarket.svg";
import Filter from "../../../assets/Filter.svg";
import Calendar from "../../../assets/Calendar.png";
import CalendarRight from "../../../assets/CalendarRight.png";
import AssetIcon from "../../../assets/AssetIcon.png";
import SortAscendingIcon from "../../../assets/SortAscendingIcon.svg";
import SortDescendingIcon from "../../../assets/SortDescendingIcon.svg";
import { TabBtn } from "../components/tab-btn";
import { MarketAsset } from "../components/market-asset";
import Button from "shared/ui/button";
import { SortModal } from "../components/sort-modal";
import { useStore } from "zustand";
import { OrderBy, marketStore } from "entities/market";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { AssetType } from "static/types";
import { SortData } from "static/data";
import { navigateToAsset } from "helpers/functions";
import InfiniteScroll from "react-infinite-scroll-component";
import HorizontalScroll from "../components/horizontal-scroll";
import { useTranslation } from "react-i18next";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import SortConfig from "../components/sort";
import Marquee from "react-fast-marquee";
import { sessionModel } from "entities/session";
import StrategyCard from "../components/strategy-card";

type PropsType = {
  currencies?: boolean;
  page: number;
  setPage: any;
};
export const AssetsContainer: React.FC<PropsType> = ({
  currencies,
  page,
  setPage,
}) => {
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const limit = 30;

  const {
    isLoading,
    activeAssetType,
    assets,
    assetsCount,
    loadAssets,
    sortConfigs,
    strategies,
    getStrategies,
  } = useStore(marketStore);

  const activeTab = currencies ? AssetType.Currency : activeAssetType;

  const isAvailable = [
    AssetType.ETF,
    AssetType.Stock,
    AssetType.Fond,
    AssetType.StructuredProduct,
    AssetType.Currency,
    AssetType.Bond,
    AssetType.EuroBond,
  ].includes(activeTab);

  const onLoadAssets = useCallback(async () => {
    if (isAvailable) {
      if (activeTab === AssetType.StructuredProduct) {
        // await getStrategies();
      } else {
        await loadAssets({
          type: String(activeTab),
          page,
          limit,
          ...([
            AssetType.ETF,
            AssetType.Stock,
            AssetType.Fond,
            AssetType.Bond,
            AssetType.EuroBond,
          ].includes(activeTab) && sortConfigs[activeTab]
            ? {
                sortBy: sortConfigs[activeTab].sortBy,
                orderBy: sortConfigs[activeTab].orderBy,
              }
            : {}),
        });
      }
    }
  }, [activeTab, isAvailable, page, sortConfigs]);

  useEffect(() => {
    setPage(0);
  }, [activeTab]);

  useEffect(() => {
    onLoadAssets();
  }, [onLoadAssets, page]);

  const handleStrategy = (symbol: string) => {
    navigate(PATH_LIST.strategy(symbol));
  };

  if (isLoading) {
    return <p className="assets-loading">{t("loading")}</p>;
  }

  if (!isAvailable) {
    return (
      <div className="not-found-coming-soon">
        <p>{"comingSoon"}</p>
      </div>
    );
  }

  if (
    activeTab === AssetType.StructuredProduct
      ? !strategies.length
      : !assets.length
  ) {
    return (
      <div className="not-found-coming-soon">
        <p>{t("notFound")}</p>
      </div>
    );
  }

  const currItemsLength = (page + 1) * limit;

  // todo this mapping just ones : use as needed
  // const asset = assets[0]; // Accessing the first item
  //   <MarketAsset
  //   key={asset.id}
  //   onClick={() => {
  //     navigateToAsset(activeTab, asset.id, navigate);
  //   }}
  //   asset={asset}
  //   gains={asset.change}
  //   gainsPercent={asset.changesPercentage}
  //   showGains={[AssetType.Stock, AssetType.ETF].includes(asset.type)}
  // />

  if (activeTab === AssetType.StructuredProduct) {
    return (
      <div className="strategy-market">
        {strategies.map(({ id, symbol, currency, data }, i) => (
          <StrategyCard
            id={i + 1}
            key={symbol}
            onClick={() => handleStrategy(id)}
            asset={strategies[i]}
            minPrice={data.minimal_invest}
            risk={data.risk}
            percent={data.annual_yield}
          />
        ))}
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={currItemsLength}
      next={() => {
        setPage(page + 1);
      }}
      hasMore={currItemsLength < assetsCount}
      scrollThreshold={0.9}
      loader={<p className="assets-loading">{t("loading")}</p>}
    >
      {assets.map((asset) => (
        <MarketAsset
          key={asset.id}
          onClick={() => {
            navigateToAsset(activeTab, asset.id, navigate);
          }}
          asset={asset}
          gains={asset.change}
          gainsPercent={asset.changesPercentage}
          showGains={[AssetType.Stock, AssetType.ETF, AssetType.Fond].includes(
            asset.type
          )}
        />
      ))}
    </InfiniteScroll>
  );
};

export const MarketPage = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [sortModal, setSortModal] = useState(false);
  const [search, setSearch] = useState("");
  const { t } = useTranslation("app");
  const [page, setPage] = useState(0);

  useBlockScrollbar(sortModal, [sortModal]);

  const {
    isLoading,
    strategies,
    activeAssetType: activeTab,
    sortConfigs: configs,
    setActiveAssetType,
    setSortConfigs,
    getStrategies,
  } = useStore(marketStore);

  const navigate = useNavigate();

  useEffect(() => {
    getStrategies();
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [sortModal]);

  // sort btn scroll animation
  // const handleScroll = () => {
  //   const scrollThreshold = 120;
  //   setIsButtonVisible(window.scrollY <= scrollThreshold);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // ....

  const handleTabClick = (type: number) => {
    // setSearch("");
    if (type != activeTab) {
      setActiveAssetType(type);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleSearch = () => {
    navigate(PATH_LIST.search);
  };
  const handleFilter = () => {
    navigate(PATH_LIST.filter);
  };

  const Categories = [
    { type: AssetType.Currency, label: "Currencies" },
    { type: AssetType.Stock, label: "Stocks" },
    ...(strategies.length > 0
      ? [{ type: AssetType.StructuredProduct, label: "strategies" }]
      : []),
    { type: AssetType.ETF, label: "ETF" },
    // { type: AssetType.Fond, label: "Fonds" },
    { type: AssetType.Bond, label: "Bonds" },
    { type: AssetType.EuroBond, label: "Eurobonds" },
  ];

  return (
    <div className="market">
      <div className="search-panel">
        <div onClick={handleSearch} className="serch-form">
          <img src={Search} alt="Search" />
          <span>{t("search")}</span>
        </div>
        <button onClick={handleFilter} className="filter-btn">
          <img src={Filter} alt="Filter" />
        </button>
      </div>
      <HorizontalScroll className="tab-bar">
        {Categories.map((cat) => (
          <TabBtn
            key={cat.type}
            text={t(cat.label)}
            active={activeTab === cat.type}
            onClick={() => {
              handleTabClick(cat.type);
            }}
          />
        ))}
      </HorizontalScroll>
      <div className="market-main">
        {/* {[AssetType.Stock, AssetType.ETF].includes(activeTab) && (
          <div className="calendar">
            <div>
              <img src={Calendar} alt="Calendar" />
              <p>{t("dividendCalendar")}</p>
            </div>
            <img src={CalendarRight} alt="Vector" />
          </div>
        )} */}
        <AssetsContainer page={page} setPage={setPage} />
      </div>

      {[
        AssetType.ETF,
        AssetType.Stock,
        AssetType.Fond,
        AssetType.Bond,
        AssetType.EuroBond,
      ].includes(activeTab) &&
        isButtonVisible && (
          <div className="btn-wrapper">
            <Button
              onClick={() => setSortModal(true)}
              children={
                configs[activeTab]?.sortBy ? (
                  <SortConfig
                    name={t(
                      SortData[activeTab].find(
                        (a) => a.by === configs[activeTab].sortBy
                      )?.text || ""
                    )}
                    config={
                      configs[activeTab].orderBy === "asc"
                        ? t("ascending")
                        : t("descending")
                    }
                    img={
                      configs[activeTab].orderBy === "asc"
                        ? "Ascending"
                        : "Descending"
                    }
                  />
                ) : (
                  t("sort")
                )
              }
              className={
                configs[activeTab]?.sortBy
                  ? "market-sort-btn sorted-configs"
                  : "market-sort-btn"
              }
              variant="dark"
            />
          </div>
        )}
      {sortModal && (
        <SortModal
          assetType={activeTab}
          sort={configs[activeTab]}
          onClose={() => setSortModal(false)}
          onSort={(v) => {
            setPage(0);
            setSortConfigs(activeTab, v);
          }}
        />
      )}
    </div>
  );
};
