import {
  ResolutionString,
  SymbolType,
} from "charting_library/charting_library";
import { LibrarySymbolInfo } from "charting_library/datafeed-api";
import { marketStore } from "entities/market";
import { ioRTStore } from "services/rt.socket";
import { AssetType } from "static/types";

// DatafeedConfiguration implementation
const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: ["1", "1D", "1W", "1M", "12M", "60M", "240M"],
};

const types: Record<AssetType, SymbolType> = {
  [AssetType.ADR_GDR]: "stock",
  [AssetType.Stock]: "stock",
  [AssetType.PreferredShare]: "stock",
  [AssetType.Bond]: "bond",
  [AssetType.Currency]: "forex",
  [AssetType.EuroBond]: "bond",
  [AssetType.Fond]: "fund",
  [AssetType.ETF]: "stock",
  [AssetType.StructuredProduct]: "stock",
};

export default {
  onReady: (callback: any) => {
    // console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: (
    userInput: any,
    exchange: any,
    symbolType: any,
    onResultReadyCallback: any
  ) => {
    // console.log("[searchSymbols]: Method call");
  },
  resolveSymbol: async (
    symbolName: any,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any,
    extension: any
  ) => {
    // console.log("[resolveSymbol]: Method call", symbolName);
    const assetInfo = marketStore.getState().assetInfo;
    if (!assetInfo?.asset) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("cannot resolve symbol");
      return;
    }
    const assetChart = marketStore.getState().assetChart;

    // Symbol information object
    const symbolInfo: LibrarySymbolInfo = {
      ticker: assetInfo.asset.symbol,
      name: assetInfo.asset.name,
      description: assetInfo.asset.name,
      type: types[assetInfo.asset.type as AssetType],
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: String(assetInfo.asset.exchange),
      minmov: 0.1,
      pricescale: assetChart?.asset?.type === AssetType.Currency ? 10000 : 100,
      has_daily: true,
      has_ticks: true,
      has_seconds: true,
      has_intraday: true,
      // has_no_volume: true,
      has_weekly_and_monthly: true,
      supported_resolutions:
        configurationData.supported_resolutions as ResolutionString[],
      volume_precision: 2,
      data_status: "delayed_streaming",
      listed_exchange: String(assetInfo.asset.exchange),
      format: "price",
    };

    // console.log("[resolveSymbol]: Symbol resolved", symbolName);
    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  },
  getBars: (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any
  ) => {
    const { from, to, firstDataRequest } = periodParams;
    // console.log("[getBars]: Method call", resolution);
    try {
      const assetChart = marketStore.getState().assetChart;
      const chart =
        resolution === "1" ? assetChart?.dayChart : assetChart?.chart;
      // console.log(chart);
      if (!chart || !chart.length) {
        onHistoryCallback([], {
          noData: true,
        });
        return;
      }
      // console.log(from, to);
      let bars: any[] = [];
      if (assetChart?.asset?.type === AssetType.Currency) {
        (chart as any[]).forEach(([date, close]) => {
          if (date / 1000 >= from && date / 1000 < to) {
            bars = [
              ...bars,
              {
                time: date,
                close,
              },
            ];
          }
        });
      } else {
        (chart as any[]).forEach(([date, open, high, low, close, volume]) => {
          if (date / 1000 >= from && date / 1000 < to) {
            bars = [
              ...bars,
              {
                time: date,
                low: low,
                high: high,
                open: open,
                close: close,
                volume,
              },
            ];
          }
        });
      }
      // console.log(`[getBars]: returned ${bars.length} bar(s)`);
      onHistoryCallback(bars, {
        noData: false,
      });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },
  subscribeBars: (
    symbolInfo: any,
    resolution: any,
    onRealtimeCallback: any,
    subscriberUID: any,
    onResetCacheNeededCallback: any
  ) => {
    // console.log(
    //   "[subscribeBars]: Method call with subscriberUID:",
    //   subscriberUID
    // );
    const assetInfo = marketStore.getState().assetInfo;
    if (!assetInfo) return;
    const symbol = assetInfo?.asset?.symbol;
    const exchange = assetInfo?.asset?.exchange?.symbol;
    if (!symbol || !exchange) return;
    const stock = `${exchange}~${symbol}`;
    const handler = {
      id: subscriberUID,
      callback: onRealtimeCallback,
    };
    let subscriptionItem = ioRTStore.getState().chartSubscribes.get(stock);
    // console.log(assetInfo);
    if (subscriptionItem) {
      subscriptionItem.handlers.push(handler);
      return;
    }
    ioRTStore.getState().chartSubscribes.set(stock, { handlers: [handler] });
    // console.log("[subscribeBars]: Subscribe to streaming. Channel:", symbol);
  },
  unsubscribeBars: (subscriberUID: any) => {
    console.log(
      "[unsubscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );
    const subscribes = ioRTStore.getState().chartSubscribes;
    subscribes.forEach((sub) => {
      const exists = sub.handlers.find((v) => v.id === subscriberUID);
      if (exists) {
        sub.handlers = sub.handlers.filter((h) => h.id !== subscriberUID);
      }
    });
  },
};
