import React, { useEffect } from "react";
import "./style.scss";
import GoBackButton from "shared/ui/back-button";
import { PasswordLoginForm } from "widgets/auth/forms";
import LogoAndTitle from "../components/logo-title";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";

export const PasswordLoginPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("onboarding-auth");

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.login);

    return () => setLink("");
  }, []);

  const HnagleNavigate = () => {
    navigate(PATH_LIST.forgotPassword);
  };
  return (
    <div className="usingPassword">
      <GoBackButton link={PATH_LIST.login} />
      <LogoAndTitle title={t("logInUsingPassword")} />
      <PasswordLoginForm />
      <span onClick={HnagleNavigate} className="forgotPassword">
        {t("forgotYourPassword")}
      </span>
    </div>
  );
};
