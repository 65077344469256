import React, { FC } from "react";
import "./style.scss";

type PropType = {
  text: string;
  htmlForId?: string;
  onClick: any;
  checked?: boolean;
  customClass?: string;
};
const RadioCard: FC<PropType> = ({
  text,
  htmlForId,
  onClick,
  checked,
  customClass,
}) => {
  return (
    <label
      onClick={onClick}
      htmlFor={htmlForId}
      className={`radioCard ${customClass}`}
    >
      <input
        defaultChecked={checked}
        className="radio"
        type="radio"
        name="radio"
        id={htmlForId}
      />
      <p>{text}</p>
    </label>
  );
};

export default RadioCard;
