import React, { FC, useState } from "react";
import "./style.scss";
import ChevronDown from "../../../../assets/ChevronDown.svg";

import { CURRENCY_FLAGS } from "static/currencies";

type PropsType = {
  currency: string;
  currencies: any[];
  dropdownFF?: boolean;
  dropdownFS?: boolean;
  onChange: (code: string) => void;
  openDropdown: () => void;
};

export const EditRatesDropdown: FC<PropsType> = ({
  currency,
  currencies,
  onChange,
  openDropdown,
  dropdownFF = false,
  dropdownFS = false,
}) => {
  return (
    <div className="edit-rates-dropdown-container">
      <div onClick={openDropdown} className="edit-rates-dropdown">
        <img src={CURRENCY_FLAGS[currency]} alt={currency} />
        <p>{currency}</p>
        <img
          src={ChevronDown}
          alt="ChevronDown"
          className={
            dropdownFF === true || dropdownFS === true
              ? "dropdown-open-chevron"
              : "dropdown-close-chevron"
          }
        />
      </div>
      {dropdownFF || dropdownFS ? (
        <div className="edit-rates-dropdown-content">
          {currencies
            .filter((c) => c.code !== currency)
            .map((c) => (
              <div key={c.id} onClick={() => onChange(c.code)}>
                <img src={CURRENCY_FLAGS[c.code]} alt={c.code} />
                <p>{c.code}</p>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};
