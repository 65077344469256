import React, { PropsWithChildren } from "react";
import "./style.scss";

type PropsType = {
  onClick?: any;
  customClass?: any;
  disabled?: boolean;
  type?: undefined | "submit";
};
const ModalButton: React.FC<PropsWithChildren<PropsType>> = ({
  children,
  onClick = () => {},
  customClass,
  disabled = false,
  type = undefined,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${customClass} modalBtn`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ModalButton;
