import { FC } from "react";
import "./style.scss";
import GoBack from "../../../../assets/GoBackApp.svg";
import ReplenishmentDetails from "../replenishment-details";
import { useTranslation } from "react-i18next";

type PropsType = {
  onClose: () => void;
};
export const ContactsModal: FC<PropsType> = ({ onClose }) => {
  const { t } = useTranslation("app");

  return (
    <div className="contacts-modal">
      <div className="contacts-modal-header">
        <img
          className="go-back-portfolio"
          onClick={onClose}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("contacts")}</p>
      </div>
      <div className="contacts-modal-main">
        <ReplenishmentDetails
          nameOfDetail={t("callUs")}
          detail={"+374 12 300 900"}
        />
        <ReplenishmentDetails
          nameOfDetail={t("sendEmail")}
          detail={"support@siriuscapital.am"}
        />
      </div>
    </div>
  );
};
