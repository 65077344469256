import { ContentType, ErrorModelDto, HttpResponse } from "../http-client";
import { Api } from "./Api";
import type { LoginUserDto, UserDto } from "./Api";
import { ResponseCodes } from "./codes";

type GenericErrorModel = HttpResponse<unknown, ErrorModelDto>;

const sircapApi = new Api<string>({
  baseUrl: process.env.REACT_APP_SIRCAP_API as string,
  baseApiParams: {
    headers: {
      "Content-Type": ContentType.Json,
    },
    format: "json",
    secure: true,
  },
  securityWorker: (token) =>
    token ? { headers: { Authorization: `Bearer ${token}` } } : {},
});

export { sircapApi, ResponseCodes };
export type {
  LoginUserDto,
  UserDto,
  ErrorModelDto,
  HttpResponse,
  GenericErrorModel,
};
