import React, { useEffect } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { PasswordField } from "widgets/auth/components";
import Button from "shared/ui/button";
import ValidationCheckbox from "../components/validation-checkbox";
import { onboardModel } from "entities/onboard";
import { RegisterEnum } from "entities/onboard";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

export const CreatePasswordPage = () => {
  const registerType = onboardModel.useRegisterType();
  const { syncUser, password, setSome } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerName);

    return () => setLink("");
  }, []);

  const navigate = useNavigate();

  const onRegister = async (pass: string) => {
    const resp = await sircapApi.onboarding.regPassword({
      password: pass,
      entity: registerType,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.UserIsExists:
          toast.error("User is exists");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should verify phone again");
          navigate(PATH_LIST.registerPhone);
          break;

        default:
          break;
      }
    } else {
      setSome({ password: pass });
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      if (registerType === RegisterEnum.Individual) {
        navigate(PATH_LIST.registerGender);
      } else {
        navigate(PATH_LIST.signContract);
      }
    }
  };

  return (
    <div className="createPassword Box">
      <GoBackButton link={PATH_LIST.registerName} />
      <p>{t("createPassword")}</p>
      <span>{t("createPasswordLogin")}</span>

      <Formik
        initialValues={{ password: password || "" }}
        onSubmit={(values) => {
          // console.log("Form submitted with values:", values);
          onRegister(values.password);
        }}
        validate={(values) => {
          const errors: any = {};
          if (values.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
          }
          if (!/[A-Z]/.test(values.password)) {
            errors.password =
              "Password must contain at least 1 upper case letter";
          }
          if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(values.password)) {
            errors.password = "Password must contain at least 1 symbol";
          }
          if (!/\d/.test(values.password)) {
            errors.password = "Password must contain at least 1 number";
          }
          return errors;
        }}
      >
        {({ values, errors }) => {
          const isAtLeastSixCharactersValid = values.password.length >= 6;
          const containsUpperCaseValid = /[A-Z]/.test(values.password);
          const containsSymbolValid = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(
            values.password
          );
          const containsNumberValid = /\d/.test(values.password);

          return (
            <Form>
              <PasswordField
                label={t("password")}
                id="password"
                isInvalid={!!errors.password}
                placeholder={t("create")}
              />
              <ValidationCheckbox
                term={t("atLeast6Chars")}
                isChecked={isAtLeastSixCharactersValid}
              />
              <ValidationCheckbox
                term={t("containsUpperCase")}
                isChecked={containsUpperCaseValid}
              />
              <ValidationCheckbox
                term={t("containsSymbol")}
                isChecked={containsSymbolValid}
              />
              <ValidationCheckbox
                term={t("containsNumber")}
                isChecked={containsNumberValid}
              />
              <Button
                type="submit"
                disabled={
                  !containsNumberValid ||
                  !containsSymbolValid ||
                  !containsUpperCaseValid ||
                  !isAtLeastSixCharactersValid
                }
                variant="dark"
                className="costumBtn"
              >
                {t("continue")}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
