import React, { useEffect } from "react";
// @ts-ignore
import messageRingtone2 from "../../static/_message.mp3";

type PropsType = {
  play: boolean;
};
const Audio: React.FC<PropsType> = ({ play }) => {
  const audioStart = () => {
    var audioElement = document.getElementById("audio");
    if (audioElement) {
      // @ts-ignore
      audioElement.src = messageRingtone2;
      // @ts-ignore
      audioElement.autoplay = true;
    }
  };

  useEffect(() => {
    if (play) audioStart();
  }, [play]);

  return <audio id="audio" className="d-none" />;
};

export default Audio;
