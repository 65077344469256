import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "shared/ui/form-field";
import Button from "shared/ui/button";
import { useTranslation } from "react-i18next";
import { useSchema } from "helpers/functions";

const Schema = (t: any) => Yup.object().shape({
  firstName: Yup.string().required(t("firstNameRequired")),
  lastName: Yup.string().required(t("lastNameRequired")),
});

type PropsType = {
  initialValues: { firstName: string; lastName: string };
  onSubmit: (firstName: string, lastName: string) => void;
};
export const IndividNameForm: React.FC<PropsType> = ({
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation("onboarding-auth");
  const schema = useSchema(Schema);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit(values.firstName, values.lastName);
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty } = formik;
        return (
          <Form>
            <FormField
              id="firstName"
              label={t("firstName")}
              type="text"
              placeholder={t("enterFirstName")}
              isInvalid={!!(errors.firstName && touched.firstName)}
            />

            <FormField
              id="lastName"
              label={t("lastName")}
              type="text"
              placeholder={t("enterLastName")}
              isInvalid={!!(errors.lastName && touched.lastName)}
              customClass="secondRow"
            />

            <Button
              type="submit"
              className="registerNameBtn"
              variant={"dark"}
              disabled={!isValid}
            >
              {t("continue")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
