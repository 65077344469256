import React, { FC } from "react";
import "./style.scss";
import BE1 from "assets/BackgroundElTop.png";
import BE2 from "assets/BackgroundElBottom.png";
import GoBack from "../../../../assets/GoBack.svg";
import ModalButton from "widgets/auth/components/modal-btn";
import { useTranslation } from "react-i18next";
import { DocumentType } from "static/types";

type PropType = {
  onClose: any;
  image: any;
  onSubmit: any;
  isSubmit: boolean;
  type: DocumentType;
};

const SubmitPhotoModal: FC<PropType> = ({
  onClose,
  image,
  onSubmit,
  type,
  isSubmit,
}) => {
  const cameraBoxClass: Record<typeof type, string> = {
    [DocumentType.Passport]: "docPhoto",
    [DocumentType.IdCard]: "docPhotoShort",
    [DocumentType.IdCardBack]: "docPhotoShort",
    [DocumentType.ProofAddress]: "docPhoto",
    [DocumentType.Selfie]: "docPhoto",
    [DocumentType.UtilityBill]: "docPhoto",
    [DocumentType.Avatar]: "docPhoto",
  };

  const { t } = useTranslation("onboarding-auth");

  return (
    <div className="submitPhotoModal">
      <div className="goBack" onClick={onClose}>
        <div>
          <img src={GoBack} alt="Go Back" />
        </div>
        <p className="backText">{t("back")}</p>
      </div>

      <div className={cameraBoxClass[type]}>
        <img src={image ? URL.createObjectURL(image) : ""} alt="img" />
      </div>
      <p>{t("ensureDetailsClear")}</p>
      <ModalButton disabled={isSubmit} onClick={onSubmit}>
        {t("submitPhoto")}
      </ModalButton>
      <span onClick={onClose}>{t("retakePhoto")}</span>

      <img
        className="backgroundElement backgroundElementTop"
        src={BE1}
        alt="BackgroundElTop"
      />
      <img
        className="backgroundElement backgroundElementBottom"
        src={BE2}
        alt="BackgroundElBottom"
      />
    </div>
  );
};

export default SubmitPhotoModal;
