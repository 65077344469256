import React, { FC } from "react";
import "./style.scss";
import { ErrorMessage, Field } from "formik";

type PropsType = {
  customClass?: "large" | "reg";
  maxLength: number;
  placeholder: string;
  id: string;
  label: string;
  autoFocus?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
  setFieldValue: any;
  touched: boolean;
};

const DateInput: FC<PropsType> = ({
  customClass = "reg",
  maxLength,
  placeholder,
  id,
  label,
  autoFocus,
  isInvalid,
  setFieldValue,
  touched,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    //@ts-ignore
    if (!isNaN(newValue)) {
      if (newValue.length <= maxLength) {
        setFieldValue(id, newValue);
      }
    }
  };

  return (
    <div className="dateBox">
      <label htmlFor={id}>{label}</label>
      <Field
        type={"number"}
        name={id}
        id={id}
        className={`dateInput ${customClass} ${
          touched ? (isInvalid ? "input-error" : "input-success") : ""
        }`}
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={handleChange}
      />
      <ErrorMessage
        name={id}
        component="span"
        className={
          customClass === "large"
            ? "date-error-large date-error-message"
            : "date-error-message"
        }
      />
    </div>
  );
};

export default DateInput;
