import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./RouterProvider";
import { ToastContainer } from "react-toastify";

import { FullPageError } from "shared/ui/full-page-error";

import "react-toastify/dist/ReactToastify.css";

export function Provider() {
  return (
    <ErrorBoundary FallbackComponent={FullPageError}>
      <BrowserRouter>
        <ToastContainer
          hideProgressBar={false}
          closeButton={false}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          autoClose={3500}
          position={"top-right"}
        />
        <Router />
      </BrowserRouter>
    </ErrorBoundary>
  );
}
