import React, { FC, useState } from "react";
import "./style.scss";
import Opened from "../../../assets/market-status/Opened.svg";
import Close from "../../../assets/market-status/Close.svg";
import PostMarket from "../../../assets/market-status/PostMarket.svg";
import PreMarket from "../../../assets/market-status/PreMarket.svg";
import Holiday from "../../../assets/market-status/Holiday.svg";
import { ExchangeStatus } from "hooks/useExchangeTimeFrame";
import { useTranslation } from "react-i18next";

type PropsType = {
  assetField?: boolean;
  onClick?: () => void;
  status: ExchangeStatus;
};

const ExchangeStatusBtn: FC<PropsType> = ({ status, onClick, assetField }) => {
  const { t } = useTranslation("app");
  const [isHovered, setIsHovered] = useState(false);

  const statusData = {
    [ExchangeStatus.Open]: {
      background: isHovered ? "#ace5dc" : "#daf2ee",
      image: Opened,
    },
    [ExchangeStatus.Closed]: {
      background: isHovered ? "#e0e3eb" : "#f0f3fa",
      image: Close,
    },
    [ExchangeStatus.PreMarket]: {
      background: isHovered ? "#ffe0b2" : "#fff3e0",
      image: PreMarket,
    },
    [ExchangeStatus.PostMarket]: {
      background: isHovered ? "#bbd9fb" : "#e3effd",
      image: PostMarket,
    },
    [ExchangeStatus.Holiday]: {
      background: isHovered ? "#e0e3eb" : "#f0f3fa",
      image: Holiday,
    },
    [ExchangeStatus.Weekend]: {
      background: isHovered ? "#e0e3eb" : "#f0f3fa",
      image: Holiday,
    },
  };

  if (status === ExchangeStatus.Open && assetField) {
    return <></>;
  }

  return (
    <div
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={assetField ? "exchange-status-icon" : "exchange-status-btn"}
      style={{ backgroundColor: statusData[status].background }}
    >
      {assetField && (  
        <div className="asset-field-status-wrapper">
          <div className="asset-field-status">{t(status)}</div>
        </div>
      )}
      <img src={statusData[status].image} alt="icon" />
    </div>
  );
};

export default ExchangeStatusBtn;
