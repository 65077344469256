import { MapProfileInfoFromUser } from "helpers/mappings";
import { createStore, useStore } from "zustand";
import { persist } from "zustand/middleware";

export enum RegisterEnum {
  Individual = "individual",
  Business = "entity",
}

export enum RegisterStep {
  Phone = 2,
  Email = 4,
  Name = 6,
  Password = 8,
  // for individual
  ProfileInfo = 10,
  PersonalDocument = 11,
  ProofOfAddress = 12,
  Selfie = 13,
  UtilityBill = 14,
  PersonalAddress = 15,
  // for business
  SignContract = 16,
  // for all
  Agreements = 18,
  Pin = 19,
  Done = 20,
}

export enum ProfileInfoStep {
  Gender,
  Birth,
  Citizen,
  HopeToAchieve,
  Experience,
  ForLiving,
  AreInvest,
}

type RegisterType = RegisterEnum | null;

export interface IUserProfileInfo {
  gender?: string;
  birthDate?: { day: string; month: string; year: string };
  citizen?: string;
  hopeToAchieve?: string;
  haveExperience?: boolean;
  whatDoForLiving?: string;
  areInvestWithSavings?: boolean;
}

export enum CameraStatus {
  None,
  Allowed,
  Denied,
  NotFound,
}

export enum EntityDocumentsEnum {
  CertOfStateRegistration = "cert-of-state-registraion",
  CertOfEntityAddress = "cert-of-entity-address",
  CertOfDirectors = "cert-of-directors",
  CertOfShareholders = "cert-of-shareholders",
  FundingAgreement = "funding-agreement",
  DocDisclosingBenOwners = "doc-disclosing-ben-owner",
  DocConfirmingEntityActiveState = "doc-confirming-entity-active-state",
  CertOfPositionAndAuthority = "cert-of-position-and-authority",
  LicenseCopies = "license-copies",
  PersonalDocumentCopies = "personal-document-copies",
  AddressOfDirector = "address-of-director",
  ResolutionOpenBrokerageAcc = "resolution-open-brokerage-account",
  FinStatements = "financial-statements-previous-2-years",
  ClientQuestionnaire = "client-questionnaire",
  CompanyStructure = "company-structure",
  AdditionalDoc = "additional-document",
}

export const defaultEntityDocs: Record<EntityDocumentsEnum, string> = {
  [EntityDocumentsEnum.CertOfStateRegistration]: "",
  [EntityDocumentsEnum.CertOfEntityAddress]: "",
  [EntityDocumentsEnum.CertOfDirectors]: "",
  [EntityDocumentsEnum.CertOfShareholders]: "",
  [EntityDocumentsEnum.FundingAgreement]: "",
  [EntityDocumentsEnum.DocDisclosingBenOwners]: "",
  [EntityDocumentsEnum.DocConfirmingEntityActiveState]: "",
  [EntityDocumentsEnum.CertOfPositionAndAuthority]: "",
  [EntityDocumentsEnum.LicenseCopies]: "",
  [EntityDocumentsEnum.PersonalDocumentCopies]: "",
  [EntityDocumentsEnum.AddressOfDirector]: "",
  [EntityDocumentsEnum.ResolutionOpenBrokerageAcc]: "",
  [EntityDocumentsEnum.FinStatements]: "",
  [EntityDocumentsEnum.ClientQuestionnaire]: "",
  [EntityDocumentsEnum.CompanyStructure]: "",
  [EntityDocumentsEnum.AdditionalDoc]: "",
};

type OnboardState = {
  id: number | null;
  name:
    | { companyName: string }
    | { firstName: string; lastName: string }
    | null;
  personalAddress: string;
  password: string;
  regStep: RegisterStep | null;
  setId: (id: number) => void;
  setSome: (obj: Partial<OnboardState>) => void;
  setStep: (step: RegisterStep) => void;
  profileInfo: IUserProfileInfo;
  profileInfoStep: ProfileInfoStep;
  documents: Record<EntityDocumentsEnum, string>;
  additionalDocument: { filename: string; name: string };
  dontHaveLicense: boolean;
  syncUser: (user: any, entity: string) => void;

  cameraStatus: CameraStatus;
  setCameraStatus: (status: CameraStatus) => void;

  registerType: RegisterType;
  setRegisterType: (value: RegisterType) => void;

  phoneNumber: string;
  email: string;

  clear: () => void;

  setPhoneNumber: (phoneNumber: string) => void;
  setEmail: (email: string) => void;
  setProfileInfo: (step: ProfileInfoStep, info: IUserProfileInfo) => void;
};

export const onboardStore = createStore<OnboardState>()(
  persist(
    (set, get) => ({
      id: null,
      registerType: null,
      regStep: null,
      profileInfo: {},
      profileInfoStep: ProfileInfoStep.Gender,
      email: "",
      phoneNumber: "",
      name: null,
      personalAddress: "",
      password: "",
      cameraStatus: CameraStatus.None,
      documents: defaultEntityDocs,
      additionalDocument: {
        filename: "",
        name: "",
      },
      dontHaveLicense: false,
      syncUser: (user: any, entity: string) => {
        set({
          id: user.id,
          profileInfo: MapProfileInfoFromUser(user.profileInfo),
          personalAddress: user.personalAddress,
          registerType: entity as RegisterType,
          regStep: user.regStep,
          phoneNumber: user.phone,
          email: user.email,
          documents: { ...defaultEntityDocs, ...user.documents },
          additionalDocument: user.additionalDocument || {
            filename: "",
            name: "",
          },
        });
      },

      clear: () => {
        set({
          id: null,
          regStep: null,
          profileInfo: {},
          profileInfoStep: ProfileInfoStep.Gender,
          email: "",
          phoneNumber: "",
          name: null,
          personalAddress: "",
          password: "",
          cameraStatus: CameraStatus.None,
          documents: defaultEntityDocs,
          additionalDocument: { filename: "", name: "" },
        });
      },

      setSome: (obj: Partial<OnboardState>) => {
        set({ ...obj }, false);
      },

      setCameraStatus: (status) => {
        set({ cameraStatus: status });
      },

      setRegisterType: (registerType: RegisterType) => {
        set({ registerType }, false);
      },

      setId: (id: number) => {
        set({ id }, false);
      },
      setStep: (step: RegisterStep) => {
        set({ regStep: step }, false);
      },

      setPhoneNumber: (phoneNumber) => {
        set({ phoneNumber });
      },
      setEmail: (email) => {
        set({ email });
      },

      setProfileInfo: (step: ProfileInfoStep, info: IUserProfileInfo) => {
        set({
          profileInfoStep: step,
          profileInfo: { ...get().profileInfo, ...info },
        });
      },
    }),
    {
      name: "onboard",
      merge: (persistedState, currState) => {
        const {
          regStep,
          registerType,
          id,
          profileInfo,
          profileInfoStep,
          documents,
          additionalDocument,
          dontHaveLicense,
        } = persistedState as Partial<OnboardState>;

        return {
          ...currState,
          registerType: registerType || null,
          regStep: regStep || RegisterStep.Phone,
          profileInfo: profileInfo || {},
          profileInfoStep: profileInfoStep || ProfileInfoStep.Gender,
          documents: documents || defaultEntityDocs,
          additionalDocument: additionalDocument || {
            filename: "",
            name: "",
          },
          dontHaveLicense: dontHaveLicense || false,
          id: id || null,
        };
      },
    }
  )
);

export const useRegisterType = () =>
  useStore(onboardStore, (state) => state.registerType);

export const useRegisterId = () => useStore(onboardStore, (state) => state.id);
export const useRegisterStep = () =>
  useStore(onboardStore, (state) => state.regStep);
export const useRegisterPhone = () =>
  useStore(onboardStore, (state) => state.phoneNumber);
export const useRegisterEmail = () =>
  useStore(onboardStore, (state) => state.email);
export const useProfileInfo = () =>
  useStore(onboardStore, (state) => state.profileInfo);
export const useProfileInfoStep = () =>
  useStore(onboardStore, (state) => state.profileInfoStep);

export const useCameraStatus = () =>
  useStore(onboardStore, (state) => state.cameraStatus);
