import React, { useState } from "react";
import Close from "../../../assets/Close.svg";
import "./style.scss";
import ModalButton from "../components/modal-btn";
import AuthCode from "react-auth-code-input";
import { useTranslation } from "react-i18next";
import { PasswordLoginForm } from "../forms";
import { PATH_LIST } from "shared/lib/react-router";
import { useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import { PasswordField } from "../components/PasswordField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSchema } from "helpers/functions";

type PropsType = {
  title: string;
  text: string;
  btnText: string;
  onClick: any;
  onHide: any;
  closeIcon?: boolean;
  customModal?: string;
  customOutlet?: string;
};

const LoginSchema = (t: any) =>
  Yup.object().shape({
    password: Yup.string().required(t("passwordIsRequired")),
  });
interface ISchema {
  password: string;
}
const initialValues: ISchema = {
  password: "",
};

const RestoreByPasswordModal: React.FC<PropsType> = ({
  title,
  text,
  btnText,
  onClick,
  onHide,
  closeIcon = true,
  customModal,
  customOutlet,
}) => {
  const { t: y } = useTranslation("yup");
  const { t: o } = useTranslation("onboarding-auth");
  const { t } = useTranslation("onboarding-auth");
  const [getPIN, setGetPIN] = useState<null | string>(null);
  const navigate = useNavigate();
  const { isLoading, resetPinCode } = useStore(accountStore);
  const schema = useSchema(LoginSchema);

  const submit = async ({ password }: ISchema) => {
    const pin = await resetPinCode({ password });
    if (pin) {
      setGetPIN(pin);
    }
  };

  return (
    <div onClick={onHide} className={`outlet ${customOutlet}`}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={` ${customModal} restore-by-password-modal `}
      >
        {closeIcon === true && (
          <div className={"closeDiv"}>
            <img onClick={onHide} className="close" src={Close} alt="Close" />
          </div>
        )}
        {!getPIN && <p>{title}</p>}
        {getPIN ? (
          <p style={{ width: "77%" }}>{o("hereIsYourPIN")}</p>
        ) : (
          <span>{text}</span>
        )}

        {getPIN ? (
          <p
            style={{
              padding: "8px 16px",
              border: "1px solid #2B2F38",
              borderRadius: "20px",
              margin: 0,
              lineHeight: "16px",
            }}
          >
            {getPIN}
          </p>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={(values) => {
                submit({
                  password: values.password.trim(),
                });
              }}
            >
              {(formik) => {
                const { errors, touched, isValid, dirty } = formik;
                return (
                  <Form>
                    <PasswordField
                      id="password"
                      label={t("password")}
                      isInvalid={!!(errors.password && touched.password)}
                      placeholder={t("enterYourPassword")}
                      autoFocus={false}
                    />
                    <div className="forgot-password-PIN-restor">
                      <span onClick={() => navigate(PATH_LIST.forgotPassword)}>
                        {o("forgotYourPassword")}
                      </span>
                    </div>

                    <ModalButton disabled={!isValid || isLoading} type="submit">
                      {btnText}
                    </ModalButton>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default RestoreByPasswordModal;
