import { ElementType, Suspense } from "react";
import { FullPageWrapper } from "../full-page-error/full-page-wrapper";
import { Spinner } from '../../../widgets/loading/index';

export function Loadable(Component: ElementType) {
  return function fn(props: any) {
    return (
      <Suspense
        fallback={
          <FullPageWrapper>
            <Spinner />
          </FullPageWrapper>
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
}
