import React, { FC } from "react";
import "./style.scss";

type PropsType = {
  purchase?: number;
  price: string | number;
  sale?: number;
  progress: number;
  currency: string;
  onClick: () => void;
};

const DepthField: FC<PropsType> = ({
  purchase,
  price,
  sale,
  progress,
  currency,
  onClick,
}) => {
  return (
    <div onClick={onClick} className="depth-field">
      <div>
        {purchase && (
          <>
            <span style={{ width: `${progress}%` }}></span>
            <p>{purchase}</p>
          </>
        )}
      </div>

      <p>
        {price}
        {currency}
      </p>
      <div>
        {sale && (
          <>
            <span style={{ width: `${progress}%` }}></span>
            <p>{sale}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default DepthField;
