import React, { FC } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";

type PropsType = {
  name: string;
  onClick: () => void;
};
const FilterSectionRow: FC<PropsType> = ({ name, onClick }) => {
  const { t } = useTranslation("app");

  return (
    <div className="filter-section-row">
      <p>{t(name)}</p>
      <button onClick={onClick}>{t("createFilter")}</button>
    </div>
  );
};

export default FilterSectionRow;
