import React, { FC } from "react";
import "./style.scss";
import { sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";

const langs = {
  en: "ENG",
  ru: "RUS",
  am: "ARM",
};

const getLang = (l: string) => {
  if (Object.keys(langs).includes(l)) {
    // @ts-ignore
    return langs[l];
  } else {
    return langs.en;
  }
};

type PropType = {
  term: string;
  PrivacyPolicy?: string;
  termsOfBrokerage?: string;
  termsOfBrokerageNew?: string;
  isChecked?: boolean;
  htmlForId: string;
  onChange?: () => void;
  className?: string;
  disabled?: boolean;
  smallFont?: boolean;
};
const TermsCheckbox: FC<PropType> = ({
  term,
  isChecked,
  PrivacyPolicy,
  termsOfBrokerage,
  termsOfBrokerageNew,
  htmlForId,
  onChange,
  className,
  disabled,
  smallFont,
}) => {
  const { i18n } = useTranslation();
  const lang = getLang(i18n.language);

  return (
    <label
      onChange={onChange}
      htmlFor={htmlForId}
      className={`${className} termsCheckbox`}
    >
      <input
        className="checkbox"
        type="checkbox"
        checked={isChecked}
        id={htmlForId}
        readOnly
        disabled={disabled}
      />
      <p style={{ fontSize: smallFont ? "12px" : "14px" }}>
        {term}
        {PrivacyPolicy && (
          <a
            target="_blank"
            href={`${sircapApi.baseUrl}/documents/Privacy policy ${lang}.pdf`}
          >
            {PrivacyPolicy}
          </a>
        )}
        {termsOfBrokerage && (
          <a
            style={{ fontSize: smallFont ? "12px" : "14px" }}
            target="_blank"
            className="brokerage-services"
            href={`${sircapApi.baseUrl}/documents/Terms Of Brokerage Services.pdf`}
          >
            {termsOfBrokerage}
          </a>
        )}
        {termsOfBrokerageNew && (
          <a
            style={{ fontSize: smallFont ? "12px" : "14px" }}
            target="_blank"
            className="brokerage-services"
            href={`${sircapApi.baseUrl}/documents/New Terms of Business ${i18n.language === "ru" ? "RU" : "AM"}.pdf`}
          >
            {termsOfBrokerageNew}
          </a>
        )}
      </p>
    </label>
  );
};

export default TermsCheckbox;
