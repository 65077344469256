import React, { useEffect, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "shared/ui/form-field";
import { onboardModel } from "entities/onboard";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useSchema } from "helpers/functions";
import { useTranslation } from "react-i18next";

let LoginSchema = (t: any) =>
  Yup.object().shape({
    text: Yup.string().required(t("addressRequired")),
  });

export const PersonalAddressPage = () => {
  const schema = useSchema(LoginSchema);
  const navigate = useNavigate();
  const { syncUser, personalAddress, setSome } = useStore(
    onboardModel.onboardStore
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const { t } = useTranslation("onboarding-auth");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.utilityBillPhotoPage);

    return () => setLink("");
  }, []);

  const onRegister = async (address: string) => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.regAddress({
      address,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      setSome({ personalAddress: address });
      navigate(PATH_LIST.termsAndConditions);
    }
    setIsSubmit(false);
  };

  return (
    <div className="personalAddress">
      <GoBackButton link={PATH_LIST.utilityBillPhotoPage} />
      <p>{t("personalAddressQuestion")}</p>
      <span>{t("searchCurrentResidentialAddress")}</span>

      <Formik
        initialValues={{ text: personalAddress || "" }}
        validationSchema={schema}
        onSubmit={(values) => {
          onRegister(values.text);
        }}
      >
        {(formik) => {
          const { errors, touched, isValid, dirty } = formik;
          return (
            <Form>
              <FormField
                id="text"
                label={t("personalAddress")}
                type="text"
                placeholder={t("enterPersonalAddress")}
                isInvalid={!!(errors.text && touched.text)}
              />

              <Button
                type="submit"
                className="registerNameBtn"
                variant={"dark"}
                disabled={!isValid || isSubmit}
              >
                {t("continue")}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
