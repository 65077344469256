import React, { FC, useState } from "react";
import "./image.scss";
import DownloadIcon from "../../../../../assets/VueABDownload.png";
import ZoomInIcon from "../../../../../assets/VueABZoomIn.png";
import ZoomOutIcon from "../../../../../assets/VueABZoomOut.png";
import CloseIcon from "../../../../../assets/CloseStories.svg";

import moment from "moment";

interface IChat {
  sender: string;
  customer: string;
  file: string;
  isLast: boolean;
  createdAt: string;
  onClick: () => void;
}

const ChatImageMessage: FC<IChat> = ({
  file,
  sender,
  createdAt,
  isLast,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`message-image message-${sender}-image ${
        isLast ? "last-msg" : ""
      }`}
    >
      <img src={file} alt="Upload Failed" />
      <span>{moment(new Date(createdAt)).format("HH:mm")}</span>
    </div>
  );
};

export default ChatImageMessage;

type PropsType = {
  onClose: () => void;
  createdAt?: any;
  image: any;
};

export const VueImageMessage: FC<PropsType> = ({
  onClose,
  createdAt,
  image,
}) => {
  const [zoomed, setZoomed] = useState(false);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    return `${hours}.${minutes}.${seconds}`;
  };

  return (
    <div className="vue-image-message" onClick={onClose}>
      <div className="vue-img-nav">
        <span>{moment(new Date(createdAt)).format("HH:mm")}</span>
        <div className="action_btns">
          {/* //todo */}
          {/* <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="action_btn-wrapper"
          >
            <a
              href={BackgroundElTop}
              download={`image-${getCurrentTime()}.jpg`}
              onClick={(e) => e.stopPropagation()}
              className="action_btn-wrapper"
            >
              <img src={DownloadIcon} alt="Action Btn" />
            </a>
          </div> */}
          <div
            onClick={(e) => {
              e.stopPropagation();
              setZoomed(!zoomed);
            }}
            className="action_btn-wrapper"
          >
            {zoomed ? (
              <img
                className="zoom-out-img"
                src={ZoomOutIcon}
                alt="Action Btn"
              />
            ) : (
              <img src={ZoomInIcon} alt="Action Btn" />
            )}
          </div>
          <div className="action_btn-wrapper">
            <img
              className="close-img-vue-icon"
              src={CloseIcon}
              alt="Action Btn"
            />
          </div>
        </div>
      </div>
      <img
        className={zoomed ? "zoomed-img" : undefined}
        src={image}
        alt="Upload Failed"
      />
    </div>
  );
};
