import React, { useEffect } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { onboardModel, RegisterEnum } from "entities/onboard";
import { CompanyNameForm, IndividNameForm } from "widgets/auth/forms";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { RegisterNameDto } from "shared/api/sircap/Api";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import { useNavigate } from "react-router-dom";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const RegisterNamePage = () => {
  const navigate = useNavigate();
  const { syncUser, name, setSome } = useStore(onboardModel.onboardStore);
  const registerType = onboardModel.useRegisterType();
  const { t } = useTranslation("onboarding-auth");  

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerEmail);

    return () => setLink("");
  }, []);

  const request = async (data: Partial<RegisterNameDto>) => {
    const resp = await sircapApi.onboarding.regName({
      entity: registerType,
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.Forbidden:
          toast.error("For continue you should verify phone again");
          navigate(PATH_LIST.registerPhone);
          break;
        default:
          break;
      }
    } else {
      // @ts-ignore
      setSome({ name: data });

      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.createPassword);
    }
  };

  const onSubmitIndividual = (firstName: string, lastName: string) => {
    request({ firstName, lastName, companyName: "" });
  };
  const onSubmitBusiness = (companyName: string) => {
    request({ firstName: "", lastName: "", companyName });
  };

  return (
    <div className="registerName">
      <GoBackButton link={PATH_LIST.registerEmail} />
      {registerType === RegisterEnum.Individual ? (
        <div className="individualName">
          <p>{t("whatsYourName")}</p>
          <span>{t("enterFullName")}</span>
        </div>
      ) : (
        <p>{t("companyNameQuestion")}</p>
      )}

      {registerType === RegisterEnum.Individual ? (
        <IndividNameForm
          initialValues={{
            // @ts-ignore
            firstName: name?.firstName || "",
            // @ts-ignore
            lastName: name?.lastName || "",
          }}
          onSubmit={onSubmitIndividual}
        />
      ) : (
        <CompanyNameForm
          initialValues={{
            // @ts-ignore
            companyName: name ? name?.companyName : "",
          }}
          onSubmit={onSubmitBusiness}
        />
      )}
    </div>
  );
};
