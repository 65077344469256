import React, { useEffect, useRef, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { PATH_LIST, RegisterStepPages } from "shared/lib/react-router";
import { RegisterStep, onboardModel } from "entities/onboard";
import { PasswordField } from "widgets/auth/components";
import { Form, Formik } from "formik";
import ValidationCheckbox from "pages/onboarding/components/validation-checkbox";
import Button from "shared/ui/button";
import { useNavigate } from "react-router-dom";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sessionModel } from "entities/session";
import { useStore } from "zustand";

export const ForgotPasswordVerifyPage = () => {
  const [timer, setTimer] = useState(59);
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const phoneNumber = onboardModel.useRegisterPhone();
  const registerType = onboardModel.useRegisterType();
  const { i18n } = useTranslation();
  const { t } = useTranslation("onboarding-auth");
  const { t: err } = useTranslation("errors");
  const { t: y } = useTranslation("yup");
  const { syncUser, clear } = useStore(onboardModel.onboardStore);
  const { addUser } = useStore(sessionModel.sessionStore);

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.forgotPassword);

    return () => setLink("");
  }, []);

  useEffect(() => {
    if (!resend && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else if (!resend && timer === 0) {
      setResend(true);
    }
  }, [timer, resend]);

  const onRegister = async (code: number, pass: string) => {
    const resp = await sircapApi.auth.codeVerify({
      id: id as number,
      password: pass,
      code: code,
      entity: registerType,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.WrongCode:
          setError(true);
          break;

        default:
          break;
      }
    } else {
      if (resp.data.code === ResponseCodes.SuccessLogin) {
        clear();
        const { user, accessToken, refreshToken, entity } = resp.data.data;
        syncUser(user, entity);
        addUser(user, accessToken, refreshToken, entity);

        if (user.regStep < RegisterStep.Done) {
          toast.warn(err("50"));

          if (user.regStep === RegisterStep.ProfileInfo) {
            // @ts-ignore
            navigate(RegisterStepPages[user.regStep]);
          } else {
            // @ts-ignore
            navigate(RegisterStepPages[user.regStep]);
          }
        } else {
          toast.success("Success login");
          navigate(PATH_LIST.home);
        }
      } else {
        toast.error("Server Error");
      }
    }
  };

  const handleResendClick = async () => {
    const resp = await sircapApi.auth.forgot({
      phone: phoneNumber,
      language: i18n.language,
      entity: registerType,
    });

    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        default:
          break;
      }
    } else {
      setResend(false);
      setTimer(59);
    }
  };

  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [result, setResult] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const handleOnChange = (newValue: string) => {
    setError(false);
    setResult(newValue);
  };

  const isFilled = result.length === 4;

  const id = onboardModel.useRegisterId();

  return (
    <div className="enterTheCode  enterTheCodeResetPassword">
      <GoBackButton link={PATH_LIST.forgotPassword} />

      <p>{t("createNewPassword")}</p>

      <Formik
        initialValues={{ password: "" }}
        onSubmit={(values) => {
          onRegister(+result, values.password);
        }}
        validate={(values) => {
          const errors: any = {};
          if (values.password.length < 6) {
            errors.password = "Password must be at least 6 characters";
          }
          if (!/[A-Z]/.test(values.password)) {
            errors.password =
              "Password must contain at least 1 upper case letter";
          }
          if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(values.password)) {
            errors.password = "Password must contain at least 1 symbol";
          }
          if (!/\d/.test(values.password)) {
            errors.password = "Password must contain at least 1 number";
          }
          return errors;
        }}
      >
        {({ values, errors }) => {
          const isAtLeastSixCharactersValid = values.password.length >= 6;
          const containsUpperCaseValid = /[A-Z]/.test(values.password);
          const containsSymbolValid = /[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(
            values.password
          );
          const containsNumberValid = /\d/.test(values.password);

          return (
            <Form className="formResetPassword">
              <PasswordField
                className="passFieldRePassword"
                label={t("password")}
                id="password"
                isInvalid={!!errors.password}
                placeholder={t("restorePassword")}
              />
              <ValidationCheckbox
                term={t("atLeast6Chars")}
                isChecked={isAtLeastSixCharactersValid}
              />
              <ValidationCheckbox
                term={t("containsUpperCase")}
                isChecked={containsUpperCaseValid}
              />
              <ValidationCheckbox
                term={t("containsSymbol")}
                isChecked={containsSymbolValid}
              />
              <ValidationCheckbox
                term={t("containsNumber")}
                isChecked={containsNumberValid}
              />

              <div className="codePartResatPassword">
                <span>
                  {t("smsSentTo")} +{phoneNumber}
                </span>

                <AuthCode
                  allowedCharacters="numeric"
                  length={4}
                  onChange={handleOnChange}
                  ref={AuthInputRef}
                  inputClassName={error ? "red" : isFilled ? "green" : ""}
                  autoFocus={false}
                />
                {error && (
                  <span className="error-verify">{y("codeInvalid")}</span>
                )}

                <span className="codeTextRe">
                  {!resend ? (
                    <>
                      {t("sendCodeAgain")}...{timer}{" "}
                    </>
                  ) : (
                    <span onClick={handleResendClick} className="resendCode">
                      {t("resendCode")}
                    </span>
                  )}
                </span>
              </div>

              <Button
                type="submit"
                disabled={
                  !containsNumberValid ||
                  !containsSymbolValid ||
                  !containsUpperCaseValid ||
                  !isAtLeastSixCharactersValid ||
                  result.length < 4
                }
                variant="dark"
                className="costumBtn"
              >
                {t("restorePassword")}
              </Button>
            </Form>
          );
        }}
      </Formik>

      <span
        className="editYourNumber"
        onClick={() => navigate(PATH_LIST.forgotPassword)}
      >
        {t("editNumber")}
      </span>
    </div>
  );
};
