import React, { useEffect, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import DocumentRow from "../components/document-row";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { onboardModel } from "entities/onboard";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { EntityDocumentsEnum } from "entities/onboard/model/onboardModel";
import { useTranslation } from "react-i18next";

export const SignContractPage = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const { id, documents, dontHaveLicense, syncUser } = useStore(
    onboardModel.onboardStore
  );
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation("onboarding-auth");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.createPassword);

    return () => setLink("");
  }, []);

  const onSubmit = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.sign({
      id: id as number,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.termsAndConditions);
    }
    setIsSubmit(false);
  };

  useEffect(() => {
    for (const [key, value] of Object.entries(documents)) {
      if (!value) {
        if (key === EntityDocumentsEnum.AdditionalDoc) {
          continue;
        } else if (key === EntityDocumentsEnum.LicenseCopies) {
          if (dontHaveLicense) continue;
          setIsValid(false);
          return;
        } else {
          setIsValid(false);
          return;
        }
      }
    }
    setIsValid(true);
  }, [documents, dontHaveLicense]);

  return (
    <div className="signContractPage">
      <h1>{t("signContract")}</h1>

      {/* <div className="warnings">
        <ul>
          <li>Все документы должны быть переведены на англ.язык</li>
          <li>Документы из регистрирующих органов должны быть актуальны</li>
        </ul>
      </div> */}

      <div className="documents">
        {Object.entries(documents).map(([key, value]) =>
          key === EntityDocumentsEnum.AdditionalDoc ? (
            <DocumentRow file={value} key={key} text={key} />
          ) : (
            <DocumentRow file={value} key={key} text={key} />
          )
        )}

        <div className="confirmCorrectness">
          <h2>{t("signature")}</h2>
          <h3>{t("confirmingCorrectnessOfFilledData")}</h3>
          <Button
            className="signContractBtn"
            children={t("confirming")}
            variant="dark"
            disabled={!isValid || isSubmit}
            type="button"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};
