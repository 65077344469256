import React, { useEffect, useState } from "react";
import "./style.scss";
import OpenChatBtn from "../components/open-chat-button";
import SupportIcon from "../../../assets/SupportIcon.svg";
import AiAdviserIcon from "../../../assets/AiAdviserIcon.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { generalSocketStore } from "services/general.socket";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";

export const ChatPage = () => {
  const navigate = useNavigate();
  const { checkChat } = useStore(generalSocketStore);
  const { t } = useTranslation("app");

  useEffect(() => {
    checkChat();
  }, []);

  return (
    <div className="chat">
      <OpenChatBtn
        // onClick={() => navigate(PATH_LIST.chatAI)}
        img={AiAdviserIcon}
        title={t("aiAdviser")}
        info={t("comingSoon")}
        notificationType="gpt"
      />
      <OpenChatBtn
        onClick={() => navigate(PATH_LIST.support)}
        img={SupportIcon}
        title={t("support")}
        notificationType="support"
      />
    </div>
  );
};
