export const GetCountryByCode = (code: string) => {
  return COUNTRIES.find((c) =>
    code.length === 2 ? c.alpha2Code === code : c.alpha3Code === code
  );
};

export const COUNTRIES = [
  {
    name: "Afghanistan",
    alpha2Code: "AF",
    alpha3Code: "AFG",
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
  },
  {
    name: "Åland Islands",
    alpha2Code: "AX",
    alpha3Code: "ALA",
    flag: "https://flagcdn.com/ax.svg",
  },
  {
    name: "Albania",
    alpha2Code: "AL",
    alpha3Code: "ALB",
    flag: "https://flagcdn.com/al.svg",
  },
  {
    name: "Algeria",
    alpha2Code: "DZ",
    alpha3Code: "DZA",
    flag: "https://flagcdn.com/dz.svg",
  },
  {
    name: "American Samoa",
    alpha2Code: "AS",
    alpha3Code: "ASM",
    flag: "https://flagcdn.com/as.svg",
  },
  {
    name: "Andorra",
    alpha2Code: "AD",
    alpha3Code: "AND",
    flag: "https://flagcdn.com/ad.svg",
  },
  {
    name: "Angola",
    alpha2Code: "AO",
    alpha3Code: "AGO",
    flag: "https://flagcdn.com/ao.svg",
  },
  {
    name: "Anguilla",
    alpha2Code: "AI",
    alpha3Code: "AIA",
    flag: "https://flagcdn.com/ai.svg",
  },
  {
    name: "Antarctica",
    alpha2Code: "AQ",
    alpha3Code: "ATA",
    flag: "https://flagcdn.com/aq.svg",
  },
  {
    name: "Antigua and Barbuda",
    alpha2Code: "AG",
    alpha3Code: "ATG",
    flag: "https://flagcdn.com/ag.svg",
  },
  {
    name: "Argentina",
    alpha2Code: "AR",
    alpha3Code: "ARG",
    flag: "https://flagcdn.com/ar.svg",
  },
  {
    name: "Armenia",
    alpha2Code: "AM",
    alpha3Code: "ARM",
    flag: "https://flagcdn.com/am.svg",
  },
  {
    name: "Aruba",
    alpha2Code: "AW",
    alpha3Code: "ABW",
    flag: "https://flagcdn.com/aw.svg",
  },
  {
    name: "Australia",
    alpha2Code: "AU",
    alpha3Code: "AUS",
    flag: "https://flagcdn.com/au.svg",
  },
  {
    name: "Austria",
    alpha2Code: "AT",
    alpha3Code: "AUT",
    flag: "https://flagcdn.com/at.svg",
  },
  {
    name: "Azerbaijan",
    alpha2Code: "AZ",
    alpha3Code: "AZE",
    flag: "https://flagcdn.com/az.svg",
  },
  {
    name: "Bahamas",
    alpha2Code: "BS",
    alpha3Code: "BHS",
    flag: "https://flagcdn.com/bs.svg",
  },
  {
    name: "Bahrain",
    alpha2Code: "BH",
    alpha3Code: "BHR",
    flag: "https://flagcdn.com/bh.svg",
  },
  {
    name: "Bangladesh",
    alpha2Code: "BD",
    alpha3Code: "BGD",
    flag: "https://flagcdn.com/bd.svg",
  },
  {
    name: "Barbados",
    alpha2Code: "BB",
    alpha3Code: "BRB",
    flag: "https://flagcdn.com/bb.svg",
  },
  {
    name: "Belarus",
    alpha2Code: "BY",
    alpha3Code: "BLR",
    flag: "https://flagcdn.com/by.svg",
  },
  {
    name: "Belgium",
    alpha2Code: "BE",
    alpha3Code: "BEL",
    flag: "https://flagcdn.com/be.svg",
  },
  {
    name: "Belize",
    alpha2Code: "BZ",
    alpha3Code: "BLZ",
    flag: "https://flagcdn.com/bz.svg",
  },
  {
    name: "Benin",
    alpha2Code: "BJ",
    alpha3Code: "BEN",
    flag: "https://flagcdn.com/bj.svg",
  },
  {
    name: "Bermuda",
    alpha2Code: "BM",
    alpha3Code: "BMU",
    flag: "https://flagcdn.com/bm.svg",
  },
  {
    name: "Bhutan",
    alpha2Code: "BT",
    alpha3Code: "BTN",
    flag: "https://flagcdn.com/bt.svg",
  },
  {
    name: "Bolivia (Plurinational State of)",
    alpha2Code: "BO",
    alpha3Code: "BOL",
    flag: "https://flagcdn.com/bo.svg",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha2Code: "BQ",
    alpha3Code: "BES",
    flag: "https://flagcdn.com/bq.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    alpha2Code: "BA",
    alpha3Code: "BIH",
    flag: "https://flagcdn.com/ba.svg",
  },
  {
    name: "Botswana",
    alpha2Code: "BW",
    alpha3Code: "BWA",
    flag: "https://flagcdn.com/bw.svg",
  },
  {
    name: "Bouvet Island",
    alpha2Code: "BV",
    alpha3Code: "BVT",
    flag: "https://flagcdn.com/bv.svg",
  },
  {
    name: "Brazil",
    alpha2Code: "BR",
    alpha3Code: "BRA",
    flag: "https://flagcdn.com/br.svg",
  },
  {
    name: "British Indian Ocean Territory",
    alpha2Code: "IO",
    alpha3Code: "IOT",
    flag: "https://flagcdn.com/io.svg",
  },
  {
    name: "United States Minor Outlying Islands",
    alpha2Code: "UM",
    alpha3Code: "UMI",
    flag: "https://flagcdn.com/um.svg",
  },
  {
    name: "Virgin Islands (British)",
    alpha2Code: "VG",
    alpha3Code: "VGB",
    flag: "https://flagcdn.com/vg.svg",
  },
  {
    name: "Virgin Islands (U.S.)",
    alpha2Code: "VI",
    alpha3Code: "VIR",
    flag: "https://flagcdn.com/vi.svg",
  },
  {
    name: "Brunei Darussalam",
    alpha2Code: "BN",
    alpha3Code: "BRN",
    flag: "https://flagcdn.com/bn.svg",
  },
  {
    name: "Bulgaria",
    alpha2Code: "BG",
    alpha3Code: "BGR",
    flag: "https://flagcdn.com/bg.svg",
  },
  {
    name: "Burkina Faso",
    alpha2Code: "BF",
    alpha3Code: "BFA",
    flag: "https://flagcdn.com/bf.svg",
  },
  {
    name: "Burundi",
    alpha2Code: "BI",
    alpha3Code: "BDI",
    flag: "https://flagcdn.com/bi.svg",
  },
  {
    name: "Cambodia",
    alpha2Code: "KH",
    alpha3Code: "KHM",
    flag: "https://flagcdn.com/kh.svg",
  },
  {
    name: "Cameroon",
    alpha2Code: "CM",
    alpha3Code: "CMR",
    flag: "https://flagcdn.com/cm.svg",
  },
  {
    name: "Canada",
    alpha2Code: "CA",
    alpha3Code: "CAN",
    flag: "https://flagcdn.com/ca.svg",
  },
  {
    name: "Cabo Verde",
    alpha2Code: "CV",
    alpha3Code: "CPV",
    flag: "https://flagcdn.com/cv.svg",
  },
  {
    name: "Cayman Islands",
    alpha2Code: "KY",
    alpha3Code: "CYM",
    flag: "https://flagcdn.com/ky.svg",
  },
  {
    name: "Central African Republic",
    alpha2Code: "CF",
    alpha3Code: "CAF",
    flag: "https://flagcdn.com/cf.svg",
  },
  {
    name: "Chad",
    alpha2Code: "TD",
    alpha3Code: "TCD",
    flag: "https://flagcdn.com/td.svg",
  },
  {
    name: "Chile",
    alpha2Code: "CL",
    alpha3Code: "CHL",
    flag: "https://flagcdn.com/cl.svg",
  },
  {
    name: "China",
    alpha2Code: "CN",
    alpha3Code: "CHN",
    flag: "https://flagcdn.com/cn.svg",
  },
  {
    name: "Christmas Island",
    alpha2Code: "CX",
    alpha3Code: "CXR",
    flag: "https://flagcdn.com/cx.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha2Code: "CC",
    alpha3Code: "CCK",
    flag: "https://flagcdn.com/cc.svg",
  },
  {
    name: "Colombia",
    alpha2Code: "CO",
    alpha3Code: "COL",
    flag: "https://flagcdn.com/co.svg",
  },
  {
    name: "Comoros",
    alpha2Code: "KM",
    alpha3Code: "COM",
    flag: "https://flagcdn.com/km.svg",
  },
  {
    name: "Congo",
    alpha2Code: "CG",
    alpha3Code: "COG",
    flag: "https://flagcdn.com/cg.svg",
  },
  {
    name: "Congo",
    alpha2Code: "CD",
    alpha3Code: "COD",
    flag: "https://flagcdn.com/cd.svg",
  },
  {
    name: "Cook Islands",
    alpha2Code: "CK",
    alpha3Code: "COK",
    flag: "https://flagcdn.com/ck.svg",
  },
  {
    name: "Costa Rica",
    alpha2Code: "CR",
    alpha3Code: "CRI",
    flag: "https://flagcdn.com/cr.svg",
  },
  {
    name: "Croatia",
    alpha2Code: "HR",
    alpha3Code: "HRV",
    flag: "https://flagcdn.com/hr.svg",
  },
  {
    name: "Cuba",
    alpha2Code: "CU",
    alpha3Code: "CUB",
    flag: "https://flagcdn.com/cu.svg",
  },
  {
    name: "Curaçao",
    alpha2Code: "CW",
    alpha3Code: "CUW",
    flag: "https://flagcdn.com/cw.svg",
  },
  {
    name: "Cyprus",
    alpha2Code: "CY",
    alpha3Code: "CYP",
    flag: "https://flagcdn.com/cy.svg",
  },
  {
    name: "Czech Republic",
    alpha2Code: "CZ",
    alpha3Code: "CZE",
    flag: "https://flagcdn.com/cz.svg",
  },
  {
    name: "Denmark",
    alpha2Code: "DK",
    alpha3Code: "DNK",
    flag: "https://flagcdn.com/dk.svg",
  },
  {
    name: "Djibouti",
    alpha2Code: "DJ",
    alpha3Code: "DJI",
    flag: "https://flagcdn.com/dj.svg",
  },
  {
    name: "Dominica",
    alpha2Code: "DM",
    alpha3Code: "DMA",
    flag: "https://flagcdn.com/dm.svg",
  },
  {
    name: "Dominican Republic",
    alpha2Code: "DO",
    alpha3Code: "DOM",
    flag: "https://flagcdn.com/do.svg",
  },
  {
    name: "Ecuador",
    alpha2Code: "EC",
    alpha3Code: "ECU",
    flag: "https://flagcdn.com/ec.svg",
  },
  {
    name: "Egypt",
    alpha2Code: "EG",
    alpha3Code: "EGY",
    flag: "https://flagcdn.com/eg.svg",
  },
  {
    name: "El Salvador",
    alpha2Code: "SV",
    alpha3Code: "SLV",
    flag: "https://flagcdn.com/sv.svg",
  },
  {
    name: "Equatorial Guinea",
    alpha2Code: "GQ",
    alpha3Code: "GNQ",
    flag: "https://flagcdn.com/gq.svg",
  },
  {
    name: "Eritrea",
    alpha2Code: "ER",
    alpha3Code: "ERI",
    flag: "https://flagcdn.com/er.svg",
  },
  {
    name: "Estonia",
    alpha2Code: "EE",
    alpha3Code: "EST",
    flag: "https://flagcdn.com/ee.svg",
  },
  {
    name: "Ethiopia",
    alpha2Code: "ET",
    alpha3Code: "ETH",
    flag: "https://flagcdn.com/et.svg",
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha2Code: "FK",
    alpha3Code: "FLK",
    flag: "https://flagcdn.com/fk.svg",
  },
  {
    name: "Faroe Islands",
    alpha2Code: "FO",
    alpha3Code: "FRO",
    flag: "https://flagcdn.com/fo.svg",
  },
  {
    name: "Fiji",
    alpha2Code: "FJ",
    alpha3Code: "FJI",
    flag: "https://flagcdn.com/fj.svg",
  },
  {
    name: "Finland",
    alpha2Code: "FI",
    alpha3Code: "FIN",
    flag: "https://flagcdn.com/fi.svg",
  },
  {
    name: "France",
    alpha2Code: "FR",
    alpha3Code: "FRA",
    flag: "https://flagcdn.com/fr.svg",
  },
  {
    name: "French Guiana",
    alpha2Code: "GF",
    alpha3Code: "GUF",
    flag: "https://flagcdn.com/gf.svg",
  },
  {
    name: "French Polynesia",
    alpha2Code: "PF",
    alpha3Code: "PYF",
    flag: "https://flagcdn.com/pf.svg",
  },
  {
    name: "French Southern Territories",
    alpha2Code: "TF",
    alpha3Code: "ATF",
    flag: "https://flagcdn.com/tf.svg",
  },
  {
    name: "Gabon",
    alpha2Code: "GA",
    alpha3Code: "GAB",
    flag: "https://flagcdn.com/ga.svg",
  },
  {
    name: "Gambia",
    alpha2Code: "GM",
    alpha3Code: "GMB",
    flag: "https://flagcdn.com/gm.svg",
  },
  {
    name: "Georgia",
    alpha2Code: "GE",
    alpha3Code: "GEO",
    flag: "https://flagcdn.com/ge.svg",
  },
  {
    name: "Germany",
    alpha2Code: "DE",
    alpha3Code: "DEU",
    flag: "https://flagcdn.com/de.svg",
  },
  {
    name: "Ghana",
    alpha2Code: "GH",
    alpha3Code: "GHA",
    flag: "https://flagcdn.com/gh.svg",
  },
  {
    name: "Gibraltar",
    alpha2Code: "GI",
    alpha3Code: "GIB",
    flag: "https://flagcdn.com/gi.svg",
  },
  {
    name: "Greece",
    alpha2Code: "GR",
    alpha3Code: "GRC",
    flag: "https://flagcdn.com/gr.svg",
  },
  {
    name: "Greenland",
    alpha2Code: "GL",
    alpha3Code: "GRL",
    flag: "https://flagcdn.com/gl.svg",
  },
  {
    name: "Grenada",
    alpha2Code: "GD",
    alpha3Code: "GRD",
    flag: "https://flagcdn.com/gd.svg",
  },
  {
    name: "Guadeloupe",
    alpha2Code: "GP",
    alpha3Code: "GLP",
    flag: "https://flagcdn.com/gp.svg",
  },
  {
    name: "Guam",
    alpha2Code: "GU",
    alpha3Code: "GUM",
    flag: "https://flagcdn.com/gu.svg",
  },
  {
    name: "Guatemala",
    alpha2Code: "GT",
    alpha3Code: "GTM",
    flag: "https://flagcdn.com/gt.svg",
  },
  {
    name: "Guernsey",
    alpha2Code: "GG",
    alpha3Code: "GGY",
    flag: "https://flagcdn.com/gg.svg",
  },
  {
    name: "Guinea",
    alpha2Code: "GN",
    alpha3Code: "GIN",
    flag: "https://flagcdn.com/gn.svg",
  },
  {
    name: "Guinea-Bissau",
    alpha2Code: "GW",
    alpha3Code: "GNB",
    flag: "https://flagcdn.com/gw.svg",
  },
  {
    name: "Guyana",
    alpha2Code: "GY",
    alpha3Code: "GUY",
    flag: "https://flagcdn.com/gy.svg",
  },
  {
    name: "Haiti",
    alpha2Code: "HT",
    alpha3Code: "HTI",
    flag: "https://flagcdn.com/ht.svg",
  },
  {
    name: "Heard Island and McDonald Islands",
    alpha2Code: "HM",
    alpha3Code: "HMD",
    flag: "https://flagcdn.com/hm.svg",
  },
  {
    name: "Vatican City",
    alpha2Code: "VA",
    alpha3Code: "VAT",
    flag: "https://flagcdn.com/va.svg",
  },
  {
    name: "Honduras",
    alpha2Code: "HN",
    alpha3Code: "HND",
    flag: "https://flagcdn.com/hn.svg",
  },
  {
    name: "Hungary",
    alpha2Code: "HU",
    alpha3Code: "HUN",
    flag: "https://flagcdn.com/hu.svg",
  },
  {
    name: "Hong Kong",
    alpha2Code: "HK",
    alpha3Code: "HKG",
    flag: "https://flagcdn.com/hk.svg",
  },
  {
    name: "Iceland",
    alpha2Code: "IS",
    alpha3Code: "ISL",
    flag: "https://flagcdn.com/is.svg",
  },
  {
    name: "India",
    alpha2Code: "IN",
    alpha3Code: "IND",
    flag: "https://flagcdn.com/in.svg",
  },
  {
    name: "Indonesia",
    alpha2Code: "ID",
    alpha3Code: "IDN",
    flag: "https://flagcdn.com/id.svg",
  },
  {
    name: "Ivory Coast",
    alpha2Code: "CI",
    alpha3Code: "CIV",
    flag: "https://flagcdn.com/ci.svg",
  },
  {
    name: "Iran",
    alpha2Code: "IR",
    alpha3Code: "IRN",
    flag: "https://flagcdn.com/ir.svg",
  },
  {
    name: "Iraq",
    alpha2Code: "IQ",
    alpha3Code: "IRQ",
    flag: "https://flagcdn.com/iq.svg",
  },
  {
    name: "Ireland",
    alpha2Code: "IE",
    alpha3Code: "IRL",
    flag: "https://flagcdn.com/ie.svg",
  },
  {
    name: "Isle of Man",
    alpha2Code: "IM",
    alpha3Code: "IMN",
    flag: "https://flagcdn.com/im.svg",
  },
  {
    name: "Israel",
    alpha2Code: "IL",
    alpha3Code: "ISR",
    flag: "https://flagcdn.com/il.svg",
  },
  {
    name: "Italy",
    alpha2Code: "IT",
    alpha3Code: "ITA",
    flag: "https://flagcdn.com/it.svg",
  },
  {
    name: "Jamaica",
    alpha2Code: "JM",
    alpha3Code: "JAM",
    flag: "https://flagcdn.com/jm.svg",
  },
  {
    name: "Japan",
    alpha2Code: "JP",
    alpha3Code: "JPN",
    flag: "https://flagcdn.com/jp.svg",
  },
  {
    name: "Jersey",
    alpha2Code: "JE",
    alpha3Code: "JEY",
    flag: "https://flagcdn.com/je.svg",
  },
  {
    name: "Jordan",
    alpha2Code: "JO",
    alpha3Code: "JOR",
    flag: "https://flagcdn.com/jo.svg",
  },
  {
    name: "Kazakhstan",
    alpha2Code: "KZ",
    alpha3Code: "KAZ",
    flag: "https://flagcdn.com/kz.svg",
  },
  {
    name: "Kenya",
    alpha2Code: "KE",
    alpha3Code: "KEN",
    flag: "https://flagcdn.com/ke.svg",
  },
  {
    name: "Kiribati",
    alpha2Code: "KI",
    alpha3Code: "KIR",
    flag: "https://flagcdn.com/ki.svg",
  },
  {
    name: "Kuwait",
    alpha2Code: "KW",
    alpha3Code: "KWT",
    flag: "https://flagcdn.com/kw.svg",
  },
  {
    name: "Kyrgyzstan",
    alpha2Code: "KG",
    alpha3Code: "KGZ",
    flag: "https://flagcdn.com/kg.svg",
  },
  {
    name: "Lao People's Democratic Republic",
    alpha2Code: "LA",
    alpha3Code: "LAO",
    flag: "https://flagcdn.com/la.svg",
  },
  {
    name: "Latvia",
    alpha2Code: "LV",
    alpha3Code: "LVA",
    flag: "https://flagcdn.com/lv.svg",
  },
  {
    name: "Lebanon",
    alpha2Code: "LB",
    alpha3Code: "LBN",
    flag: "https://flagcdn.com/lb.svg",
  },
  {
    name: "Lesotho",
    alpha2Code: "LS",
    alpha3Code: "LSO",
    flag: "https://flagcdn.com/ls.svg",
  },
  {
    name: "Liberia",
    alpha2Code: "LR",
    alpha3Code: "LBR",
    flag: "https://flagcdn.com/lr.svg",
  },
  {
    name: "Libya",
    alpha2Code: "LY",
    alpha3Code: "LBY",
    flag: "https://flagcdn.com/ly.svg",
  },
  {
    name: "Liechtenstein",
    alpha2Code: "LI",
    alpha3Code: "LIE",
    flag: "https://flagcdn.com/li.svg",
  },
  {
    name: "Lithuania",
    alpha2Code: "LT",
    alpha3Code: "LTU",
    flag: "https://flagcdn.com/lt.svg",
  },
  {
    name: "Luxembourg",
    alpha2Code: "LU",
    alpha3Code: "LUX",
    flag: "https://flagcdn.com/lu.svg",
  },
  {
    name: "Macao",
    alpha2Code: "MO",
    alpha3Code: "MAC",
    flag: "https://flagcdn.com/mo.svg",
  },
  {
    name: "North Macedonia",
    alpha2Code: "MK",
    alpha3Code: "MKD",
    flag: "https://flagcdn.com/mk.svg",
  },
  {
    name: "Madagascar",
    alpha2Code: "MG",
    alpha3Code: "MDG",
    flag: "https://flagcdn.com/mg.svg",
  },
  {
    name: "Malawi",
    alpha2Code: "MW",
    alpha3Code: "MWI",
    flag: "https://flagcdn.com/mw.svg",
  },
  {
    name: "Malaysia",
    alpha2Code: "MY",
    alpha3Code: "MYS",
    flag: "https://flagcdn.com/my.svg",
  },
  {
    name: "Maldives",
    alpha2Code: "MV",
    alpha3Code: "MDV",
    flag: "https://flagcdn.com/mv.svg",
  },
  {
    name: "Mali",
    alpha2Code: "ML",
    alpha3Code: "MLI",
    flag: "https://flagcdn.com/ml.svg",
  },
  {
    name: "Malta",
    alpha2Code: "MT",
    alpha3Code: "MLT",
    flag: "https://flagcdn.com/mt.svg",
  },
  {
    name: "Marshall Islands",
    alpha2Code: "MH",
    alpha3Code: "MHL",
    flag: "https://flagcdn.com/mh.svg",
  },
  {
    name: "Martinique",
    alpha2Code: "MQ",
    alpha3Code: "MTQ",
    flag: "https://flagcdn.com/mq.svg",
  },
  {
    name: "Mauritania",
    alpha2Code: "MR",
    alpha3Code: "MRT",
    flag: "https://flagcdn.com/mr.svg",
  },
  {
    name: "Mauritius",
    alpha2Code: "MU",
    alpha3Code: "MUS",
    flag: "https://flagcdn.com/mu.svg",
  },
  {
    name: "Mayotte",
    alpha2Code: "YT",
    alpha3Code: "MYT",
    flag: "https://flagcdn.com/yt.svg",
  },
  {
    name: "Mexico",
    alpha2Code: "MX",
    alpha3Code: "MEX",
    flag: "https://flagcdn.com/mx.svg",
  },
  {
    name: "Micronesia (Federated States of)",
    alpha2Code: "FM",
    alpha3Code: "FSM",
    flag: "https://flagcdn.com/fm.svg",
  },
  {
    name: "Moldova",
    alpha2Code: "MD",
    alpha3Code: "MDA",
    flag: "https://flagcdn.com/md.svg",
  },
  {
    name: "Monaco",
    alpha2Code: "MC",
    alpha3Code: "MCO",
    flag: "https://flagcdn.com/mc.svg",
  },
  {
    name: "Mongolia",
    alpha2Code: "MN",
    alpha3Code: "MNG",
    flag: "https://flagcdn.com/mn.svg",
  },
  {
    name: "Montenegro",
    alpha2Code: "ME",
    alpha3Code: "MNE",
    flag: "https://flagcdn.com/me.svg",
  },
  {
    name: "Montserrat",
    alpha2Code: "MS",
    alpha3Code: "MSR",
    flag: "https://flagcdn.com/ms.svg",
  },
  {
    name: "Morocco",
    alpha2Code: "MA",
    alpha3Code: "MAR",
    flag: "https://flagcdn.com/ma.svg",
  },
  {
    name: "Mozambique",
    alpha2Code: "MZ",
    alpha3Code: "MOZ",
    flag: "https://flagcdn.com/mz.svg",
  },
  {
    name: "Myanmar",
    alpha2Code: "MM",
    alpha3Code: "MMR",
    flag: "https://flagcdn.com/mm.svg",
  },
  {
    name: "Namibia",
    alpha2Code: "NA",
    alpha3Code: "NAM",
    flag: "https://flagcdn.com/na.svg",
  },
  {
    name: "Nauru",
    alpha2Code: "NR",
    alpha3Code: "NRU",
    flag: "https://flagcdn.com/nr.svg",
  },
  {
    name: "Nepal",
    alpha2Code: "NP",
    alpha3Code: "NPL",
    flag: "https://flagcdn.com/np.svg",
  },
  {
    name: "Netherlands",
    alpha2Code: "NL",
    alpha3Code: "NLD",
    flag: "https://flagcdn.com/nl.svg",
  },
  {
    name: "New Caledonia",
    alpha2Code: "NC",
    alpha3Code: "NCL",
    flag: "https://flagcdn.com/nc.svg",
  },
  {
    name: "New Zealand",
    alpha2Code: "NZ",
    alpha3Code: "NZL",
    flag: "https://flagcdn.com/nz.svg",
  },
  {
    name: "Nicaragua",
    alpha2Code: "NI",
    alpha3Code: "NIC",
    flag: "https://flagcdn.com/ni.svg",
  },
  {
    name: "Niger",
    alpha2Code: "NE",
    alpha3Code: "NER",
    flag: "https://flagcdn.com/ne.svg",
  },
  {
    name: "Nigeria",
    alpha2Code: "NG",
    alpha3Code: "NGA",
    flag: "https://flagcdn.com/ng.svg",
  },
  {
    name: "Niue",
    alpha2Code: "NU",
    alpha3Code: "NIU",
    flag: "https://flagcdn.com/nu.svg",
  },
  {
    name: "Norfolk Island",
    alpha2Code: "NF",
    alpha3Code: "NFK",
    flag: "https://flagcdn.com/nf.svg",
  },
  {
    name: "Korea",
    alpha2Code: "KP",
    alpha3Code: "PRK",
    flag: "https://flagcdn.com/kp.svg",
  },
  {
    name: "Northern Mariana Islands",
    alpha2Code: "MP",
    alpha3Code: "MNP",
    flag: "https://flagcdn.com/mp.svg",
  },
  {
    name: "Norway",
    alpha2Code: "NO",
    alpha3Code: "NOR",
    flag: "https://flagcdn.com/no.svg",
  },
  {
    name: "Oman",
    alpha2Code: "OM",
    alpha3Code: "OMN",
    flag: "https://flagcdn.com/om.svg",
  },
  {
    name: "Pakistan",
    alpha2Code: "PK",
    alpha3Code: "PAK",
    flag: "https://flagcdn.com/pk.svg",
  },
  {
    name: "Palau",
    alpha2Code: "PW",
    alpha3Code: "PLW",
    flag: "https://flagcdn.com/pw.svg",
  },
  {
    name: "Palestine, State of",
    alpha2Code: "PS",
    alpha3Code: "PSE",
    flag: "https://flagcdn.com/ps.svg",
  },
  {
    name: "Panama",
    alpha2Code: "PA",
    alpha3Code: "PAN",
    flag: "https://flagcdn.com/pa.svg",
  },
  {
    name: "Papua New Guinea",
    alpha2Code: "PG",
    alpha3Code: "PNG",
    flag: "https://flagcdn.com/pg.svg",
  },
  {
    name: "Paraguay",
    alpha2Code: "PY",
    alpha3Code: "PRY",
    flag: "https://flagcdn.com/py.svg",
  },
  {
    name: "Peru",
    alpha2Code: "PE",
    alpha3Code: "PER",
    flag: "https://flagcdn.com/pe.svg",
  },
  {
    name: "Philippines",
    alpha2Code: "PH",
    alpha3Code: "PHL",
    flag: "https://flagcdn.com/ph.svg",
  },
  {
    name: "Pitcairn",
    alpha2Code: "PN",
    alpha3Code: "PCN",
    flag: "https://flagcdn.com/pn.svg",
  },
  {
    name: "Poland",
    alpha2Code: "PL",
    alpha3Code: "POL",
    flag: "https://flagcdn.com/pl.svg",
  },
  {
    name: "Portugal",
    alpha2Code: "PT",
    alpha3Code: "PRT",
    flag: "https://flagcdn.com/pt.svg",
  },
  {
    name: "Puerto Rico",
    alpha2Code: "PR",
    alpha3Code: "PRI",
    flag: "https://flagcdn.com/pr.svg",
  },
  {
    name: "Qatar",
    alpha2Code: "QA",
    alpha3Code: "QAT",
    flag: "https://flagcdn.com/qa.svg",
  },
  {
    name: "Republic of Kosovo",
    alpha2Code: "XK",
    alpha3Code: "UNK",
    flag: "https://flagcdn.com/xk.svg",
  },
  {
    name: "Réunion",
    alpha2Code: "RE",
    alpha3Code: "REU",
    flag: "https://flagcdn.com/re.svg",
  },
  {
    name: "Romania",
    alpha2Code: "RO",
    alpha3Code: "ROU",
    flag: "https://flagcdn.com/ro.svg",
  },
  {
    name: "Russian Federation",
    alpha2Code: "RU",
    alpha3Code: "RUS",
    flag: "https://flagcdn.com/ru.svg",
  },
  {
    name: "Rwanda",
    alpha2Code: "RW",
    alpha3Code: "RWA",
    flag: "https://flagcdn.com/rw.svg",
  },
  {
    name: "Saint Barthélemy",
    alpha2Code: "BL",
    alpha3Code: "BLM",
    flag: "https://flagcdn.com/bl.svg",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha2Code: "SH",
    alpha3Code: "SHN",
    flag: "https://flagcdn.com/sh.svg",
  },
  {
    name: "Saint Kitts and Nevis",
    alpha2Code: "KN",
    alpha3Code: "KNA",
    flag: "https://flagcdn.com/kn.svg",
  },
  {
    name: "Saint Lucia",
    alpha2Code: "LC",
    alpha3Code: "LCA",
    flag: "https://flagcdn.com/lc.svg",
  },
  {
    name: "Saint Martin (French part)",
    alpha2Code: "MF",
    alpha3Code: "MAF",
    flag: "https://flagcdn.com/mf.svg",
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha2Code: "PM",
    alpha3Code: "SPM",
    flag: "https://flagcdn.com/pm.svg",
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha2Code: "VC",
    alpha3Code: "VCT",
    flag: "https://flagcdn.com/vc.svg",
  },
  {
    name: "Samoa",
    alpha2Code: "WS",
    alpha3Code: "WSM",
    flag: "https://flagcdn.com/ws.svg",
  },
  {
    name: "San Marino",
    alpha2Code: "SM",
    alpha3Code: "SMR",
    flag: "https://flagcdn.com/sm.svg",
  },
  {
    name: "Sao Tome and Principe",
    alpha2Code: "ST",
    alpha3Code: "STP",
    flag: "https://flagcdn.com/st.svg",
  },
  {
    name: "Saudi Arabia",
    alpha2Code: "SA",
    alpha3Code: "SAU",
    flag: "https://flagcdn.com/sa.svg",
  },
  {
    name: "Senegal",
    alpha2Code: "SN",
    alpha3Code: "SEN",
    flag: "https://flagcdn.com/sn.svg",
  },
  {
    name: "Serbia",
    alpha2Code: "RS",
    alpha3Code: "SRB",
    flag: "https://flagcdn.com/rs.svg",
  },
  {
    name: "Seychelles",
    alpha2Code: "SC",
    alpha3Code: "SYC",
    flag: "https://flagcdn.com/sc.svg",
  },
  {
    name: "Sierra Leone",
    alpha2Code: "SL",
    alpha3Code: "SLE",
    flag: "https://flagcdn.com/sl.svg",
  },
  {
    name: "Singapore",
    alpha2Code: "SG",
    alpha3Code: "SGP",
    flag: "https://flagcdn.com/sg.svg",
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha2Code: "SX",
    alpha3Code: "SXM",
    flag: "https://flagcdn.com/sx.svg",
  },
  {
    name: "Slovakia",
    alpha2Code: "SK",
    alpha3Code: "SVK",
    flag: "https://flagcdn.com/sk.svg",
  },
  {
    name: "Slovenia",
    alpha2Code: "SI",
    alpha3Code: "SVN",
    flag: "https://flagcdn.com/si.svg",
  },
  {
    name: "Solomon Islands",
    alpha2Code: "SB",
    alpha3Code: "SLB",
    flag: "https://flagcdn.com/sb.svg",
  },
  {
    name: "Somalia",
    alpha2Code: "SO",
    alpha3Code: "SOM",
    flag: "https://flagcdn.com/so.svg",
  },
  {
    name: "South Africa",
    alpha2Code: "ZA",
    alpha3Code: "ZAF",
    flag: "https://flagcdn.com/za.svg",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha2Code: "GS",
    alpha3Code: "SGS",
    flag: "https://flagcdn.com/gs.svg",
  },
  {
    name: "Korea",
    alpha2Code: "KR",
    alpha3Code: "KOR",
    flag: "https://flagcdn.com/kr.svg",
  },
  {
    name: "Spain",
    alpha2Code: "ES",
    alpha3Code: "ESP",
    flag: "https://flagcdn.com/es.svg",
  },
  {
    name: "Sri Lanka",
    alpha2Code: "LK",
    alpha3Code: "LKA",
    flag: "https://flagcdn.com/lk.svg",
  },
  {
    name: "Sudan",
    alpha2Code: "SD",
    alpha3Code: "SDN",
    flag: "https://flagcdn.com/sd.svg",
  },
  {
    name: "South Sudan",
    alpha2Code: "SS",
    alpha3Code: "SSD",
    flag: "https://flagcdn.com/ss.svg",
  },
  {
    name: "Suriname",
    alpha2Code: "SR",
    alpha3Code: "SUR",
    flag: "https://flagcdn.com/sr.svg",
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha2Code: "SJ",
    alpha3Code: "SJM",
    flag: "https://flagcdn.com/sj.svg",
  },
  {
    name: "Swaziland",
    alpha2Code: "SZ",
    alpha3Code: "SWZ",
    flag: "https://flagcdn.com/sz.svg",
  },
  {
    name: "Sweden",
    alpha2Code: "SE",
    alpha3Code: "SWE",
    flag: "https://flagcdn.com/se.svg",
  },
  {
    name: "Switzerland",
    alpha2Code: "CH",
    alpha3Code: "CHE",
    flag: "https://flagcdn.com/ch.svg",
  },
  {
    name: "Syrian Arab Republic",
    alpha2Code: "SY",
    alpha3Code: "SYR",
    flag: "https://flagcdn.com/sy.svg",
  },
  {
    name: "Taiwan",
    alpha2Code: "TW",
    alpha3Code: "TWN",
    flag: "https://flagcdn.com/tw.svg",
  },
  {
    name: "Tajikistan",
    alpha2Code: "TJ",
    alpha3Code: "TJK",
    flag: "https://flagcdn.com/tj.svg",
  },
  {
    name: "Tanzania",
    alpha2Code: "TZ",
    alpha3Code: "TZA",
    flag: "https://flagcdn.com/tz.svg",
  },
  {
    name: "Thailand",
    alpha2Code: "TH",
    alpha3Code: "THA",
    flag: "https://flagcdn.com/th.svg",
  },
  {
    name: "Timor-Leste",
    alpha2Code: "TL",
    alpha3Code: "TLS",
    flag: "https://flagcdn.com/tl.svg",
  },
  {
    name: "Togo",
    alpha2Code: "TG",
    alpha3Code: "TGO",
    flag: "https://flagcdn.com/tg.svg",
  },
  {
    name: "Tokelau",
    alpha2Code: "TK",
    alpha3Code: "TKL",
    flag: "https://flagcdn.com/tk.svg",
  },
  {
    name: "Tonga",
    alpha2Code: "TO",
    alpha3Code: "TON",
    flag: "https://flagcdn.com/to.svg",
  },
  {
    name: "Trinidad and Tobago",
    alpha2Code: "TT",
    alpha3Code: "TTO",
    flag: "https://flagcdn.com/tt.svg",
  },
  {
    name: "Tunisia",
    alpha2Code: "TN",
    alpha3Code: "TUN",
    flag: "https://flagcdn.com/tn.svg",
  },
  {
    name: "Turkey",
    alpha2Code: "TR",
    alpha3Code: "TUR",
    flag: "https://flagcdn.com/tr.svg",
  },
  {
    name: "Turkmenistan",
    alpha2Code: "TM",
    alpha3Code: "TKM",
    flag: "https://flagcdn.com/tm.svg",
  },
  {
    name: "Turks and Caicos Islands",
    alpha2Code: "TC",
    alpha3Code: "TCA",
    flag: "https://flagcdn.com/tc.svg",
  },
  {
    name: "Tuvalu",
    alpha2Code: "TV",
    alpha3Code: "TUV",
    flag: "https://flagcdn.com/tv.svg",
  },
  {
    name: "Uganda",
    alpha2Code: "UG",
    alpha3Code: "UGA",
    flag: "https://flagcdn.com/ug.svg",
  },
  {
    name: "Ukraine",
    alpha2Code: "UA",
    alpha3Code: "UKR",
    flag: "https://flagcdn.com/ua.svg",
  },
  {
    name: "United Arab Emirates",
    alpha2Code: "AE",
    alpha3Code: "ARE",
    flag: "https://flagcdn.com/ae.svg",
  },
  {
    name: "United Kingdom",
    alpha2Code: "GB",
    alpha3Code: "GBR",
    flag: "https://flagcdn.com/gb.svg",
  },
  {
    name: "United States of America",
    alpha2Code: "US",
    alpha3Code: "USA",
    flag: "https://flagcdn.com/us.svg",
  },
  {
    name: "Uruguay",
    alpha2Code: "UY",
    alpha3Code: "URY",
    flag: "https://flagcdn.com/uy.svg",
  },
  {
    name: "Uzbekistan",
    alpha2Code: "UZ",
    alpha3Code: "UZB",
    flag: "https://flagcdn.com/uz.svg",
  },
  {
    name: "Vanuatu",
    alpha2Code: "VU",
    alpha3Code: "VUT",
    flag: "https://flagcdn.com/vu.svg",
  },
  {
    name: "Venezuela",
    alpha2Code: "VE",
    alpha3Code: "VEN",
    flag: "https://flagcdn.com/ve.svg",
  },
  {
    name: "Vietnam",
    alpha2Code: "VN",
    alpha3Code: "VNM",
    flag: "https://flagcdn.com/vn.svg",
  },
  {
    name: "Wallis and Futuna",
    alpha2Code: "WF",
    alpha3Code: "WLF",
    flag: "https://flagcdn.com/wf.svg",
  },
  {
    name: "Western Sahara",
    alpha2Code: "EH",
    alpha3Code: "ESH",
    flag: "https://flagcdn.com/eh.svg",
  },
  {
    name: "Yemen",
    alpha2Code: "YE",
    alpha3Code: "YEM",
    flag: "https://flagcdn.com/ye.svg",
  },
  {
    name: "Zambia",
    alpha2Code: "ZM",
    alpha3Code: "ZMB",
    flag: "https://flagcdn.com/zm.svg",
  },
  {
    name: "Zimbabwe",
    alpha2Code: "ZW",
    alpha3Code: "ZWE",
    flag: "https://flagcdn.com/zw.svg",
  },
];
