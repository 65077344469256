import ChatTextMessage from "./text";
import ChatVoiceMessage from "./voice";
import ChatImageMessage from "./image";
import ChatVideoMessage from "./video";
import ChatFileMessage from "./file";
import ChatTypingTextMessage from "./typing";

export const ChatMessageType = {
  ChatTextMessage,
  ChatVoiceMessage,
  ChatImageMessage,
  ChatVideoMessage,
  ChatFileMessage,
  ChatTypingTextMessage,
};
