import React, { FC, useState } from "react";
import "./style.scss";
import SecurityIcon from "../../../../assets/GoBackApp.svg";
import FilterModal from "../filter-modal";
import { useTranslation } from "react-i18next";
import moment from "moment";

type PropsType = {
  onChange?: any;
  name?: string;
  type?: "range" | "array" | "string";
  value?: string | string[] | null | { from: number; to: number };
  checkbox?: boolean;
  checked?: boolean;
  id?: any;
};
const FilterField: FC<PropsType> = ({
  onChange,
  name = "lorem",
  value = "All",
  type,
  checkbox = false,
  checked,
  id,
}) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation("app");

  const formatValue = (step: number): string | undefined => {
    if (step === 7) return `1 ${t("trillion")} +`;
    if (step === 6) return `100 ${t("billion")}`;
    if (step === 5) return `1 ${t("billion")}`;
    if (step === 4) return `100 ${t("million")}`;
    if (step === 3) return `1 ${t("million")}`;
    if (step === 2) return `100${t("k")}`;
    if (step === 1) return `10${t("k")}`;
    if (step === 0) return `0`;
    return undefined;
  };

  const valueStr =
    typeof value === "string"
      ? value.charAt(0).toUpperCase() + value.slice(1)
      : value;

  return (
    <>
      <label
        htmlFor={checkbox ? id : undefined}
        onClick={!checkbox ? () => setModal(true) : undefined}
        className="filter-field"
      >
        <p>{name}</p>
        {!checkbox ? (
          <div className="filter-field-value">
            {!checkbox ? (
              <span>
                {type === "array"
                  ? value
                    ? // @ts-ignore
                      `${t("selected")}: ${value.length}`
                    : t("all")
                  : type === "range"
                  ? value
                    ? id !== "maturityDate"
                      ? ["dividendYield", "yieldMaturity"].includes(id) // @ts-ignore
                        ? `${value.from}% - ${value.to}%` // @ts-ignore
                        : `${formatValue(value.from)} - ${formatValue(
                            // @ts-ignore
                            value.to
                          )}` // @ts-ignore
                      : `${value.from} - ${value.to}`
                    : t("all")
                  : t(valueStr?.toString() ?? t("all"))}
              </span>
            ) : (
              <span>{checked}</span>
            )}
            <img
              style={{ rotate: "180deg" }}
              src={SecurityIcon}
              alt="Change-icon"
            />
          </div>
        ) : (
          <div className="filter-field-checkbox">
            <label className="toggle">
              <input
                type="checkbox"
                className="toggle__input"
                id={id}
                checked={checked}
                onChange={onChange}
              />
              <span className="toggle-track">
                <span className="toggle-indicator">
                  <span className="checkMark">
                    <svg
                      viewBox="0 0 24 24"
                      id="ghq-svg-check"
                      role="presentation"
                      aria-hidden="true"
                    >
                      <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                    </svg>
                  </span>
                </span>
              </span>
            </label>
          </div>
        )}
      </label>

      {modal && (
        <FilterModal
          type={id}
          onClose={() => setModal(false)}
          value={value}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default FilterField;
