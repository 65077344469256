import React, { useEffect, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import "react-international-phone/style.css";
import { RegisterEmailForm } from "widgets/auth/forms";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { onboardModel } from "entities/onboard";
import { useStore } from "zustand";
import { toast } from "react-toastify";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import Modal from "widgets/auth/modal";

export const RegisterEmailPage = () => {
  const navigate = useNavigate();
  const { syncUser } = useStore(onboardModel.onboardStore);
  const registerType = onboardModel.useRegisterType();
  const { email, setEmail } = useStore(onboardModel.onboardStore);
  const [isSubmit, setIsSubmit] = useState(false);
  const { i18n, t } = useTranslation("onboarding-auth");
  const [showModal, setShowModal] = useState(false);

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerPhone);

    return () => setLink("");
  }, []);

  const onRegister = async (email: string) => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.regEmail({
      email,
      entity: registerType,
      language: i18n.language,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.UserIsExists:
          setShowModal(true);
          break;

        case ResponseCodes.EmailIsUsed:
          toast.error("Email is used");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should verify phone again");
          navigate(PATH_LIST.registerPhone);
          break;
        default:
          break;
      }
    } else {
      setEmail(email);
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.verifyEmail);
    }
    setIsSubmit(false);
  };

  return (
    <div className="registerEmail">
      <GoBackButton link={PATH_LIST.registerPhone} />
      <p>{t("emailAddress")}</p>
      <span>{t("enterEmail")}</span>

      <RegisterEmailForm
        isSubmit={isSubmit}
        initialValues={{ email: email || "" }}
        onSubmit={onRegister}
      />

      {showModal && (
        <Modal
          title={t("accExists")}
          text={t("accWhitPNExists")}
          btnText={t("logIn")}
          showSecondBtn={false}
          onClick={() => {
            // setShowModal(false);
            navigate(PATH_LIST.login);
          }}
          onHide={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
