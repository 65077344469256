import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import "./style.scss";
import RadioCard from "../components/radio-card";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { useStore } from "zustand";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

export const InvestingWithSavingsPage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const { syncUser } = useStore(onboardModel.onboardStore);
  const [isSubmitting, setIsSubmit] = useState(false);
  const [selected, setSelected] = useState<boolean | undefined>(
    "areInvestWithSavings" in profileInfo
      ? profileInfo.areInvestWithSavings
      : undefined
  );

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.whatDoYouDoForALiving);

    return () => setLink("");
  }, []);

  const HandleNavigat = async () => {
    setProfileInfo(ProfileInfoStep.AreInvest, {
      areInvestWithSavings: selected,
    });

    setIsSubmit(true);

    const resp = await sircapApi.onboarding.profileInfo({
      profileInfo: {
        ...profileInfo,
        birthDate: `${profileInfo.birthDate?.day}.${profileInfo.birthDate?.month}.${profileInfo.birthDate?.year}`,
      },
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.chooseDocType);
    }
    setIsSubmit(false);
  };
  const { t } = useTranslation("onboarding-auth");

  return (
    <div className="investingWithSavings Box">
      <GoBackButton link={PATH_LIST.whatDoYouDoForALiving} />
      <p>{t("investingWithSavings")}</p>

      <RadioCard
        text={t("yes")}
        htmlForId="yes"
        checked={selected}
        onClick={() => setSelected(true)}
      />
      <RadioCard
        text={t("no")}
        htmlForId="no"
        checked={selected === false}
        onClick={() => setSelected(false)}
      />

      <Button
        type="submit"
        onClick={HandleNavigat}
        className="registerNameBtn"
        variant={"dark"}
        disabled={selected === undefined || isSubmitting}
      >
        {t("continue")}
      </Button>
    </div>
  );
};
