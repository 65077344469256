import React, { FC } from "react";

import "./style.scss";
type PropType = {
  title: string;
  info: string;
  type?: any[];
};
const ReviewDetails: FC<PropType> = ({ title, info, type }) => {
  return (
    <div
      className="review-details-row"
      style={{
        flexDirection: type ? "column" : "row",
        gap: type ? "4px" : "12px",
      }}
    >
      <p>{title}</p>
      {!type ? (
        <span>{info}</span>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            rowGap: "0px",
            justifyContent: "flex-start",
          }}
        >
          {type.map((arrInfo, index) => (
            <span key={index}>
              {arrInfo}
              {index < type.length - 1 && ","}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReviewDetails;
