import { sessionModel } from "entities/session";
import { useCurrentUser } from "entities/session/model/sessionModel";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "zustand";

const useCanAction = () => {
  const user = useCurrentUser();
  const { setInactiveAccountModal, setSignContractModal, logout } = useStore(
    sessionModel.sessionStore
  );
  const navigate = useNavigate();

  const action = (cb: any) => {
    if (!user) {
      // logout
    } else if (user?.status !== 1) {
      // check if user is not active
      setInactiveAccountModal(true);
    } else if (!user?.signContract) {
      // check if user not sign contract
      setSignContractModal(true);
    } else {
      cb();
    }
  };

  return action;
};

export default useCanAction;
