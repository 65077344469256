import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import "./style.scss";
import RadioCard from "../components/radio-card";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

export const RegisterGenderPage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const [gender, setGender] = useState(profileInfo.gender || "");
  const { t } = useTranslation("onboarding-auth");


  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.createPassword);

    return () => setLink("");
  }, []);

  const HandleNavigate = () => {
    setProfileInfo(ProfileInfoStep.Birth, { gender });
    navigate(PATH_LIST.registerBirthday);
  };

  return (
    <div className="registerGender">
      <GoBackButton link={PATH_LIST.createPassword} />
      <p>{t("whatsYourGender")}</p>

      <RadioCard
        text={t("male")}
        htmlForId="male"
        checked={gender === "male"}
        onClick={() => setGender("male")}
      />
      <RadioCard
        text={t("female")}
        htmlForId="female"
        checked={gender === "female"}
        onClick={() => setGender("female")}
      />

      <Button
        onClick={HandleNavigate}
        className="registerNameBtn"
        variant={"dark"}
        disabled={!gender}
      >
        Continue
      </Button>
    </div>
  );
};
