import { sessionModel } from "entities/session";
import { sircapApi } from "shared/api/sircap";
import { Socket, io } from "socket.io-client";
import { createStore, useStore } from "zustand";

const baseUrl = process.env.REACT_APP_SIRCAP_API as string;

interface IOStore {
  socket: Socket;
  connected: boolean;
  isLoading: boolean;
  rooms: {
    id: number;
    user_id: number;
    user_type: string;
    unread_messages_user: number;
    type: string;
  }[];
  connect: () => void;
  disconnect: () => void;
  setConnected: (is: boolean) => void;
  addRoom: (room: any) => void;
  checkChat: () => Promise<void>;
}
const generalSocketStore = createStore<IOStore>()((set, get) => ({
  socket: io({ autoConnect: false }),
  connected: false,
  isLoading: true,
  rooms: [],

  connect: () => {
    const oldSocket = get().socket;
    if (oldSocket) oldSocket.disconnect();
    const socket = io(baseUrl, {
      path: "/general-socket",
      autoConnect: false,
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 1500,
      reconnectionDelayMax: 2000,
      query: {
        token: sessionModel.sessionStore.getState().accessToken,
      },
    });

    socket
      .on("connect", () => {
        set({ connected: true, isLoading: false });
      })
      .on("disconnect", () => {
        set({ connected: false });
      })
      .on("connect_error", () => {
        set({ connected: false });
      });

    set({
      isLoading: true,
      socket: socket,
    });
    socket.connect();
  },
  disconnect: () => {
    const oldSocket = get().socket;
    if (oldSocket) oldSocket.disconnect();
  },
  setConnected: (is: boolean) => {
    set({ connected: is });
  },
  addRoom: (room: any) => {
    const rooms = get().rooms;
    const existsRoom = rooms.find((r) => r.id === room.id);
    const newRoomsList = existsRoom
      ? rooms.map((r) =>
          r.id === room.id
            ? { ...r, unread_messages_user: room.unread_messages_user }
            : r
        )
      : [...rooms, room];
    set({ rooms: newRoomsList });
  },
  checkChat: async () => {
    try {
      const resp = await sircapApi.chat.check({});
      set({ rooms: resp.data.data });
    } catch (error) {}
  },
}));

const useUnreadMessagesCount = (type: string) =>
  useStore(generalSocketStore, (s) =>
    type === "all"
      ? s.rooms
          .map((r) => r.unread_messages_user)
          .concat(0)
          .reduce((a, b) => a + b)
      : s.rooms.find((r) => r.type === type)?.unread_messages_user || 0
  );

export { generalSocketStore, useUnreadMessagesCount };
