import React, { useEffect, useState } from "react";
import "./style.scss";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "shared/ui/button";
import { PATH_LIST } from "shared/lib/react-router";
import LogoAndTitle from "../components/logo-title";
import { useTranslation } from "react-i18next";
// import { supported } from "@github/webauthn-json/browser-ponyfill";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { toast } from "react-toastify";

export const LoginPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("onboarding-auth");
  const [supportBiometrics, setSupportBiometric] = useState(false);

  useEffect(() => {
    setSupportBiometric(browserSupportsWebAuthn());
  }, []);

  const HandleNavigateToPassword = () => {
    navigate(PATH_LIST.loginPassword);
  };

  const HandleNavigatToBiometrics = () => {
    if (supportBiometrics) {
      // navigate(PATH_LIST.loginBiometric);
      toast.info("Coming soon");
    } else {
      toast.warning("Sorry but your device is not support biometrics");
    }
  };

  const HnagleNavigate = () => {
    navigate(PATH_LIST.forgotPassword);
  };

  return (
    <>
      <div className="login">
        <LogoAndTitle title={t("hello")} />

        <div className="loginBtns">
          {/* <Button onClick={HandleNavigatToBiometrics} variant={"dark"}>
            {t("logInUsingBiometrics")}
          </Button> */}

          <Button onClick={HandleNavigateToPassword} variant={"light"}>
            {t("logInUsingPassword")}
          </Button>
        </div>
        <span onClick={HnagleNavigate} className="forgotPassword">
          {t("forgotYourPassword")}
        </span>

        <NavLink className="navLinkLoginMobile" to={PATH_LIST.chooseAccType}>
          <p>
            {t("dontHaveAcc")} <span>{t("signUp")}</span>
          </p>
        </NavLink>
      </div>
    </>
  );
};
