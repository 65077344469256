import React, { FC } from "react";
import "./style.scss";
import { UploadFile } from "widgets/auth/components";
import { useTranslation } from "react-i18next";
import { EntityDocumentsEnum } from "entities/onboard/model/onboardModel";

type PropsType = {
  text: string;
  file: string;
};

const DocumentRow: FC<PropsType> = ({ text, file }) => {
  const { t } = useTranslation("onboarding-auth");

  return (
    <div className="documentRow">
      {text === EntityDocumentsEnum.AdditionalDoc ? (
        <p>{t(text)}</p>
      ) : (
        <span>{t(text)}</span>
      )}
      <UploadFile
        id={text}
        license={text === EntityDocumentsEnum.LicenseCopies}
        addDocName={text === EntityDocumentsEnum.AdditionalDoc}
        file={file}
      />
    </div>
  );
};

export default DocumentRow;
