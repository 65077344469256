import React, { FC } from "react";
import "./style.scss";
import { CURRENCY_FLAGS } from "static/currencies";

type PropType = {
  currency: string;
  htmlForId: string;
  isChecked?: boolean;
  onChange?: () => void;
  customClass?: string;
};

export const AddAccCurrencyRow: FC<PropType> = ({
  currency,
  htmlForId,
  isChecked,
  onChange,
  customClass,
}) => {
  return (
    <label
      onChange={onChange}
      htmlFor={htmlForId}
      className={`add-account-currency-checkbox ${customClass}`}
    >
      <div>
        <img src={CURRENCY_FLAGS[currency]} alt={`${currency}-icon`} />
        <p>{currency}</p>
      </div>
      <input
        id={htmlForId}
        className="checkbox"
        type="checkbox"
        checked={isChecked}
        readOnly
      />
    </label>
  );
};
