import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "widgets/loading";

const NotFoundContainer: React.FC<
  PropsWithChildren<{
    condition: boolean;
    isLoading?: boolean;
    animation?: boolean;
  }>
> = ({ condition, isLoading, children, animation }) => {
  const { t } = useTranslation("app");

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "160px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (condition) {
    return <>{children}</>;
  } else {
    return (
      <div
        className="not-found-coming-soon"
        style={{
          marginBlock: "50px",
          animationDuration: animation ? "0s" : undefined,
        }}
      >
        <p>{t("notFound")}</p>
      </div>
    );
  }
};

export default NotFoundContainer;
