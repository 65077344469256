import { initializeApp } from "firebase/app";
import {
  Messaging,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";

const config = {
  apiKey: "AIzaSyA8QE0NnKdMfH_h3P9uOlSwVy4W_lx0I7s",
  authDomain: "sircap-dev.firebaseapp.com",
  projectId: "sircap-dev",
  storageBucket: "sircap-dev.appspot.com",
  messagingSenderId: "731140448324",
  appId: "1:731140448324:web:87ccdaff8316814c465c11",
  measurementId: "G-ENWSVW3FDJ",
};

const app = initializeApp(config);

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    return null;
  } catch (err) {
    return null;
  }
})();

export const requestForToken = () => {
  return new Promise(async (resolve, reject) => {
    const messagingService = await messaging;
    if (!messagingService) resolve(null);
    getToken(messagingService as Messaging, {})
      .then((currentToken) => {
        if (currentToken) {
          // console.log("current token for client: ", currentToken);
          resolve(currentToken);
        } else {
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // );
          reject(null);
        }
      })
      .catch((err) => {
        // console.log("An error occurred while retrieving token. ", err);
        reject(err);
      });
  });
};

export const onMessageListener = () => {
  return new Promise(async (resolve) => {
    const messagingService = await messaging;
    if (!messagingService) return;
    onMessage(messagingService as Messaging, (payload: any) => {
      resolve(payload);
    });
  });
};
