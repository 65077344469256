import React, { useEffect, useRef, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { RegisterEnum, onboardModel } from "entities/onboard";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";

export const VerifyPhonePage = () => {
  const phoneNumber = onboardModel.useRegisterPhone();
  const registerType = onboardModel.useRegisterType();

  const [timer, setTimer] = useState(59);
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const id = onboardModel.useRegisterId();
  const { syncUser } = useStore(onboardModel.onboardStore);
  const { i18n } = useTranslation("errors");
  const { t } = useTranslation("onboarding-auth");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerPhone);

    return () => setLink("");
  }, []);

  useEffect(() => {
    if (!resend && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else if (!resend && timer === 0) {
      setResend(true);
    }
  }, [timer, resend]);

  const handleResendClick = async () => {
    const resp = await sircapApi.onboarding.regPhone({
      phone: phoneNumber,
      entity: registerType as RegisterEnum,
      language: i18n.language,
    });

    if (resp.error) {
      //
    } else {
      setResend(false);
      setTimer(59);
    }
  };

  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [result, setResult] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const { t: y } = useTranslation("yup");
  const onRegister = async (code: number) => {
    const resp = await sircapApi.onboarding.verifyPhone({
      id: id as number,
      code,
      entity: registerType,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.WrongCode:
          setError(true);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.registerEmail);
    }
  };

  const handleOnChange = (newValue: string) => {
    setResult(newValue);
    setError(false);

    if (newValue.length === 4) {
      onRegister(+newValue);
    }
  };
  const isFilled = result.length === 4;

  useEffect(() => {
    if ("OTPCredential" in window) {
      // alert("OTPCredential exists!");
      window.addEventListener("DOMContentLoaded", (e) => {
        const input = document.querySelector(
          'input[autocomplete="one-time-code"]'
        );
        if (!input) {
          // alert("no-input");
          return;
        }
        const ac = new AbortController();
        const form = input.closest("form");
        if (form) {
          form.addEventListener("submit", (e) => {
            ac.abort();
          });
        }
        navigator.credentials
          .get({
            // @ts-ignore
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            alert(otp);
            // @ts-ignore
            input.value = otp.code;
            if (form) form.submit();
          })
          .catch((err) => {
            alert(err);
          });
      });
    }
  }, []);

  return (
    <div className="enterTheCode">
      <GoBackButton link={PATH_LIST.registerPhone} />
      <p>{t("enterCode")}</p>
      <span>
        {t("smsSentTo")} +{phoneNumber}
      </span>

      <AuthCode
        allowedCharacters="numeric"
        length={4}
        onChange={handleOnChange}
        ref={AuthInputRef}
        inputClassName={error ? "red" : isFilled ? "green" : ""}
      />

      {error && <span className="error-verify">{y("codeInvalid")}</span>}

      {!resend ? (
        <span className=" codeTextRe sendCodeAgain">
          {t("sendCodeAgain")}...{timer}{" "}
        </span>
      ) : (
        <span onClick={handleResendClick} className="codeTextRe resendCode">
          {t("resendCode")}
        </span>
      )}

      <span
        className="editYourNumber"
        onClick={() => navigate(PATH_LIST.registerPhone)}
      >
        {t("editNumber")}
      </span>
    </div>
  );
};
