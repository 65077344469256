import { sessionModel } from "entities/session";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import BE1 from "assets/BackgroundElTop.png";
import BE2 from "assets/BackgroundElBottom.png";
import Logo from "assets/Logo.svg";
import LogoSmall from "../../../assets/LogoSmall.svg";
import { PATH_LIST, PATH_PAGE } from "shared/lib/react-router";
import Am from "../../../assets/Armenia.svg";
import Ru from "../../../assets/Russia.svg";
import US from "../../../assets/US.svg";
import { useTranslation } from "react-i18next";
import GoBackButton from "shared/ui/back-button";
import { useCallback, useEffect } from "react";
import { sircapApi } from "shared/api/sircap";

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

export function MainLayout() {
  const user = sessionModel.useCurrentUser();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { t: tBase } = useTranslation();
  const { t } = useTranslation("onboarding-auth");
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.language) {
      if (user.language !== i18n.language) {
        i18n.changeLanguage(user.language);
        const html = document.getElementsByTagName("html");
        html[0].setAttribute("lang", user.language);
      }
    }
  }, [user?.language, i18n?.language]);

  const changeLanguage = useCallback(
    (language: string): void => {
      if (i18n.isInitialized && i18n.language !== language) {
        i18n.changeLanguage(language);
        sircapApi.changeLanguage(language);

        // document.cookie = `i18next=${language}; SameSite=Lax; Domain=.${domain}; path=/; Secure`;
        const html = document.getElementsByTagName("html");
        html[0].setAttribute("lang", language);
      }
    },
    [i18n]
    // [tBase, i18n]
  );

  return (
    <div
      className={`main-layout ${
        location.pathname === PATH_LIST.accountHasBeenCreated &&
        "acc-created-layout"
      }`}
    >
      <nav
        className={
          location.pathname === PATH_LIST.chooseAccType ||
          location.pathname === PATH_LIST.login
            ? "navbar"
            : location.pathname === PATH_LIST.accountHasBeenCreated
            ? "navbar navbar3 acc-created-layout"
            : location.pathname === PATH_LIST.forgotPassword ||
              location.pathname === PATH_LIST.forgotPasswordVerify ||
              location.pathname === PATH_LIST.loginPassword
            ? "navbar navbarAuth"
            : "navbar navbar2"
        }
      >
        <img
          // onClick={() => window.open("https://siriuscapital.am/", "_blank")}
          onClick={() => navigate("/")}
          className="logo"
          src={Logo}
          alt="logo"
        />
        <img
          // onClick={() => window.open("https://siriuscapital.am/", "_blank")}
          onClick={() => navigate("/")}
          className="logo-small"
          src={LogoSmall}
          alt="logo"
        />

        <GoBackButton
          classNameText="goBackNavbar2Text"
          className="goBackNavbar2"
        />

        <div className="navbarRight">
          <li className="language">
            <ul className="languageH">
              <li
                className={i18n.language == "am" ? "active" : ""}
                onClick={() => changeLanguage("am")}
              >
                <img src={Am} alt="Armenia" /> {t("armenia")}
              </li>
              <li
                className={i18n.language == "ru" ? "active" : ""}
                onClick={() => changeLanguage("ru")}
              >
                <img src={Ru} alt="Russia" /> {t("russian")}
              </li>
              <li
                className={i18n.language == "en" ? "active" : ""}
                onClick={() => changeLanguage("en")}
              >
                <img src={US} alt="US" /> {t("english")}
              </li>
            </ul>
          </li>
          {location.pathname === PATH_LIST.chooseAccType && (
            <NavLink className="navLinkLogin" to={PATH_LIST.login}>
              <p>{t("logIn")}</p>
            </NavLink>
          )}
          {location.pathname.includes("/auth/login") && (
            <NavLink className="navLinkLogin" to={PATH_LIST.chooseAccType}>
              <p>{t("signUp")}</p>
            </NavLink>
          )}
        </div>
      </nav>

      <img
        className="backgroundElement backgroundElementTop"
        src={BE1}
        alt="BackgroundElTop"
      />
      <img
        className="backgroundElement backgroundElementBottom"
        src={BE2}
        alt="BackgroundElBottom"
      />

      <Outlet />
      {/* footer */}
    </div>
  );
}
