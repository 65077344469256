import React, { FC } from "react";
import Vector from "../../../../assets/Vector.svg";
import "./style.scss";

type JoinUs = {
  image: string;
  accountVariant: string;
  info: string;
  onClick: any;
};

const JoinUsVariant: FC<JoinUs> = ({
  image,
  accountVariant,
  info,
  onClick,
}) => {
  return (
    <div className="joinCardLink" onClick={onClick}>
      <div className="joinCard">
        <div className="variantIcon">
          <img src={image} alt="Join Us Img" />
        </div>
        <div className="text">
          <p>{accountVariant}</p>
          <span>{info}</span>
        </div>
        <div className="vector">
          <img src={Vector} alt="Vector" />
        </div>
      </div>
    </div>
  );
};

export default JoinUsVariant;
