import React, { FC, useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "absolute",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  zIndex: "1000",
  color: "red",
};
type PropsType = {
  handleFier: boolean;
};

const CanvasConfetti: FC<PropsType> = ({ handleFier }) => {
  const refAnimationInstance = useRef(null);

  // @ts-ignore
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  //@ts-ignore
  const makeShot = useCallback((particleRatio, opts) => {
    if (refAnimationInstance.current) {
      //@ts-ignore
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.9 },
        particleCount: Math.floor(300 * particleRatio),
        // colors: ["#F8009E", "#FFFDD0"],
        colors: ["#F8009E", "#FAEBD7"],
        // colors: ["#F8009E", "#C0F3BF"],
      });
    }
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    if (handleFier) {
      fire();
    }
  }, [handleFier]);

  //@ts-ignore
  return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
};

export default CanvasConfetti;
