import React, { useEffect, useRef, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { RegisterEnum, onboardModel } from "entities/onboard";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const VerifyEmailPage = () => {
  const [timer, setTimer] = useState(59);
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const { syncUser } = useStore(onboardModel.onboardStore);
  const email = onboardModel.useRegisterEmail();
  const registerType = onboardModel.useRegisterType();
  const [error, setError] = useState<boolean>(false);
  const { i18n, t } = useTranslation("onboarding-auth");
  const { t: y } = useTranslation("yup");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerEmail);

    return () => setLink("");
  }, []);

  useEffect(() => {
    if (!resend && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else if (!resend && timer === 0) {
      setResend(true);
    }
  }, [timer, resend]);

  const handleResendClick = async () => {
    const resp = await sircapApi.onboarding.regEmail({
      email: email,
      entity: registerType,
      language: i18n.language,
    });

    if (resp.error) {
      //
    } else {
      setResend(false);
      setTimer(59);
    }
  };

  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [result, setResult] = useState<string>("");
  const handleOnChange = (newValue: string) => {
    setResult(newValue);

    if (newValue.length === 4) {
      onVerify(+newValue);
    }
  };
  const isFilled = result.length === 4;

  const handleNavigate = () => {
    navigate(PATH_LIST.registerEmail);
  };

  const onVerify = async (code: number) => {
    const resp = await sircapApi.onboarding.verifyEmail({
      code,
      entity: registerType,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.WrongCode:
          setError(true);
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should verify phone again");
          navigate(PATH_LIST.registerPhone);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.registerName);
    }
  };

  return (
    <div className="enterTheCode">
      <GoBackButton link={PATH_LIST.registerEmail} />
      <p>{t("youveGotMail")}</p>
      <span>{t("otpSentToEmail")}</span>

      <AuthCode
        allowedCharacters="numeric"
        length={4}
        onChange={handleOnChange}
        ref={AuthInputRef}
        inputClassName={error ? "red" : isFilled ? "green" : ""}
      />
      {error && <span className="error-verify">{y("codeInvalid")}</span>}

      {!resend ? (
        <span className=" codeTextRe sendCodeAgain">
          {t("sendCodeAgain")}...{timer}{" "}
        </span>
      ) : (
        <span onClick={handleResendClick} className="codeTextRe resendCode">
          {t("resendCode")}
        </span>
      )}

      <span className="editYourNumber" onClick={handleNavigate}>
        {t("editEmail")}
      </span>
    </div>
  );
};
