import React, { FC, useState } from "react";
import "./style.scss";
import Edit from "../../../../assets/icon-edit.svg";
import Trash from "../../../../assets/icon-trash.svg";
import { useTranslation } from "react-i18next";

type PropType = {
  onClose: () => void;
  onRenameClick: () => void;
  onRemoveClick: () => void;
};

export const EditFilterCard: FC<PropType> = ({
  onClose,
  onRenameClick,
  onRemoveClick,
}) => {
  const { t } = useTranslation("app");

  return (
    <>
      <div onClick={onClose} className="outlet-edit-filter-card"></div>

      <div className="edit-filter-card">
        <div className="edit-filter-card-header">
          <p>{t("changeFilter")}</p>
        </div>

        <div onClick={onRenameClick} className="edit-filter-row">
          <img src={Edit} alt="icon" />
          <p>{t("rename")}</p>
        </div>

        <div onClick={onRemoveClick} className="edit-filter-row">
          <img src={Trash} alt="icon" />
          <p>{t("remove")}</p>
        </div>
      </div>
    </>
  );
};
