import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import DatePicker from "../components/date-picker";
import RadioCard from "pages/onboarding/components/radio-card";
import Button from "shared/ui/button";

export const TaxReportPage = () => {
  const navigate = useNavigate();
  const [yearDate, setYearDate] = useState(new Date());

  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };

  return (
    <div className="tax-report">
      <div className="tax-report-header">
        <img
          className="go-back-tax"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>Tax report</p>
      </div>
      <div className="tax-report-main">
        <div className="tax-important-info">
          Tax report is only for Armenian residents.
        </div>
        <p className="tax-section-name">Select year</p>
        <div className="report-date-wrapper">
          <DatePicker
            deadline="Year"
            selected={yearDate}
            onChange={(date: any) => setYearDate(date)}
            report={true}
          />
        </div>

        <p className="tax-section-name">Select language</p>
        <div className="select-tax-language">
          <RadioCard
            customClass="tax-language"
            checked
            onClick={console.log("dd")}
            text="Armenian"
            htmlForId="radio"
          />
          <RadioCard
            customClass="tax-language"
            onClick={console.log("dd")}
            text="English"
            htmlForId="radio1"
          />
          <RadioCard
            customClass="tax-language"
            onClick={console.log("dd")}
            text="Russian"
            htmlForId="radio2"
          />
        </div>

        <div className="btn-wrapper">
          <Button type="submit" variant="dark" className="costumBtn">
            Request
          </Button>
        </div>
      </div>
    </div>
  );
};
