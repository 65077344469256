import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import SendMessage from "../../../assets/SendMessage.svg";
import SendVoice from "../../../assets/SendVoice.svg";
import SendFile from "../../../assets/SendFile.svg";
import DeleteVoice from "../../../assets/DeleteVoice.png";
import SupportIcon from "../../../assets/SupportIcon.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import CommandQuestion from "../components/command-question";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { SendFileModal } from "../components/send-file-modal";
import Modal from "widgets/auth/modal";
import { useStore } from "zustand";
import { ChatType, ToChatType, chatStore } from "services/chat.socket";
import moment from "moment";
import { Spinner } from "widgets/loading";
import Audio from "shared/ui/Audio";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import ChatMessage, {
  ChatMessageWrapper,
  MessageType,
} from "../components/chat-message";
import { VueImageMessage } from "../components/chat-message/types/image";
import PageDown from "../../../assets/PageDown.png";
import { VueVideoMessage } from "../components/chat-message/types/video";
import { blobToFile, getChatFileUrl } from "helpers/functions";
import { toast } from "react-toastify";
import TakePhotoModal from "pages/onboarding/components/take-photo-modal";
import { DocumentType } from "static/types";
import { useTranslation } from "react-i18next";

interface IChat {
  room_id: number;
  id: number;
  sender: string;
  customer: string;
  text: string;
  type: MessageType;
  file: string | null;
  createdAt: string | any;
}

export const SupportChatPage = ({}) => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("sms");
  const [uploadModal, setUploadModal] = useState(false);
  const [stopRecordingModal, setStopRecordingModal] = useState("False");
  const [commands, setCommands] = useState(true);
  const [chat, setChat] = useState<
    (IChat & {
      isLast: boolean;
      padding: number;
      lastMessageDate: null | string;
    })[]
  >([]);
  const [imageDate, setImageDate] = useState<IChat & { createdAt: string }>();
  const [imageModal, setImageModal] = useState(false);
  const [videoDate, setVideoDate] = useState<IChat & { createdAt: string }>();
  const [videoUrl, setVideoUrl] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>();
  const [videoModal, setVideoModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const chatSupportMainRef = useRef<HTMLDivElement>(null);
  const dateRefs = useRef({});
  const recorderControls = useAudioRecorder();
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const [playAudio, setPlayAudio] = useState(false);
  const [stickyDate, setStickyDate] = useState("");
  const pageDownRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const limit = 50;
  const { t } = useTranslation("app");

  const [isRecord, setIsRecord] = useState(false);
  const [loadOldMessages, setLoadOldMessages] = useState(false);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.chat);
  };

  useBlockScrollbar(uploadModal || imageModal, [uploadModal, imageModal]);

  const {
    socket,
    connect,
    disconnect,
    isLoading,
    connected,
    addOldMessages,
    messages,
    messagesCount,
    setMessages,
    addNewMessage,
  } = useStore(chatStore);
  useEffect(() => {
    connect();

    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (connected) {
      socket.off("new-message");
      socket.on("new-message", onNewMessage);

      socket.emit(
        "get-messages",
        { page: 0, limit, type: ChatType.Support },
        (data: any) => {
          setMessages(data[0], data[1]);
        }
      );
    }
  }, [connected]);

  // pagination
  useEffect(() => {
    if (connected) {
      if (page > 0 && page * limit < messagesCount) {
        setLoadOldMessages(true);
        socket.emit(
          "get-messages",
          { page, limit, type: ChatType.Support },
          (data: any) => {
            addOldMessages(data[0]);
            // if (chatSupportMainRef.current) {
            //   chatSupportMainRef.current.scrollTo({
            //     top: chatSupportMainRef.current.scrollHeight,
            //     behavior: "smooth",
            //   });
            // }
            setLoadOldMessages(false);
          }
        );
      }
    }
  }, [page, messagesCount, connected]);

  const playRingtone = () => {
    setPlayAudio(true);
    setTimeout(() => setPlayAudio(false), 1000);
  };
  const onNewMessage = (msg: any) => {
    playRingtone();
    addNewMessage(msg);
    scrollToBottom();
  };

  useEffect(() => {
    setChat(() => {
      const list = [...messages];
      return list.map((m, i) => {
        const next: any = list[i + 1];
        let padding = 4,
          isLast = false,
          lastMessageDate = null;
        if (next) {
          if (next.sender !== m.sender) {
            isLast = true;
            padding = 16;
          }

          const currMsgDate = moment(m.createdAt).format("DD");
          const nextMsgDate = moment(next.createdAt).format("DD");
          if (currMsgDate !== nextMsgDate) {
            lastMessageDate = moment(next.createdAt).format("DD.MM.YYYY");
          }
        } else {
          padding = 0;
          isLast = true;
        }
        return { ...m, padding, isLast, lastMessageDate };
      });
    });
  }, [messages]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 1) {
            // @ts-ignore
            setStickyDate(entry.target.getAttribute("data-date"));
          }
        });
      },
      { threshold: [1], root: chatSupportMainRef.current }
    );

    Object.values(dateRefs.current).forEach((dateLabel) => {
      if (dateLabel) {
        // @ts-ignore
        observer.observe(dateLabel);
      }
    });

    return () => observer.disconnect();
  }, [chat]);

  // <scroll chat render + show date while scroll>
  const handleScroll = () => {
    if (chatSupportMainRef.current) {
      const scrollThreshold = 1000;
      const diff =
        chatSupportMainRef.current.scrollHeight -
        chatSupportMainRef.current.scrollTop;
      if (diff > scrollThreshold) {
        setScrolled(true);
        setTimeout(() => {
          setScrolled(false);
        }, 1500);
      } else {
        setScrolled(false);
      }

      const top = chatSupportMainRef.current.scrollTop <= 50;
      if (top && !loadOldMessages) {
        setPage((p) => p + 1);
      }

      // setStickyDate(moment().format("DD.MM.YYYY"));
    }
  };
  useEffect(() => {
    if (chatSupportMainRef.current) {
      chatSupportMainRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatSupportMainRef.current) {
        chatSupportMainRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, loadOldMessages]);

  useEffect(() => {
    setTimeout(() => {
      if (chatSupportMainRef.current) {
        chatSupportMainRef.current.scrollTo({
          top: chatSupportMainRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 5);
  }, []);
  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatSupportMainRef.current) {
        chatSupportMainRef.current.scrollTo({
          top: chatSupportMainRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  };
  useEffect(() => {
    if (page == 0) {
      scrollToBottom();
    }
  }, [chat, page]);
  // </scroll chat render + show date while scroll>

  const capitalizeFirstLetter = () => {
    setMessage((text) =>
      text.replace(/(^|\. *)([a-z])/g, (match) => match.toUpperCase())
    );
  };

  function SetNewSize(textarea: any) {
    textarea.style.height = "0px";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  const emitMessage = (type: MessageType, text: string, file?: File | null) => {
    socket.emit("send-message", {
      type,
      text,
      file,
      fileType: file ? file.name.split(".").pop() : null,
      to: ToChatType.Admin,
    });
  };

  const handleSendSMS = () => {
    const msg = message.trim();
    if (msg) {
      emitMessage(MessageType.Text, msg);
      setMessage("");

      // Reset textarea height to default
      const textarea = document.getElementById(
        "textareaId"
      ) as HTMLTextAreaElement;
      if (textarea) {
        textarea.style.height = "auto";
      }
    }
  };

  const [sendBlob, setSendBlob] = useState(false);
  const handleRecord = () => {
    setMessageType("voice");
    setIsRecord(true);
    recorderControls.startRecording();
  };
  const handleSendVoice = () => {
    try {
      setSendBlob(true);
      setIsRecord(false);
      recorderControls.stopRecording();
    } catch (error) {
      toast.error("Error");
    }
  };

  useEffect(() => {
    if (recordedBlob) {
      const file = blobToFile(recordedBlob, "voice.mp3");
      emitMessage(MessageType.Voice, "", file);
    }
  }, [recordedBlob]);

  const handleDeleteRecord = () => {
    setSendBlob(false);
    setIsRecord(false);
    recorderControls.stopRecording();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendSMS();
    }
    if (event.key === "Enter" && event.shiftKey && message.trim() == "") {
      event.preventDefault();
    }
  };

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [takePhoto, setTakePhoto] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onUploadPhoto = (image: Blob) => {
    try {
      const file = blobToFile(image, `photo.jpg`);
      emitMessage(MessageType.Image, "", file);
    } catch (error) {}
  };

  const closeVideo = () => {
    setTakePhoto(false);

    if (videoRef.current) {
      const mediaStream = videoRef.current.srcObject as MediaStream;
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }

      videoRef.current.srcObject = null;
    }
  };

  const handleUploadFile = (msgType: MessageType) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    let accept = "";
    if (msgType === MessageType.Image) {
      accept = "image/*, video/*";
    }
    // fileInput.accept = ".jpg, .jpeg, .png, .gif, .mp4, .avi, .mp3, .wav, .pdf, .doc, .xls, .zip";
    fileInput.accept = accept;
    fileInput.multiple = false;

    fileInput.addEventListener("change", (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        const selected = files[0];
        const size = selected.size;

        const kb = (size as unknown as number) / 1024;
        const mb = kb / 1024;

        if (mb > 5) {
          toast.warning("Max size: 5mb");
        } else {
          setSelectedFile(selected);

          let typeForSend = msgType;
          if (msgType === MessageType.File || msgType === MessageType.Image) {
            if (selected.type.split("/")[0] === "image") {
              typeForSend = MessageType.Image;
            } else if (selected.type.split("/")[0] === "video") {
              typeForSend = MessageType.Video;
            }
          }

          emitMessage(typeForSend, "", selected);
        }
      }
    });

    fileInput.click();
  };

  const onRecordComplete = useCallback(
    (blob: Blob) => {
      if (recordedBlob) {
        if (recordedBlob.size === blob.size) {
          return;
        }
      } else {
        if (!sendBlob) {
          if (!isRecord) {
            setMessageType("sms");
          }
          return;
        }
      }
      setMessageType("sms");
      setRecordedBlob(sendBlob ? blob : null);
    },
    [sendBlob, recordedBlob, isRecord]
  );

  const getDataDate = (i: number, isLast: boolean) => {
    try {
      return moment(chat[isLast ? i + 1 : i - 1].createdAt).format(
        "DD.MM.YYYY"
      );
    } catch (error) {
      return moment(chat[i].createdAt).format("DD.MM.YYYY");
    }
  };

  return (
    <div className="chat-support">
      <div className="chat-support-header">
        <img
          className="go-back-portfolio"
          onClick={() => {
            messageType == "sms"
              ? handleNavigate()
              : setStopRecordingModal("GoBack");
          }}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("support")}</p>
      </div>

      {isLoading ? (
        <div className="chat-spinner-wrapper">
          <Spinner />
        </div>
      ) : (
        <>
          <div ref={chatSupportMainRef} className="chat-support-main">
            <div className=""></div>
            <div
              className={
                scrolled ? "message-date" : "message-date message-date-hide"
              }
            >
              {stickyDate && <p>{stickyDate}</p>}
            </div>

            {chat.map(
              (
                {
                  id,
                  type,
                  room_id,
                  sender,
                  createdAt,
                  text,
                  padding,
                  isLast,
                  lastMessageDate,
                  file,
                },
                index
              ) => (
                <Fragment key={index}>
                  {!lastMessageDate && (
                    <div
                      ref={(el) =>
                        // @ts-ignore
                        (dateRefs.current[getDataDate(index, false)] = el)
                      }
                      data-date={getDataDate(index, false)}
                    ></div>
                  )}
                  {index === 0 && (
                    <div className={"message-date-label"}>
                      <p>{moment(createdAt).format("DD.MM.YYYY")}</p>
                    </div>
                  )}
                  <ChatMessageWrapper
                    key={id}
                    id={id}
                    sender={sender}
                    className={`message-box message-${sender}-box`}
                    style={{ paddingBottom: `${padding}px` }}
                    type="support"
                  >
                    <ChatMessage
                      message={{
                        id,
                        room_id,
                        type: type || MessageType.Text,
                        sender: sender,
                        // wt shuld be in customer: "K",
                        customer: "K",
                        createdAt: createdAt,
                        isLast,
                        text,
                        file,
                        onImgClick: (file: string, date: any) => {
                          setImageUrl(file);
                          setImageDate(date);
                          setImageModal(true);
                        },
                        onVideoClick: (file: string, date: any) => {
                          setVideoUrl(file);
                          setVideoDate(date);
                          setVideoModal(true);
                        },
                      }}
                    />
                  </ChatMessageWrapper>
                  {lastMessageDate && (
                    <div className={"message-date-label"}>
                      <p>{lastMessageDate}</p>
                    </div>
                  )}
                </Fragment>
              )
            )}

            {/* <div className="command-question-container">
              {commands && (
                <>
                  <CommandQuestion question="Replenishment" />
                  <CommandQuestion question="Testing" />
                  <CommandQuestion question="Bonuses" />
                  <CommandQuestion question="Withdrawal" />
                  <CommandQuestion question="Taxes" />
                  <CommandQuestion question="Rates" />
                </>
              )}
              <span
                onClick={() => {
                  setCommands(!commands);
                  scrollToBottom();
                }}
                className={commands ? "qa-btn" : "qa-btn qa-btn-false"}
              >
                QA
              </span>
            </div> */}

            <div
              className={
                scrolled
                  ? "page-down-icon"
                  : "page-down-icon page-down-icon-hide"
              }
              ref={pageDownRef}
              onClick={() => {
                scrollToBottom();
                setScrolled(true);
              }}
              onMouseEnter={() => {
                if (pageDownRef.current) {
                  pageDownRef.current.classList.add("page-down-icon-static");
                  pageDownRef.current.classList.remove("page-down-icon");
                  pageDownRef.current.classList.remove("page-down-icon-hide");
                }
              }}
              onMouseLeave={() => {
                if (pageDownRef.current) {
                  pageDownRef.current.classList.remove("page-down-icon-static");
                  pageDownRef.current.classList.add("page-down-icon");
                  pageDownRef.current.classList.add("page-down-icon-hide");
                }
              }}
            >
              <img src={PageDown} alt="Page Down" />
            </div>
          </div>

          <div className="send-message-box">
            <img
              onClick={() => {
                messageType == "sms"
                  ? setUploadModal(true)
                  : setStopRecordingModal("Upload");
              }}
              src={SendFile}
              alt="icon"
            />

            {messageType == "sms" && (
              <textarea
                id="textareaId"
                name="message"
                autoFocus={true}
                onChange={(e) => {
                  const newValue = e.target.value;

                  if (newValue.charAt(0) === " ") {
                    setMessage(newValue.slice(1));
                  } else {
                    setMessage(newValue);
                  }

                  capitalizeFirstLetter();
                }}
                value={message}
                placeholder={t("yourMessage")}
                rows={1}
                onInput={(e) => SetNewSize(e.target)}
                onKeyDown={handleKeyDown}
                wrap="soft"
              ></textarea>
            )}
            {messageType == "voice" && (
              <div className="recording__container">
                <AudioRecorder
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                  }}
                  showVisualizer={true}
                  downloadOnSavePress={false}
                  downloadFileExtension={"mp3"}
                  recorderControls={recorderControls}
                  onRecordingComplete={onRecordComplete}
                />
                <button
                  onClick={handleDeleteRecord}
                  className="delete-record"
                ></button>
              </div>
            )}

            <img
              onClick={() => {
                if (message.length < 1 && messageType == "sms") {
                  handleRecord();
                } else if (message.length >= 1 && messageType == "sms") {
                  handleSendSMS();
                } else if (messageType == "voice") {
                  handleSendVoice();
                }
              }}
              // className={`send-message-btn
              //  ${!message.trim() && "disabled-send-btn" }`
              // }
              className="send-message-btn"
              src={
                message.length >= 1 || messageType == "voice"
                  ? SendMessage
                  : SendVoice
              }
              alt="icon"
            />
          </div>
        </>
      )}

      {uploadModal && messageType == "sms" && (
        <SendFileModal
          onClose={() => setUploadModal(false)}
          onCameraClick={() => {
            setUploadModal(false);
            setTakePhoto(true);
          }}
          onGalleryClick={() => {
            setUploadModal(false);
            handleUploadFile(MessageType.Image);
          }}
          onFileUploadClick={() => {
            setUploadModal(false);
            handleUploadFile(MessageType.File);
          }}
        />
      )}
      {stopRecordingModal !== "False" && (
        <Modal
          customModal="modalLogOut"
          btnText={t("continue")}
          onClick={() => {
            setStopRecordingModal("False");
          }}
          btnText2={t("discard")}
          onClickSecondBtn={() => {
            if (stopRecordingModal === "Upload") {
              setUploadModal(true);
            } else {
              handleNavigate();
            }
            setStopRecordingModal("False");
            setMessageType("sms");
            recorderControls.stopRecording();
          }}
          onHide={() => setStopRecordingModal("False")}
          customOutlet="customOutlet"
          title={t("discardVoice")}
          text={t("sureToStopRecording")}
          showSecondBtn={true}
          closeIcon={false}
        />
      )}

      {imageModal && (
        <VueImageMessage
          createdAt={imageDate}
          onClose={() => setImageModal(false)}
          image={imageUrl}
        />
      )}
      {videoModal && (
        <VueVideoMessage
          videoUrl={videoUrl}
          createdAt={videoDate}
          onClose={() => setVideoModal(false)}
        />
      )}

      {takePhoto && (
        <TakePhotoModal
          className="acc-take-photo"
          onOpen={(image: Blob) => {
            onUploadPhoto(image);
            closeVideo();
          }}
          onClose={closeVideo}
          type={DocumentType.Avatar}
        ></TakePhotoModal>
      )}

      <Audio play={playAudio} />
    </div>
  );
};
