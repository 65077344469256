import React, { useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import { StrategyChart } from "widgets/strategy/chart";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import { DealsTable, MarketChart } from "widgets/market";
import {
  getMLValue,
  getStoryImgUrl,
  getStrategyImgUrl,
  navigateToAsset,
} from "helpers/functions";
import { AssetType, OperationType } from "static/types";
import { ActionBtn } from "../components/action-btn";
import useCanAction from "hooks/useCanAction";
import { useTranslation } from "react-i18next";
import HorizontalScroll from "../components/horizontal-scroll";
import { TabBtn } from "../components/tab-btn";
import IndicatorDetails from "../components/indicators-details-row";
import ReviewDetails from "../components/review-details-row";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper/modules";

const RiskTypes = ["Low Risk", "Average Risk", "High Risk"];

export const StrategyPage = () => {
  const [activeBtn, setActiveBtn] = useState("Graph");
  const { asset_id } = useParams();
  const { isLoading, strategy, getStrategy, loadAssetDeals, assetDeals } =
    useStore(marketStore);
  const [activeTab, setActiveTab] = useState("indicators");
  const { t, i18n } = useTranslation("app");
  const action = useCanAction();
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };

  useEffect(() => {
    getStrategy(asset_id!);
    loadAssetDeals(+asset_id!);
  }, []);

  const scroll = () => {
    const stockDetails = document.querySelector(".stock-details");
    if (stockDetails) {
      stockDetails.scrollTop = 0;
    }
  };

  const handleTabClick = (text: string) => {
    setActiveTab(text);
    scroll();
  };

  if (isLoading) {
    return (
      <div className={`stock-details`}>
        <div className="stock-details-main">
          <p className="assets-loading assets-loading-page">{t("loading")}</p>
        </div>
      </div>
    );
  }

  const symbol = strategy?.asset?.symbol || "";
  const risk = strategy?.asset?.data?.risk;
  const chartSymbol = strategy?.asset?.data?.chart_symbol;
  const currency = strategy?.asset?.currency?.code;
  const currencySymbol = strategy?.asset?.currency?.symbol;
  const asset = strategy?.account?.assets?.find((a) => a.symbol === symbol!);
  const annualYield = strategy?.asset?.data?.annual_yield;
  const desc = strategy?.description || "";
  const customInfo = strategy?.customInfo || null;
  console.log(customInfo);

  const getTotalAmount = () => {
    try {
      return +(asset!.count * asset!.price).toFixed(2);
    } catch (error) {
      return "";
    }
  };

  return (
    <div className="strategy-page">
      <div
        className={`strategy-header-container strategy-header-container-${risk}`}
        style={{
          background: customInfo
            ? `linear-gradient(256.2deg, ${customInfo.info.background.to} -7.74%, ${customInfo.info.background.from} 100%)`
            : "",
        }}
      >
        <div className="strategy-header">
          <img
            className="go-back-strategy"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          {/* <p style={{ color: customInfo ? "#2B2F38" : "#f9f9f9" }}> */}
          <p>{strategy?.asset?.name}</p>
        </div>
        <div className="strategy-header-info">
          <div className="sh-info-box">
            {customInfo && customInfo.info?.yield ? (
              <>
                <p>{getMLValue(customInfo.info.yield.p, i18n.language)}</p>
                <span>
                  {getMLValue(customInfo.info.yield.span, i18n.language)}
                </span>
              </>
            ) : (
              <>
                <p>≈ {annualYield}%</p>
                <span>{t("annualReturn")}</span>
              </>
            )}
          </div>
          <div className="sh-info-box">
            <p>
              {strategy?.asset?.data?.minimal_invest} {currencySymbol}
            </p>
            <span>{t("initialInvestmentAmount")}</span>
          </div>
          <div className="sh-info-box">
            <span>{t(RiskTypes[risk ? risk - 1 : 0])}</span>
          </div>
        </div>
      </div>
      <div
        className="strategy-main"
        style={{ paddingTop: customInfo ? "0px" : "24px" }}
      >
        {/* //! TRANSLATE */}
        {asset && asset.count > 0 && (
          <div className="current-investment-amount-strategy">
            <label>Current investment amount</label>
            <div>
              <p>
                {getTotalAmount()} {currencySymbol}
              </p>
              <span>{annualYield}% annual return</span>
            </div>
          </div>
        )}
        {!customInfo ? (
          <div className="strategy-info-section">
            <button
              className={activeBtn == "Graph" ? "active-btn" : ""}
              onClick={() => setActiveBtn("Graph")}
            >
              {t("graph")}
            </button>
            <button
              className={activeBtn == "Description" ? "active-btn" : ""}
              onClick={() => setActiveBtn("Description")}
            >
              {t("description")}
            </button>
          </div>
        ) : (
          <HorizontalScroll className="tab-bar tab-bar-cat">
            {assetDeals.length > 0 && (
              <TabBtn
                text={t("trading")}
                active={activeTab === "trading"}
                onClick={() => {
                  handleTabClick("trading");
                }}
              />
            )}
            <TabBtn
              text={t("indicators")}
              active={activeTab === "indicators"}
              onClick={() => {
                handleTabClick("indicators");
              }}
            />
            <TabBtn
              text={t("review")}
              active={activeTab === "review"}
              onClick={() => {
                handleTabClick("review");
              }}
            />
            <TabBtn
              text={t("disclaimer")}
              active={activeTab === "disclaimer"}
              onClick={() => {
                handleTabClick("disclaimer");
              }}
            />
          </HorizontalScroll>
        )}
        {!customInfo ? (
          activeBtn == "Graph" ? (
            <div className="sm-info-container">
              <StrategyChart
                chart={strategy?.chart}
                title={chartSymbol ? `${chartSymbol} stock chart` : undefined}
              />
              <DealsTable assetId={strategy?.asset?.id || undefined} />
            </div>
          ) : (
            <div
              className="sm-info-container sm-info-container-description"
              dangerouslySetInnerHTML={{ __html: desc }}
            ></div>
          )
        ) : activeTab === "trading" ? (
          <>
            <DealsTable
              assetId={strategy?.asset?.id || undefined}
              noClearTrades={true}
            />
          </>
        ) : activeTab == "indicators" ? (
          <div className="sm-tab-name">
            {customInfo.indicators.list &&
              //@ts-ignore
              customInfo.indicators.list.map((param, index) => (
                <div key={index} className="indicators-title review-title">
                  <p>{getMLValue(param.label, i18n.language)}</p>
                  <span className="description-span">
                    {getMLValue(param.value, i18n.language)}
                  </span>
                </div>
              ))}

            {customInfo.indicators.companies && (
              <Swiper
                style={{ width: "100%", padding: "16px 24px" }}
                wrapperClass="strategy-indicator-box"
                spaceBetween={12}
                slidesPerView={1.1}
                scrollbar={{ draggable: true }}
              >
                {/* @ts-ignore */}
                {customInfo.indicators.companies.map((param, index) => (
                  <SwiperSlide key={index}>
                    <div className="indicator-box">
                      <div
                        style={{
                          display: "flex",
                          gap: "12px",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "6px",
                        }}
                      >
                        <img
                          style={{
                            width: "36px",
                            height: "36px",
                            borderRadius: "100%",
                          }}
                          src={getStrategyImgUrl(
                            strategy?.asset?.symbol || "",
                            param.logo
                          )}
                          alt={param.name}
                        />
                        <p>{param.name}</p>
                      </div>
                      {/* @ts-ignore */}
                      {param.data.map((param, index) => (
                        <div className="indicator-box-row">
                          <span
                            style={{
                              textAlign: "left",
                            }}
                          >
                            {getMLValue(param.label, i18n.language)}
                          </span>
                          <span
                            style={{
                              fontWeight: 600,
                              color: " #131722",
                              textAlign: "right",
                            }}
                          >
                            {getMLValue(param.value, i18n.language)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        ) : activeTab == "review" ? (
          <div className="sm-tab-name">
            {customInfo.info.description && (
              <div className="review-title">
                <p>{t("description")}</p>
              </div>
            )}
            {customInfo.info.description && (
              <span className="description-span">
                {getMLValue(customInfo.info.description, i18n.language)}
              </span>
            )}

            {customInfo.info.description && (
              <div className="review-title">
                <p>{t("keyParameters")}</p>
              </div>
            )}

            {customInfo.info.params &&
              //@ts-ignore
              customInfo.info.params.map((param, index) => (
                <ReviewDetails
                  key={index}
                  title={getMLValue(param.label, i18n.language)}
                  info={getMLValue(param.value, i18n.language)}
                  type={param.type == "array" ? param.value : null}
                />
              ))}

            {customInfo.info.pre_image_description && (
              <span className="description-span" style={{ marginTop: "24px" }}>
                {getMLValue(
                  customInfo.info.pre_image_description,
                  i18n.language
                )}
              </span>
            )}
            {customInfo.info.image.title && (
              <div className="review-title">
                <p>{getMLValue(customInfo.info.image.title, i18n.language)}</p>
              </div>
            )}
            {customInfo.info.image.name_from_gallery && (
              <img
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  marginBottom: "24px",
                }}
                src={getStrategyImgUrl(
                  strategy?.asset?.symbol || "",
                  customInfo.info.image.name_from_gallery
                )}
                alt="img"
              />
            )}

            {customInfo.info.params2 &&
              //@ts-ignore
              customInfo.info.params2.map((param, index) => (
                <ReviewDetails
                  key={index}
                  title={getMLValue(param.label, i18n.language)}
                  info={getMLValue(param.value, i18n.language)}
                />
              ))}
          </div>
        ) : (
          <div className="sm-tab-name">
            <div className="review-title">
              <p>{t("disclaimer")}</p>
            </div>

            {customInfo.disclaimer.text && (
              <div className="indicators-title review-title">
                <span className="description-span">
                  {getMLValue(customInfo.disclaimer.text, i18n.language)}
                </span>
              </div>
            )}
            {customInfo.disclaimer.text && (
              <div className="indicators-title review-title">
                <span className="description-span">
                  {getMLValue(customInfo.disclaimer.text, i18n.language)}
                </span>
              </div>
            )}

            <div className="review-title">
              <p>{t("references")}</p>
            </div>

            {customInfo.disclaimer.references &&
              //@ts-ignore
              customInfo.disclaimer.references.map((param, index) => (
                <div key={index} className="indicators-info">
                  <p style={{ fontSize: "14px" }}>
                    {getMLValue(param.title, i18n.language)}
                  </p>
                  <span>
                    {t("sources")} {/* @ts-ignore */}
                    {param.list.map((item, index) => (
                      <span
                        style={{ fontWeight: 600, fontSize: "13px" }}
                        key={index}
                      >
                        {getMLValue(item.label, i18n.language)}
                        {index < param.list.length - 1 && ", "}
                      </span>
                    ))}
                  </span>
                  <ul>
                    {/* @ts-ignore */}
                    {param.list.map((item, idx) => (
                      <li>
                        <a
                          key={idx}
                          href={item.link[i18n.language]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getMLValue(item.link, i18n.language)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        )}
      </div>

      {asset && asset.count ? (
        <div className="strategy-action-btns-wrapper">
          <ActionBtn
            action="Increase"
            onClick={() =>
              action(() =>
                navigateToAsset(
                  AssetType.StructuredProduct,
                  asset_id,
                  navigate,
                  OperationType.Buy
                )
              )
            }
            // price={getAssetPrice("sell")}
            type="dark"
            disabled={false}
          />
          <ActionBtn
            action="Decrease"
            onClick={() =>
              action(() =>
                navigateToAsset(
                  AssetType.StructuredProduct,
                  asset_id,
                  navigate,
                  OperationType.Sell
                )
              )
            }
            // price={getAssetPrice("buy")}
            type="dark"
            disabled={false}
          />
        </div>
      ) : (
        <div className="btn-wrapper deal-btn-wrapper">
          <Button
            type="submit"
            variant="dark"
            className="costumBtn deal-btn"
            onClick={() =>
              action(() =>
                navigateToAsset(
                  AssetType.StructuredProduct,
                  asset_id,
                  navigate,
                  OperationType.Buy
                )
              )
            }
          >
            {t("invest")}
          </Button>
        </div>
      )}
    </div>
  );
};
