import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import OpenCameraButton from "widgets/open-camera-btn";
import TakePhotoModal from "../components/take-photo-modal";
import SubmitPhotoModal from "../components/submit-photo-modal";
import UtilityBillSubmitEx from "../../../assets/UtilityBillSubmitEx.png";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { onboardModel } from "entities/onboard";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { ContentType } from "shared/api/http-client";
import { blobToFile } from "helpers/functions";
import { DocumentType } from "static/types";

enum ModalStep {
  Zero,
  Photo,
  Submit,
}
export const UtilityBillPhotoPage = () => {
  const [step, setStep] = useState<ModalStep>(ModalStep.Zero);
  const [image, setImage] = useState<any>();
  const { syncUser } = useStore(onboardModel.onboardStore);
  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("onboarding-auth");

  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.selfieWithDocumentPage);

    return () => setLink("");
  }, []);

  const onOpen = () => {
    setStep(ModalStep.Photo);
  };
  const skipTo = () => {
    navigate(PATH_LIST.personalAddress);
  };
  const SubmitPhoto = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.document(
      {
        idDocType: "UTILITY_BILL",
        fiz: blobToFile(image, `UTILITY_BILL.jpg`),
      },
      { type: ContentType.FormData }
    );
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error(err(String(resp.error.code)));
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.termsAndConditions);
    }
    setIsSubmit(false);
  };

  return (
    <>
      <div className="proofOfAddress">
        <GoBackButton link={PATH_LIST.selfieWithDocumentPage} />
        <p>{t("takeUtilityBillPhoto")}</p>
        <span>{t("alignDocumentFrameCapture")}</span>

        <OpenCameraButton customClass="cameraBtnCustom" onOpen={onOpen} />
        <p onClick={skipTo} className="skipStep">
          {t("skip")}
        </p>
      </div>

      {step === ModalStep.Photo && (
        <TakePhotoModal
          type={DocumentType.UtilityBill}
          onOpen={(image: any) => {
            setImage(image);
            setStep(ModalStep.Submit);
          }}
          onClose={() => {
            setStep(ModalStep.Zero);
          }}
        />
      )}

      {step === ModalStep.Submit && (
        <SubmitPhotoModal
          type={DocumentType.UtilityBill}
          onSubmit={SubmitPhoto}
          image={image}
          onClose={() => setStep(ModalStep.Photo)}
          isSubmit={isSubmit}
        />
      )}
    </>
  );
};
