import React, { useEffect, useState } from "react";
import "./style.scss";
import CheckIcon from "../../../../assets/icon-check.svg";
import { CURRENCY_FLAGS } from "static/currencies";
import ReliabilityRating from "../reliability-rating-row";
import DatePicker from "../date-picker";
// @ts-ignore
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import RangeSliderInput from "../range-slider-input";
import { ReactCountryDropdown } from "widgets/country-select";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import { marketStore } from "entities/market";
import moment from "moment";

type PropTypeRow = {
  variants?: Array<{ name: string; value: string }>;
  onRowClick?: any;
  checked?: boolean;
  //bond
  currency?: {
    currency: string;
  };
  bondType?: {
    bondType: string;
  };
  yieldMaturity?: { from: number; to: number };
  maturityDate?: { from: string; to: string };
  reliabilityRating?: { reliabilityRating: number[] };
  industry?: {
    industry: string;
  };
  coupon?: {
    coupon: string;
  };
  //stock
  segment?: {
    segment: string;
  };
  stockIndustry?: {
    stockIndustry: string;
  };
  country?: {
    country: string[];
  };
  dividendYield?: { from: number; to: number };
  marketCap?: { from: number; to: number };
};

// @ts-ignore
export const FilterRow: React.FC<PropTypeRow> = ({
  onRowClick,
  checked,
  //bond
  currency,
  bondType,
  yieldMaturity,
  maturityDate,
  reliabilityRating,
  industry,
  coupon,
  // stock
  segment,
  stockIndustry,
  country,
  dividendYield,
  marketCap,
  variants,
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  const { t } = useTranslation("app");
  const dateF = "YYYY.MM.DD";
  const [startDate, setStartDate] = useState(
    maturityDate?.from || moment().format(dateF)
  );
  const [endDate, setEndDate] = useState(
    maturityDate?.to || moment().add(1, "year").format(dateF)
  );

  const reliabilityVariants = [1, 2, 3, 4, 5, 6];
  const [ratings, setRatings] = useState<number[]>(
    reliabilityRating?.reliabilityRating || []
  );

  const [selectedCountries, setSelectedCountries] = useState<string[]>(
    country?.country?.length ? country?.country : []
  );
  // Progres values
  const [yieldMaturityValues, setYieldMaturityValues] = useState<
    [number, number]
  >([0, 100]);
  const [dividendYieldValues, setDividendYieldValues] = useState<
    [number, number]
    // @ts-ignore
  >(dividendYield ? [dividendYield.from, dividendYield.to] : [0, 100]);

  const { countries } = useStore(marketStore);

  const [marketCapStep, setMarketCapStep] = useState<[number, number]>(
    marketCap ? [marketCap.from, marketCap.to] : [0, 7]
  );
  const [marketCapValues, setMarketCapValues] = useState<[number, number]>([
    0, 1e12,
  ]);
  const adjustValues = (newValues: [number, number]) => {
    newValues[0] = Math.max(0, Math.min(newValues[0], 99));
    newValues[1] = Math.max(1, Math.min(newValues[1], 100));

    if (newValues[0] >= newValues[1]) {
      if (newValues[0] !== 0) {
        newValues[0] = newValues[1] - 1;
      } else {
        newValues[1] = newValues[0] + 1;
      }
    }

    return newValues;
  };
  const handleSliderInput = (valueType: string) => (newValues: number[]) => {
    if (valueType === "yieldMaturity") {
      const [from, to] = adjustValues([...newValues] as [number, number]);
      if (from === 0 && to === 100) {
        onRowClick(null);
      } else {
        onRowClick({ from, to });
      }
      setYieldMaturityValues(adjustValues([...newValues] as [number, number]));
    } else if (valueType === "marketCap") {
      const [from, to] = adjustValues([...newValues] as [number, number]);
      if (from === 0 && to === 7) {
        onRowClick(null);
      } else {
        onRowClick({ from, to });
      }
      setMarketCapStep(adjustValues([from, to]));
    } else if (valueType === "dividendYield") {
      const [from, to] = adjustValues([...newValues] as [number, number]);
      if (from === 0 && to === 100) {
        onRowClick(null);
      } else {
        onRowClick({ from, to });
      }
      setDividendYieldValues(adjustValues([from, to]));
    }
  };
  const handleInputChange = (
    valueType: string,
    index: number,
    newValue: number
  ) => {
    if (valueType === "yieldMaturity") {
      const newValues: [number, number] = [...yieldMaturityValues];
      newValues[index] = newValue;
      setYieldMaturityValues(adjustValues(newValues));
      const [from, to] = adjustValues(newValues);
      if (from === 0 && to === 100) {
        onRowClick(null);
      } else {
        onRowClick({ from, to });
      }
    } else if (valueType === "marketCap") {
      const v = marketCapStep;
      v[index] = newValue;
      const [from, to] = adjustValues(v);
      if (from === 0 && to === 7) {
        onRowClick(null);
      } else {
        onRowClick({ from, to });
      }
      setMarketCapStep(adjustValues([from, to]));
    } else if (valueType === "dividendYield") {
      const newValues: [number, number] = [...dividendYieldValues];
      newValues[index] = newValue;
      setDividendYieldValues(adjustValues(newValues));
      const [from, to] = adjustValues(newValues);
      if (from === 0 && to === 100) {
        onRowClick(null);
      } else {
        onRowClick({ from, to });
      }
    }
  };
  const formatValue = (step: number): string | undefined => {
    if (step === 7) return `1 ${t("trillion")} +`;
    if (step === 6) return `100 ${t("billion")}`;
    if (step === 5) return `1 ${t("billion")}`;
    if (step === 4) return `100 ${t("million")}`;
    if (step === 3) return `1 ${t("million")}`;
    if (step === 2) return `100 ${t("k")}`;
    if (step === 1) return `10 ${t("k")}`;
    if (step === 0) return "0";
    return undefined;
  };

  const capValuesData = [
    { value: `1 ${t("trillion")} +`, label: "7" },
    { value: `100 ${t("billion")}`, label: "6" },
    { value: `1 ${t("billion")}`, label: "5" },
    { value: `100 ${t("million")}`, label: "4" },
    { value: `1 ${t("million")}`, label: "3" },
    { value: `100 ${t("k")}`, label: "2" },
    { value: `10 ${t("k")}`, label: "1" },
    { value: "0", label: "0" },
  ];

  useEffect(() => {
    const stepToValue: Record<number, number> = {
      0: 0,
      1: 1e4,
      2: 1e5,
      3: 1e6,
      4: 1e8,
      5: 1e9,
      6: 1e11,
      7: 1e12,
    };
    const updatedValues: [number, number] = [
      stepToValue[marketCapStep[0]] ?? marketCapValues[0],
      stepToValue[marketCapStep[1]] ?? marketCapValues[1],
    ];
    setMarketCapValues(updatedValues);

    // console.log("steps - " + marketCapStep[0] + " | " + marketCapStep[1]);
    // console.log("------------------------");
    // console.log("values - " + updatedValues[0] + " | " + updatedValues[1]);
  }, [marketCapStep]);
  // .............. //

  useEffect(() => {
    if (reliabilityRating) {
      onRowClick([0, 6].includes(ratings.length) ? null : ratings);
    }
  }, [ratings]);

  useEffect(() => {
    if (maturityDate) {
      onRowClick({ from: startDate, to: endDate });
    }
  }, [startDate, endDate]);

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick();
    }
    setIsChecked(!isChecked);
  };

  if (currency) {
    return (
      <div onClick={handleRowClick} className="filter-row">
        <div>
          <img
            src={CURRENCY_FLAGS[currency.currency]}
            alt={`${currency}-icon`}
          />
          <p>{currency.currency}</p>
        </div>
        {isChecked && <img src={CheckIcon} alt="CheckIcon" />}
      </div>
    );
  } else if (bondType) {
    return variants ? (
      variants.map((v) => (
        <div
          key={v.value}
          onClick={() => onRowClick(v.value)}
          className="filter-row"
        >
          <p>{v.name}</p>
          {bondType.bondType === v.value && (
            <img src={CheckIcon} alt="CheckIcon" />
          )}
        </div>
      ))
    ) : (
      <></>
    );
  } else if (yieldMaturity) {
    return (
      <div className="filter-range-slider">
        <RangeSliderInput
          label="From"
          value={yieldMaturityValues[0]}
          onChange={(e: any) =>
            handleInputChange("yieldMaturity", 0, +e.target.value)
          }
          symbol="%"
        />
        <RangeSliderInput
          label="To"
          value={yieldMaturityValues[1]}
          onChange={(e: any) =>
            handleInputChange("yieldMaturity", 1, +e.target.value)
          }
          symbol="%"
        />
        <RangeSlider
          className="range-slider"
          min={0}
          max={100}
          step={1}
          defaultValue={[0, 100]}
          value={yieldMaturityValues}
          onInput={handleSliderInput("yieldMaturity")}
        />
      </div>
    );
  } else if (maturityDate) {
    return (
      <div className="filter-date-wrapper">
        <DatePicker
          deadline="Start"
          selected={moment(startDate, dateF).toDate()}
          onChange={(date: any) => setStartDate(moment(date).format(dateF))}
          report={true}
          withPortal={true}
        />
        <DatePicker
          deadline="End"
          selected={moment(endDate, dateF).toDate()}
          onChange={(date: any) => setEndDate(moment(date).format(dateF))}
          report={true}
          withPortal={true}
        />
      </div>
    );
  } else if (reliabilityRating) {
    return (
      <>
        {reliabilityVariants.map((index) => (
          <div
            key={index}
            className="filter-r-rating-row"
            onClick={() => {
              setRatings(
                ratings.includes(index)
                  ? ratings.filter((v) => v !== index)
                  : [...ratings, index]
              );
            }}
          >
            <ReliabilityRating index={index} />
            {ratings.includes(index) && (
              <img className="check-icon" src={CheckIcon} alt="CheckIcon" />
            )}
          </div>
        ))}
      </>
    );
  } else if (industry) {
    return (
      <div onClick={handleRowClick} className="filter-row">
        <p>{industry.industry}</p>
        {isChecked && <img src={CheckIcon} alt="CheckIcon" />}
      </div>
    );
  } else if (coupon) {
    return variants ? (
      variants.map((v) => (
        <div
          key={v.value}
          onClick={() => onRowClick(v.value)}
          className="filter-row"
        >
          <p>{v.name}</p>
          {coupon.coupon === v.value && <img src={CheckIcon} alt="CheckIcon" />}
        </div>
      ))
    ) : (
      <></>
    );
  }
  //stock modals
  else if (segment) {
    return (
      <div onClick={handleRowClick} className="filter-row">
        <p>{segment.segment}</p>
        {isChecked && <img src={CheckIcon} alt="CheckIcon" />}
      </div>
    );
  } else if (stockIndustry) {
    return (
      <div onClick={handleRowClick} className="filter-row">
        <p>{stockIndustry.stockIndustry}</p>
        {isChecked && <img src={CheckIcon} alt="CheckIcon" />}
      </div>
    );
  } else if (country) {
    return (
      <div className="filter-country-dropdown">
        <ReactCountryDropdown
          onSelect={(c: string[]) => {
            setSelectedCountries(c);
            if (onRowClick) {
              onRowClick(c);
            }
          }}
          countryCode={selectedCountries || []}
          specificCodes={countries}
          placeholder="select"
          labelType="full"
          className="filter-country-select"
          customSelectedClass="filter-country-selected"
          customWrapperClass="filter-country-dropdown-wrapper"
          dropdown={true}
          returnAlpha2={true}
          multiple={true}
        />
        {/* <p>{country.country}</p>
        {isChecked && <img src={CheckIcon} alt="CheckIcon" />} */}
      </div>
    );
  } else if (dividendYield) {
    return (
      <div className="filter-range-slider">
        <RangeSliderInput
          label="From"
          value={dividendYieldValues[0]}
          onChange={(e: any) =>
            handleInputChange("dividendYield", 0, +e.target.value)
          }
          symbol="%"
        />
        <RangeSliderInput
          label="To"
          value={dividendYieldValues[1]}
          onChange={(e: any) =>
            handleInputChange("dividendYield", 1, +e.target.value)
          }
          symbol="%"
        />
        <RangeSlider
          className="range-slider"
          min={0}
          max={100}
          step={1}
          defaultValue={[0, 100]}
          value={dividendYieldValues}
          onInput={handleSliderInput("dividendYield")}
        />
      </div>
    );
  } else if (marketCap) {
    return (
      <div className="filter-range-slider market-cap-range-slider">
        <RangeSliderInput
          label="From"
          value={formatValue(marketCapStep[0])}
          textType
          disabled
          symbol="$"
          dropdown={capValuesData
            .filter(({ label }) => {
              return (
                !marketCapStep.includes(+label) && +label < marketCapStep[1]
              );
            })
            .map(({ value, label }) => (
              <p
                onClick={() => handleInputChange("marketCap", 0, +label)}
                key={label}
              >
                {value}
              </p>
            ))}
        />
        <RangeSliderInput
          label="To"
          value={formatValue(marketCapStep[1])}
          textType
          disabled
          symbol="$"
          dropdown={capValuesData
            .filter(({ label }) => {
              return (
                !marketCapStep.includes(+label) && +label > marketCapStep[0]
              );
            })
            .map(({ value, label }) => (
              <p
                onClick={() => handleInputChange("marketCap", 1, +label)}
                key={label}
              >
                {value}
              </p>
            ))}
        />
        <RangeSlider
          className="range-slider"
          min={0}
          max={7}
          step={1}
          defaultValue={[0, 7]}
          value={marketCapStep}
          onInput={handleSliderInput("marketCap")}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

type PropType = {
  type: string;
  onClose: () => void;
  value: null | string[] | any;
  onChange: (
    value:
      | string
      | string[]
      | null
      | { from: number; to: number }
      | { from: Date; to: Date }
  ) => void;
};

const FilterModal: React.FC<PropType> = ({
  type,
  onClose,
  value,
  onChange,
}) => {
  const { t, i18n } = useTranslation("app");
  const { stockIndustries, sectors, countries } = useStore(marketStore);

  const CURRENCIES = ["USD", "EUR", "RUB", "AMD"];
  const currencyArray =
    type === "currency"
      ? CURRENCIES.map((c) => ({
          currency: c,
          checked: value !== null ? value.includes(c) : false,
          onClick: () => {
            if (value !== null) {
              if (value.includes(c)) {
                const new_list = value.filter((v: any) => v !== c);
                onChange(new_list.length ? new_list : null);
              } else {
                onChange([...value, c]);
              }
            } else {
              onChange([c]);
            }
          },
        }))
      : [];

  const BOND_TYPES = [
    { name: t("Government"), value: "government" },
    { name: t("Corporate"), value: "corporate" },
  ];
  const industryArray = [
    {
      industry: "Банки",
      onClick: () => console.log("handleindustry1Change"),
    },
    {
      industry: "Горное дело",
      onClick: () => console.log("handleindustry2Change"),
    },
  ];

  const COUPON_TYPES = [
    { name: t("Floating"), value: "floating" },
    { name: t("Fixed"), value: "fixed" },
  ];
  const segmentArray =
    type === "segment"
      ? sectors.map((sector) => ({
          segment:
            sector[
              sector[`name_${i18n.language}`]
                ? `name_${i18n.language}`
                : "name_en"
            ],
          checked: value !== null ? value.includes(sector.alias) : false,
          onClick: () => {
            if (value !== null) {
              const values = sectors.filter((v: any) =>
                value.includes(v.alias)
              );
              if (value.includes(sector.alias)) {
                const new_list = values.filter(
                  (v: any) => v.alias !== sector.alias
                );
                onChange(new_list.length ? new_list : null);
              } else {
                onChange([...values, sector]);
              }
            } else {
              onChange([sector]);
            }
          },
        }))
      : [];

  const stockIndustryArray =
    type === "stockIndustry"
      ? stockIndustries.map((industries) => ({
          stockIndustry:
            industries[
              industries[`name_${i18n.language}`]
                ? `name_${i18n.language}`
                : "name_en"
            ],
          checked: value !== null ? value.includes(industries.alias) : false,
          onClick: () => {
            if (value !== null) {
              const values = stockIndustries.filter((v) =>
                value.includes(v.alias)
              );
              if (value.includes(industries.alias)) {
                const new_list = values.filter(
                  (v) => v.alias !== industries.alias
                );
                onChange(new_list.length ? new_list : null);
              } else {
                onChange([...values, industries]);
              }
            } else {
              onChange([industries]);
            }
          },
        }))
      : [];

  const modalTitle = {
    //bond
    currency: "Currency",
    bondType: "Bond type",
    yieldMaturity: "Yield to maturity",
    maturityDate: "Maturity date",
    reliabilityRating: "Reliability rating",
    industry: "Industry",
    coupon: "Coupon",
    //stock
    segment: "Segment",
    stockIndustry: "Industry",
    country: "Country",
    dividendYield: "Dividend yield",
    marketCap: "Market cap",
    filtration: "Filtration",
  };
  const config = {
    //bond
    currency: currencyArray.map((currencyItem) => ({
      onRowClick: currencyItem.onClick,
      checked: currencyItem.checked,
      currency: { ...currencyItem },
    })),
    bondType: {
      variants: BOND_TYPES,
      onRowClick: onChange,
      bondType: { bondType: value },
    },
    yieldMaturity: {
      yieldMaturity: { from: value?.from || 0, to: value?.to || 100 },
      onRowClick: (c: { from: number; to: number } | null) => {
        onChange(c);
      },
    },
    maturityDate: {
      maturityDate: { from: value?.from, to: value?.to },
      onRowClick: (c: { from: Date; to: Date } | null) => {
        onChange(c);
      },
    },
    reliabilityRating: {
      onRowClick: onChange,
      reliabilityRating: { reliabilityRating: value },
    },
    industry: industryArray.map((industryItem) => ({
      onRowClick: industryItem.onClick,
      industry: { ...industryItem },
    })),
    coupon: {
      variants: COUPON_TYPES,
      onRowClick: onChange,
      coupon: { coupon: value },
    },
    //stock
    segment: segmentArray.map((segmentItem) => ({
      onRowClick: segmentItem.onClick,
      checked: segmentItem.checked,
      segment: { ...segmentItem },
    })),
    stockIndustry: stockIndustryArray.map((stockIndustryItem) => ({
      onRowClick: stockIndustryItem.onClick,
      checked: stockIndustryItem.checked,
      stockIndustry: { ...stockIndustryItem },
    })),
    country: {
      onRowClick: (c: string[] | null) => {
        onChange(c && c.length ? c : null);
      },
      country: { country: value },
    },
    dividendYield: {
      dividendYield: { from: value?.from || 0, to: value?.to || 100 },
      onRowClick: (c: { from: number; to: number } | null) => {
        onChange(c);
      },
    },
    marketCap: {
      marketCap: { from: value?.from || 0, to: value?.to || 7 },
      onRowClick: (c: { from: number; to: number } | null) => {
        onChange(c);
      },
    },
  };

  const title = t(modalTitle[type as keyof typeof modalTitle]);
  const itemConfig = config[type as keyof typeof config];

  const RowComponent = (config: any, index: number) => {
    return <FilterRow key={index} {...config} />;
  };

  return (
    <div className="filter-bottom-sheet-modal-wrapper">
      <div onClick={onClose} className="outlet-filter-modal"></div>

      <div
        className={
          type !== "reliabilityRating" && type !== "country"
            ? "filter-modal"
            : "filter-r-rating-modal filter-modal"
        }
      >
        <div className="filter-modal-header">
          <span onClick={onClose}>{t("close")}</span>
          {title && <p>{t(title)}</p>}
          <span
            onClick={() => {
              onChange(null);
              onClose();
            }}
          >
            {t("all")}
          </span>
        </div>
        {/* <FilterRow {...itemConfig} /> */}

        {/* {config[type as keyof typeof config]?.map((itemConfig, index) => (
          <FilterRow key={index} {...itemConfig} />
        ))} */}

        {type === "maturityDate" ||
        type === "yieldMaturity" ||
        type === "dividendYield" ||
        type === "marketCap" ||
        type === "reliabilityRating" ||
        type === "bondType" ||
        type === "coupon" ||
        type === "country" ? (
          RowComponent(itemConfig, 0)
        ) : (
          <>
            {Array.isArray(itemConfig) &&
              itemConfig.map((item: any, index: number) =>
                RowComponent(item, index)
              )}
          </>
        )}
      </div>
    </div>
  );
};
export default FilterModal;
