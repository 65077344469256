import { ErrorMessage, Field } from "formik";
import React from "react";
import "./style.scss";

type PropsType = {
  label: string;
  id: string;
  type: string;
  isInvalid: boolean;
  placeholder?: string;
  customClass?: string;
  autoFocus?: boolean;
};
const FormField: React.FC<PropsType> = ({
  label,
  id,
  type,
  isInvalid,
  placeholder = "",
  customClass,
  autoFocus,
}) => {
  return (
    <div className={`${customClass} form-row`}>
      <label htmlFor={id}>{label}</label>
      <Field
        type={type}
        name={id}
        id={id}
        className={isInvalid ? "input-error" : null}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      <ErrorMessage name={id} component="span" className="error" />
    </div>
  );
};

export default FormField;
