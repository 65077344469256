import React, { useEffect, useRef, useState } from "react";
import GoBack from "../../../assets/GoBackApp.svg";
import "./style.scss";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { showNotification } from "helpers/functions";
import { accountStore } from "entities/account";

export const VerifyChangedPhonePage = () => {
  const [timer, setTimer] = useState(59);
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const { i18n, t: err } = useTranslation("errors");
  const { t: y } = useTranslation("yup");
  const { t } = useTranslation("onboarding-auth");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    requestedPhone: phoneNumber,
    requestChangePhone,
    confirmChangePhone,
  } = useStore(accountStore);

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerPhone);

    return () => setLink("");
  }, []);

  useEffect(() => {
    if (!resend && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else if (!resend && timer === 0) {
      setResend(true);
    }
  }, [timer, resend]);

  const handleResendClick = () => {
    if (isLoading) return;
    requestChangePhone(
      {
        phone: phoneNumber,
        language: i18n.language,
      },
      err,
      () => {
        setResend(false);
        setTimer(59);
      }
    );
  };
  const handleNavigateBack = () => {
    if (isLoading) return;
    navigate(PATH_LIST.changePhone);
  };

  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [result, setResult] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const onRegister = (code: number) => {
    setIsLoading(true);
    confirmChangePhone(
      { code },
      err,
      () => {
        setIsLoading(false);
        navigate(PATH_LIST.account, { state: { refreshUser: true } });
        showNotification(t("phoneNumberChanged"));
      },
      () => {
        setIsLoading(false);
        setError(true);
      }
    );
  };

  const handleOnChange = (newValue: string) => {
    setResult(newValue);
    setError(false);

    if (newValue.length === 4) {
      onRegister(+newValue);
    }
  };
  const isFilled = result.length === 4;

  useEffect(() => {
    if ("OTPCredential" in window) {
      // alert("OTPCredential exists!");
      window.addEventListener("DOMContentLoaded", (e) => {
        const input = document.querySelector(
          'input[autocomplete="one-time-code"]'
        );
        if (!input) {
          // alert("no-input");
          return;
        }
        const ac = new AbortController();
        const form = input.closest("form");
        if (form) {
          form.addEventListener("submit", (e) => {
            ac.abort();
          });
        }
        navigator.credentials
          .get({
            // @ts-ignore
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            alert(otp);
            // @ts-ignore
            input.value = otp.code;
            if (form) form.submit();
          })
          .catch((err) => {
            alert(err);
          });
      });
    }
  }, []);

  return (
    <div className="enterTheCodeAccount">
      <div className="enterTheCodeAccount-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigateBack}
          src={GoBack}
          alt="Go Back"
        />
      </div>
      <p>{t("enterCode")}</p>
      <span>
        {t("smsSentTo")} +{phoneNumber}
      </span>
      <AuthCode
        disabled={isLoading}
        allowedCharacters="numeric"
        length={4}
        onChange={handleOnChange}
        ref={AuthInputRef}
        inputClassName={error ? "red" : isFilled ? "green" : ""}
      />
      {error && <span className="error-verify">{y("codeInvalid")}</span>}
      {!resend ? (
        <span className=" codeTextRe sendCodeAgain">
          {t("sendCodeAgain")}...{timer}{" "}
        </span>
      ) : (
        <span onClick={handleResendClick} className="codeTextRe resendCode">
          {t("resendCode")}
        </span>
      )}
      <span className="editYourNumber" onClick={handleNavigateBack}>
        {t("editNumber")}
      </span>
    </div>
  );
};
