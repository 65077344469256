import React, { useEffect, useState, useCallback } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import ChevronDown from "../../../assets/ChevronDown.svg";
import { useNavigate, useParams } from "react-router-dom";
import Button from "shared/ui/button";
import { PriceTypeModal } from "../components/price-type";
import { AssetType, OperationType, OrderType } from "static/types";
import { dashboardStore } from "entities/dashboard";
import { useStore } from "zustand";
import { marketStore, useTariff } from "entities/market";
import {
  getCommission,
  getFromLotsText,
  getRatePrice,
  navigateToAsset,
} from "helpers/functions";
import { formatMoney } from "helpers/money";
import Loading from "widgets/loading";
import Modal from "widgets/auth/modal";
import { useTranslation } from "react-i18next";
import { ResponseCodes } from "shared/api/sircap";
import { toast } from "react-toastify";
import { ioRTStore } from "services/rt.socket";
import QuantityInput from "../components/quantity-input";
import useCanAction from "hooks/useCanAction";
import useExchangeTimeFrame, {
  ExchangeStatus,
} from "hooks/useExchangeTimeFrame";
import {
  OrderTypes,
  OrderTypesForCurrencies,
  OrderTypesForLimitOrders,
} from "static/data";
import BargainingAlert from "../components/alert";

export const SellPage = () => {
  const { t } = useTranslation("app");
  const { t: tError } = useTranslation("errors");
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [checkedIndex, setCheckedIndex] = useState<number>(0);
  const [specificPrice, setSpecificPrice] = useState<number>();
  const [inputValue, setInputValue] = useState<number>();
  const [type, setType] = useState("");
  const operation = OperationType.Sell;
  const { asset_id } = useParams();
  const {
    currencies,
    loadCurrencies,
    user_deposit_accounts,
    user_brokerage_accounts,
    all_rates,
    loadUserAccounts,
    loadAllRates,
  } = useStore(dashboardStore);
  const { connected, subscribe, unsubscribe } = useStore(ioRTStore);
  const {
    isLoading,
    assetInfo,
    loadAssetInfo,
    openOrder,
    setAssetRealTimePrice,
  } = useStore(marketStore);
  const tariff = useTariff();
  const symbol = assetInfo?.asset?.symbol || "";
  const currSymbol = assetInfo?.asset?.currency?.symbol || "";
  const currCode = assetInfo?.asset?.currency?.code || "";
  const isCurrency = assetInfo?.asset?.type === AssetType.Currency;
  const [confirmDeal, setConfirmDeal] = useState(false);
  const action = useCanAction();
  const exchange = useExchangeTimeFrame(assetInfo?.asset?.exchange);
  const lotSize = assetInfo?.asset?.minimal_quantity || 1;

  const isExchangeActive = [
    AssetType.Stock,
    AssetType.ETF,
    AssetType.Fond,
  ].includes(+type)
    ? !!exchange?.isActive
    : true;
  const onlyForLimitOrders = [
    AssetType.Stock,
    AssetType.ETF,
    AssetType.Fond,
  ].includes(+type)
    ? exchange &&
      [ExchangeStatus.PreMarket, ExchangeStatus.PostMarket].includes(
        exchange.status
      )
    : false;

  const orderTypes = (
    isCurrency
      ? OrderTypesForCurrencies
      : onlyForLimitOrders
      ? OrderTypesForLimitOrders
      : OrderTypes
  )[operation];
  const isSpecific = orderTypes[checkedIndex].showInput;
  const withSirius = orderTypes[checkedIndex].with_sirius;
  const [load, setLoad] = useState(false);
  const [dealError, setDealError] = useState(false);

  useEffect(() => {
    if (onlyForLimitOrders) {
      setCheckedIndex(0);
    }
  }, [onlyForLimitOrders]);

  useEffect(() => {
    try {
      const urlObject = new URL(window.location.href);
      const assetType = urlObject.searchParams.get("type")!;
      const limitOrderPrice = urlObject.searchParams.get("price");

      if (limitOrderPrice) {
        setSpecificPrice(+limitOrderPrice);
        setCheckedIndex(1);
      }

      setType(assetType);

      loadAssetInfo(asset_id);
      loadUserAccounts();
      loadAllRates();
      loadCurrencies();
    } catch (error) {
      navigate(-1);
    }
  }, []);

  const exchangeCode = exchange?.exchange_code;
  useEffect(() => {
    if (!connected || !exchangeCode || !symbol || !isExchangeActive) {
      return;
    }

    const ticker = `${exchangeCode}~${symbol}`;
    subscribe(ticker);

    return () => {
      unsubscribe(ticker);
    };
  }, [symbol, exchangeCode, isExchangeActive, connected]);

  const getUserAccount = useCallback(() => {
    if (assetInfo && assetInfo.asset) {
      if (isCurrency) {
        const acc = user_deposit_accounts.find(
          (acc) => acc.currency.code === symbol
        );
        return {
          symbol: currencies.find((c) => c.code === symbol)?.symbol || "",
          amount: formatMoney(acc?.amount || 0),
        };
      } else {
        const acc = user_brokerage_accounts.find(
          (acc) => acc.asset.symbol === symbol
        );
        const qty = acc?.quantity || 0;
        return {
          symbol: currencies.find((c) => c.code === symbol)?.symbol || "",
          amount: `${qty} ${symbol}`,
          totalAmount: qty,
        };
      }
    }
    return {
      symbol: currencies.find((c) => c.code === symbol)?.symbol || "",
      amount: 0,
    };
  }, [
    user_deposit_accounts,
    user_brokerage_accounts,
    assetInfo,
    currencies,
    isCurrency,
  ]);

  const getAssetPriceBid = useCallback(() => {
    try {
      if ([AssetType.ETF, AssetType.Stock, AssetType.Fond].includes(+type)) {
        const info = assetInfo?.asset;
        if (info) return info.bid;
        return 0;
      } else if ([AssetType.Bond, AssetType.EuroBond].includes(+type)) {
        const info = assetInfo?.asset;
        if (info) {
          const { ai, nominal } = info?.data;
          return formatMoney((info.bid / 100) * nominal + ai);
        }
        return 0;
      } else {
        const bidPrice = assetInfo?.asset?.bid;
        if (bidPrice) {
          return +bidPrice.toFixed(2);
        }
        const price = getRatePrice(all_rates, {
          trade_asset: assetInfo?.asset?.currency.code,
          price_asset: assetInfo?.asset?.symbol,
        });
        if (price) {
          return +(1 / price).toFixed(2);
        } else {
          return 0;
        }
      }
    } catch (error) {
      return 0;
    }
  }, [assetInfo, all_rates]);

  const getAssetPrice = useCallback((): number => {
    try {
      if (isSpecific) return +(specificPrice || 0);
      return getAssetPriceBid();
    } catch (error) {
      return 0;
    }
  }, [isSpecific, specificPrice, getAssetPriceBid]);

  const getTotalPrice = useCallback(() => {
    try {
      const price = getAssetPrice();
      const total = price * +(inputValue || 0) * lotSize;
      if (!total) return 0;
      return parseFloat(total.toFixed(2));
    } catch (error) {
      return 0;
    }
  }, [inputValue, assetInfo, getAssetPrice, lotSize]);

  const getAvailableLots = useCallback(() => {
    try {
      const acc = getUserAccount();
      const lots = isCurrency
        ? +((acc.amount as number) / lotSize).toFixed(4)
        : acc.totalAmount;
      if (lots === Infinity) return 0;
      if (!lots) return 0;
      return +lots;
    } catch (error) {
      return 0;
    }
  }, [getUserAccount, lotSize]);

  const getMinimumLots = useCallback(() => {
    try {
      const isCurrency = assetInfo?.asset?.type === AssetType.Currency;
      if (isCurrency) return 1;
      // const price = getAssetPrice();
      // const minCommission = assetInfo?.asset?.minimal_commission;
      // if (!minCommission) return 1;
      // const [_, __, commision] = getCommission(
      //   tariff,
      //   assetInfo!.asset!.type,
      //   assetInfo!.asset!.symbol,
      //   price,
      //   OperationType.Sell,
      //   assetInfo?.asset?.data?.country || ""
      // );
      // const absComm = Math.abs(commision);
      // const maxLotsCommission = absComm * getAvailableLots();
      // if (maxLotsCommission < minCommission) {
      //   return getAvailableLots();
      // }
      // const lots = Math.ceil(minCommission / absComm);
      // if (lots === Infinity || lots < 1) return 1;
      // return lots;
      return 1;
    } catch (error) {
      return 1;
    }
  }, [tariff, assetInfo, specificPrice, checkedIndex, getAvailableLots]);

  const getAssetDayChange = useCallback(() => {
    try {
      const quote = assetInfo?.asset;

      if (quote?.change && quote.changesPercentage) {
        return {
          isLoss: quote!.change < 0,
          percent: `${quote!.changesPercentage}%`,
          amount: `${quote!.change}$`,
        };
      } else {
        return {
          isLoss: false,
          percent: "",
          amount: "",
        };
      }
    } catch (error) {
      return {
        isLoss: false,
        percent: "",
        amount: "",
      };
    }
  }, [assetInfo]);

  const getCommissionWrap = useCallback(() => {
    try {
      return getCommission(
        tariff,
        assetInfo!.asset!.type,
        assetInfo!.asset!.symbol,
        getTotalPrice(),
        OperationType.Sell,
        assetInfo?.asset?.minimal_commission || 0,
        withSirius,
        assetInfo?.asset?.data?.country || ""
      );
    } catch (error) {
      return [0, 0, 0];
    }
  }, [tariff, assetInfo, getTotalPrice]);

  const getTotalPriceAndCommission = (): string => {
    try {
      const total = getTotalPrice();
      if (!total) return "";
      const commission = getCommissionWrap()[2];
      return `${+(total + commission).toFixed(2)}${currSymbol}`;
    } catch (error) {
      return "";
    }
  };

  const getAssetCommission = () => {
    try {
      const total = getTotalPrice();
      if (!total) return <></>;

      const [commission, additional, amount] = getCommissionWrap();
      if (!amount) return <></>;

      return (
        <div className="deal-commission">
          {amount > assetInfo!.asset!.minimal_commission ? (
            <p>
              {t("commission")}: {commission}%{" "}
              {additional ? `+ ${additional}$` : ""}
            </p>
          ) : (
            <p>
              {t("commission")}: {additional ? `+ ${additional}$` : ""}
            </p>
          )}
          <span>
            {amount}
            {assetInfo!.asset!.currency.symbol}
          </span>
        </div>
      );
    } catch (error) {
      return <></>;
    }
  };

  const getAssetYield = useCallback(() => {
    try {
      if ([AssetType.Bond, AssetType.EuroBond].includes(+type)) {
        const info = assetInfo?.asset;
        if (info) {
          const { bid_yield } = info?.data;
          const yield_v = formatMoney(bid_yield);

          return (
            <div className="deal-commission">
              <p>
                {t("yield")}: {yield_v}%
              </p>
              {/* <span>
                {amount}
                {assetInfo!.asset!.currency.symbol}
              </span> */}
            </div>
          );
        }
        return <></>;
      }

      return <></>;
    } catch (error) {
      return <></>;
    }
  }, [assetInfo]);

  const handleSell = (pinCode: string) => {
    if (assetInfo?.asset) {
      setLoad(true);
      openOrder(
        assetInfo.asset.id,
        {
          pinCode,
          comments: "",
          asset: assetInfo.asset.id,
          type: isSpecific ? OrderType.Limit : OrderType.Market,
          price: isSpecific ? +(specificPrice || 0) : getAssetPrice(),
          quantity: +(inputValue || 0) * lotSize,
          operation: OperationType.Sell,
          with_sirius: withSirius,
        },
        tError,
        (error?: number) => {
          setLoad(false);
          if (error) {
            if (error === ResponseCodes.WrongPinCode) {
              setDealError(true);
            } else {
              const err = String(error);
              toast.error(tError(err));
            }
          } else {
            toast.success("Success");
            navigateToAsset(assetInfo!.asset!.type, asset_id, navigate);
          }
        }
      );
    }
  };

  const unavailable = useCallback(() => {
    const acc = getUserAccount();
    const minLots = getMinimumLots();
    const maxLots = getAvailableLots();
    const userBalance = acc.amount as any;
    if (
      !userBalance ||
      !inputValue ||
      inputValue < 0 ||
      inputValue < minLots ||
      inputValue > maxLots
    )
      return true;
    if (isCurrency) {
      return inputValue > userBalance;
    } else {
      return inputValue > parseFloat(userBalance as string);
    }
  }, [
    inputValue,
    isCurrency,
    getUserAccount,
    getMinimumLots,
    getAvailableLots,
  ]);

  // useEffect(() => {
  //   console.log(assetInfo?.asset?.bid);
  //   console.log(assetInfo?.asset?.ask);
  //   console.log("====================================");
  // }, [assetInfo]);

  // useEffect(() => {
  //   console.log(specificPrice);
  // }, [specificPrice]);

  const getDepthValidationAlert = () => {
    const isCurrency = assetInfo?.asset?.type === AssetType.Currency;

    if (isSpecific && +specificPrice! > 0 && isCurrency) {
      if (
        +specificPrice! >= assetInfo!.asset!.bid &&
        +specificPrice! <= assetInfo!.asset!.ask
      ) {
        return;
      } else {
        return (
          <span className="depth-validation-alert">
            {t("priceMustBeWithinTheRange")} {assetInfo!.asset!.bid} /{" "}
            {assetInfo!.asset!.ask}
          </span>
        );
      }
    }
    return;
  };

  if (isLoading) return <Loading />;

  return (
    <div className="deal-container">
      <div className="deal-header">
        <img
          className="go-back-portfolio"
          onClick={() => navigate(-1)}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          <p>{symbol}</p>
          <div>
            <span
              style={{
                padding: getAssetDayChange().percent ? 4 : 0,
                margin: getAssetDayChange().percent ? 4 : 0,
              }}
            >
              {getAssetPriceBid()}
              {currSymbol}
            </span>
            {getAssetDayChange().percent && (
              <span className={getAssetDayChange().isLoss ? "loss" : ""}>
                {getAssetDayChange().percent}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="deal-main">
        <div className="deal-on-account">
          <span className="title-span">{t("sellFromAccount")}</span>
          <div className="deal-on-account-box">
            <div>
              <span>{t("mainAccount")}</span>
              <p>
                {getUserAccount().amount}
                {getUserAccount().symbol}
              </p>
            </div>
          </div>
        </div>
        {onlyForLimitOrders && (
          <BargainingAlert
            variant="info"
            text={t("onlyLimitOrdersAvailable")}
          />
        )}
        <div onClick={() => setModal(true)} className="price-type-container">
          <p>{t(orderTypes[checkedIndex].title)}</p>
          <img src={ChevronDown} alt="ChevronDown" />
        </div>

        <div className="price-box">
          {isSpecific ? (
            <>
              <input
                className="specific-price-input"
                type={"number"}
                value={specificPrice}
                onChange={(e) => {
                  // const newValue = e.target.value.replace(/[^0-9.]/g, "");
                  const strValue = String(e.target.value);
                  const value = +strValue || "";
                  setSpecificPrice(value as number);
                }}
                min={0}
                autoFocus={true}
              />
              <span>{currSymbol}</span>
              {+(specificPrice || 0) <= 0 && (
                <span className="error-spec-price">{t("wrongValue")}</span>
              )}
              {getDepthValidationAlert()}
            </>
          ) : (
            <p>
              {getAssetPrice()}
              {currSymbol}
            </p>
          )}
        </div>
        <div className="deal-quantity">
          <label htmlFor="quantity">{t("quantity")}</label>
          <QuantityInput
            assetType={type}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setIsActive={setIsActive}
            fromLots={getMinimumLots()}
          />
          {isActive && (
            <div className="info-about">
              <p>
                1 {t("lot")} = {assetInfo?.asset?.minimal_quantity} {t("unit")}
              </p>
              <p>
                {t("minimum")}:
                {getFromLotsText(getMinimumLots()).includes("lots")
                  ? getFromLotsText(getMinimumLots()).replace("lots", t("lots"))
                  : getFromLotsText(getMinimumLots()).replace("lot", t("lot"))}
              </p>
              <p>
                {t("canSell")}:{" "}
                {getFromLotsText(getAvailableLots()).includes("lots")
                  ? getFromLotsText(getAvailableLots()).replace(
                      "lots",
                      t("lots")
                    )
                  : getFromLotsText(getAvailableLots()).replace(
                      "lot",
                      t("lot")
                    )}
              </p>
            </div>
          )}
        </div>
        {getAssetYield()}
        {getAssetCommission()}
      </div>
      <div className="btn-wrapper deal-btn-wrapper">
        <Button
          type="submit"
          disabled={
            unavailable() ||
            isLoading ||
            !isExchangeActive ||
            getDepthValidationAlert() ||
            (isSpecific && +(specificPrice || 0) <= 0)
          }
          variant="dark"
          className="costumBtn deal-btn"
          // onClick={handleSell}
          onClick={() => action(() => setConfirmDeal(true))}
        >
          {t("sell")}
          <span>{getTotalPriceAndCommission()}</span>
        </Button>
      </div>
      {modal && (
        <PriceTypeModal
          onClose={() => setModal(false)}
          checked={checkedIndex}
          setChecked={setCheckedIndex}
          list={orderTypes}
        />
      )}
      {confirmDeal === true && (
        <Modal
          customModal="modalLogOut"
          onClick={handleSell}
          onHide={() => setConfirmDeal(false)}
          title={t("confirmDeal")}
          text={t("toCompleteDeal")}
          btnText={t("confirm")}
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          isLoading={load}
          dealError={dealError}
        />
      )}
    </div>
  );
};
