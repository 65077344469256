import React, { FC } from "react";
import "./style.scss";

type PropType = {
  text: string;
  active: boolean;
  onClick: () => void;
};

export const TabBtn: FC<PropType> = ({ text, active, onClick }) => {
  return (
    <button className={`tab-btn ${active ? "active-tab" : ""}`} onClick={onClick}>
      <span>{text}</span>
    </button>
  );
};
