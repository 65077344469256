import React, { FC, useState } from "react";
import "./style.scss";
import Image from "../../../../assets/icon-image.svg";
import Camera from "../../../../assets/icon-camera.svg";
import Trash from "../../../../assets/icon-trash.svg";

import { AddAccCurrencyRow } from "../add-acc-currency-checkbox";
import { useTranslation } from "react-i18next";

type PropType = {
  onClose: () => void;
  onGalleryClick: () => void;
  onCameraClick: () => void;
  onRemoveClick: () => void;
  remove: boolean;
};

export const EditProfilePhoto: FC<PropType> = ({
  onClose,
  onGalleryClick,
  onCameraClick,
  onRemoveClick,
  remove,
}) => {
  const { t } = useTranslation("app");
  return (
    <div className="bottom-sheet-modal-wrapper">
      <div onClick={onClose} className="outlet-edit-profil-img"></div>

      <div className="edit-profil-img">
        <div className="edit-profil-img-header">
          <p>{t("editProfilePhoto")}</p>
        </div>

        <div onClick={onGalleryClick} className="edit-profile-row">
          <img src={Image} alt="icon" />
          <p>{t("gallery")}</p>
        </div>

        <div onClick={onCameraClick} className="edit-profile-row">
          <img src={Camera} alt="icon" />
          <p>{t("camera")}</p>
        </div>

        {remove === true && (
          <div onClick={onRemoveClick} className="edit-profile-row">
            <img src={Trash} alt="icon" />
            <p>{t("removePhoto")}</p>
          </div>
        )}
      </div>
    </div>
  );
};
