import React, { useState } from "react";
import "./style.scss";
import IconCamera from "../../assets/IconCamera.svg";
import { useStore } from "zustand";
import { CameraStatus, onboardModel } from "entities/onboard";
import { useTranslation } from "react-i18next";

type PropsType = {
  onOpen: any;
  customClass?: string;
};
const OpenCameraButton: React.FC<PropsType> = ({ onOpen, customClass }) => {
  const { setCameraStatus } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");

  const onClick = () => {
    if (navigator.mediaDevices === undefined) {
      (navigator as any).mediaDevices = {};
      setCameraStatus(CameraStatus.NotFound);
      return;
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        // First get ahold of the legacy getUserMedia, if present
        const getUserMedia =
          // @ts-ignore
          navigator.getUserMedia ||
          // @ts-ignore
          navigator.webkitGetUserMedia ||
          // @ts-ignore
          navigator.mozGetUserMedia ||
          // @ts-ignore
          navigator.msGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }

    setCameraStatus(CameraStatus.Allowed);
    onOpen();
  };

  return (
    <>
      <button onClick={onClick} className={`${customClass} openCameraBtn`}>
        <img src={IconCamera} alt="CameraIcon" />
        {t("openCameraAndTakePhoto")}
      </button>
    </>
  );
};

export default OpenCameraButton;
