import React, { useState, useEffect, useRef, FC } from "react";
import styles from "./styles.module.css";
import { COUNTRIES } from "static/countries";
import CheckIcon from "../../assets/icon-check.svg";
import { useTranslation } from "react-i18next";

export const CaretDownIcon = (props: any) => {
  const setStyle = () => {
    let style;
    switch (props.point) {
      case "up":
        style = {
          position: "absolute",
          color: "#696969",
          right: "20px",
          transition: "all 0.3s",
          transform: "rotate(180deg)",
          height: "16px",
        };
        break;

      case "down":
        style = {
          position: "absolute",
          right: "20px",
          color: "#696969",
          transition: "all 0.3s",
          height: "16px",
        };
        break;

      case "up_white":
        style = {
          position: "absolute",
          left: "10px",
          top: "-10px",
          transform: "rotate(180deg)",
          color: "#fff",
          height: "16px",
        };
        break;
    }
    return style;
  };
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="caret-down"
      className="svg-inline--fa fa-caret-down fa-w-10"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      height="18px"
      color={props.color}
      // @ts-ignore
      style={setStyle()}
    >
      <path
        fill="currentColor"
        d="M31.3 192h257.3c17.8 0 26.7 
              21.5 14.1 34.1L174.1 354.8c-7.8 
              7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 
              213.5 13.5 192 31.3 192z"
      ></path>
    </svg>
  );
};
type PropsType = {
  dropdown?: boolean;
  customWrapperClass?: string;
  customSelectedClass?: string;
  specificCodes?: string[];
  returnAlpha2?: boolean;
  multiple?: boolean;
};
export const ReactCountryDropdown: FC<PropsType & any> = (
  props: PropsType & any
) => {
  const [countries, setCountries] = useState<any[]>([]);
  const [countriesCopy, setCountriesCopy] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState<any>({});
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
  const { t } = useTranslation("app");
  const dropdownRef = useRef(null);

  useEffect(() => {
    defaultCountrySetter(props.countryCode ? props.countryCode : "US");
    preFetchCountries().then((res) => {
      // console.log({ res });
      setCountries(res);
      setCountriesCopy(res);
    });

    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  const defaultCountrySetter = (d: string | string[]) => {
    preFetchCountries().then((countries) => {
      if (props.multiple) {
        const defaultC = countries.filter(
          (country) =>
            d.includes(country.alpha3Code) || d.includes(country.alpha2Code)
        );
        setSelectedCountries(defaultC || []);
      } else {
        const defaultC = countries.find(
          (country) => country.alpha3Code === d || country.alpha2Code === d
        );
        setDefaultCountry(defaultC || countries[0]);
      }
    });
  };

  const preFetchCountries = async () => {
    // const data = await fetch(
    //   "https://restcountries.com/v2/all?fields=name,flag,alpha3Code"
    // );
    // const result = await data.json();
    let result = COUNTRIES;
    if (props.specificCodes && props.specificCodes.length) {
      result = result.filter(
        (c) =>
          props.specificCodes.includes(c.alpha2Code) ||
          props.specificCodes.includes(c.alpha3Code)
      );
    }
    return result;
  };

  const handleClickOutSide = (e: { target: any }) => {
    // @ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const toggleDropDown = () => {
    /* reset all countries before closing */
    if (!open) {
      setCountries(countriesCopy);
    }
    setOpen(!open);
  };

  const handleCountryClick = (country: React.SetStateAction<any>) => {
    const code = props?.returnAlpha2
      ? country?.alpha2Code
      : country?.alpha3Code;

    if (props.onSelect) {
      if (props.multiple) {
        const newList = selectedCountries.includes(country)
          ? selectedCountries.filter((s) => s !== country)
          : [...selectedCountries, country];
        setSelectedCountries(newList);
        props.onSelect(
          newList.map((c) =>
            props?.returnAlpha2 ? c?.alpha2Code : c?.alpha3Code
          )
        );
      } else {
        props.onSelect(code);
        setDefaultCountry(country);
      }
    }

    /* Hide the dropdown menu on selecting a country */
    toggleDropDown();
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.toLowerCase();
    let filteredCountries = countriesCopy.filter((i) =>
      // @ts-ignore
      i.name.toLowerCase().includes(input.toLowerCase())
    );
    setCountries(filteredCountries);
  };

  return (
    <div className={`${styles.container} ${props.className}`} ref={dropdownRef}>
      {!props.multiple && (
        <div
          className={`${
            props.customSelectedClass ? props.customSelectedClass + " " : ""
          } ${styles.dropdown}`}
          onClick={toggleDropDown}
        >
          <img
            className={styles.country_flag}
            src={defaultCountry?.flag}
            alt={defaultCountry?.name}
          />
          <span className={styles.selected_country}>
            {defaultCountry?.name}
          </span>
          {!props.customSelectedClass && (
            <CaretDownIcon point={open ? "up" : "down"} />
          )}
        </div>
      )}

      {open && !props.dropdown && (
        <div className={styles.dropdown_items_wrapper}>
          {/* <CaretDownIcon point="up_white" /> */}
          <div className={styles.input_wrapper}>
            <input
              onChange={(e) => handleSearchInput(e)}
              className={styles.country_search}
              type="text"
              placeholder={t("searchCountry")}
            />
          </div>
          <div className={styles.dropdown_items}>
            {countries.map((i, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleCountryClick(i)}
                  className={styles.dropdown_item}
                >
                  <img className={styles.country_flag} src={i.flag} alt="" />
                  <span className={styles.dropdown_item_title}> {i.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {props.dropdown && (
        <div
          className={`${
            props.customWrapperClass ? props.customWrapperClass + " " : ""
          } ${styles.dropdown_items_wrapper}`}
        >
          <div className={styles.input_wrapper}>
            <input
              onChange={(e) => handleSearchInput(e)}
              className={styles.country_search}
              type="text"
              placeholder={t("searchCountry")}
            />
          </div>

          <div
            className={
              props.multiple
                ? `${styles.dropdown_items_larg} ${styles.dropdown_items}`
                : styles.dropdown_items
            }
          >
            {countries.map((i, index) => {
              return (
                <div
                  style={{ position: "relative" }}
                  key={index}
                  onClick={() => handleCountryClick(i)}
                  className={styles.dropdown_item}
                >
                  <img
                    className={styles.country_flag}
                    src={i.flag}
                    alt="Flag"
                  />
                  <span className={styles.dropdown_item_title}> {i.name}</span>
                  {props.multiple && selectedCountries.includes(i) && (
                    <img
                      style={{ position: "absolute", right: "24px" }}
                      src={CheckIcon}
                      alt="CheckIcon"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
