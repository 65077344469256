import React, { useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import { UserStatus } from "../components/account-status";
import { sessionStore } from "entities/session/model/sessionModel";
import TariffColumn from "../components/tariffColumn";
import { marketStore } from "entities/market";

export const TariffsPage: React.FC = () => {
  const { t } = useTranslation("app");
  const { user } = useStore(sessionStore);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };

  const { tariffs } = useStore(marketStore);
  const tariffID = user!.tariff - 1;

  if (!tariffs || !tariffs.length || !user || !user?.tariff) {
    return <></>;
  }

  const tariffColumns = [
    { title: "Minimum balance", info: tariffs[tariffID].minBalance },
    { title: "Armenian exchange", classNameTitle: "lightTitle" },
    {
      title: "Government and corporate bonds",
      info: tariffs[tariffID].govBonds,
    },
    {
      title: "Global markets",
      background: "darkLine",
      classNameTitle: "lightTitle",
    },
    {
      title: "Exchange traded stocks/bonds",
      background: "darkLine",
      info: tariffs[tariffID].exBonds,
    },
    { title: "FX", classNameTitle: "lightTitle" },
    { title: "G-10 Currencies", info: tariffs[tariffID].g10Currencies },
    { title: "Reginal currencies", info: tariffs[tariffID].regCurrencies },
    {
      title: "OTC",
      background: "darkLine",
      classNameTitle: "lightTitle",
    },
    {
      title: "Eurobonds",
      background: "darkLine",
      info: tariffs[tariffID].otc_euroBonds,
    },
    {
      title: "ADRs",
      background: "darkLine",
      info: tariffs[tariffID].otc_ADRs,
    },
    {
      title: "GDRs",
      background: "darkLine",
      info: tariffs[tariffID].otc_GDRs,
    },
    { title: "Custody annual fee", classNameTitle: "lightTitle" },
    { title: "Eurobonds", info: tariffs[tariffID].caf_euroBonds },
    { title: "ADRs", info: tariffs[tariffID].caf_ADRs },
    { title: "GDRs", info: tariffs[tariffID].caf_GDRs },
    { title: "local bonds", info: tariffs[tariffID].localBonds },
    { title: "local stocks", info: tariffs[tariffID].localStocks },
    { title: "global bonds", info: tariffs[tariffID].globalBonds },
    { title: "global stocks", info: tariffs[tariffID].globalStocks },
    {
      title: "Free of Payment Transfers within CDA",
      info: tariffs[tariffID].freePaymentWithinCDA,
    },
    {
      title: "Free of Payment Transfers outside CDA",
      info: tariffs[tariffID].freePaymentOutsideCDA,
    },
    {
      title: "Asset Management",
      background: "darkLine",
      classNameTitle: "lightTitle",
    },
    {
      title: "Management fee",
      background: "darkLine",
      info: tariffs[tariffID].managementFee,
    },
    {
      title: "Individual strategy",
      background: "darkLine",
      info: tariffs[tariffID].individualStrategy,
    },
    {
      title: "Index strategies – ETFs",
      background: "darkLine",
      info: tariffs[tariffID].indexStrategies,
    },
    {
      title: "Success fee",
      background: "darkLine",
      info: tariffs[tariffID].successFee,
    },
    {
      title: "Individual strategy",
      background: "darkLine",
      info: tariffs[tariffID].individualStrategySuccess,
    },
    {
      title: "Index strategies – ETFs",
      background: "darkLine",
      info: tariffs[tariffID].indexStrategiesSuccess,
    },
    { title: "Advisory", classNameTitle: "lightTitle" },
    { title: "Personal advisor", info: tariffs[tariffID].personalAdvisor },
    { title: "Robo Advisor", info: tariffs[tariffID].roboAdvisor },
  ];

  return (
    <div className="acc-tariffs">
      <div className="acc-tariffs-header">
        <img
          className="go-back-tariffs"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("myTariffs")}</p>
        <UserStatus status={user?.tariff} />
      </div>
      <div className="acc-tariffs-main">
        <table>
          <tbody>
            {tariffColumns.map((column, index) => (
              <TariffColumn
                key={index}
                title={t(column.title)}
                info={column.info}
                background={column.background}
                classNameTitle={column.classNameTitle}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
