import React, { useCallback, useRef, useState } from "react";
import JapaneseCandlesticks from "../../../assets/JapaneseCandlesticks.png";
import LineGraph from "../../../assets/LineGraph.png";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HCStock from "highcharts/modules/stock";
import HCMore from "highcharts/highcharts-more";
import "./style.scss";

HCStock(Highcharts);
HCMore(Highcharts);

const dateFormats = ["%H:%M", "%d.%m", "%d.%m", "%Y.%m"];

type PropsType = {
  chart: any;
  title?: string;
};

export const StrategyChart: React.FC<PropsType> = React.memo(
  ({ chart, title }) => {
    // const chartRef = useRef<any | null>(null);

    const getAssetChart = useCallback(() => {
      try {
        if (!chart) return [];
        let list = chart || [];
        list = list.map(
          ([date, open, high, low, close, volume]: any, i: any) => ({
            y: close,
            open: open,
            close: close,
            low,
            high,
            volume,
            x: date,
          })
        );
        return [...list].reverse();
      } catch (error) {
        return [];
      }
    }, [chart]);

    // const secondLineData = getAssetChart().map((dataPoint) => {
    //   return {
    //     x: dataPoint.x,
    //     y: dataPoint.close + 15,
    //   };
    // });

    const chartOptions = {
      // navigator: {
      //   enabled: true,
      //   adaptToUpdatedData: false,
      //   series: {
      //     data: getAssetChart(),
      //   },
      // },
      // scrollbar: {
      //   liveRedraw: false,
      // },

      rangeSelector: {
        x: 0,
        buttonTheme: {
          borderRadius: 5,
          width: 42,
          height: 36,
          fill: "white",
          "stroke-width": 0,

          style: {
            color: "#000F30",
            fontWeight: "bold",
            outline: "1px solid #BFC4CE",
            borderRadius: "8px",
          },
          states: {
            hover: {
              fill: "white",
              style: {
                color: "#000F30",
                outline: "2px solid #000F30",
              },
              "stroke-width": 0,
            },
            select: {
              fill: "white",
              style: {
                color: "#000F30",
                outline: "2px solid #000F30",
              },
              "stroke-width": 0,
            },
          },
        },
        buttons: [
          {
            type: "day",
            count: 1,
            text: "1D",
            title: "1D",
          },
          {
            type: "week",
            count: 1,
            text: "7D",
            title: "7D",
          },
          {
            type: "month",
            count: 1,
            text: "1M",
            title: "1M",
          },
          {
            type: "all",
            text: "All",
            title: "View all",
          },
        ],
        allButtonsEnabled: true,
        enabled: true,
        inputEnabled: false,
        selected: 2,
      },
      title: {
        text: title,
        align: "left",
        verticalAlign: "bottom",

        style: {
          fontWeight: "bold", // Add other style properties as needed
          fontSize: "14px",
        },
      },
      tooltip: {
        headerFormat: "",
        formatter: function () {
          const thisVar: any = this;
          const date =
            "Date: <b>" + new Date(thisVar.x).toLocaleDateString() + "</b>";
          const price = `Price: <b>${thisVar.y}</b>`;
          return `${date}<br />${price}`;
        },
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
            lineWidth: 1,
          },
          dataGrouping: {
            approximation: "average",
          },
        },
      },
      xAxis: {
        // min: 0,
        // max: getAssetChart().length - 1,
        // scrollbar: {
        //   enabled: true, // Enable the scrollbar
        // },
        // panning: true, // Enable dragging to scroll
        // panKey: "shift", // Require holding down the Shift key while dragging to enable panning

        // ........

        labels: {
          formatter: function () {
            const thisVar: any = this;
            const value = thisVar.value;
            try {
              const selected = thisVar.chart.rangeSelector.selected;
              if (!selected) {
                return Highcharts.dateFormat("%d.%m", value);
              }
              return Highcharts.dateFormat(dateFormats[selected], value);
            } catch (error) {
              return Highcharts.dateFormat("%d.%m", value);
            }
          },
        },
        lineWidth: 0,
        tickWidth: 0,
        gridLineWidth: 1,
      },
      yAxis: {
        opposite: true,
        gridLineWidth: 1,
      },
      // legend: {
      //   align: "left", // Set the alignment to the left
      //   x: 0, // Adjust the horizontal offset as needed
      // },
      legend: {
        align: "left",
        verticalAlign: "bottom",
        floating: false,
        spacingTop: 50,
        symbol: "circle",
      },
      series: [
        {
          type: "line",
          // name: "First Line",
          data: getAssetChart(),
          showInLegend: false,
          threshold: null,
          lineColor: "#3898E0",

          marker: {
            symbol: "circle",
            fillColor: "#3898E0", // Color for the dots
            lineColor: "#3898E0", // Border color for the dots
          },
        },
        // {
        //   type: "line",
        //   name: "Second Line",
        //   data: secondLineData,
        //   showInLegend: true,
        //   threshold: null,
        //   lineColor: "#F8009E",

        //   marker: {
        //     symbol: "circle",
        //     fillColor: "#F8009E",
        //     lineColor: "#F8009E",
        //   },
        // },
      ],
    };

    if (!chart || !chart?.length) return <></>;

    return (
      <>
        <div className="strategy-charts">
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"chart"}
            options={chartOptions}
            containerProps={{
              className: "strategy-chart-container",
            }}
          />
        </div>
      </>
    );
  }
);
