import { useEffect, useRef, useState, DependencyList } from "react";

export const useAssetVolatility = (price: number) => {
  const prevPriceRef = useRef<number>(price);
  const [changedPart, setChangedPart] = useState<string>("");
  const [unchangedPart, setUnchangedPart] = useState<string>("");
  const [volatility, setVolatility] = useState("Upswing" || "Downswing");

  const getChangeIndex = (prev: number, curr: number): number => {
    const prevStr = prev.toString();
    const currStr = curr.toString();
    let index = 0;

    while (index < prevStr.length && prevStr[index] === currStr[index]) {
      index++;
    }
    return index;
  };

  useEffect(() => {
    const newNumber = price;
    const changeIndex = getChangeIndex(prevPriceRef.current, newNumber);

    if (newNumber > prevPriceRef.current) {
      setVolatility("Upswing");
    } else if (newNumber == prevPriceRef.current) {
      setVolatility(volatility);
    } else {
      setVolatility("Downswing");
    }
    const change = getChangeIndex(prevPriceRef.current, newNumber);
    const prevStr = prevPriceRef.current.toString();
    const currStr = newNumber.toString();
    setChangedPart(currStr.slice(changeIndex));
    setUnchangedPart(currStr.slice(0, changeIndex));
    prevPriceRef.current = newNumber;
  }, [price]);

  const volatilityElementRef = useRef<HTMLSpanElement>(null);
  const initialRender = useRef(true);

  useEffect(() => {
    // if (!initialRender.current) {
    const volatilityElement = volatilityElementRef.current;
    if (volatilityElement) {
      // console.log("====================================");
      // console.log();
      // console.log("====================================");
      volatilityElement.classList.add(
        volatility === "Upswing"
          ? "changed-price-upswing"
          : "changed-price-downswing"
      );

      const changedTimeout = setTimeout(() => {
        volatilityElement.classList.remove(
          "changed-price-downswing",
          "changed-price-upswing"
        );
      }, 490);

      return () => {
        clearTimeout(changedTimeout);
      };
    }
    // } else {
    //   initialRender.current = false;
    // }
  }, [changedPart]);

  return {
    changedPart,
    unchangedPart,
    volatilityElementRef,
  };
};
