import React, { useEffect, useState } from "react";
import "./style.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ProgressLine } from "widgets/progress-line";
import { PATH_LIST } from "shared/lib/react-router";
import { onboardModel } from "entities/onboard";
import { useTranslation } from "react-i18next";

export const AuthLayout = () => {
  const location = useLocation();

  return (
    <div
      className={
        location.pathname !== PATH_LIST.accountHasBeenCreated &&
        location.pathname !== PATH_LIST.login
          ? "auth-layout"
          : "auth-layout authLayoutAccCreated"
      }
    >
      <Outlet />
    </div>
  );
};
