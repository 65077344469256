import React, { useCallback, useState, useEffect } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { TabBtn } from "../components/tab-btn";
import { ActionBtn } from "../components/action-btn";
import IndicatorQ from "../../../assets/IndicatorQuest.svg";
import { Progress } from "rsuite";
import ReliabilityRating from "../components/reliability-rating-row";
import IndicatorDetails from "../components/indicators-details-row";
import DividentsDetails from "../components/dividents-details-row";
import HorizontalScroll from "../components/horizontal-scroll";
import { DealsTable, MarketChart } from "widgets/market";
import { sessionModel } from "entities/session";
import { marketStore } from "entities/market";
import { useStore } from "zustand";
import { formatPrice, isBondMatured } from "helpers/functions";
import { AssetType, OperationType } from "static/types";
import { dashboardStore } from "entities/dashboard";
import ReviewDetails from "../components/review-details-row";
import { useTranslation } from "react-i18next";
import { formatMoney, getAvgValue } from "helpers/money";
import AssetIcon from "../components/asset-icon";
import { GetCountryByCode } from "static/countries";
import useCanAction from "hooks/useCanAction";
import useSaveHandler from "hooks/useSaveToFavorites";
import SavedToFavNotification from "../components/saved-to-favorites-notification";
import SaveToFavBtn from "../components/save-to-favorites-btn";

export const BondDetailsPage = () => {
  const { t } = useTranslation("app");
  const [activeTab, setActiveTab] = useState("Bargaining");
  const { asset_id } = useParams();

  const action = useCanAction();

  const {
    isLoading,
    assetInfo,
    loadAssetInfo,
    loadAssetChart,
    addFavorites,
    removeFavorite,
    favorites,
  } = useStore(marketStore);

  const symbol = assetInfo?.asset?.symbol || "";
  const isMatured = isBondMatured(assetInfo?.asset);

  const getAssetInfo = useCallback(() => {
    try {
      const info = assetInfo?.info;
      return info;
    } catch (error) {
      return null;
    }
  }, [assetInfo]);

  const getAssetPrice = useCallback(
    (type: "buy" | "sell" | "avg") => {
      try {
        const info = assetInfo?.asset;
        const { ai, nominal } = info?.data;
        let price: any;
        if (type == "avg") {
          price = getAvgValue(info!.ask, info!.bid);
          price = formatMoney((price! / 100) * nominal + ai);
        } else {
          price = type === "buy" ? info?.ask : info?.bid;
          price = formatMoney((price! / 100) * nominal + ai);
        }
        return `${price}${assetInfo!.asset!.currency.symbol}`;
      } catch (error) {
        return "";
      }
    },
    [assetInfo]
  );

  const getAssetBidAsk = useCallback(
    (type: "buy" | "sell" | "avg") => {
      try {
        const info = assetInfo?.asset;
        const { ai, nominal } = info?.data;
        let price: any;
        if (type == "avg") {
          price = getAvgValue(info!.ask, info!.bid);
          price = formatMoney(price!);
        } else {
          price = type === "buy" ? info?.ask : info?.bid;
          price = formatMoney(price!);
        }
        return `${price}%`;
      } catch (error) {
        return "";
      }
    },
    [assetInfo]
  );

  const getAssetYield = useCallback(() => {
    try {
      const info = assetInfo?.asset;
      const { bid_yield, ask_yield } = info?.data;
      const yield_v = formatMoney(getAvgValue(bid_yield, ask_yield));
      return `${yield_v} %`;
    } catch (error) {
      return "";
    }
  }, [assetInfo]);

  useEffect(() => {
    loadAssetInfo(asset_id);
    loadAssetChart(asset_id);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { prevPathDeal } = useStore(dashboardStore);
  const [toPath, setToPath] = useState<any>(-1);

  useEffect(() => {
    if (prevPathDeal === true) {
      setToPath(PATH_LIST.market);
    } else {
      setToPath(-1);
    }
  }, [location]);

  const handleNavigate = () => {
    navigate(toPath);
  };
  const scroll = () => {
    const stockDetails = document.querySelector(".stock-details");
    if (stockDetails) {
      stockDetails.scrollTop = 0;
    }
  };

  const handleDeal = (type: number, operation: string) => {
    if (operation === "buy") {
      navigate(PATH_LIST.buy(asset_id!) + `?type=${type}`);
    } else {
      navigate(PATH_LIST.sell(asset_id!) + `?type=${type}`);
    }
  };
  const handleTabClick = (text: string) => {
    setActiveTab(text);
    scroll();
  };
  const showDetailsPage = () => {
    return (
      <>
        {activeTab === "Bargaining" ? (
          <div className="bond-details-main bond-details-mainbonds-bargaining">
            {assetInfo?.asset?.data?.is_otc === 1 && (
              <div className="bargaining-alert">
                <span>{t("tradingInOTCMode")}</span>
              </div>
            )}
            <div className="bond-info-bargaining">
              <AssetIcon
                asset={{
                  type: assetInfo?.asset?.type || AssetType.Bond,
                  symbol: symbol!,
                }}
                className="asset-logo"
              />
              <div>
                <div className="gain-percent">
                  <p className="price">{getAssetBidAsk("avg")}</p>
                  <p className="price gains-percent">{getAssetPrice("avg")}</p>
                </div>
                <span>{getAssetYield()}</span>
                <p>{t("perAnnum")}</p>
              </div>
            </div>
            {/* <div className="charts">
              <div
                className="chart-type-icon"
                onClick={() => {
                  setChart(!chart);
                }}
              >
                <img
                  src={chart == true ? LineGraph : JapaneseCandlesticks}
                  alt="chart-type-icon"
                />
              </div>
            </div> */}
            <MarketChart
            // onlyLineChart
            />
            <DealsTable assetId={assetInfo?.asset?.id || undefined} />
            {/* <div className="reliability-rating">
              <p className="rr-text">Reliability rating</p>
              <ReliabilityRating text="Very high" color={"#008069"} />
            </div> */}
          </div>
        ) : activeTab === "Coupons" ? (
          <div className="bond-details-main">
            {/* //TODO translate before coupons */}
            <div className="nearest-coupon">
              <p className="title-NC">Nearest coupon</p>
              <div className="main-NC">
                <div>
                  <p>26.01.2024</p> <span>34,41 ₽</span>
                </div>
                <div>
                  <p>Payment date</p> <span>6,90 % per annual</span>
                </div>
              </div>
              <div className="nkd-progress">
                <div className="nkd-progress-header">
                  <div className="nkd-progress-header-text">
                    <p>НКД</p>
                    <img src={IndicatorQ} alt="icon" />
                  </div>
                  <span>12,88 ₽</span>
                </div>
                <Progress.Line
                  className="progress-line-bond"
                  percent={30}
                  strokeColor="#F8009E"
                  showInfo={false}
                />
              </div>
            </div>
            {/* <div className="indicators-title">
              <p>Payment calendar</p>
            </div>
            <div className="dividents-title">
              <p>Payment date</p>
              <p>Coupon</p>
            </div>
            <DividentsDetails
              date={"26.01.2024"}
              price={"34,41 ₽"}
              year={"for 2022"}
              percent={"6,90 % per annue"}
            /> */}
          </div>
        ) : activeTab === "Indicators" ? (
          <div className="bond-details-main">
            <div className="not-found-coming-soon">
              <p>{t("notFound")}</p>
            </div>
          </div>
        ) : (
          <div className="bond-details-main bond-details-main-review">
            {/* <div className="review-title">
              <p>{t("description")}</p>
            </div>
            <span className="description-span">
              {getAssetInfo()?.description}
            </span>
            <div className="description-details">
              <ReviewDetails
                title="Industry"
                info={getAssetInfo()?.industry || ""}
              />
              <ReviewDetails
                title="Сapitalization"
                info={getAssetMarketCap()}
              />
            </div> */}
            <div className="review-title">
              <p>{t("info")}</p>
            </div>
            <div className="info-details">
              {/* <ReviewDetails title="Instrument type" info="Lorem Ipsum" /> */}
              {/* <ReviewDetails
                title="Ticker"
                info={assetInfo?.asset?.symbol || ""}
              /> */}
              <ReviewDetails
                title={t("country")}
                info={
                  GetCountryByCode(assetInfo?.asset?.data?.country || "")
                    ?.name || ""
                }
              />
              <ReviewDetails
                title="ISIN"
                info={assetInfo?.asset?.symbol || ""}
              />
              <ReviewDetails
                title={t("currency")}
                info={assetInfo?.asset?.currency?.code || ""}
              />
              <ReviewDetails
                title={t("maturityDate")}
                info={assetInfo?.asset?.data?.maturity || ""}
              />
              {/* <ReviewDetails
                title="Exchange"
                info={getAssetInfo()?.exchange || ""}
              /> */}
              {/* <ReviewDetails title="Lot size" info="1 lot = 100 piece" />
              <ReviewDetails title="Calculation mode" info="T1" />
              <ReviewDetails title="For qualified investors" info="No" /> */}
            </div>
          </div>
        )}
      </>
    );
  };

  const { saved, showNotification, handleSave } = useSaveHandler(
    +asset_id!,
    addFavorites,
    removeFavorite
  );

  if (isLoading) {
    return (
      <div className={`stock-details stock-details${activeTab}`}>
        <div className="stock-details-header">
          <img
            className="go-back-portfolio"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <div>
            <p>{symbol}</p>
          </div>
        </div>
        <div className="stock-details-main">
          <p className="assets-loading assets-loading-page">{t("loading")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`bond-details bond-details${activeTab}`}>
      {showNotification && <SavedToFavNotification symbol={symbol} />}

      <div className="bond-details-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          <p>{symbol}</p>
          <span>
            {getAssetInfo()?.companyName || assetInfo?.asset?.name || ""}
          </span>
        </div>

        {/* @ts-ignore */}
        <SaveToFavBtn onclick={() => handleSave(asset_id)} saved={saved} />
      </div>
      <HorizontalScroll className="tab-bar">
        <TabBtn
          text={t("trading")}
          active={activeTab === "Bargaining"}
          onClick={() => handleTabClick("Bargaining")}
        />
        {/* <TabBtn
          text={t("coupons")}
          active={activeTab === "Coupons"}
          onClick={() => handleTabClick("Coupons")}
        /> */}
        {/* <TabBtn
          text={t("indicators")}
          active={activeTab === "Indicators"}
          onClick={() => handleTabClick("Indicators")}
        /> */}
        <TabBtn
          text={t("review")}
          active={activeTab === "Review"}
          onClick={() => handleTabClick("Review")}
        />
      </HorizontalScroll>
      {showDetailsPage()}
      <div className="action-btns-wrapper">
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Sell))
          }
          action={t("sell")}
          price={getAssetPrice("sell")}
          disabled={isMatured}
        />
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Buy))
          }
          action={t("buy")}
          price={getAssetPrice("buy")}
          type="dark"
          disabled={isMatured}
        />
      </div>
    </div>
  );
};
