import React from "react";
import "./style.scss";
import ProgressUp from "../../../../assets/BalanceUp.svg";
import ProgressDown from "../../../../assets/BalanceDown.svg";
import AssetIcon from "../asset-icon";
import { IUserBrokerageAccount } from "entities/dashboard";
import { navigateToAsset } from "helpers/functions";
import { AssetType } from "static/types";
import { useNavigate } from "react-router";
import { PATH_LIST } from "shared/lib/react-router";
import { useAssetVolatility } from "hooks/useAssetVolatility";
import NotSellable from "../../../../assets/NotSellable.svg";
import { useTranslation } from "react-i18next";

type PropType = {
  asset: IUserBrokerageAccount;
  profitLoss: {
    progress: number;
    percent: number;
  };
  currentPrice: number;
  bondData?: {
    maturity: string;
    avg_yield: number;
  };
  nonTrading: boolean;
};

export const PortfolioItem: React.FC<PropType> = ({
  asset,
  profitLoss,
  currentPrice,
  bondData,
  nonTrading,
}) => {
  const { t } = useTranslation("app");
  const { quantity, price, date, asset: assetItem } = asset;
  const { type, symbol, currency, id } = assetItem;
  const currency_symbol = currency.symbol;
  const navigate = useNavigate();
  const onNavigate = () => {
    if (type === AssetType.StructuredProduct) {
      navigate(PATH_LIST.strategy(id!));
    } else {
      navigateToAsset(type, id!, navigate);
    }
  };

  const stockPrice = +(currentPrice * quantity).toFixed(2);
  const { changedPart, unchangedPart, volatilityElementRef } =
    useAssetVolatility(stockPrice);

  if ([AssetType.Stock, AssetType.ETF, AssetType.Fond].includes(type)) {
    return (
      <div className="portfolio-row-card" onClick={onNavigate}>
        {nonTrading && <div className="non-trading-background" />}
        <div className="price-quantity-wrapper">
          <AssetIcon asset={{ symbol: symbol, type: type }} />
          <div className="price-quantity">
            <p>{symbol}</p>
            <span>
              {quantity} <b>•</b> {+price.toFixed(2)} {currency_symbol}
            </span>
          </div>
        </div>

        <div className="portfolio-price-percent">
          {nonTrading ? (
            <div className="not-sellable-price-wrapper">
              <div className="not-sellable">
                <img src={NotSellable} alt="NotSellable" />

                <div className="not-sellable-popup-wrapper">
                  <div className="not-sellable-popup">{t("notSellable")}</div>
                </div>
              </div>

              <p
                className="price"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {unchangedPart}
                <p
                  className="price"
                  id="volatility"
                  //@ts-ignorex
                  ref={volatilityElementRef}
                >
                  {changedPart}
                </p>
                {currency_symbol}
              </p>
            </div>
          ) : (
            <p
              className="price"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {unchangedPart}
              <p
                className="price"
                id="volatility"
                //@ts-ignorex
                ref={volatilityElementRef}
              >
                {changedPart}
              </p>
              {currency_symbol}
            </p>
          )}

          <div className="portfolio-profit-loss">
            <img
              src={profitLoss.progress < 0 ? ProgressDown : ProgressUp}
              alt="icon"
            />
            <span className={profitLoss.progress < 0 ? "loss" : "profit"}>
              {+profitLoss.progress.toFixed(2)} {currency_symbol} (
              {+profitLoss.percent.toFixed(2)}%)
            </span>
          </div>
        </div>
      </div>
    );
  } else if (
    [AssetType.Bond, AssetType.EuroBond, AssetType.StructuredProduct].includes(
      type
    )
  ) {
    // const { maturity, avg_yield } = bondData!;
    return (
      <div className="portfolio-row-card" onClick={onNavigate}>
        {nonTrading && <div className="non-trading-background" />}
        <div className="price-quantity-wrapper">
          <AssetIcon asset={{ symbol: symbol, type: type }} />{" "}
          <div className="price-quantity">
            <p>{symbol}</p>
            {/* <span>till {maturity}</span> */}
            <span>
              {quantity} <b>•</b> {+price.toFixed(2)} {currency_symbol}
            </span>
            {/* {nonTrading ? <span>None trading</span> : <span>trading</span>} */}
          </div>
        </div>

        <div className="portfolio-price-percent">
          {nonTrading ? (
            <div className="not-sellable-price-wrapper">
              <div className="not-sellable">
                <img src={NotSellable} alt="NotSellable" />

                <div className="not-sellable-popup-wrapper">
                  <div className="not-sellable-popup">{t("notSellable")}</div>
                </div>
              </div>

              <p>
                {+(currentPrice * quantity).toFixed(2)} {currency_symbol}
              </p>
            </div>
          ) : (
            <p>
              {+(currentPrice * quantity).toFixed(2)} {currency_symbol}
            </p>
          )}

          <div className="portfolio-profit-loss">
            <img
              src={profitLoss.progress < 0 ? ProgressDown : ProgressUp}
              alt="icon"
            />
            <span className={profitLoss.progress < 0 ? "loss" : "profit"}>
              {+profitLoss.progress.toFixed(2)} {currency_symbol} (
              {+profitLoss.percent.toFixed(2)}%)
            </span>
          </div>
        </div>
        {/* <div className="portfolio-price-percent">
          <p>
            {+(count * currentPrice).toFixed(2)} {currency_symbol}
          </p>
          <div className="portfolio-profit-loss">
            <span className={"profit"}>{avg_yield}%</span>
            <span>{"  "} к погашению</span>
          </div>
        </div> */}
      </div>
    );
  }
  return <></>;
};
