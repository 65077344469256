import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import "./style.scss";
import RadioCard from "../components/radio-card";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

export const HopeToAchievePage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const [selected, setSelected] = useState<string | undefined>(
    profileInfo.hopeToAchieve || undefined
  );
  const { t } = useTranslation("onboarding-auth");

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerCitizenship);

    return () => setLink("");
  }, []);

  const HandleNavigat = () => {
    setProfileInfo(ProfileInfoStep.Experience, { hopeToAchieve: selected! });
    navigate(PATH_LIST.investingExperience);
  };

  const handleRadioChange = (e: string) => {
    setSelected(e);
  };
  return (
    <div className="hopeToAchieve Box">
      <GoBackButton link={PATH_LIST.registerCitizenship} />
      <p>{t("whatDoYouHopeToAchieve")}</p>

      <RadioCard
        text={t("growCapital")}
        htmlForId="1"
        checked={selected === "1"}
        onClick={() => handleRadioChange("1")}
      />
      <RadioCard
        text={t("speculate")}
        htmlForId="2"
        checked={selected === "2"}
        onClick={() => handleRadioChange("2")}
      />
      <RadioCard
        text={t("wantToHedge")}
        htmlForId="3"
        checked={selected === "3"}
        onClick={() => handleRadioChange("3")}
      />
      <RadioCard
        text={t("wantToLearnInvestments")}
        htmlForId="4"
        checked={selected === "4"}
        onClick={() => handleRadioChange("4")}
      />
      <RadioCard
        text={t("dontKnowForSure")}
        htmlForId="5"
        checked={selected === "5"}
        onClick={() => handleRadioChange("5")}
      />

      <Button
        type="submit"
        onClick={HandleNavigat}
        className="registerNameBtn"
        variant={"dark"}
        disabled={selected === undefined}
      >
        {t("continue")}
      </Button>
    </div>
  );
};
