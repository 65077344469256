import React, { FC } from "react";
import "./style.scss";
import AssetIcon from "../asset-icon";
import { useTranslation } from "react-i18next";
import { getMLValue, getStrategyImgUrl } from "helpers/functions";

const RiskTypes = ["Low Risk", "Average Risk", "High Risk"];

type PropsType = {
  id: number;
  asset: any;
  minPrice: number | string;
  risk: number;
  percent: number | string;
  onClick: () => void;
};
const StrategyCard: FC<PropsType> = ({
  id,
  asset,
  minPrice,
  risk,
  percent,
  onClick,
}) => {
  const { t, i18n } = useTranslation("app");

  const { type, symbol, name, currency, data, customInfo } = asset;
  const cardImageUrl =
    customInfo && customInfo.info?.card
      ? getStrategyImgUrl(
          symbol,
          getMLValue(customInfo.info.card_web, i18n.language)
        )
      : null;

  if (cardImageUrl) {
    return (
      <div onClick={onClick} className={`strategy-card`}>
        <img className="custom-st-background-icon" src={cardImageUrl} />
      </div>
    );
  }

  return (
    <div onClick={onClick} className={`strategy-card strategy-card-${risk} `}>
      <div className="strategy-card-asset">
        <AssetIcon
          asset={{
            type: type,
            symbol: symbol,
            currency: currency,
            data: data,
          }}
        />
        <p>{name}</p>
      </div>
      <div className="strategy-card-info">
        <div className="strategy-card-payment-details">
          <span>
            {t("from")} {minPrice} {currency.symbol}
          </span>
          <p>{t(RiskTypes[risk - 1])}</p>
        </div>
        <div className="strategy-card-profit">
          <p>≈ {percent}%</p>
          <span>{t("annually")}</span>
        </div>
      </div>

      <img
        className="st-background-icon"
        src={require(`../../../../assets/strategy/st${(id % 5) + 1}.svg`)}
        alt="icon"
      />
    </div>
  );
};

export default StrategyCard;
