import React, { FC } from "react";
import RemoveTransaction from "../../../assets/RemoveTransaction.svg";
import { OperationStatus } from "static/types";

const Status: Record<OperationStatus, string> = {
  [OperationStatus.Actively]: "Actively",
  [OperationStatus.Completed]: "",
  [OperationStatus.Canceled]: "",
  [OperationStatus.CancellationRequest]: "Cancelling",
  [OperationStatus.OnPerformance]: "On Performance",
  [OperationStatus.Error]: "",
  [OperationStatus.Refusal]: "",
  [OperationStatus.Expired]: "",
  [OperationStatus.PartiallyCompleted]: "",
  [OperationStatus.NotExecuted]: "",
  [OperationStatus.WithdrawnClients]: "",
};

type PropType = {
  ticker: string;
  status: number;
  orderType: string;
  operationType: string;
  price: string;
  quantity: string;
  quantity_completed: string;
  onCancel: any;
};
const DealRow: FC<PropType> = ({
  ticker,
  status,
  orderType,
  operationType,
  price,
  quantity,
  quantity_completed,
  onCancel,
}) => {
  return (
    <div className="transaction-row">
      <p>{ticker}</p>
      <p>{orderType}</p>
      <p>{operationType}</p>
      <p>{price}</p>
      <p>
        {quantity} / {quantity_completed}
      </p>
      {[OperationStatus.Actively, OperationStatus.OnPerformance].includes(
        status
      ) && <img onClick={onCancel} src={RemoveTransaction} alt="Remove" />}
    </div>
  );
};

export default DealRow;
