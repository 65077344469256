import React, { FC, useState } from "react";
import "./text.scss";
import moment from "moment";
import ChatTypingSimulation from "widgets/chat/typing";

interface IChat {
  sender: string;
  customer: string;
  text: string;
  file: string | null;
  createdAt: string;
  isLast: boolean;
}

const ChatTypingTextMessage: FC<IChat> = ({
  sender,
  createdAt,
  text,
  isLast,
}) => {
  return (
    <div
      className={`message-text message-${sender}-text ${
        isLast ? "last-msg" : ""
      }`}
    >
      <ChatTypingSimulation />
      <span>{moment(new Date(createdAt)).format("HH:mm")}</span>
    </div>
  );
};
export default ChatTypingTextMessage;
