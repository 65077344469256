import React, { useState } from "react";
import "./style.scss";
import { PortfolioItem } from "../portfolio-item";
import { IUserBrokerageAccount } from "entities/dashboard";
import { useTranslation } from "react-i18next";
import { MarketAsset } from "../market-asset";
import { navigateToAsset } from "helpers/functions";
import { useNavigate } from "react-router-dom";
import { AssetType, AssetTypes } from "static/types";

type PropsType = {
  data?: any[];
  getProfitLoss: (asset: IUserBrokerageAccount) => any;
  getCurrentPrice: (asset: IUserBrokerageAccount) => any;
  category: string;
  getBondData: any;
  favorites?: any[];
};
const PortfolioSection: React.FC<PropsType> = ({
  data,
  category,
  getProfitLoss,
  getCurrentPrice,
  getBondData,
  favorites,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation("app");
  const navigate = useNavigate();

  return (
    <div className="portfolio-section">
      <p className="category-name">{category}</p>
      {!favorites && data
        ? data
            .slice(0, showMore ? data.length : 3)
            .map((item, index) => (
              <PortfolioItem
                key={index}
                asset={item}
                profitLoss={getProfitLoss(item)}
                currentPrice={getCurrentPrice(item)}
                bondData={getBondData(item)}
                nonTrading={item?.nonTrading || false}
              />
            ))
        : favorites &&
          favorites.map(({ asset }: any) => (
            <MarketAsset
              key={asset.id}
              onClick={() => {
                navigateToAsset(asset.type, asset.id, navigate);
              }}
              asset={asset}
              gains={asset.change}
              gainsPercent={asset.changesPercentage}
              showGains={[
                AssetType.Stock,
                AssetType.ETF,
                AssetType.Fond,
              ].includes(asset.type)}
            />
          ))}
      {data && data.length > 3 && (
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? t("showLess") : t("showMore")}
        </button>
      )}
    </div>
  );
};

export default PortfolioSection;

// favorites.map((asset, index) => (
//   <MarketAsset
//     key={asset.id}
//     onClick={() => {
//       navigateToAsset(asset.type, asset.id, navigate);
//     }}
//     asset={asset}
//     gains={asset.change}
//     gainsPercent={asset.changesPercentage}
//     showGains={[AssetType.Stock, AssetType.ETF].includes(asset.type)}
//   />
