import React, { FC } from "react";
import RemoveTransaction from "../../../../assets/RemoveTransaction.png";
import "./style.scss";
import { useTranslation } from "react-i18next";

type PropType = {
  index: number;
};

const ReliabilityRating: FC<PropType> = ({ index }) => {
  const { t } = useTranslation("app");
  const reliabilityLevels = [
    { color: "#00796B", text: t("Very high") },
    { color: "#388E3C", text: t("High") },
    { color: "#FBC02D", text: t("Medium") },
    { color: "#F57C00", text: t("Low") },
    { color: "#E64A19", text: t("Very low") },
    { color: "#D32F2F", text: t("Negative") },
  ];

  const reliabilityLevel = reliabilityLevels[index - 1];
  const color = reliabilityLevel ? reliabilityLevel.color : "gray";
  const displayText = reliabilityLevel ? reliabilityLevel.text : "Unknown";

  return (
    <div
      style={{ borderLeft: `12px solid ${color}` }}
      className="reliability-rating-row"
    >
      <p>{displayText}</p>
    </div>
  );
};

export default ReliabilityRating;
