import { useEffect, useState } from "react";
import moment from "moment";
import { IExchange, marketStore } from "entities/market";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";

export enum ExchangeStatus {
  Closed = "closed",
  PreMarket = "pre-market",
  Open = "open",
  PostMarket = "post-market",
  Holiday = "holiday",
  Weekend = "weekend",
}
export enum ExchangeInfo {
  closeToPre = "close_to_pre",
  closeToOpen = "close_to_open",
  preToOpenClose2 = "close_to_open_close",
  openToClose = "open_to_close",
  close2ToOpen = "close2_to_open",
  close3ToPost = "close3_to_open",
  postToClose = "post_to_close",
}
interface ExchangeTimeFrameItem {
  from: string;
  to: string;
  type: ExchangeStatus;
  progress_percent: number;
}
export type ExchangeTimeFrame = null | {
  status: ExchangeStatus;
  point: number;
  time_frame: Array<ExchangeTimeFrameItem>;
  current_index: number;
  exchange_code: string;
  exchange_logo: string;
  exchange_name: string;
  timezone: string;
  info: ExchangeInfo;
  time_left: string;
  isActive: boolean;
};

const useExchangeTimeFrame = (ex: IExchange | any): ExchangeTimeFrame => {
  const { t } = useTranslation("app");
  const [state, setState] = useState<ExchangeTimeFrame>(null);
  // const { exchanges } = useStore(marketStore);

  const formatPercent = (value: number) => {
    return +value.toFixed(2);
  };

  const formatTimeLeft = (v: number) => {
    const hours = parseInt(String(v / 60));
    const days = parseInt(String(hours / 24));

    const getHours = (h: number) => {
      return `${h} ${h === 1 ? t("hour") : t("hours")}`;
    };

    const getDays = (d: number) => {
      return `${d} ${d === 1 ? t("day") : t("days")}`;
    };

    const getMinutes = (m: number) => {
      return `${m} ${m === 1 ? t("minute") : t("minutes")}`;
    };

    if (days > 0) {
      const leftHours = hours % 24;
      return `${getDays(days)}${
        leftHours > 0 ? ` ${t("and")} ${getHours(leftHours)}` : ""
      }`;
    }

    if (hours > 0) {
      const leftMinutes = v % 60;
      return `${getHours(hours)}${
        leftMinutes > 0 ? ` ${t("and")} ${getMinutes(leftMinutes)}` : ""
      }`;
    }

    if (v > 0) return getMinutes(v);

    return `${t("lessThen")} ${t("minute")}`;
  };

  const getTimeFrame = (exchange: IExchange | null) => {
    try {
      // const IS_DEV_MODE = process.env.REACT_APP_DEV_MODE === "true";
      // if (IS_DEV_MODE) return true;
      // if day is weekend all exchanges are closed

      // const exchange = exchangesList.find((e) => e.id === exId);
      if (!exchange) return null;

      if (!exchange.open || !exchange.close || !exchange.timezone) {
        return null;
      }

      const {
        open,
        close,
        timezone,
        pre_open,
        pre_close,
        after_open,
        after_close,
      } = exchange;

      // moment.tz.setDefault(timezone);
      const startTime = moment(open, "HH:mm").tz(timezone, true);
      const endTime = moment(close, "HH:mm").tz(timezone, true);
      const preOpen = moment(pre_open, "HH:mm").tz(timezone, true);
      const preClose = moment(pre_close, "HH:mm").tz(timezone, true);
      const afterOpen = moment(after_open, "HH:mm").tz(timezone, true);
      const afterClose = moment(after_close, "HH:mm").tz(timezone, true);

      const nowTime = moment().tz(timezone).set("date", startTime.get("date"));
      // console.log(nowTime.format("HH:mm"));
      const unit = 100 / (24 * 60);
      const zeroTime = moment("00:00", "HH:mm").tz(timezone, true);

      const point = formatPercent(nowTime.diff(zeroTime, "minutes") * unit);

      const weekday = nowTime.format("dddd");
      const isWeekend = ["Sunday", "Saturday"].includes(weekday);
      let info: ExchangeInfo | null = null;
      let time_left = 0;

      if (exchange?.isHoliday) {
        info =
          pre_open && pre_close
            ? ExchangeInfo.closeToPre
            : ExchangeInfo.closeToOpen;
        time_left = moment(pre_open && pre_close ? preOpen : startTime)
          .add(
            weekday === "Sunday" ? 1 : weekday === "Friday" ? 3 : 2,
            weekday === "Sunday" ? "day" : "days"
          )
          .diff(nowTime, "minutes");

        return {
          status: ExchangeStatus.Holiday,
          point,
          time_frame: [
            {
              from: "00:00",
              to: "24:00",
              type: ExchangeStatus.Holiday,
              progress_percent: 100,
            },
          ],
          exchange_code: exchange.symbol,
          exchange_logo: exchange.logo,
          exchange_name: exchange.name,
          timezone,
          current_index: 0,
          info,
          time_left: formatTimeLeft(time_left),
          isActive: false,
        };
      }

      if (isWeekend) {
        info =
          pre_open && pre_close
            ? ExchangeInfo.closeToPre
            : ExchangeInfo.closeToOpen;
        time_left = moment(pre_open && pre_close ? preOpen : startTime)
          .add(
            weekday === "Sunday" ? 1 : 2,
            weekday === "Sunday" ? "day" : "days"
          )
          .diff(nowTime, "minutes");

        return {
          status: ExchangeStatus.Weekend,
          point,
          time_frame: [
            {
              from: "00:00",
              to: "24:00",
              type: ExchangeStatus.Weekend,
              progress_percent: 100,
            },
          ],
          exchange_code: exchange.symbol,
          exchange_logo: exchange.logo,
          exchange_name: exchange.name,
          timezone,
          current_index: 0,
          info,
          time_left: formatTimeLeft(time_left),
          isActive: false,
        };
      }

      let status: ExchangeStatus = ExchangeStatus.Closed;
      if (nowTime.isBetween(startTime, endTime)) {
        status = ExchangeStatus.Open;
        info = ExchangeInfo.openToClose;
        time_left = endTime.diff(nowTime, "minutes");
      } else if (
        after_open &&
        after_close &&
        nowTime.isBetween(afterOpen, afterClose)
      ) {
        status = ExchangeStatus.PostMarket;
        info = ExchangeInfo.postToClose;
        time_left = afterClose.diff(nowTime, "minutes");
      } else if (
        pre_open &&
        pre_close &&
        nowTime.isBetween(preOpen, preClose)
      ) {
        status = ExchangeStatus.PreMarket;
        info = ExchangeInfo.preToOpenClose2;
        time_left = preClose.diff(nowTime, "minutes");
      }

      if (status === ExchangeStatus.Closed) {
        if (pre_open && pre_close && preOpen.diff(nowTime, "minutes") > 0) {
          info = ExchangeInfo.closeToPre;
          time_left = preOpen.diff(nowTime, "minutes");
        } else if (startTime.diff(nowTime, "minutes") > 0) {
          info =
            pre_open && pre_close
              ? ExchangeInfo.close2ToOpen
              : ExchangeInfo.closeToOpen;
          time_left = startTime.diff(nowTime, "minutes");
        } else if (
          after_open &&
          after_close &&
          afterOpen.diff(nowTime, "minutes") > 0
        ) {
          info = ExchangeInfo.close3ToPost;
          time_left = afterOpen.diff(nowTime, "minutes");
        }
      }

      const time_frame: ExchangeTimeFrameItem[] = [];

      if (pre_open && pre_close) {
        const preOpen = moment(pre_open, "HH:mm").tz(timezone, true);
        const preClose = moment(pre_close, "HH:mm").tz(timezone, true);

        time_frame.push({
          from: "00:00",
          to: pre_open,
          type: ExchangeStatus.Closed,
          progress_percent: formatPercent(
            preOpen.diff(zeroTime, "minutes") * unit
          ),
        });

        time_frame.push({
          from: pre_open,
          to: pre_close,
          type: ExchangeStatus.PreMarket,
          progress_percent: formatPercent(
            preClose.diff(preOpen, "minutes") * unit
          ),
        });
      } else {
        time_frame.push({
          from: "00:00",
          to: open,
          type: ExchangeStatus.Closed,
          progress_percent: formatPercent(
            startTime.diff(zeroTime, "minutes") * unit
          ),
        });
      }

      let lastItem = time_frame[time_frame.length - 1];
      let lastItemTime = moment(lastItem.to, "HH:mm").tz(timezone, true);
      const diffUntilOpen = startTime.diff(lastItemTime, "minutes");

      if (diffUntilOpen > 0) {
        time_frame.push({
          from: lastItem.to,
          to: open,
          type: ExchangeStatus.Closed,
          progress_percent: formatPercent(diffUntilOpen * unit),
        });
      }

      time_frame.push({
        from: open,
        to: close,
        type: ExchangeStatus.Open,
        progress_percent: formatPercent(
          endTime.diff(startTime, "minutes") * unit
        ),
      });

      zeroTime.add(1, "day");
      if (after_open && after_close) {
        const afterOpen = moment(after_open, "HH:mm").tz(timezone, true);
        const afterClose = moment(after_close, "HH:mm").tz(timezone, true);

        lastItem = time_frame[time_frame.length - 1];
        lastItemTime = moment(lastItem.to, "HH:mm").tz(timezone, true);
        const diffBetweenCloseAndAfterOpen = afterOpen.diff(
          lastItemTime,
          "minutes"
        );

        if (diffBetweenCloseAndAfterOpen > 0) {
          time_frame.push({
            from: lastItem.to,
            to: after_open,
            type: ExchangeStatus.Closed,
            progress_percent: formatPercent(
              diffBetweenCloseAndAfterOpen * unit
            ),
          });
        }

        time_frame.push({
          from: after_open,
          to: after_close,
          type: ExchangeStatus.PostMarket,
          progress_percent: formatPercent(
            afterClose.diff(afterOpen, "minutes") * unit
          ),
        });

        time_frame.push({
          from: after_close,
          to: "00:00",
          type: ExchangeStatus.Closed,
          progress_percent: formatPercent(
            zeroTime.diff(afterClose, "minutes") * unit
          ),
        });
      } else {
        time_frame.push({
          from: close,
          to: "00:00",
          type: ExchangeStatus.Closed,
          progress_percent: formatPercent(
            zeroTime.diff(endTime, "minutes") * unit
          ),
        });
      }

      if (status === ExchangeStatus.Closed && info === null) {
        info =
          pre_open && pre_close
            ? ExchangeInfo.closeToPre
            : ExchangeInfo.closeToOpen;

        if (weekday === "Friday") {
          if (pre_open && pre_close) {
            time_left = moment(preOpen).add(3, "days").diff(nowTime, "minutes");
          } else {
            time_left = moment(startTime)
              .add(3, "days")
              .diff(nowTime, "minutes");
          }
        } else {
          if (pre_open && pre_close) {
            time_left = moment(preOpen).add(1, "day").diff(nowTime, "minutes");
          } else {
            time_left = moment(startTime)
              .add(1, "day")
              .diff(nowTime, "minutes");
          }
        }
      }

      let currentPoint = point;
      let currentPercent = 0;
      const timeFrameIndexByPoint = time_frame.findIndex((v, i) => {
        currentPercent += time_frame[i].progress_percent;
        return point <= currentPercent;
      });

      try {
        const item = time_frame[timeFrameIndexByPoint];
        currentPoint = formatPercent(
          nowTime.diff(
            moment(item.from, "HH:mm").tz(timezone, true),
            "minutes"
          ) *
            (100 /
              moment(item.to, "HH:mm").diff(
                moment(item.from, "HH:mm"),
                "minutes"
              ))
        );
      } catch (error) {}

      return {
        status,
        point: currentPoint,
        time_frame,
        exchange_code: exchange.symbol,
        exchange_logo: exchange.logo,
        exchange_name: exchange.name,
        timezone,
        current_index: timeFrameIndexByPoint,
        info: info as ExchangeInfo,
        time_left: formatTimeLeft(time_left),
        isActive: [
          ExchangeStatus.Open,
          ExchangeStatus.PreMarket,
          ExchangeStatus.PostMarket,
        ].includes(status),
      };
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    setState(getTimeFrame(ex));

    const interval = setInterval(() => {
      setState(getTimeFrame(ex));
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, [ex]);

  return state;
};

export default useExchangeTimeFrame;
