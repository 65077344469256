import React, { FC, useEffect, useRef } from "react";
import "./style.scss";
import LoadingCrcle from "../../assets/LoadingCrcle.png";
import LoadingCrcleM from "../../assets/LoadingCrcleMobile.png";
import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation("app");

  return (
    <div className="loading">
      <div className="loader-mobile"></div>
      <div className="loader"></div>
      <p>{t("loading")}</p>
    </div>
  );
};

export default Loading;

type PropsType = {
  style?: React.CSSProperties;
};

export const Spinner: FC<PropsType> = ({ style }) => {
  return (
    <div className="circle-spinner" style={style}>
      <div className="loader-mobile"></div>
    </div>
  );
};
