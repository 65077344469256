import React, { FC } from "react";
import "./style.scss";
import Opened from "../../../assets/market-status/Opened.svg";
import Close from "../../../assets/market-status/Close.svg";
import PostMarket from "../../../assets/market-status/PostMarket.svg";
import PreMarket from "../../../assets/market-status/PreMarket.svg";
import Holiday from "../../../assets/market-status/Holiday.svg";
import { useTranslation } from "react-i18next";
import { ExchangeStatus, ExchangeTimeFrame } from "hooks/useExchangeTimeFrame";

type PropsType = {
  onClose: () => void;
  tf: ExchangeTimeFrame;
};

const ExchangeStatusComp: FC<PropsType> = ({ onClose, tf }) => {
  const { t, i18n } = useTranslation("app");
  const statusData = {
    [ExchangeStatus.Open]: {
      background: "#daf2ee",
      image: Opened,
      color: "#22ab94",
      info: "",
    },
    [ExchangeStatus.Closed]: {
      background: "#f0f3fa",
      image: Close,
      color: "#9598a1",
      info: "",
    },
    [ExchangeStatus.PreMarket]: {
      background: "#fff3e0",
      image: PreMarket,
      color: "#ff9800",
      info: "",
    },
    [ExchangeStatus.PostMarket]: {
      background: "#e3effd",
      image: PostMarket,
      color: "#2962ff",
      info: "",
    },
    [ExchangeStatus.Holiday]: {
      background: "#f0f3fa",
      image: Holiday,
      color: "#9598a1",
      info: "",
    },
    [ExchangeStatus.Weekend]: {
      background: "#f0f3fa",
      image: Holiday,
      color: "#9598a1",
      info: "",
    },
  };
  const currentDate = new Date();
  const weekdays =
    i18n.language === "en"
      ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      : i18n.language === "ru"
      ? ["вс", "пн", "вт", "ср", "чт", "пт", "сб"]
      : ["Կիր", "Երկ", "Երեք", "Չոր", "Հնգ", "Ուրբ", "Շաբ"];
  const weekdayIndex = currentDate.getDay();
  const weekdayName = weekdays[weekdayIndex];

  if (!tf) return <></>;
  const {
    status,
    timezone,
    time_frame,
    point,
    current_index,
    info,
    time_left,
  } = tf;

  return (
    <div
      className="bottom-sheet-modal-wrapper"
      style={{
        justifyContent: "center",
      }}
    >
      <div onClick={onClose} className="outlet-stock-status-modal"></div>

      <div className="exchange-status">
        <div className="exchange-status-header">
          <div
            className="exchange-status-symbol"
            style={{ backgroundColor: statusData[status].background }}
          >
            <img src={statusData[status].image} alt="symbol" />
          </div>
          <p style={{ color: statusData[status].color }}>{t(status)}</p>
        </div>
        <p
          className="status-info"
          dangerouslySetInnerHTML={{
            __html: t(info, {
              close_reason: t(status),
              time_left: `${time_left} ${i18n.language === "am" ? "-ից" : ""}`,
            }),
          }}
        ></p>
        <div className="timeline">
          <span style={{ width: "26px" }}>{weekdayName.toUpperCase()}</span>
          {time_frame.map((item, i) => (
            <div
              key={i}
              className="line"
              style={{
                backgroundColor: statusData[item.type].color,
                width: `${item.progress_percent}%`,
              }}
            >
              {i === current_index && (
                <div className="point" style={{ left: `${point}%` }}></div>
              )}

              <div className="timeline-comment-wrapper">
                <div className="timeline-comment">
                  <p>
                    <span
                      style={{
                        color: statusData[item.type].color,
                      }}
                    >
                      {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                    </span>
                    {item.from} - {item.to}
                  </p>
                </div>
              </div>
              {i === current_index && (
                <div className="from-to">
                  <span>{item.from}</span>
                  <span>{item.to}</span>
                </div>
              )}
            </div>
          ))}
        </div>
        <span className="timezone">
          {t("exchangeTimezone")}: {timezone}
        </span>
      </div>
    </div>
  );
};

export default ExchangeStatusComp;
