import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import "./style.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BirthdayForm } from "widgets/auth/forms";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

export const RegisterBirthdayPage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerGender);

    return () => setLink("");
  }, []);

  const handleNavigate = (birthDate: any) => {
    setProfileInfo(ProfileInfoStep.Citizen, { birthDate });
    navigate(PATH_LIST.registerCitizenship);
  };

  return (
    <div className="registerBirthday Box">
      <GoBackButton link={PATH_LIST.registerGender} />
      <p>{t("whenIsYourBirthday")}</p>

      <BirthdayForm
        initialValues={
          profileInfo.birthDate
            ? profileInfo.birthDate
            : { day: "", month: "", year: "" }
        }
        onClick={handleNavigate}
      />
    </div>
  );
};
