import React, { useCallback, useEffect } from "react";
import "./style.scss";
import { LanguageRow } from "../language-row";
import { useTranslation } from "react-i18next";
import { sircapApi } from "shared/api/sircap";

const domain = process.env.REACT_APP_COOKIE_DOMAIN;

type PropType = {
  onClose: () => void;
};

export const LanguageModal: React.FC<PropType> = ({ onClose }) => {
  const { t: o } = useTranslation("onboarding-auth");
  const { t, i18n } = useTranslation("app");

  const changeLanguage = useCallback(
    (language: string): void => {
      if (i18n.isInitialized && i18n.language !== language) {
        i18n.changeLanguage(language);

        // document.cookie = `i18next=${language}; SameSite=Lax; Domain=.${domain}; path=/; Secure`;
        const html = document.getElementsByTagName("html");
        html[0].setAttribute("lang", language);
        sircapApi.changeLanguage(language);
      }
    },
    [i18n]
  );

  // const changeLanguage = (language: any) => {
  //   console.log(i18n.language + " - onclick");

  //   i18n.changeLanguage(language);
  //   localStorage.setItem("lang", language);
  //   document.cookie = `i18next=${language}; SameSite=Lax; Domain=.${domain}; Secure`;
  //   const html = document.getElementsByTagName("html");
  //   html[0].setAttribute("lang", language);

  //   console.log(i18n.language + " + onclick");
  // };

  return (
    <div className="bottom-sheet-modal-wrapper">
      <div onClick={onClose} className="outlet-edit-profil-img"></div>

      <div className="edit-profil-img">
        <div className="edit-profil-img-header">
          <p>{t("selectLanguage")}</p>
        </div>

        <LanguageRow
          isChecked={i18n.language === "am"}
          language={o("armenia")}
          languageImage="Armenian"
          onLangClick={() => {
            changeLanguage("am");
            onClose();
          }}
        />
        <LanguageRow
          isChecked={i18n.language === "en"}
          language={o("english")}
          languageImage="English"
          onLangClick={() => {
            changeLanguage("en");
            onClose();
          }}
        />
        <LanguageRow
          isChecked={i18n.language === "ru"}
          language={o("russian")}
          languageImage="Russian"
          onLangClick={() => {
            changeLanguage("ru");
            onClose();
          }}
        />
      </div>
    </div>
  );
};
