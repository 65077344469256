import React, { FC } from "react";
import "./style.scss";

type PropType = {
  term: string;
  isChecked?: boolean;
};
const ValidationCheckbox: FC<PropType> = ({ term, isChecked }) => {
  return (
    <div className="validationCheckbox">
      <input className="checkbox" type="checkbox" checked={isChecked} readOnly />
      <p>{term}</p>
    </div>
  );
};

export default ValidationCheckbox;
