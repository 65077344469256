import React, { FC } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";

type PropsType = {
  symbol: string;
};
const SavedToFavNotification: FC<PropsType> = ({ symbol }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("app");

  return (
    <p className="saved-to-favorites-notification">
      {symbol} {t("isNowInYour")}{" "}
      <span
        onClick={() =>
          navigate(PATH_LIST.whatToBuy, {
            state: { activeTab: "MyFavorites" },
          })
        }
      >
        {t("favorites")}
      </span>
    </p>
  );
};

export default SavedToFavNotification;
