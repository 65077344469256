import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import "./style.scss";
import { ReactCountryDropdown } from "widgets/country-select";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const RegisterCitizenshipPage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");
  const [isLoading, setIsLoading] = useState(false);

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerBirthday);

    return () => setLink("");
  }, []);

  const [countryByIp, setCountryByIp] = useState("ARM");
  const [selectedCountry, setSelectedCountry] = useState<string>(
    profileInfo.citizen || countryByIp
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const resp = await axios.get("https://ipapi.co/json/", {
          timeout: 4000,
        });
        if (resp.data) {
          const { country_code_iso3 } = resp.data;
          setCountryByIp(country_code_iso3);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const HandleNavigate = () => {
    setProfileInfo(ProfileInfoStep.HopeToAchieve, {
      citizen: selectedCountry!,
    });
    navigate(PATH_LIST.hopeToAchieve);
  };

  return (
    <div className="registerCitizenship Box">
      <GoBackButton link={PATH_LIST.registerBirthday} />
      <p>{t("whatsYourCitizenship")}</p>
      {!isLoading ? (
        <ReactCountryDropdown
          onSelect={(c: string) => setSelectedCountry(c)}
          countryCode={selectedCountry}
          placeholder="select"
          labelType="full"
          className="country-select"
        />
      ) : (
        <p>Loading...</p>
      )}
      {/* {selectedCountry && <p>Selected country: {selectedCountry.name}</p>} */}
      <Button
        disabled={!selectedCountry || isLoading}
        type="submit"
        onClick={HandleNavigate}
        className="registerNameBtn"
        variant={"dark"}
      >
        {t("continue")}
      </Button>
    </div>
  );
};
