import React, { FC, useContext } from "react";
import "./style.scss";
import GoBack from "assets/GoBack.svg";
import { useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

type PropsType = {
  link?: string;
  className?: string;
  classNameText?: string;
  backByOne?: boolean;
};
const GoBackButton: FC<PropsType> = ({
  link,
  className,
  classNameText,
  backByOne,
}) => {
  const { link: linkStore } = useStore(sessionModel.sessionStore);
  const navigate = useNavigate();
  const { t } = useTranslation("onboarding-auth");

  const go = () => {
    // @ts-ignore
    navigate(link || linkStore || -1);
  };

  return (
    <div className={`${className} goBack`}>
      <span
        className="goBacklink"
        onClick={() => (backByOne ? navigate(-1) : go())}
      >
        <img src={GoBack} alt="Go Back" />
        <p className={`${classNameText} backText`}>{t("back")}</p>
      </span>
    </div>
  );
};

export default GoBackButton;
