import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import GoBackButton from "shared/ui/back-button";
import Button from "shared/ui/button";
import "./style.scss";
import RadioCard from "../components/radio-card";
import { useStore } from "zustand";
import { ProfileInfoStep, onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";

export const InvestingExperiencePage = () => {
  const navigate = useNavigate();
  const { profileInfo, setProfileInfo } = useStore(onboardModel.onboardStore);
  const [selected, setSelected] = useState<boolean | undefined>(
    "haveExperience" in profileInfo ? profileInfo.haveExperience : undefined
  );
  const { t } = useTranslation("onboarding-auth");

  const { setLink } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.hopeToAchieve);

    return () => setLink("");
  }, []);

  const HandleNavigat = () => {
    setProfileInfo(ProfileInfoStep.ForLiving, {
      haveExperience: selected,
    });
    navigate(PATH_LIST.whatDoYouDoForALiving);
  };

  return (
    <div className="investingExperience">
      <GoBackButton link={PATH_LIST.hopeToAchieve} />
      <p>{t("experienceInvesting")}</p>

      <RadioCard
        text={t("yes")}
        htmlForId="yes"
        checked={selected}
        onClick={() => setSelected(true)}
      />
      <RadioCard
        text={t("no")}
        htmlForId="no"
        checked={selected === false}
        onClick={() => setSelected(false)}
      />

      <Button
        type="submit"
        onClick={HandleNavigat}
        className="registerNameBtn"
        variant={"dark"}
        disabled={selected === undefined}
      >
        {t("continue")}
      </Button>
    </div>
  );
};
