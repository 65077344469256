import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import DocumentTypeBtn from "../components/document-type-btn";
import GoBackButton from "shared/ui/back-button";
import PersonalDocumentModal from "../components/personal-document-modal";
import TakePhotoModal from "../components/take-photo-modal";
import SubmitPhotoModal from "../components/submit-photo-modal";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { onboardModel } from "entities/onboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sessionModel } from "entities/session";
import { useStore } from "zustand";
import { ContentType } from "shared/api/http-client";
import { blobToFile } from "helpers/functions";
import { DocumentType } from "static/types";

type PropType = {};

enum ModalStep {
  Zero,
  Start,
  PhotoPassport,
  SubmitPassport,
  PhotoIdFront,
  SubmitIdFront,
  PhotoIdBack,
  SubmitIdBack,
}

const TakePhotoModalType: Record<any, any> = {
  [DocumentType.Passport]: ModalStep.PhotoPassport,
  [DocumentType.IdCard]: ModalStep.PhotoIdFront,
  [DocumentType.IdCardBack]: ModalStep.PhotoIdBack,
};

const SubmitPhotoModalType: Record<any, any> = {
  [DocumentType.Passport]: ModalStep.SubmitPassport,
  [DocumentType.IdCard]: ModalStep.SubmitIdFront,
  [DocumentType.IdCardBack]: ModalStep.SubmitIdBack,
};

export const ChooseDocTypePage: FC<PropType> = ({}) => {
  const [docType, setDocType] = useState<DocumentType>(DocumentType.Passport);
  const [step, setStep] = useState<ModalStep>(ModalStep.Zero);
  const [firstImage, setFirstImage] = useState<any>();
  const [idBackImage, setIdBackImage] = useState<any>();
  const navigate = useNavigate();
  const { syncUser } = useStore(onboardModel.onboardStore);
  const { t: err } = useTranslation("errors");
  const [isSubmit, setIsSubmit] = useState(false);
  const { t } = useTranslation("onboarding-auth");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.investingWithSavings);

    return () => setLink("");
  }, []);

  const ModalPassport = () => {
    setDocType(DocumentType.Passport);
    setStep(ModalStep.Start);
  };
  const ModalIdCard = () => {
    setDocType(DocumentType.IdCard);
    setStep(ModalStep.Start);
  };

  const SubmitPassportPhoto = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.document(
      {
        idDocType: "PASSPORT",
        fiz: blobToFile(firstImage, `PASSPORT.jpg`),
      },
      { type: ContentType.FormData }
    );
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error(err(String(resp.error.code)));
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.proofOfAddressAddressPhotoPage);
    }
    setIsSubmit(false);
  };
  const SubmitIdFrontPhoto = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.document(
      {
        idDocType: "ID_CARD",
        idDocSubType: "FRONT_SIDE",
        fiz: blobToFile(firstImage, `ID_CARD-FRONT_SIDE.jpg`),
      },
      { type: ContentType.FormData }
    );
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error(err(String(resp.error.code)));
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      setDocType(DocumentType.IdCardBack);
      setStep(ModalStep.PhotoIdBack);
    }
    setIsSubmit(false);
  };
  const SubmitIdBackPhoto = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.document(
      {
        idDocType: "ID_CARD",
        idDocSubType: "BACK_SIDE",
        fiz: blobToFile(idBackImage, `ID_CARD-BACK_SIDE.jpg`),
      },
      { type: ContentType.FormData }
    );
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error(err(String(resp.error.code)));
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.proofOfAddressAddressPhotoPage);
    }
    setIsSubmit(false);
  };

  return (
    <>
      <div className="createAcc chooseDocType">
        <GoBackButton link={PATH_LIST.investingWithSavings} />

        <p>{t("uploadPassportOrID")}</p>
        <span>{t("chooseDocumentType")}</span>

        <div className="joinCards">
          <DocumentTypeBtn type={t("passport")} onClick={ModalPassport} />
          <DocumentTypeBtn type={t("idCard")} onClick={ModalIdCard} />
        </div>
      </div>
      {step > ModalStep.Zero && (
        <PersonalDocumentModal
          type={docType as any}
          onOpen={() => setStep(TakePhotoModalType[docType])}
          onClose={() => setStep(ModalStep.Zero)}
        />
      )}

      {[ModalStep.PhotoPassport, ModalStep.PhotoIdFront].includes(step) && (
        <TakePhotoModal
          type={docType}
          onOpen={(image: string) => {
            setFirstImage(image);
            setStep(SubmitPhotoModalType[docType]);
          }}
          onClose={() => setStep(ModalStep.Start)}
        />
      )}

      {step === ModalStep.PhotoIdBack && (
        <TakePhotoModal
          type={docType}
          onOpen={(image: string) => {
            setIdBackImage(image);
            setStep(SubmitPhotoModalType[docType]);
          }}
          onClose={() => {
            setDocType(DocumentType.IdCard);
            setStep(ModalStep.SubmitIdFront);
          }}
        />
      )}

      {step === ModalStep.SubmitPassport && (
        <SubmitPhotoModal
          type={DocumentType.Passport}
          onSubmit={SubmitPassportPhoto}
          image={firstImage}
          onClose={() => setStep(TakePhotoModalType[docType])}
          isSubmit={isSubmit}
        />
      )}

      {step === ModalStep.SubmitIdFront && (
        <SubmitPhotoModal
          type={DocumentType.IdCard}
          onSubmit={SubmitIdFrontPhoto}
          image={firstImage}
          onClose={() => setStep(TakePhotoModalType[docType])}
          isSubmit={isSubmit}
        />
      )}

      {step === ModalStep.SubmitIdBack && (
        <SubmitPhotoModal
          type={DocumentType.IdCardBack}
          onSubmit={SubmitIdBackPhoto}
          image={idBackImage}
          onClose={() => setStep(TakePhotoModalType[docType])}
          isSubmit={isSubmit}
        />
      )}
    </>
  );
};
