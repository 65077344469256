import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import Search from "../../../assets/SearchMarket.svg";
import Filter from "../../../assets/Filter.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import { Activity, Transaction } from "../components/activities-transactions";
import DatePicker from "../components/date-picker";
import { accountStore } from "entities/account";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "widgets/loading";
import { useTranslation } from "react-i18next";

export const HistoryPage = () => {
  const {
    transactions,
    transactionsCount,
    activeTransactions,
    activeTransactionsCount,
    activites,
    activitesCount,
    isLoading,
    getActivities,
    getTransactions,
    getActiveTransactions,
  } = useStore(accountStore);
  const navigate = useNavigate();
  const [activeHistory, setActiveHistory] = useState("Activities");
  const [activeTabHistory, setActiveTabHistory] = useState("Activities");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { t } = useTranslation("app");

  const [page, setPage] = useState(0);
  const limit = 50;

  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };
  const handleSearch = () => {
    navigate(PATH_LIST.historySearch);
  };

  useEffect(() => {
    if (activeHistory === "Activities") {
      getActivities(page, limit);
    } else if (activeHistory === "Transactions") {
      getTransactions(page, limit);
    } else {
      getActiveTransactions(page, limit);
    }
  }, [activeHistory, page]);

  const currItemsLength = (page + 1) * limit;
  const count =
    activeHistory === "Activities"
      ? activitesCount
      : activeHistory === "Transactions"
      ? transactionsCount
      : activeTransactionsCount;

  return (
    <div className="history">
      <div className="history-header">
        <img
          className="go-back-history"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("history")}</p>
        {/* <img
          className="search-history"
          onClick={handleSearch}
          src={Search}
          alt="Search"
        /> */}
        {/* <img
          className="search-history"
          onClick={handleSearch}
          src={Filter}
          alt="Filter"
        /> */}
      </div>
      {/* <div className="date-deadline-container">
        <DatePicker
          deadline="Start"
          selected={startDate}
          onChange={(date: any) => setStartDate(date)}
        />
        <DatePicker
          deadline="End"
          selected={endDate}
          onChange={(date: any) => setEndDate(date)}
        />
      </div> */}

      <div className="query-wrapper">
        <div className="query-type">
          <button
            className={activeHistory == "Activities" ? "active-btn" : ""}
            onClick={() => {
              setPage(0);
              setActiveHistory("Activities");
            }}
          >
            {t("activities")}
          </button>

          <button
            className={activeHistory == "Transactions" ? "active-btn" : ""}
            onClick={() => {
              setPage(0);
              setActiveHistory("Transactions");
            }}
          >
            {t("transactions")}
          </button>

          <button
            className={activeHistory == "Pending" ? "active-btn" : ""}
            onClick={() => {
              setPage(0);
              setActiveHistory("Pending");
            }}
          >
            {t("Pending")}
          </button>
        </div>
      </div>

      <div className="history-main" id="scrollable-container">
        {isLoading ? (
          <div className="history-loader">
            <Spinner />
          </div>
        ) : count ? (
          <InfiniteScroll
            dataLength={currItemsLength}
            next={() => {
              setPage(page + 1);
            }}
            hasMore={currItemsLength < count}
            scrollThreshold={0.9}
            scrollableTarget={"scrollable-container"}
            loader={<p className="assets-loading">{t("loading")}</p>}
          >
            {activeHistory === "Activities"
              ? activites.map((a, i) => (
                  <Activity
                    key={i}
                    activity={a}
                    onTrigger={() => getActivities(page, limit)}
                  />
                ))
              : activeHistory === "Transactions"
              ? transactions.map((t: any, i) => (
                  <Transaction key={i} deal={t} />
                ))
              : activeTransactions.map((t: any, i) => (
                  <Transaction key={i} deal={t} />
                ))}
          </InfiniteScroll>
        ) : (
          <div className="not-found-coming-soon">
            <p>{t("noData")}</p>
          </div>
        )}
      </div>
    </div>
  );
};
