import { RegisterStep } from "entities/onboard";
import { HttpClient } from "../http-client";
import { EntityDocumentsEnum } from "entities/onboard/model/onboardModel";
import { EntityType } from "static/types";
import { IUser, sessionStore } from "entities/session/model/sessionModel";
import { IFavoritesDto, IFilterDto } from "entities/market";

export interface LoginUserDto {
  entity: any;
  email: string;
  phone: string;
  password: string;
}

export interface RegisterPhoneDto {
  phone: string;
  entity: any;
  language: string;
}

export interface ForgotPasswordDto {
  phone: string;
  entity: any;
  language: string;
}

export interface VerifyPhoneDto {
  id: number;
  entity: any;
  code: number;
}

export interface RegisterEmailDto {
  entity: any;
  email: string;
  language: string;
}

export interface VerifyEmailDto {
  entity: any;
  code: number;
}

export interface RestorePasswordDto {
  id: number;
  entity: any;
  code: number;
  password: string;
}

export interface RegisterNameDto {
  entity: any;
  firstName?: string;
  lastName?: string;
  companyName?: string;
}

export interface RegisterPasswordDto {
  entity: any;
  password: string;
}

export interface RegisterInfoDto {
  profileInfo: any;
}

export interface RegisterAddressDto {
  address: any;
}

export interface CheckAgreeDto {
  entity: any;
  subscribe: boolean;
}

export interface PinCodeDto {
  code: string;
}

export interface UserDto {
  code: number;
  data: {
    user: IUser;
    entity: EntityType;
    accessToken: string;
    refreshToken: string;
  };
}

export interface SendDocumentDto {
  idDocType:
    | "ID_CARD"
    | "PASSPORT"
    | "SELFIE"
    | "UTILITY_BILL"
    | "RESIDENCE_PERMIT";
  idDocSubType?: "FRONT_SIDE" | "BACK_SIDE";
  fiz: File;
}

export interface UploadAvatarDto {
  avatar: File;
}

export interface SignContractDto {
  sign: File;
  pinCode: string;
  signWidth: number;
  signHeight: number;
}

export interface ChangePasswordDto {
  password: string;
  newPassword: string;
}

export interface ChangePinCodeDto {
  pinCode: string;
  newPinCode: string;
}
export interface ResetPinCodeDto {
  password: string;
}

export interface SendEntityDocumentDto {
  type: EntityDocumentsEnum | string;
  entity: Blob;
}

interface ResponseDto {
  code: number;
  data: {
    // step: RegisterStep;
    user: {
      id: number;
      phone: string;
      email: string;
      profileInfo: any;
      personalAddress: string;
      regStep: RegisterStep;
      documents: any;
      additionalDocument: any;
    };
    entity: EntityType;
    accessToken: string;
    refreshToken: string;
  };
}

export interface WithdrawRequestDto {
  currency: string;
  account_number: string;
  amount: number;
  info_source: number;
}
export interface WithdrawCancelDto {
  id: number;
}

export interface OpenOrderDto {
  pinCode: string;
  comments: string;
  asset: number;
  type: string;
  price: number;
  quantity: number;
  operation: string;
  with_sirius: boolean;
}

export interface EnableNotificationsDto {
  notification_token: string;
  device_id: string;
}

export interface DisableNotificationsDto {
  device_id: string;
}

export interface GenerateReportDto {
  from: string;
  to: string;
}

// export const GetTariffs = async () => {
//   try {
//     const resp = await instance.get("tariffs");
//     return { data: resp.data.data };
//   } catch (error) {
//     return { data: [] };
//   }
// };

export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  auth = {
    token: this.getRequest<UserDto>("/auth/token"),
    login: this.postRequest<LoginUserDto, UserDto>("/auth/login"),
    forgot: this.postRequest<ForgotPasswordDto>("/auth/restore-password"),
    codeVerify: this.postRequest<RestorePasswordDto>("/auth/confirm"),
  };
  onboarding = {
    regPhone: this.postRequest<RegisterPhoneDto>("/auth/reg/set-phone"),
    verifyPhone: this.postRequest<VerifyPhoneDto, ResponseDto>(
      "/auth/reg/verify-phone"
    ),
    regEmail: this.postRequest<RegisterEmailDto, ResponseDto>(
      "/auth/reg/set-email"
    ),
    verifyEmail: this.postRequest<VerifyEmailDto, ResponseDto>(
      "/auth/reg/verify-email"
    ),
    regName: this.postRequest<RegisterNameDto, ResponseDto>(
      "/auth/reg/set-name"
    ),
    regPassword: this.postRequest<RegisterPasswordDto, ResponseDto>(
      "/auth/reg/set-password"
    ),
    profileInfo: this.postRequest<RegisterInfoDto, ResponseDto>(
      "/auth/reg/set-info"
    ),
    document: this.postRequest<SendDocumentDto, ResponseDto>(
      "/auth/reg/send-document"
    ),
    entityDocument: this.postRequest<SendEntityDocumentDto, ResponseDto>(
      "/auth/reg/send-entity-doc"
    ),
    entityAdditionalDocument: this.postRequest<
      SendEntityDocumentDto,
      ResponseDto
    >("/auth/reg/send-entity-add-doc"),
    sign: this.postRequest<any, ResponseDto>("/auth/reg/sign-contract"),
    regAddress: this.postRequest<RegisterAddressDto, ResponseDto>(
      "/auth/reg/set-address"
    ),
    checkAgree: this.postRequest<CheckAgreeDto, ResponseDto>(
      "/auth/reg/check-agree"
    ),
    pinCode: this.postRequest<PinCodeDto, ResponseDto>(
      "/auth/reg/set-pin-code"
    ),
  };
  dashboard = {
    currencies: this.getRequest("/dashboard/currencies"),
    exchanges: this.getRequest("/dashboard/exchanges"),
    quotes: this.getRequest("/dashboard/quotes"),
    bondsQuotes: this.getRequest("/dashboard/quotes/bonds"),
    brokerageAssets: this.getRequest("/dashboard/brokerage-assets"),
    rates: this.getRequest("/dashboard/rates"),
    accounts: this.getRequest("/dashboard/accounts"),
    stories: this.getRequest("/story/all"),
  };
  account = {
    uploadAvatar: this.postRequest<UploadAvatarDto>("/account/avatar"),
    getContract: this.getRequest("/account/sign-contract"),
    signContract: this.postRequest<SignContractDto>("/account/sign-contract"),
    removeAvatar: this.deleteRequest("/account/avatar"),
    changePass: this.postRequest<ChangePasswordDto>("/account/change-password"),
    changePinCode: this.postRequest<ChangePinCodeDto>(
      "/account/change-pin-code"
    ),
    resetPinCode: this.postRequest<ResetPinCodeDto>("/account/reset-pin-code"),

    requestChangeEmail: this.postRequest<Partial<RegisterEmailDto>>(
      "/account/request-change-email"
    ),
    confirmChangeEmail: this.postRequest<{ code: number }>(
      "/account/confirm-change-email"
    ),
    requestChangePhone: this.postRequest<Partial<RegisterPhoneDto>>(
      "/account/request-change-phone"
    ),
    confirmChangePhone: this.postRequest<{ code: number }>(
      "/account/confirm-change-phone"
    ),
    activities: this.getRequest("/account/activities"),
    transactions: this.getRequest("/account/transactions"),
    activeTransactions: this.getRequest("/account/active-transactions"),
    reportsList: this.getRequest("/reports/list"),
    generateReport: this.postRequest<GenerateReportDto>("/reports/generate"),

    promoCode: (value: string) => this.postRequest(`/promo/${value}/activate`),
    requestDataDeletion: this.postRequest(`/account/request-data-deletion`),
  };
  transfer = {
    depositInfo: this.getRequest("/transfer/deposit-info"),
    withdrawInfo: this.getRequest("/transfer/withdrawal-info"),
    withdrawRequest: this.postRequest<WithdrawRequestDto>(
      "/transfer/withdrawal-request"
    ),
    withdrawCancel: this.postRequest<WithdrawCancelDto>(
      "/transfer/withdrawal-cancel"
    ),
    currencies: this.getRequest("/transfer/currencies"),
  };
  market = {
    assets: this.getRequest(`/market/assets`),
    strategies: this.getRequest(`/market/assets/strategies-for-client`),
    getStrategy: (symbol: string) =>
      this.getRequest(`/market/assets/strategies/${symbol}`),
    whatToBuy: this.getRequest(`/what-to-buy/all`),
    assetPrice: (symbol: string) =>
      this.getRequest(`/market/assets/${symbol}/real-time`),
    search: (q: string) => this.getRequest(`/market/assets/search?q=${q}`),
    assetInfo: (asset_id: number) =>
      this.getRequest(`/market/assets/${asset_id}/info`),
    assetDepth: (asset_id: number) =>
      this.getRequest(`/market/assets/${asset_id}/depth`),
    assetChart: (asset_id: number) =>
      this.getRequest(`/market/assets/${asset_id}/chart`),
    assetIcon: (type: number, symbol: string) =>
      this.getRequest(`/market/assets/${type}/${symbol}/icon`),

    assetDeals: (id: number) => this.getRequest(`/market/assets/${id}/deals`),
    cancelDeal: (id: number) =>
      this.deleteRequest(`/market/assets/${id}/deals`),
    createDeal: (id: number) =>
      this.postRequest<OpenOrderDto>(`/market/assets/${id}/deals`),

    loadFilters: this.getRequest(`/filters/all`),
    createFilter: this.postRequest<IFilterDto>(`/filters/create`),
    updateFilter: (id: number) =>
      this.postRequest<IFilterDto>(`/filters/${id}/update`),
    deleteFilter: (id: number) => this.deleteRequest(`/filters/${id}/delete`),

    filterStockIndustries: this.getRequest(`/filters/industries`),
    filterSectors: this.getRequest(`/filters/sectors`),
    filterCountries: this.getRequest(`/filters/countries`),

    getAssetsByFilters: this.postRequest(`/filters/assets-by-filter`),
    getBondsByFilters: this.postRequest(`/filters/bonds-by-filter`),
  };
  whatToBuy = {
    loadFavorites: this.getRequest(`/favorites`),
    addFavorites: this.postRequest<IFavoritesDto>(`/favorites`),
    removeFavorite: (id: number) => this.deleteRequest(`/favorites/${id}`),
  };
  chat = {
    check: this.getRequest(`/chat/check`),
  };
  nots = {
    enable: this.postRequest<EnableNotificationsDto>(
      "/notifications/push/enable"
    ),
    disable: this.postRequest<DisableNotificationsDto>(
      "/notifications/push/disable"
    ),
  };
  tariffs = this.getRequest("/tariffs");
  handleError = async (dto: { error: string; error_info: string }) => {
    try {
      await this.postRequest("/handle-error")({
        platform: "web",
        error: dto.error,
        error_info: dto.error_info,
      });
    } catch (error) {}
  };
  changeLanguage = async (language: string) => {
    try {
      const user = sessionStore.getState().user;
      if (user) sessionStore.setState({ user: { ...user, language } });
      await this.postRequest("/account/change-language")({
        language,
      });
    } catch (error) {}
  };
}
