import React, { FC, useState } from "react";
import "./style.scss";
import ModalButton from "widgets/auth/components/modal-btn";
import Close from "../../../../assets/Close.svg";
import { useTranslation } from "react-i18next";

type PropsType = {
  title: string;
  text?: string;
  value?: string;
  onSubmit: (name: string) => void;
  onHide?: () => void;
  isLoading: boolean;
  renameFilter?: boolean;
};

export const CreateFilterModal: React.FC<PropsType> = ({
  title,
  text,
  onSubmit,
  onHide,
  isLoading,
  renameFilter,
  value,
}) => {
  const { t } = useTranslation("app");
  const [characters, setCharacters] = useState(renameFilter ? value! : "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const capitalizedValue =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setCharacters(capitalizedValue);
  };

  const isInputValid = characters.trim().length > 0 && characters.length <= 40;

  return (
    <div onClick={onHide} className="create-filter-outlet">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modal-create-filter"
      >
        <div className="modal-create-filter-header">
          <p>{title}</p>
          {onHide && (
            <img onClick={onHide} className="close" src={Close} alt="Close" />
          )}
        </div>
        <span>{text}</span>
        <div
          className="filter-name-inpute"
          style={{ marginBlock: !text ? "10px" : "24px" }}
        >
          <input
            id="filterName"
            type="text"
            placeholder={t("EnterFilterName")}
            autoComplete="off"
            // autoCapitalize="characters"
            autoFocus
            value={characters}
            onChange={handleInputChange}
            style={{
              borderColor: characters.length > 40 ? "#EB5757" : "#bfc4ce",
            }}
          />
          <label
            style={{
              color: characters.length > 40 ? "#EB5757" : "#696f79",
              justifyContent:
                characters.length > 40 ? "space-between" : "flex-end",
            }}
            htmlFor="filterName"
          >
            {characters.length > 40 && <span>{t("40CharactersMaximum")}</span>}
            {characters.length}/40
          </label>
        </div>

        <div className="create-filter-btns-wrapper">
          <ModalButton customClass="create-filter-white-btn" onClick={onHide}>
            {t("cancel")}
          </ModalButton>
          <ModalButton
            disabled={!isInputValid || isLoading}
            onClick={() => onSubmit(characters)}
          >
            {renameFilter ? t("rename") : t("create")}
          </ModalButton>
        </div>
      </div>
    </div>
  );
};
