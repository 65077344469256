import React, { FC } from "react";
import "./style.scss";
import Save1 from "../../../../assets/save1.svg";
import Save2 from "../../../../assets/save2.svg";
import Save3 from "../../../../assets/save3.svg";

type PropsType = {
  onclick: () => void;
  saved: boolean;
};
const SaveToFavBtn: FC<PropsType> = ({ onclick, saved }) => {
  return (
    <button
      onClick={onclick}
      className={"save-to-favorites-star"}
      style={{ opacity: saved ? 1 : 0.8 }}
    >
      <img src={saved ? Save2 : Save3} alt="Save" />
    </button>
  );
};

export default SaveToFavBtn;
