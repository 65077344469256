import React, { FC, useState } from "react";
import "./text.scss";
import moment from "moment";

interface IChat {
  sender: string;
  customer: string;
  text: string;
  file: string | null;
  createdAt: string;
  isLast: boolean;
}

const ChatTextMessage: FC<IChat> = ({ sender, createdAt, text, isLast }) => {
  return (
    <div
      className={`message-text message-${sender}-text ${
        isLast ? "last-msg" : ""
      }`}
    >
      <p dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, "<br />") }} />
      <span>{moment(new Date(createdAt)).format("HH:mm")}</span>
    </div>
  );
};
export default ChatTextMessage;
