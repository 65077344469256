import React, { FC } from "react";
import "./style.scss";

type PropType = {
  img: any;
  name: string;
  price: number;
};

export const AccountItem: FC<PropType> = ({ img, name, price }) => {
  return (
    <div className="account-card">
      <div>
        <img src={img} alt="flag" />
        <span>{name}</span>
      </div>
      <p>{price}</p>
    </div>
  );
};
