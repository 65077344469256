import React, { FC } from "react";
import "./style.scss";
import PremiumAcc from "../../../../assets/PremiumAcc.svg";

type PropType = {
  status: any;
};

const tariffs: any = {
  1: "Sirius Standard",
  2: "Sirius Prime",
  3: "Sirius Prime Plus",
};

export const UserStatus: FC<PropType> = ({ status }) => {
  return (
    <div className="status-card">
      {status === 3 && <img src={PremiumAcc} alt="Premium User Icon" />}
      <p>{tariffs[status]}</p>
    </div>
  );
};
