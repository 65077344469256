import { useCallback, useEffect, useState } from "react";
import "./style.scss";
import HorizontalScroll from "../components/horizontal-scroll";
import { TabBtn } from "../components/tab-btn";
import { MarketAsset } from "../components/market-asset";
import { sircapApi } from "shared/api/sircap";
import { getMLValue, navigateToAsset } from "helpers/functions";
import { useLocation, useNavigate } from "react-router-dom";
import StrategyCard from "../components/strategy-card";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import PortfolioSection from "../components/portfolio-section";
import { IUserBrokerageAccount, dashboardStore } from "entities/dashboard";
import { AssetType, AssetTypes } from "static/types";
import StoriesModal from "../stories";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { useTranslation } from "react-i18next";
import { IFavorites } from "../../../entities/market/index";

export const WhatToBuyPage = () => {
  const { t } = useTranslation("app");
  const [activeTab, setActiveTab] = useState("Tops");
  const [isLoading, setIsLoading] = useState(false);
  const [whatToBuy, setWhatToBuy] = useState([]);
  const [portfolioList, setPortfolioList] = useState<Record<number, any[]>>({});
  const [favoritesList, setFavoritesList] = useState<Record<number, any[]>>({});
  const {
    user_brokerage_accounts,
    user_non_trading_accounts,
    loadUserAccounts,
    loadAllRates,
  } = useStore(dashboardStore);

  const navigate = useNavigate();

  const {
    loadFavorites,
    favorites,
    isLoading: isLoad,
    strategies,
    getStrategies,
  } = useStore(marketStore);

  const handleNavigate = (symbol: string) => {
    navigate(PATH_LIST.strategy(symbol));
  };
  const scroll = () => {
    const stockDetails = document.querySelector(".stock-details");
    if (stockDetails) {
      stockDetails.scrollTop = 0;
    }
  };
  const handleTabClick = (text: string) => {
    setActiveTab(text);
    scroll();
  };
  useEffect(() => {
    setIsLoading(true);
    getStrategies();
    sircapApi.market
      .whatToBuy()
      .then((resp) => {
        setWhatToBuy(resp?.data?.data || []);
        setShowMore((resp?.data?.data || []).map(() => false));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const getUserBrokerageAccount = useCallback(() => {
    return [
      ...user_brokerage_accounts
        .filter((a) => a.quantity > 0)
        .map((v) => ({ ...v, nonTrading: false })),
      ...user_non_trading_accounts
        .filter((a) => a.quantity > 0)
        .map((v) => ({ ...v, nonTrading: true })),
    ];
  }, [user_brokerage_accounts, user_non_trading_accounts]);

  useEffect(() => {
    loadUserAccounts();
    loadAllRates();
    loadFavorites();
  }, []);

  useEffect(() => {
    const portfolio = getUserBrokerageAccount();
    if (portfolio.length) {
      setPortfolioList(() => {
        const list: Record<number, any[]> = {};
        for (const item of portfolio) {
          const type = item.asset.type;
          if (!(type in list)) {
            list[type] = [item];
          } else {
            list[type].push(item);
          }
        }
        return list;
      });
    }
  }, [getUserBrokerageAccount]);

  useEffect(() => {
    if (favorites.length) {
      setFavoritesList(() => {
        const list: Record<number, any[]> = {};
        for (const asset of favorites) {
          const type = asset.asset!.type;
          if (!(type in list)) {
            list[type] = [asset];
          } else {
            list[type].push(asset);
          }
        }
        return list;
      });
    }
  }, [favorites]);

  const getProfitLoss = (account: IUserBrokerageAccount) => {
    const { asset, price, quantity } = account;

    let info = {
      progress: 0,
      percent: 0,
    };
    if ([AssetType.Stock, AssetType.ETF, AssetType.Fond].includes(asset.type)) {
      const quote = +((asset.bid + asset.ask) / 2).toFixed(2) || 0;
      if (quote) {
        const quotePrice = quote * quantity;
        const buyPrice = price * quantity;
        const i = buyPrice / 100;
        const diff = +(quotePrice - buyPrice);
        info.progress = diff;
        info.percent = diff / i;
      }
      return info;
    } else if ([AssetType.Bond, AssetType.EuroBond].includes(asset.type)) {
      const quote =
        parseFloat(
          (
            ((asset.ask + asset.bid) / 2 / 100) * asset.data.nominal +
            asset.data.ai
          ).toFixed(2)
        ) || 0;
      if (quote) {
        const quotePrice = quote * quantity;
        const buyPrice = price * quantity;
        const i = buyPrice / 100;
        const diff = +(quotePrice - buyPrice);
        info.progress = diff;
        info.percent = diff / i;
      }
      return info;
    }
    return info;
  };

  const getCurrentPrice = (account: IUserBrokerageAccount) => {
    const { type, bid, ask, data } = account.asset;
    if ([AssetType.Stock, AssetType.ETF, AssetType.Fond].includes(type)) {
      return +((bid + ask) / 2).toFixed(2) || 0;
    } else if (
      [
        AssetType.Bond,
        AssetType.EuroBond,
        AssetType.StructuredProduct,
      ].includes(type)
    ) {
      return (
        parseFloat(
          (((ask + bid) / 2 / 100) * data.nominal + data.ai).toFixed(2)
        ) || 0
      );
    } else {
      return 0;
    }
  };

  const getBondData = (account: IUserBrokerageAccount) => {
    const { type, symbol, data } = account.asset;
    if (
      [
        AssetType.Bond,
        AssetType.EuroBond,
        AssetType.StructuredProduct,
      ].includes(type)
    ) {
      const avg_yield = (data.ask_yield + data.bid_yield) / 2;
      return {
        maturity: data.maturity,
        avg_yield: parseFloat(
          ((avg_yield / 100) * data.nominal + data.ai).toFixed(2)
        ),
      };
    } else {
      return null;
    }
  };

  const [story, setStory] = useState<any>(false);
  const [showMore, setShowMore] = useState<boolean[]>([]);

  useBlockScrollbar(story, [story]);

  const showDetailsPage = () => {
    return (
      <>
        {activeTab === "Tops" ? (
          <div className="what-to-buy-main wtbm-tops">
            {whatToBuy.length ? (
              whatToBuy.map((section: any, index: number) => (
                <div key={index}>
                  <p className="section-title">{getMLValue(section.title)}</p>

                  {section.list
                    .slice(0, showMore[index] ? section.list.length : 3)
                    .map((asset: any) => (
                      <MarketAsset
                        key={asset.id}
                        onClick={() => {
                          navigateToAsset(asset.type, asset.id, navigate);
                        }}
                        asset={asset}
                        gains={asset.change}
                        gainsPercent={asset.changesPercentage}
                        showGains={[
                          AssetType.Stock,
                          AssetType.ETF,
                          AssetType.Fond,
                        ].includes(asset.type)}
                        about
                        onAboutClick={() =>
                          setStory({
                            asset,
                            info: section.info.find(
                              (s: any) => s.symbol === asset.symbol
                            ),
                          })
                        }
                      />
                    ))}
                  {section.list.length > 3 && (
                    <button
                      onClick={() =>
                        setShowMore(
                          showMore.map((v, i) => (i === index ? !v : v))
                        )
                      }
                    >
                      {showMore[index] ? t("showLess") : t("showMore")}
                    </button>
                  )}
                </div>
              ))
            ) : isLoading ? (
              <p className="assets-loading">{t("loading")}</p>
            ) : (
              <div className="not-found-coming-soon">
                <p>{t("notFound")}</p>
              </div>
            )}
          </div>
        ) : activeTab === "MyPortfolio" ? (
          <div className="what-to-buy-main">
            {/* <div className="calendar">
              <div>
                <img src={Award} alt="Calendar" />
                <p>Improve portfolio</p>
              </div>
              <img src={AwardRight} alt="Vector" />
            </div> */}

            {Object.entries(portfolioList)
              .sort((a, b) => a[1].length - b[1].length)
              .map(([key, list]) => (
                <PortfolioSection
                  key={key}
                  data={list}
                  getProfitLoss={getProfitLoss}
                  getCurrentPrice={getCurrentPrice}
                  getBondData={getBondData}
                  category={t(AssetTypes[+key as unknown as AssetType])}
                />
              ))}
          </div>
        ) : activeTab === "MyFavorites" ? (
          <div className="what-to-buy-main">
            {favorites.length ? (
              Object.entries(favoritesList)
                .sort((a, b) => a[1].length - b[1].length)
                .map(([key, list]) => (
                  <PortfolioSection
                    key={key}
                    favorites={list}
                    getProfitLoss={getProfitLoss}
                    getCurrentPrice={getCurrentPrice}
                    getBondData={getBondData}
                    category={t(AssetTypes[+key as unknown as AssetType])}
                  />
                ))
            ) : (
              <p className="no-favorites-text">
                {t("startSavingYourFavorites")}
              </p>
            )}
          </div>
        ) : (
          <>
            {strategies.length ? (
              <div className="what-to-buy-main wtbm-strategy">
                {strategies.map(({ id, symbol, currency, data }, i) => (
                  <StrategyCard
                    id={i + 1}
                    key={symbol}
                    onClick={() => handleNavigate(id)}
                    asset={strategies[i]}
                    minPrice={data.minimal_invest}
                    risk={data.risk}
                    percent={data.annual_yield}
                  />
                ))}
              </div>
            ) : isLoad ? (
              <p className="assets-loading">{t("loading")}</p>
            ) : (
              <div className="not-found-coming-soon">
                <p>{t("notFound")}</p>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  return (
    <div className="what-to-buy">
      <HorizontalScroll className="tab-bar">
        <TabBtn
          text={t("top10")}
          active={activeTab === "Tops"}
          onClick={() => handleTabClick("Tops")}
        />
        <TabBtn
          text={t("myPortfolio")}
          active={activeTab === "MyPortfolio"}
          onClick={() => handleTabClick("MyPortfolio")}
        />
        <TabBtn
          text={t("myFavorites")}
          active={activeTab === "MyFavorites"}
          onClick={() => handleTabClick("MyFavorites")}
        />
        {strategies.length > 0 && (
          <TabBtn
            text={t("strategies")}
            active={activeTab === "Strategies"}
            onClick={() => handleTabClick("Strategies")}
          />
        )}
      </HorizontalScroll>
      {showDetailsPage()}

      {story && (
        <StoriesModal
          currentIndex={0}
          onAllStoriesEnd={() => setStory(false)}
          whatToBuyStories
          data={story}
        />
      )}
    </div>
  );
};

//  {( favorites.map(({ asset }: any)) => (
// <PortfolioSection
// key = { key };
// data={favorites}
// getProfitLoss={getProfitLoss}
// getCurrentPrice={getCurrentPrice}
// getBondData={getBondData}
// category={t(AssetTypes[+key as unknown as AssetType])}
// favorites={favorites}
// />))}

{
  /*  // favorites.map(({ asset }: any) => (
              //   <MarketAsset
              //     key={asset.id}
              //     onClick={() => {
              //       navigateToAsset(asset.type, asset.id, navigate);
              //     }}
              //     asset={asset}
              //     gains={asset.change}
              //     gainsPercent={asset.changesPercentage}
              //     showGains={[AssetType.Stock, AssetType.ETF].includes(
              //       asset.type
              //     )}
              //     onAboutClick={() =>
              //       setStory({
              //         asset,
              //         info: favorites.find(
              //           (s: any) => s.symbol === asset.symbol
              //         ),
              //       })
              //     }
              //   />
              // ))
              favorites.map(({ asset, key }: any) => (
                <PortfolioSection
                  key={key}
                  favorites={favorites}
                  getProfitLoss={getProfitLoss}
                  getCurrentPrice={getCurrentPrice}
                  getBondData={getBondData}
                  category={t(AssetTypes[+key as unknown as AssetType])}
                />
              )) */
}

//  favorites.map(({ asset }: any) => (
//             <MarketAsset
//               key={asset.id}
//               onClick={() => {
//                 navigateToAsset(asset.type, asset.id, navigate);
//               }}
//               asset={asset}
//               gains={asset.change}
//               gainsPercent={asset.changesPercentage}
//               showGains={[AssetType.Stock, AssetType.ETF].includes(
//                 asset.type
//               )}
//               onAboutClick={() =>
//                 setStory({
//                   asset,
//                   info: favorites.find(
//                     (s: any) => s.symbol === asset.symbol
//                   ),
//                 })
//               }
//             />
//           ))
