import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import GoBack from "../../../../assets/GoBack.svg";
import IconCamera from "../../../../assets/IconCamera.svg";
// import Webcam from "./react-webcam";
import { Camera } from "./react-camera-pro";
import SwitchCamera from "../../../../assets/SwitchCamera.png";
import "./style.scss";
import { b64toBlob } from "helpers/functions";
import { useTranslation } from "react-i18next";
import { DocumentType } from "static/types";

type PropType = {
  onOpen: any;
  onClose: any;
  type: DocumentType;
  className?: string;
};
export type FacingMode = "user" | "environment";
export type AspectRatio = "cover" | number;

const TakePhotoModal: FC<PropType> = ({ onOpen, onClose, type, className }) => {
  const camera = useRef(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);

  const [numberDevices, setNumberDevices] = useState<number>(0);

  type FacingType = "front" | "back";
  const facingMode: Record<typeof type, FacingType> = {
    [DocumentType.Passport]: "back",
    [DocumentType.IdCard]: "back",
    [DocumentType.IdCardBack]: "back",
    [DocumentType.ProofAddress]: "back",
    [DocumentType.Selfie]: "front",
    [DocumentType.UtilityBill]: "back",
    [DocumentType.Avatar]: "front",
  };
  // const [facing, setFacing] = useState<FacingType>("front" || facingMode[type]);
  const { t: on } = useTranslation("onboarding-auth");
  const { t } = useTranslation("app");
  const cameraBoxClass: Record<typeof type, string> = {
    [DocumentType.Passport]: "",
    [DocumentType.IdCard]: "cameraBoxShort",
    [DocumentType.IdCardBack]: "cameraBoxShort",
    [DocumentType.ProofAddress]: "",
    [DocumentType.Selfie]: "",
    [DocumentType.UtilityBill]: "",
    [DocumentType.Avatar]: "",
  };
  const cameraBtnClass: Record<typeof type, string> = {
    [DocumentType.Passport]: "cameraBtn",
    [DocumentType.IdCard]: "",
    [DocumentType.IdCardBack]: "",
    [DocumentType.ProofAddress]: "cameraBtn",
    [DocumentType.Selfie]: "cameraBtn",
    [DocumentType.UtilityBill]: "cameraBtn",
    [DocumentType.Avatar]: "cameraBtn",
  };
  const firstLineText: Record<typeof type, string> = {
    [DocumentType.Passport]: "",
    [DocumentType.IdCard]: on("frontIDCard"),
    [DocumentType.IdCardBack]: on("backIDCard"),
    [DocumentType.ProofAddress]: "",
    [DocumentType.Selfie]: "",
    [DocumentType.UtilityBill]: "",
    [DocumentType.Avatar]: "",
  };

  const secondLineText: Record<typeof type, string> = {
    [DocumentType.Passport]: on("alignPassportFrameCaptureLong"),
    [DocumentType.IdCard]: on("positionFrontIDCardFrame"),
    [DocumentType.IdCardBack]: on("positionBackIDCardFrame"),
    [DocumentType.ProofAddress]: on("alignPassportFrameCaptureLong"),
    [DocumentType.Selfie]: on("focusCameraFacePassportID"),
    [DocumentType.UtilityBill]: on("alignDocumentFrameCaptureDescription"),
    [DocumentType.Avatar]: t("takePhoto"),
  };

  useEffect(() => {
    // (async () => {
    //   const devices = await navigator.mediaDevices.enumerateDevices();
    //   const videoDevices = devices.filter((i) => i.kind == "videoinput");
    //   setDevices(videoDevices);
    // })();
  }, []);

  const onPhoto = useCallback(() => {
    if (camera && camera.current) {
      try {
        // @ts-ignore
        // const imageSrc = camera.current.getScreenshot();
        // const blob = b64toBlob(imageSrc, "image/jpeg");
        // blob && onOpen(blob);
        // @ts-ignore
        const imageSrc = camera.current.takePhoto();
        const blob = b64toBlob(imageSrc, "image/jpeg");
        blob && onOpen(blob);
      } catch (error) {}
    }
  }, [camera]);

  // useEffect(() => {
  //   try {
  //     if (devices.length <= 1) return;
  //     let newDevice: MediaDeviceInfo | undefined;
  //     if (facing === "front") {
  //       newDevice = [...devices].shift();
  //     } else {
  //       newDevice = [...devices].pop();
  //     }
  //     if (newDevice) {
  //       setDeviceId(newDevice!.deviceId);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [devices, facing]);

  const onChangeCamera = useCallback(() => {
    // setFacing(facing === "front" ? "back" : "front");
    if (camera.current) {
      // @ts-ignore
      const result = camera.current.switchCamera();
      console.log(result);
    }
  }, [camera]);

  return (
    <div className={`takePhotoType ${className}`}>
      <div className="goBack" onClick={onClose}>
        <div>
          <img src={GoBack} alt="Go Back" />
        </div>
        <p className="backText">{t("back")}</p>
      </div>

      <div className="textTPM">
        {type !== DocumentType.Passport && <p>{firstLineText[type]}</p>}
        <span>{secondLineText[type]}</span>
      </div>

      <div className={`${cameraBoxClass[type]} cameraBox`}>
        <div className="videoCover">
          {/* <Webcam
            className="video"
            ref={camera}
            videoConstraints={{
              deviceId: deviceId ? { exact: deviceId } : undefined,
            }}
            audio={false}
            screenshotFormat="image/jpeg"
            mirrored={
              facing === "front" &&
              [DocumentType.Selfie, DocumentType.Avatar].includes(type)
            }
          ></Webcam> */}
          <Camera
            ref={camera}
            aspectRatio="cover"
            numberOfCamerasCallback={(i) => setNumberDevices(i)}
            // videoSourceDeviceId={activeDeviceId}
            errorMessages={{
              noCameraAccessible:
                "No camera device accessible. Please connect your camera or try a different browser.",
              permissionDenied:
                "Permission denied. Please refresh and give camera permission.",
              switchCamera:
                "It is not possible to switch camera to different one because there is only one video device accessible.",
              canvas: "Canvas is not supported.",
            }}
            // videoReadyCallback={() => {
            //   console.log("Video feed ready.");
            // }}
          />
        </div>
      </div>

      <div className="cameraBtns">
        <button
          onClick={onPhoto}
          className={`${cameraBtnClass[type]} takePhotoBtn`}
        >
          <img src={IconCamera} alt="DocumentExample" />
        </button>
        {numberDevices > 1 && (
          <button
            onClick={onChangeCamera}
            className={`takePhotoBtn switchCamera`}
          >
            <img src={SwitchCamera} alt="SwitchCamera" />
          </button>
        )}
      </div>
    </div>
  );
};

export default TakePhotoModal;
