import { AssetType, OperationType, OrderType } from "./types";

export const SortData = {
  [String(AssetType.Stock)]: [
    { by: "symbol", text: "By symbol" },
    { by: "ask", text: "By price" },
    { by: "name", text: "By name" },
    { by: "change", text: "By change per day" },
  ],
  [String(AssetType.ETF)]: [
    { by: "symbol", text: "By symbol" },
    { by: "ask", text: "By price" },
    { by: "name", text: "By name" },
    { by: "change", text: "By change per day" },
  ],
  [String(AssetType.Fond)]: [
    { by: "symbol", text: "By symbol" },
    { by: "ask", text: "By price" },
    { by: "name", text: "By name" },
    { by: "change", text: "By change per day" },
  ],
  [String(AssetType.Bond)]: [
    { by: "maturity_date", text: "By maturity date" },
    { by: "rating", text: "By reliability rating" },
    { by: "yield", text: "By yield to maturity" },
  ],
  [String(AssetType.EuroBond)]: [
    { by: "maturity_date", text: "By maturity date" },
    { by: "rating", text: "By reliability rating" },
    { by: "yield", text: "By yield to maturity" },
  ],
};

export const OrderTypes = {
  [OperationType.Buy]: [
    {
      type: OrderType.Market,
      operation: OperationType.Buy,
      showInput: false,
      with_sirius: false,
      title: "Market price",
      info: "Buy at market price",
    },
    {
      type: OrderType.Limit,
      operation: OperationType.Buy,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Buy for no more than the specified price",
    },
  ],
  [OperationType.Sell]: [
    {
      type: OrderType.Market,
      operation: OperationType.Sell,
      showInput: false,
      with_sirius: false,
      title: "Market price",
      info: "Sell at market price",
    },
    {
      type: OrderType.Limit,
      operation: OperationType.Sell,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Sell for not less than the specified price",
    },
  ],
};

export const OrderTypesForCurrencies = {
  [OperationType.Buy]: [
    {
      type: OrderType.Market,
      operation: OperationType.Buy,
      showInput: false,
      with_sirius: false,
      title: "Market price",
      info: "Buy at market price",
    },
    {
      type: OrderType.Limit,
      operation: OperationType.Buy,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Buy for no more than the specified price",
    },
    {
      type: OrderType.Market,
      operation: OperationType.Buy,
      showInput: false,
      with_sirius: true,
      title: "Trade with Sirius",
      info: "Execute your trade directly with Sirius",
    },
  ],
  [OperationType.Sell]: [
    {
      type: OrderType.Market,
      operation: OperationType.Sell,
      showInput: false,
      with_sirius: false,
      title: "Market price",
      info: "Sell at market price",
    },
    {
      type: OrderType.Limit,
      operation: OperationType.Sell,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Sell for not less than the specified price",
    },
    {
      type: OrderType.Market,
      operation: OperationType.Sell,
      showInput: false,
      with_sirius: true,
      title: "Trade with Sirius",
      info: "Execute your trade directly with Sirius",
    },
  ],
};

export const OrderTypesForLimitOrders = {
  [OperationType.Buy]: [
    {
      type: OrderType.Limit,
      operation: OperationType.Buy,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Buy for no more than the specified price",
    },
  ],
  [OperationType.Sell]: [
    {
      type: OrderType.Limit,
      operation: OperationType.Sell,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Sell for not less than the specified price",
    },
  ],
};
