import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import { PasswordShowHide } from "../PasswordShowHide";
import "./style.scss";

type PropsType = {
  label: string;
  id: string;
  isInvalid?: boolean;
  placeholder?: string;
  className?: string;
  isValidated?: boolean;
  autoFocus?: boolean;
  autoComplete?: string | undefined;
};

export const PasswordField: FC<PropsType> = ({
  label,
  id,
  isInvalid,
  placeholder = "",
  className,
  autoFocus = true,
  autoComplete = "on",
  isValidated = false,
}) => {
  return (
    <div className={`${className} form-row`}>
      <label htmlFor={id}>{label}</label>
      <Field
        type={"password"}
        name={id}
        id={id}
        className={isInvalid ? "input-error" : null}
        placeholder={placeholder}
        component={PasswordShowHide}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />
      <ErrorMessage name={id} component="span" className="error" />
    </div>
  );
};
