import React, { FC } from "react";
import "./style.scss";
import GoBack from "../../../../assets/GoBack.svg";
import CameraIssue from "../../../../assets/CameraIssue.svg";
import { useTranslation } from "react-i18next";

type PropType = {
  onClose?: any;
  onOpen?: any;
};

const CameraAccessNotFoundModal: FC<PropType> = ({ onClose, onOpen }) => {
  const { t } = useTranslation("onboarding-auth");

  return (
    <div className="cameraAccessNotFoundModal">
      <div className="goBack" onClick={onClose}>
        <div>
          <img src={GoBack} alt="Go Back" />
        </div>
        <p className="backText">{t("back")}</p>
      </div>

      <img src={CameraIssue} alt="CameraIssueImg" />

      <div className="textCAM">
        <p>{t("cameraAccessIssue")}</p>
        <span>{t("cameraNotDetected")}</span>
      </div>

      <div className="warnings">
        <ul>
          <li>{t("ensureCameraConnected")}</li>
          <li>{t("verifyCameraAccess")}</li>
          <li>{t("checkOtherApps")}</li>
        </ul>
      </div>

      <div className="unableToResolveText">
        <p>{t("unableToResolveIssue")}</p>
        <span>{t("progressSavedMessage")}</span>
      </div>

      <a className="stillNeedHelp" href="">
        {t("stillNeedHelp")}
      </a>
      <span className="notNowBtn">{t("stillNeedHelp")}</span>
    </div>
  );
};

export default CameraAccessNotFoundModal;
