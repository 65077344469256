import { marketStore } from "entities/market";
import { useState, useRef, useCallback, useEffect } from "react";
import { useStore } from "zustand";

const useSaveHandler = (
  asset_id: number,
  addFavorites: (id: any) => void,
  removeFavorite: (id: number) => void
) => {
  const { favorites } = useStore(marketStore);
  const [saved, setSaved] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (favorites.find(({ asset }: any) => asset.id === asset_id)) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  }, [favorites]);

  const handleSave = useCallback(
    (asset_id: number) => {
      setSaved((prevSaved) => !prevSaved);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      if (!saved) {
        addFavorites({ asset_id });
        setShowNotification(true);

        timerRef.current = setTimeout(() => {
          setShowNotification(false);
        }, 3300);
      } else {
        removeFavorite(asset_id);
        setShowNotification(false);
      }
    },
    [addFavorites, removeFavorite, saved]
  );

  return { saved, showNotification, handleSave, favorites };
};

export default useSaveHandler;
