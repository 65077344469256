import React, { FC } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";

type PropType = {
  info: string;
  img: string;
  onClick: () => void;
};

export const Stories: FC<PropType> = ({ info, img, onClick }) => {
  const { t } = useTranslation("app");

  return (
    <div
      style={{ backgroundImage: `url(${img})` }}
      onClick={onClick}
      className="stories-card"
    >
      <span dangerouslySetInnerHTML={{ __html: info }} />
    </div>
  );
};
