import React from "react";
import "./style.scss";
import ProgressUp from "../../../../assets/BalanceUp.svg";
import ProgressDown from "../../../../assets/BalanceDown.svg";
import NotSellable from "../../../../assets/NotSellable.svg";
import AssetIcon from "../asset-icon";
import { IUserBrokerageAccount } from "entities/dashboard";
import { useTranslation } from "react-i18next";

type PropType = {
  asset: IUserBrokerageAccount;
  price: number;
  progress: number;
  onClick: any;
  nonTrading: boolean;
};

export const PartfolioItem: React.FC<PropType> = ({
  price,
  progress,
  onClick,
  asset,
  nonTrading,
}) => {
  const { t } = useTranslation("app");
  const { quantity: count, asset: assetItem } = asset;
  const currency_symbol = assetItem.currency.symbol;

  return (
    <div
      className={
        nonTrading ? "partfolio-card  partfolio-promo-card" : "partfolio-card"
      }
      onClick={onClick}
    >
      {nonTrading && <div className="non-trading-background" />}

      {nonTrading && (
        <div className="not-sellable">
          <img src={NotSellable} alt="NotSellable" />

          <div className="not-sellable-popup-wrapper">
            <div className="not-sellable-popup">{t("notSellable")}</div>
          </div>
        </div>
      )}

      <AssetIcon asset={assetItem} className="icon" />
      <span>{assetItem.symbol}</span>
      <p>
        {+(price * count).toFixed(2)}
        {currency_symbol}
      </p>
      <div className="item-progress">
        <p>
          {count} <b>•</b> {price}
          {currency_symbol}
        </p>
        <span className={progress < 0 ? "danger" : ""}>{progress}%</span>
        {/* <img src={progress < 0 ? ProgressDown : ProgressUp} alt="vector" /> */}
        {/* {nonTrading ? <span>None trading</span> : <span>trading</span>} */}
      </div>
    </div>
  );
};
