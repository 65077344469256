import React, { FC, useEffect, useRef, useState } from "react";
import "./video.scss";
import moment from "moment";
import CloseIcon from "../../../../../assets/CloseStories.svg";
import Play from "../../../../../assets/PlayAudio.svg";
import Pause from "../../../../../assets/PauseAudio.svg";
import Muted from "../../../../../assets/Muted.png";
import Unmuted from "../../../../../assets/Unmuted.png";

interface IChat {
  sender: string;
  createdAt: string;
  isLast: boolean;
  videoUrl: string | null;
  onClick: () => void;
}

const ChatVideoMessage: FC<IChat> = ({
  sender,
  createdAt,
  isLast,
  videoUrl,
  onClick,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  const playPauseToggle = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const updateMuteState = () => {
      if (videoRef.current) {
        setIsMuted(videoRef.current.muted);
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("volumechange", updateMuteState);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("volumechange", updateMuteState);
      }
    };
  }, [videoRef.current]);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  useEffect(() => {
    const updateCurrentTime = () => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime);
      }
    };

    const updateTotalDuration = () => {
      if (videoRef.current) {
        setTotalDuration(videoRef.current.duration);
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("timeupdate", updateCurrentTime);
      videoRef.current.addEventListener("loadedmetadata", updateTotalDuration);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("timeupdate", updateCurrentTime);
        videoRef.current.removeEventListener(
          "loadedmetadata",
          updateTotalDuration
        );
      }
    };
  }, [videoRef.current]);
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  const timeRemaining = totalDuration - currentTime;

  return (
    <div
      onClick={() => {
        onClick();
        if (videoRef.current && !videoRef.current.paused) {
          videoRef.current.pause();
          onClick();
          setIsPlaying(!isPlaying);
        }
      }}
      className={`message-video message-${sender}-video ${
        isLast ? "last-msg" : ""
      }`}
    >
      <video
        ref={videoRef}
        className="custom-video-player"
        // onClick={(e) => e.stopPropagation()}
      >
        <source src={videoUrl ?? ""} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <button
        className="play-pause-video"
        onClick={(e) => {
          e.stopPropagation();
          playPauseToggle();
        }}
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        <img
          className={!isPlaying ? "video-stopped" : undefined}
          src={isPlaying ? Pause : Play}
          alt={isPlaying ? "Pause" : "Play"}
        />
      </button>

      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleMute();
        }}
        className="remaining-sound-wrapper"
      >
        <p className="time-remaining-video">{formatTime(timeRemaining)}</p>
        <img src={isMuted ? Muted : Unmuted} alt="sound" />
      </div>

      <span>{moment(new Date(createdAt)).format("HH:mm")}</span>
    </div>
  );
};

export default ChatVideoMessage;

interface VueVideoMessageProps {
  onClose: () => void;
  createdAt?: any;
  videoUrl: any | null;
}

export const VueVideoMessage: FC<VueVideoMessageProps> = ({
  onClose,
  createdAt,
  videoUrl,
}) => {
  return (
    <div className="vue-video-message" onClick={onClose}>
      <div className="vue-vid-nav">
        <span>{moment(new Date(createdAt)).format("HH:mm")}</span>
        <div className="action_btns">
          <div className="action_btn-wrapper">
            <img
              className="close-img-vue-icon"
              src={CloseIcon}
              alt="Action Btn"
            />
          </div>
        </div>
      </div>
      <video autoPlay onClick={(e) => e.stopPropagation()} controls>
        <source src={videoUrl ?? ""} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
