import React, { FC } from "react";
import "./style.scss";
import StockFilter from "../../../../assets/StockFilter.svg";
import BondFilter from "../../../../assets/BondFilter.svg";
import MyFilter from "../../../../assets/MyFilter.svg";
import EditFilter from "../../../../assets/EditFilter.svg";
import FilterDetail from "../filter-detail";
import { IFilter } from "entities/market";

export enum FilterType {
  StockFilter = "StockFilter",
  BondFilter = "BondFilter",
  MyFilter = "MyFilter",
}

const ImageMap: Record<FilterType, string> = {
  [FilterType.StockFilter]: StockFilter,
  [FilterType.BondFilter]: BondFilter,
  [FilterType.MyFilter]: MyFilter,
};

type PropsType = {
  type?: FilterType;
  filter: IFilter;
  myFilter?: boolean;
  onMyFilterEdit?: () => void;
  onOpen: any;
};

const FilterCard: FC<PropsType> = ({
  type,
  filter,
  myFilter = false,
  onMyFilterEdit,
  onOpen,
}) => {
  const imageSrc = ImageMap[type || FilterType.MyFilter];

  const getFilterClass = () => {
    if (!myFilter && imageSrc !== BondFilter) {
      return "default-filter";
    } else if (myFilter) {
      return "default-filter my-filter";
    } else if (!myFilter && imageSrc == BondFilter) {
      return "default-filter default-bond-filter";
    } else {
      return "default-filter";
    }
  };
  const filterClass = getFilterClass();

  return (
    <div className={filterClass} onClick={onOpen}>
      {myFilter && (
        <img
          onClick={(e) => {
            e.stopPropagation();
            onMyFilterEdit && onMyFilterEdit();
          }}
          className="edit-filter-icon"
          src={EditFilter}
          alt="Edit Icon"
        />
      )}
      <div className="filter-info">
        <p>{filter.name}</p>
        <div className="filter-detail-container">
          {/* <FilterDetail detail={detail1} />
          {detail2 && <FilterDetail detail={detail2} />}
          {detail2 && moreDetails && (
            <FilterDetail detail={`+ ${moreDetails}`} />
          )} */}
        </div>
      </div>

      {/* Fix(change) bond img */}
      <img src={imageSrc} alt="Filter Icon" />
    </div>
  );
};

export default FilterCard;
