import React, { useMemo } from "react";
import Button from "shared/ui/button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "shared/ui/form-field";
import { useTranslation } from "react-i18next";
import { useSchema } from "helpers/functions";

const LoginSchema = (t: any) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("emailIsRequired"))
      .required(t("emailIsRequired")),
  });

type PropsType = {
  initialValues: any;
  isSubmit: boolean;
  onSubmit: (email: string) => void;
  customClass?: string;
  wrapper?: boolean;
};

export const RegisterEmailForm: React.FC<PropsType> = ({
  initialValues,
  onSubmit,
  isSubmit,
  customClass,
  wrapper = false,
}) => {
  const { t } = useTranslation("onboarding-auth");
  const schema = useSchema(LoginSchema);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit(values.email);
      }}
    >
      {(formik) => {
        const { errors, touched, isValid, dirty } = formik;
        return (
          <Form className={customClass}>
            <FormField
              type="email"
              id="email"
              label={t("email")}
              isInvalid={!!(errors.email && touched.email)}
              placeholder={t("enterYourEmail")}
              autoFocus={true}
            />

            {wrapper == true ? (
              <div className="btn-wrapper">
                <Button
                  type="submit"
                  disabled={!isValid || isSubmit}
                  variant="dark"
                >
                  {t("continue")}
                </Button>
              </div>
            ) : (
              <Button
                type="submit"
                disabled={!isValid || isSubmit}
                variant="dark"
              >
                {t("continue")}
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
