import { CameraStatus, onboardModel } from "entities/onboard";
import CameraAccessDeniedModal from "pages/onboarding/components/camera-access-denied";
import CameraAccessNotFoundModal from "pages/onboarding/components/camera-access-not-found";
import React from "react";
import { useStore } from "zustand";

const CameraAccess: React.FC = () => {
  const cameraStatus = onboardModel.useCameraStatus();
  const { setCameraStatus } = useStore(onboardModel.onboardStore);

  return (
    <>
      {cameraStatus === CameraStatus.Denied && (
        <CameraAccessDeniedModal
          onClose={() => {
            setCameraStatus(CameraStatus.None);
          }}
          onOpen={() => {
            setCameraStatus(CameraStatus.None);
          }}
        />
      )}

      {cameraStatus === CameraStatus.NotFound && (
        <CameraAccessNotFoundModal
          onClose={() => {
            setCameraStatus(CameraStatus.None);
          }}
          onOpen={() => {
            setCameraStatus(CameraStatus.None);
          }}
        />
      )}
    </>
  );
};

export default CameraAccess;
