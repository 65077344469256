import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import Gift from "../../../../assets/PromoGift1.svg";
import Gift2 from "../../../../assets/PromoGift2.svg";
import PromoCheckmark from "../../../../assets/PromoCheckmark.svg";
import CanvasConfetti from "shared/ui/canvas-confetti";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import { ResponseCodes } from "shared/api/sircap";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";

type PropType = {
  onClose: () => void;
};

export const PromoCodeModal: React.FC<PropType> = ({ onClose }) => {
  const { t } = useTranslation("app");
  const [value, setValue] = useState("");
  const [validation, setValidation] = useState("");
  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const { promoCode } = useStore(accountStore);
  const [disabled, setDisabled] = useState(false);
  const [promo, setPromo] = useState<any>();

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (promo.type === "asset") {
      navigate(PATH_LIST.portfolio);
    } else {
      navigate(PATH_LIST.history);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\s+/g, "").toUpperCase();
    setValue(inputValue);
  };

  const handleApply = async () => {
    setDisabled(true);

    const resp = await promoCode(value);

    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.PromoCodeNotFound:
          setValidation("rejected");
          setTriggerConfetti(false);
          break;

        case ResponseCodes.PromoCodeExpired:
          setValidation("expired");
          setTriggerConfetti(false);
          break;

        case ResponseCodes.PromoCodeActivated:
          setValidation("expired");
          setTriggerConfetti(false);
          break;

        default:
          break;
      }
    } else {
      setValidation("approved");
      setTriggerConfetti(true);
      setPromo(resp.data.data);
    }
    setDisabled(false);
  };

  return (
    <div className="bottom-sheet-modal-wrapper">
      <div onClick={onClose} className="outlet-promo-code-bottom-sheet"></div>

      <div className="promo-code-bottom-sheet">
        {validation !== "approved" ? (
          <img className="gift" src={Gift} alt="gift" />
        ) : (
          <img className="gift gift2" src={Gift2} alt="gift" />
        )}

        {validation !== "approved" && (
          <div className="promo-code-bottom-sheet-header">
            <p>{t("havePromoCode")}</p>
            <span>{t("enterToClaim")}</span>
          </div>
        )}

        {validation === "approved" && (
          <div className="promo-code-bottom-sheet-header promo-code-bottom-sheet-header-congrats">
            <p>{t("congrats")}</p>
            <span>
              {t("youGot")}{" "}
              {promo.type === "asset" ? promo.data?.qty : promo.data?.amount}{" "}
              {promo.type === "asset"
                ? promo.additionalData?.asset?.symbol
                : promo.data?.currency}
            </span>
          </div>
        )}

        <div className="promo-form-wrapper">
          {validation !== "approved" && (
            <>
              <label htmlFor="promo">{t("promoCode")}</label>
              <form>
                <input
                  autoComplete="off"
                  id="promo"
                  type="text"
                  placeholder={t("enterCode")}
                  autoFocus
                  onChange={handleInputChange}
                  value={value}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleApply();
                  }}
                  style={{
                    cursor: disabled ? "pointer" : "pointer",
                    opacity: disabled ? 0.7 : 1,
                  }}
                  disabled={disabled}
                >
                  {t("apply")}
                </button>
                {validation !== "approved" && validation !== "" && (
                  <span>
                    {validation === "rejected"
                      ? t("invalidPromo")
                      : validation === "expired"
                      ? t("expiredPromo")
                      : ""}
                  </span>
                )}
              </form>
            </>
          )}

          {validation === "approved" && (
            <div className="approved-promo-code" onClick={handleNavigate}>
              <img src={PromoCheckmark} alt="PromoCheckmark" />
              <div>
                <p>
                  <b>#{value}</b> {t("approved")}
                </p>
                <span>
                  {promo.type === "asset"
                    ? promo.data?.qty
                    : promo.data?.amount}{" "}
                  x{" "}
                  {promo.type === "asset"
                    ? promo.additionalData?.asset?.symbol
                    : promo.data?.currency}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {triggerConfetti && <CanvasConfetti handleFier={triggerConfetti} />}
    </div>
  );
};
