import React, { FC } from "react";
import "./style.scss";
import GoBack from "../../../../assets/GoBack.svg";
import CameraIssue from "../../../../assets/CameraIssue.svg";
import ModalButton from "widgets/auth/components/modal-btn";
import { useTranslation } from "react-i18next";

type PropType = {
  onClose?: any;
  onOpen?: any;
};

const CameraAccessDeniedModal: FC<PropType> = ({ onClose, onOpen }) => {
  const { t } = useTranslation("onboarding-auth");
  return (
    <div className="cameraAccessModal">
      <div className="goBack" onClick={onClose}>
        <div>
          <img src={GoBack} alt="Go Back" />
        </div>
        <p className="backText">{t("back")}</p>
      </div>

      <img src={CameraIssue} alt="CameraIssueImg" />

      <div className="textCAM">
        <p>{t("enableCameraAccess")}</p>
        <span>{t("cameraAccessDescription")}</span>
      </div>

      <ModalButton onClick={onOpen} customClass="enableCameraBtn">
        {t("enableCameraAccessButton")}
      </ModalButton>
      <span onClick={onClose} className="notNowBtn">
        {t("notNow")}
      </span>
    </div>
  );
};

export default CameraAccessDeniedModal;
