import React from "react";
import "./style.scss";

type PropsType = {
  variant?: "dark" | "light" | "noBorder";
  disabled?: any;
  children: any;
  className?: string;
  onClick?: any;
  type?: "button" | "submit" | "reset";
};
const Button: React.FC<PropsType> = ({
  variant = "light",
  className = "",
  disabled,
  children,
  onClick = () => {},
  type = "button",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`button ${variant} ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
