import React, { FC } from "react";
import Vector from "../../../../assets/Vector.svg";
import "./style.scss";

type JoinUs = {
  type: string;
  onClick: any;
};

const DocumentTypeBtn: FC<JoinUs> = ({ type, onClick }) => {
  return (
    <div className="doctype" onClick={onClick}>
      <p>{type}</p>
      <div className="vector">
        <img src={Vector} alt="Vector" />
      </div>
    </div>
  );
};

export default DocumentTypeBtn;
