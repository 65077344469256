import React, { FC } from "react";
import "./style.scss";
import EditIcon from "../../../../assets/InfoEdit.svg";
import SecurityIcon from "../../../../assets/GoBackApp.svg";

type PropType = {
  name: string;
  info?: string;
  onEdit: () => void;
  security?: boolean;
  deletion?: boolean;
};

export const PersonalInfoEditCard: FC<PropType> = ({
  name,
  info,
  onEdit,
  security = false,
  deletion,
}) => {
  return (
    <div
      onClick={security ? onEdit : undefined}
      className={
        security
          ? "personal-info-card security-card"
          : "personal-info-card personal-info-card-media"
      }
    >
      <p
        style={{
          color: deletion ? "red" : "rgba(43, 47, 56, 1)",
        }}
      >
        {name}
      </p>
      {!security ? (
        <div>
          <span>{info}</span>
          <img onClick={onEdit} src={EditIcon} alt="Edit-icon" />
        </div>
      ) : (
        <div>
          <img
            style={{ rotate: "180deg" }}
            src={SecurityIcon}
            alt="Change-icon"
          />
        </div>
      )}
    </div>
  );
};
