import Logo from "../../../assets/LastPageLogo.svg";
import "./style.scss";
import GoBackButton from "shared/ui/back-button";
import React, { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";

const canvasStyles = {
  position: "absolute",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  zIndex: "5",
};

export function AccountHasBeenCreatedPage() {
  const refAnimationInstance = useRef(null);
  const { t } = useTranslation("onboarding-auth");

  const navigate = useNavigate();

  //@ts-ignore
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);
  //@ts-ignore

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      //@ts-ignore
      refAnimationInstance.current({
        ...opts,
        origin: { y: 1 },
        particleCount: Math.floor(150 * particleRatio),
      });
  }, []);

  const handleNavigate = () => {
    navigate(PATH_LIST.login);
  };

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 50,
    });

    makeShot(0.8, {
      spread: 150,
    });

    makeShot(0.8, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.8, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.8, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    const start = setTimeout(() => {
      fire();
    }, 200);

    return () => {
      clearTimeout(start);
    };
  }, []);

  return (
    <>
      <div className="accountCreated">
        <a href="/">
          <img src={Logo} alt="logo" />
        </a>
        <p>{t("accountSuccessfullyCreated")}</p>
        <button onClick={handleNavigate}>{t("logIn")}</button>
      </div>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        // @ts-ignore
        style={canvasStyles}
      />
    </>
  );
}
