import React, { useCallback, useEffect, useRef, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";

export const PINCodePage = () => {
  const { syncUser } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");
  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  const { t: y } = useTranslation("yup");
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [pin, setPin] = useState("");
  const [result, setResult] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState("setPIN");
  const setAndVerify = {
    setPIN: t("setPINCode"),
    verify: t("verifyPINCode"),
  };
  const navigate = useNavigate();
  useEffect(() => {
    setLink(PATH_LIST.termsAndConditions);

    return () => setLink("");
  }, []);

  const onRegister = async (pinCode: string) => {
    setLoading(true);
    const resp = await sircapApi.onboarding.pinCode({
      code: pinCode,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.accountHasBeenCreated);
    }
    setLoading(false);
  };

  const handleOnChange = useCallback(
    (newValue: string) => {
      setError(false);
      if (newValue.length === 4 && step == "setPIN") {
        AuthInputRef.current?.clear();
        setResult(newValue);
        setStep("verify");
      } else if (newValue.length === 4 && step == "verify") {
        setPin(newValue);
        if (result !== newValue) {
          setError(true);
        } else {
          onRegister(newValue);
        }
      }
    },
    [step, result, pin]
  );
  const isFilled = (step == "setPIN" ? result : pin).length === 4;

  return (
    <div className="pin-code-page">
      <GoBackButton link={PATH_LIST.termsAndConditions} />
      <p>{setAndVerify[step as keyof typeof setAndVerify]}</p>
      <span>{t("pinCodeInfo")}</span>
      <AuthCode
        disabled={isLoading}
        ref={AuthInputRef}
        allowedCharacters="numeric"
        length={4}
        onChange={handleOnChange}
        inputClassName={error ? "red" : isFilled ? "green" : ""}
      />
      {error && (
        <span className="error-verify error-verify-pin">
          {step == "verify" ? y("pinDontMatch") : y("incorrectPIN")}
        </span>
      )}
    </div>
  );
};
