import { useRef, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useTranslation } from "react-i18next";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { showNotification } from "helpers/functions";
import { useStore } from "zustand";
import { accountStore } from "entities/account";
import RestoreByPasswordModal from "widgets/auth/restore-by-password-modal";

export const ChangePINPage = () => {
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const { t: y } = useTranslation("yup");
  const [currentPin, setCurrentPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [result, setResult] = useState("");
  const [resetPinModal, setResetPinModal] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [step, setStep] = useState("prev");
  const { isLoading, changePinCode } = useStore(accountStore);
  const { t: err } = useTranslation("errors");
  const { t: o } = useTranslation("onboarding-auth");
  const { t } = useTranslation("app");

  const ChangeSteps = {
    prev: t("enterCurrentPin"),
    new: t("enterNewPIN"),
    confirm: t("confirmNewPIN"),
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.security);
  };

  const onRequest = async () => {
    changePinCode({ pinCode: currentPin, newPinCode: newPin }, err, () => {
      navigate(PATH_LIST.account);
      showNotification(t("PINChanged"));
    });
  };

  const handleOnChange = (newValue: string) => {
    setError(false);

    if (newValue.length === 4 && step == "prev") {
      AuthInputRef.current?.clear();
      setCurrentPin(newValue);
      setStep("new");
    } else if (newValue.length === 4 && step == "new") {
      AuthInputRef.current?.clear();
      setNewPin(newValue);
      setStep("confirm");
    } else if (newValue.length === 4 && step == "confirm") {
      setResult(newValue);
      if (newValue != newPin) {
        setError(true);
      } else {
        onRequest();
      }
    }
  };
  const isFilled = result.length === 4;

  return (
    <div className="change-PIN">
      <div className="change-PIN-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("changePinCode")}</p>
      </div>
      <div className="change-PIN-main">
        <p>{ChangeSteps[step as keyof typeof ChangeSteps]}</p>
        <AuthCode
          disabled={isLoading}
          ref={AuthInputRef}
          allowedCharacters="numeric"
          length={4}
          onChange={handleOnChange}
          inputClassName={error ? "red" : isFilled ? "green" : ""}
        />
        <div className="forgot-password">
          <span onClick={() => setResetPinModal(true)}>
            {o("forgotYourPIN")}
          </span>
        </div>

        {resetPinModal && (
          <RestoreByPasswordModal
            title={o("forgotYourPIN")}
            text={o("restorePINByPassword")}
            btnText={o("submit")}
            onClick={() => {}}
            onHide={() => setResetPinModal(false)}
          />
        )}
      </div>
    </div>
  );
};

//  todo ADD VALIDATION
//  {step == "verify" ? y("pinDontMatch") : y("incorrectPIN")}
