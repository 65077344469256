import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { useTranslation } from "react-i18next";
import { showNotification } from "helpers/functions";
import { accountStore } from "entities/account";
import { ResponseCodes } from "shared/api/sircap";

export const VerifyChangedEmailPage = () => {
  const [timer, setTimer] = useState(59);
  const [resend, setResend] = useState(false);
  const navigate = useNavigate();
  const {
    requestedEmail: email,
    requestChangeEmail,
    confirmChangeEmail,
  } = useStore(accountStore);
  const [error, setError] = useState<boolean>(false);
  const { i18n, t } = useTranslation("onboarding-auth");
  const { t: y } = useTranslation("yup");
  const { t: err } = useTranslation("errors");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(PATH_LIST.registerEmail);

    return () => setLink("");
  }, []);

  useEffect(() => {
    if (!resend && timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else if (!resend && timer === 0) {
      setResend(true);
    }
  }, [timer, resend]);

  const handleResendClick = () => {
    if (isLoading) return;
    requestChangeEmail(
      {
        email: email,
        language: i18n.language,
      },
      err,
      () => {
        setResend(false);
        setTimer(59);
      }
    );
  };

  const AuthInputRef = useRef<AuthCodeRef>(null);
  const [result, setResult] = useState<string>("");
  const handleOnChange = (newValue: string) => {
    setResult(newValue);

    if (newValue.length === 4) {
      onVerify(+newValue);
    }
  };
  const isFilled = result.length === 4;

  const handleNavigateBack = () => {
    if (isLoading) return;
    navigate(PATH_LIST.changeEmail);
  };

  const onVerify = (code: number) => {
    setIsLoading(true);
    confirmChangeEmail(
      { code },
      err,
      () => {
        setIsLoading(false);
        navigate(PATH_LIST.account, { state: { refreshUser: true } });
        showNotification(t("phoneNumberChanged"));
      },
      () => {
        setIsLoading(false);
        setError(true);
      }
    );
  };

  return (
    <div className="enterTheCodeAccount">
      <div className="enterTheCodeAccount-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigateBack}
          src={GoBack}
          alt="Go Back"
        />
      </div>
      <p>{t("youveGotMail")}</p>
      <span>{t("otpSentToEmail")}</span>

      <AuthCode
        disabled={isLoading}
        allowedCharacters="numeric"
        length={4}
        onChange={handleOnChange}
        ref={AuthInputRef}
        inputClassName={error ? "red" : isFilled ? "green" : ""}
      />
      {error && (
        <span className="error-verify error-changed">{y("codeInvalid")}</span>
      )}

      {!resend ? (
        <span className=" codeTextRe sendCodeAgain">
          {t("sendCodeAgain")}...{timer}{" "}
        </span>
      ) : (
        <span onClick={handleResendClick} className="codeTextRe resendCode">
          {t("resendCode")}
        </span>
      )}

      <span className="editYourNumber" onClick={handleNavigateBack}>
        {t("editEmail")}
      </span>
    </div>
  );
};
