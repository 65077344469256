import React, { useEffect, useState } from "react";
import "./style.scss";
import Pending from "../../../../assets/Pending.svg";
import Successful from "../../../../assets/Successful.svg";
import Rejected from "../../../../assets/Rejected.svg";
import AssetIcon from "../asset-icon";
import { getCurrSymbol } from "helpers/functions";
import { AssetType, OperationStatus } from "static/types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IAsset, marketStore } from "entities/market";
import RemoveTransaction from "../../../../assets/RemoveTransaction.svg";
import { useStore } from "zustand";
import { accountStore } from "entities/account";

interface IActivityType {
  id: number;
  t: "payment" | "deposit" | "withdrawal";
  type: "payment" | "deposit" | "withdrawal" | "commission";
  status: number;
  amount: number;
  currency: string;
  createdAt: string;
  comment: string;
  asset: {
    symbol: string;
  };
}

type ActivitiesProps = {
  activity: IActivityType;
  onTrigger: any;
};

const statusTitles = [
  "Pending",
  "Rejected",
  "Error",
  "Successful",
  "Cancellation",
  "Cancelled",
];

enum SendStatus {
  Processed = 1,
  Reject = 2,
  Error = 3,
  Send = 4,
  CancellationRequest = 5,
  Cancelled = 6,
}

export const Activity: React.FC<ActivitiesProps> = ({
  activity,
  onTrigger,
}) => {
  const { t: a } = useTranslation("app");

  const PaymentTypes: Record<string, (as_symbol: string) => string> = {
    // const PaymentTypes: any = {
    dividend: (as_symbol: string) => `${a("dividendPayment")} (${as_symbol})`,
    coupon: (as_symbol: string) => `${a("couponPayment")} (${as_symbol})`,
    nominal: (as_symbol: string) => `${a("bondRedemption")} (${as_symbol})`,
    deposit: (as_symbol: string) => `${a("deposit")}`,
    withdrawal: (as_symbol: string) => `${a("withdrawal")}`,
  };

  const { id, asset, t, type, amount, currency, status, createdAt, comment } =
    activity;

  // @ts-ignore
  const symbol = t == "payment" ? currency?.symbol : getCurrSymbol(currency);

  const { isLoading, cancelWithdrawal } = useStore(accountStore);

  const cancel = () => {
    cancelWithdrawal(id, onTrigger);
  };

  return (
    <div className="activities-row">
      <div className="price-and-activity">
        {/* <p>
          {["deposit", "payment"].includes(t) ? "+" : "-"}
          {+amount.toFixed(2)}
          {symbol}
        </p> */}
        <p>
          {["deposit", "payment"].includes(t) ? "+" : "-"}
          {amount !== undefined ? (+amount).toFixed(2) : "0.00"}
          {symbol}
        </p>
        <span>
          {["payment", "deposit", "withdrawal"].includes(t)
            ? PaymentTypes[type]
              ? PaymentTypes[type](asset?.symbol || "")
              : comment
            : comment}{" "}
          {a("at")} {moment(createdAt).format("DD.MM.YYYY HH:mm")}
        </span>
      </div>
      <div className="status">
        {!status ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <p className={statusTitles[SendStatus.Send - 1]}>
              {a(statusTitles[SendStatus.Send - 1])}
            </p>
            <img src={Successful} alt={statusTitles[SendStatus.Send - 1]} />
          </div>
        ) : (
          <>
            <div className="status-field">
              <p className={statusTitles[status - 1]}>
                {a(statusTitles[status - 1])}
              </p>
              <img
                src={
                  status === SendStatus.Processed ||
                  status === SendStatus.CancellationRequest
                    ? Pending
                    : status === SendStatus.Send
                    ? Successful
                    : Rejected
                }
                alt={statusTitles[status - 1]}
              />
            </div>
            {status === SendStatus.Processed && (
              <button
                onClick={cancel}
                disabled={isLoading}
                className="cancel-pending-deal"
              >
                <span>{a("cancel")}</span>
                <img src={RemoveTransaction} alt="RemoveTransaction" />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Transactions Component -------------------------------------------------

interface IDealType {
  id: number;
  operation: "buy" | "sell";
  status: number;
  commission: number;
  commission_completed: number;
  quantity: number;
  quantity_complited: number;
  price: number;
  asset: IAsset;
  currency: {
    symbol: string;
    code: string;
  };
  order_open_time: string;
  order_close_time: string;
}

type TransactionsProps = {
  deal: IDealType;
};

const isSuccessStatus = (status: number) => {
  return [
    OperationStatus.Completed,
    OperationStatus.PartiallyCompleted,
  ].includes(status);
};

const isPendingStatus = (status: number) => {
  return [
    OperationStatus.Actively,
    OperationStatus.OnPerformance,
    OperationStatus.CancellationRequest,
  ].includes(status);
};

export const Transaction: React.FC<TransactionsProps> = ({ deal }) => {
  const { t } = useTranslation("app");
  const {
    operation,
    status,
    commission,
    commission_completed,
    quantity,
    quantity_complited,
    price,
    currency,
    asset,
  } = deal;

  const { isLoading, cancelDeal } = useStore(marketStore);

  const getTotalAmount = (): number => {
    if (isSuccessStatus(status)) {
      let total = price * quantity_complited;
      if (operation === "buy") {
        // total = total + Math.abs(completed_commission);
      } else {
        // total = total - Math.abs(completed_commission);
      }
      return +total.toFixed(2);
    } else {
      let total = price * quantity;
      if (operation === "buy") {
        // total = total + Math.abs(commission);
      } else {
        // total = total - Math.abs(commission);
      }
      return +total.toFixed(2);
    }
  };
  // const getTotalAmount = (): number => { // Explicitly stated return type
  //   const total = isSuccessStatus(status)
  //     ? price * quantity_complited
  //     : price * quantity;
  //   return +total.toFixed(2);
  // };

  const getTotalCommission = (): number => {
    // if (isSuccessStatus(status)) {
    //   return +Math.abs(completed_commission).toFixed(2);
    // } else {
    //   return +Math.abs(commission).toFixed(2);
    // }
    return +Math.abs(commission_completed).toFixed(2);
  };

  return (
    <div className="transactions-row">
      <div className="transaction-asset-info">
        <AssetIcon asset={asset} />
        <div
          className="name-type"
          style={{ gap: status === SendStatus.Processed ? "8px" : "0" }}
        >
          <p>{asset.symbol}</p>
          <span>
            {+deal.quantity_complited.toFixed(2)} <b>•</b>{" "}
            {+deal.price.toFixed(2)}
            {currency.symbol} <br />
            {t(`${operation}H`)} {t("at")}{" "}
            {moment(deal.order_close_time || deal.order_open_time).format(
              "DD.MM.YY HH:mm"
            )}
          </span>
        </div>
      </div>

      {isPendingStatus(status) ? (
        <div className="price-percent-status">
          <div style={{ gap: "0px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className={
                  isPendingStatus(status)
                    ? "price-pending"
                    : !isSuccessStatus(status)
                    ? "price-rejected"
                    : undefined
                }
              >
                {getTotalAmount()} {currency.symbol}
              </p>
              <img
                src={
                  isPendingStatus(status)
                    ? Pending
                    : isSuccessStatus(status)
                    ? Successful
                    : Rejected
                }
                alt={String(status)}
              />
            </div>
            <span style={{ marginRight: "4px" }}>
              {t("commission").toLowerCase()}: {getTotalCommission()}{" "}
              {currency.symbol}
            </span>
            {[OperationStatus.Actively, OperationStatus.OnPerformance].includes(
              status
            ) ? (
              <button
                onClick={() => cancelDeal(deal.id)}
                disabled={isLoading}
                className="cancel-pending-deal"
                style={{ marginTop: "8px", opacity: isLoading ? 0.5 : 1 }}
              >
                <span>{t("cancel")}</span>
                <img src={RemoveTransaction} alt="RemoveTransaction" />
              </button>
            ) : (
              <span style={{ color: "rgba(255, 165, 0, 1)" }}>
                {t("Cancellation")}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className="price-percent-status">
          <div>
            <p
              className={
                isPendingStatus(status)
                  ? "price-pending"
                  : !isSuccessStatus(status)
                  ? "price-rejected"
                  : undefined
              }
            >
              {getTotalAmount()} {currency.symbol}
            </p>
            <span>
              {t("commission").toLowerCase()}: <br />
              {getTotalCommission()} {currency.symbol}
            </span>
          </div>
          <img
            src={
              isPendingStatus(status)
                ? Pending
                : isSuccessStatus(status)
                ? Successful
                : Rejected
            }
            alt={String(status)}
          />
        </div>
      )}
    </div>
  );
};
