import React, { FC } from "react";
import "./style.scss";
import { useUnreadMessagesCount } from "services/general.socket";

export enum NotificationType {}

type PropsType = {
  type: string;
  navIcon?: boolean;
};
const NotificationIcon: FC<PropsType> = ({ type, navIcon = false }) => {
  const count = useUnreadMessagesCount(type);
  if (!count) return <></>;

  return (
    <div className={navIcon ? "notification-nav-icon" : "notification-icon"}>
      <p>{count > 9 ? "9+" : count}</p>
    </div>
  );
};

export default NotificationIcon;
