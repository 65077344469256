import React, { PropsWithChildren, useState } from "react";
import { ChatMessageType } from "./types";
import SupportIcon from "../../../../assets/SupportIcon.svg";
import AiAdviserIcon from "../../../../assets/AiAdviserIcon.svg";
import { getChatFileUrl } from "helpers/functions";

export enum MessageType {
  Text = "text",
  Voice = "voice",
  Image = "image",
  Video = "video",
  File = "file",

  Typing = "typing",
}

type PropsType = {
  message: {
    id: number;
    room_id: number;
    sender: string;
    customer: string;
    type: MessageType;
    text: string;
    file: string | null;
    createdAt: string;
    isLast: boolean;
    onImgClick?: (file: string, date: any) => void;
    onVideoClick?: (file: string, date: any) => void;
  };
};

export const ChatMessageWrapper: React.FC<
  PropsWithChildren<{
    id: number;
    sender: string;
    className: string;
    style: any;
    type: "support" | "gpt";
  }>
> = ({ id, sender, children, className, style, type }) => {
  return (
    <div className={className} style={style} key={id}>
      {(sender === "admin" || sender === "gpt") && (
        <img
          className="support-icon"
          src={type === "support" ? SupportIcon : AiAdviserIcon}
          alt="Icon"
        />
      )}
      {children}
    </div>
  );
};

const ChatMessage: React.FC<PropsType> = (props) => {
  const { message } = props;

  const handleImgClick = () => {
    if (message.onImgClick) {
      message.onImgClick(
        getChatFileUrl(message.room_id, message.file as string),
        message.createdAt
      );
    }
  };
  const handleVideoClick = () => {
    if (message.onVideoClick) {
      message.onVideoClick(
        getChatFileUrl(message.room_id, message.file as string),
        message.createdAt
      );
    }
  };

  switch (message.type) {
    case MessageType.Text:
      return <ChatMessageType.ChatTextMessage {...message} />;
    case MessageType.Voice:
      return (
        <ChatMessageType.ChatVoiceMessage
          {...message}
          src={getChatFileUrl(message.room_id, message.file as string)}
        />
      );
    case MessageType.Image:
      return (
        <ChatMessageType.ChatImageMessage
          onClick={handleImgClick}
          {...message}
          file={getChatFileUrl(message.room_id, message.file as string)}
        />
      );
    case MessageType.Video:
      return (
        <ChatMessageType.ChatVideoMessage
          onClick={handleVideoClick}
          videoUrl={getChatFileUrl(message.room_id, message.file as string)}
          {...message}
        />
      );
    case MessageType.File:
      return (
        <ChatMessageType.ChatFileMessage
          {...message}
          file={getChatFileUrl(message.room_id, message.file as string)}
        />
      );
    case MessageType.Typing:
      return <ChatMessageType.ChatTypingTextMessage {...message} />;
    default:
      return <></>;
  }
};

export default ChatMessage;
