import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import Swap from "../../../../assets/Swap.svg";
import { EditRatesDropdown } from "../edit-rates-dropdown";
import { useRatesCurrencies } from "entities/dashboard";

type PropType = {
  handleFirstDropdown: any;
  handleSecondDropdown: any;
  dropdownFF: boolean;
  dropdownFS: boolean;
  priceAsset: string;
  tradeAsset: string;
  reset: () => void;
  onChange: (priceAsset: string, tradeAsset: string) => void;
};

export const EditRatesCard: FC<PropType> = ({
  handleFirstDropdown,
  handleSecondDropdown,
  dropdownFF,
  dropdownFS,
  priceAsset,
  tradeAsset,
  onChange,
  reset,
}) => {
  const currencies = useRatesCurrencies();

  return (
    <div className="edit-rates-card">
      <EditRatesDropdown
        dropdownFF={dropdownFF}
        openDropdown={handleFirstDropdown}
        onChange={(code: string) => {
          onChange(priceAsset, code);
          reset();
        }}
        currency={tradeAsset}
        currencies={currencies.filter((v) => v.code !== priceAsset)}
      />
      <img
        onClick={() => {
          onChange(tradeAsset, priceAsset);
          reset();
        }}
        className="swap"
        src={Swap}
        alt="Swap"
      />
      <EditRatesDropdown
        dropdownFS={dropdownFS}
        openDropdown={handleSecondDropdown}
        onChange={(code: string) => {
          onChange(code, tradeAsset);
          reset();
        }}
        currency={priceAsset}
        currencies={currencies.filter((v) => v.code !== tradeAsset)}
      />
    </div>
  );
};
