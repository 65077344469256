import { onboardModel } from "entities/onboard";
import { sessionModel } from "entities/session";
import { PropsWithChildren, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { sircapApi } from "shared/api/sircap";
import { PATH_LIST } from "shared/lib/react-router";
import Loading from "widgets/loading";
import { useStore } from "zustand";

export function OnboardGuard(props: PropsWithChildren) {
  const { addUser, accessToken, refreshToken } = useStore(
    sessionModel.sessionStore
  );
  const { syncUser } = useStore(onboardModel.onboardStore);

  const [isLogined, setIsLogined] = useState<boolean | null>(null);

  useEffect(() => {
    if (!accessToken) return;
    try {
      (async () => {
        sircapApi.setSecurityData(refreshToken);
        const resp = await sircapApi.auth.token();
        if (resp.error) setIsLogined(false);
        else {
          const { user, accessToken, refreshToken, entity } = resp.data.data;
          syncUser(user, entity);
          addUser(user, accessToken, refreshToken, entity);
          setIsLogined(true);
        }
      })();
    } catch (error) {
      setIsLogined(false);
    }
  }, []);

  if (!accessToken) return <Navigate to={PATH_LIST.chooseAccType} />;
  if (isLogined === null) return <Loading />;
  if (!isLogined) return <Navigate to={PATH_LIST.chooseAccType} />;

  return <>{props.children}</>;
}
