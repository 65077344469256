import React, { useEffect, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import { sessionModel } from "entities/session";
import TermsCheckbox from "../components/terms-checkbox";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { RegisterEnum, onboardModel } from "entities/onboard";
import { toast } from "react-toastify";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";

export const TermsAndConditionsPage = () => {
  const [checked, setChecked] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const navigate = useNavigate();
  const registerType = onboardModel.useRegisterType();
  const { syncUser } = useStore(onboardModel.onboardStore);
  const { t } = useTranslation("onboarding-auth");

  const { setLink, addUser } = useStore(sessionModel.sessionStore);
  useEffect(() => {
    setLink(
      registerType === RegisterEnum.Individual
        ? PATH_LIST.personalAddress
        : PATH_LIST.signContract
    );

    return () => setLink("");
  }, []);

  const onRegister = async () => {
    const resp = await sircapApi.onboarding.checkAgree({
      subscribe,
      entity: registerType,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const { user, accessToken, refreshToken, entity } = resp.data.data;
      syncUser(user, entity);
      addUser(null, accessToken, refreshToken, entity);

      navigate(PATH_LIST.pinCode);
    }
  };

  return (
    <div className="termsAndConditions">
      <GoBackButton
        link={
          registerType === RegisterEnum.Individual
            ? PATH_LIST.personalAddress
            : PATH_LIST.signContract
        }
      />
      <p>{t("consentToTerms")}</p>

      <div className="termsCheckboxCard">
        <TermsCheckbox
          term={t("consentToProcessingData")}
          PrivacyPolicy={t("privacyPolicy")}
          htmlForId="1"
          isChecked={checked}
          onChange={() => setChecked(!checked)}
        />
        <TermsCheckbox
          term={t("subscribeToNewsletter")}
          htmlForId="2"
          isChecked={subscribe}
          onChange={() => setSubscribe(!subscribe)}
        />
      </div>

      <Button
        type="submit"
        onClick={onRegister}
        className="registerNameBtn"
        variant={"dark"}
        disabled={!checked}
      >
        {t("continue")}
      </Button>
    </div>
  );
};
