import React, { FC } from "react";
import "./style.scss";

type PropType = {
  type?: "light" | "dark";
  action: string;
  price?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const ActionBtn: FC<PropType> = ({
  type = "light",
  action,
  price,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`action-btn action-btn-${type}`}
    >
      <p>{action}</p>
      <span>{price}</span>
    </button>
  );
};
