import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { AppGuard, OnboardGuard, sessionModel } from "entities/session";
import { AuthLayout, MainLayout, OnBoardingLayout } from "pages/layouts";
import { PATH_LIST, PATH_PAGE } from "shared/lib/react-router";
import { Loadable } from "shared/ui/loadable";
import {
  ForgotPasswordPage,
  ForgotPasswordVerifyPage,
  LoginPage,
  PasswordLoginPage,
} from "pages/auth";
import {
  AccountHasBeenCreatedPage,
  ChooseAccTypePage,
  ChooseDocTypePage,
  CreatePasswordPage,
  HopeToAchievePage,
  InvestingExperiencePage,
  InvestingWithSavingsPage,
  PINCodePage,
  PersonalAddressPage,
  ProofOfAddressPage,
  RegisterBirthdayPage,
  RegisterCitizenshipPage,
  RegisterEmailPage,
  RegisterGenderPage,
  RegisterNamePage,
  RegisterPhonePage,
  SelfieWithDocumentPage,
  TermsAndConditionsPage,
  UtilityBillPhotoPage,
  VerifyEmailPage,
  VerifyPhonePage,
  WhatDoYouDoForALivingPage,
} from "pages/onboarding";
import { SignContractPage } from "pages/onboarding/sign-contract";
import { AppLayout } from "pages/layouts/app-layout";
import {
  AccountPage,
  AssetSearchPage,
  BondDetailsPage,
  BrokerageReportPage,
  BuyPage,
  ChangeEmailPage,
  ChangePINPage,
  ChangePasswordPage,
  ChangePhonePage,
  ChatPage,
  CreateBondFilterPage,
  CreateStockFilterPage,
  CurrencyDetailsPage,
  DepositPage,
  EditRatesPage,
  FilterPage,
  FilterReviewPage,
  HistoryPage,
  HistorySearchPage,
  HomePage,
  InvestPage,
  MarketPage,
  PersonalInfoPage,
  PortfolioPage,
  SecurityPage,
  SellPage,
  StockDetailsPage,
  StrategyPage,
  SupportChatPage,
  TariffsPage,
  TaxReportPage,
  VerifyChangedEmailPage,
  VerifyChangedPhonePage,
  WhatToBuyPage,
  WithdrawalPage,
} from "pages/app";
import { SignUserContractPage } from "pages/app/sign-contract";
import { AIChatPage } from "pages/app/chat-ai";

const Page404 = Loadable(lazy(() => import("pages/page-404")));

const wrapOnboardGuard = (Page: any) => (
  <OnboardGuard>
    <Page />
  </OnboardGuard>
);

const wrapAppGuard = (Page: any) => (
  <AppGuard>
    <Page />
  </AppGuard>
);

const devMode = process.env.REACT_APP_DEV_MODE === "true";

export function Router() {
  const isAuth = sessionModel.useAuth();

  return useRoutes([
    {
      element: <MainLayout />,
      children: [
        {
          path: PATH_PAGE.auth.root,
          element: <AuthLayout />,
          children: [
            {
              path: PATH_PAGE.auth.login.root,
              children: [
                {
                  path: "",
                  element: <LoginPage />,
                },
                {
                  path: PATH_PAGE.auth.login.password,
                  element: <PasswordLoginPage />,
                },
                {
                  path: PATH_PAGE.auth.login.forgotPassword,
                  element: <ForgotPasswordPage />,
                },
                {
                  path: PATH_PAGE.auth.login.forgotPasswordVerify,
                  element: <ForgotPasswordVerifyPage />,
                },
              ],
            },
          ],
        },
        {
          path: PATH_PAGE.onboarding.root,
          element: <OnBoardingLayout />,
          children: [
            {
              path: PATH_PAGE.onboarding.chooseAccType,
              element: <ChooseAccTypePage />,
            },
            {
              path: PATH_PAGE.onboarding.registerPhone,
              element: <RegisterPhonePage />,
            },
            {
              path: PATH_PAGE.onboarding.verifyPhone,
              element: <VerifyPhonePage />,
            },
            {
              path: PATH_PAGE.onboarding.registerEmail,
              element: wrapOnboardGuard(RegisterEmailPage),
            },
            {
              path: PATH_PAGE.onboarding.verifyEmail,
              element: wrapOnboardGuard(VerifyEmailPage),
            },
            {
              path: PATH_PAGE.onboarding.registerName,
              element: wrapOnboardGuard(RegisterNamePage),
            },
            {
              path: PATH_PAGE.onboarding.createPassword,
              element: wrapOnboardGuard(CreatePasswordPage),
            },
            {
              path: PATH_PAGE.onboarding.registerGender,
              element: wrapOnboardGuard(RegisterGenderPage),
            },
            {
              path: PATH_PAGE.onboarding.registerBirthday,
              element: wrapOnboardGuard(RegisterBirthdayPage),
            },
            {
              path: PATH_PAGE.onboarding.registerCitizenship,
              element: wrapOnboardGuard(RegisterCitizenshipPage),
            },

            {
              path: PATH_PAGE.onboarding.hopeToAchieve,
              element: wrapOnboardGuard(HopeToAchievePage),
            },
            {
              path: PATH_PAGE.onboarding.investingExperience,
              element: wrapOnboardGuard(InvestingExperiencePage),
            },
            {
              path: PATH_PAGE.onboarding.whatDoYouDoForALiving,
              element: wrapOnboardGuard(WhatDoYouDoForALivingPage),
            },
            {
              path: PATH_PAGE.onboarding.investingWithSavings,
              element: wrapOnboardGuard(InvestingWithSavingsPage),
            },
            {
              path: PATH_PAGE.onboarding.chooseDocType,
              element: wrapOnboardGuard(ChooseDocTypePage),
            },
            {
              path: PATH_PAGE.onboarding.selfieWithDocumentPage,
              element: wrapOnboardGuard(SelfieWithDocumentPage),
            },
            {
              path: PATH_PAGE.onboarding.proofOfAddressAddressPhotoPage,
              element: wrapOnboardGuard(ProofOfAddressPage),
            },
            {
              path: PATH_PAGE.onboarding.utilityBillPhotoPage,
              element: wrapOnboardGuard(UtilityBillPhotoPage),
            },
            {
              path: PATH_PAGE.onboarding.personalAddress,
              element: wrapOnboardGuard(PersonalAddressPage),
            },
            {
              path: PATH_PAGE.onboarding.termsAndConditions,
              element: wrapOnboardGuard(TermsAndConditionsPage),
            },
            {
              path: PATH_PAGE.onboarding.pinCode,
              element: wrapOnboardGuard(PINCodePage),
            },
            {
              path: PATH_PAGE.onboarding.accountHasBeenCreated,
              element: wrapOnboardGuard(AccountHasBeenCreatedPage),
            },
            {
              path: PATH_PAGE.onboarding.signContract,
              element: wrapOnboardGuard(SignContractPage),
            },
          ],
        },
      ],
    },
    {
      path: "",
      element: (
        <Navigate to={true ? PATH_LIST.home : PATH_LIST.login} replace />
      ),
    },
    {
      element: wrapAppGuard(AppLayout),
      path: PATH_PAGE.app.root,
      children: [
        {
          path: PATH_PAGE.app.home.root,
          children: [
            {
              element: <HomePage />,
              path: "",
            },
            {
              element: <EditRatesPage />,
              path: PATH_PAGE.app.home.editRates,
            },
            {
              element: <PortfolioPage />,
              path: PATH_PAGE.app.home.portfolio,
            },
          ],
        },
        {
          path: PATH_PAGE.app.whatToBuy.root,
          children: [
            {
              element: <WhatToBuyPage />,
              path: "",
            },
            // {
            //   element: <StrategyPage />,
            //   path: PATH_PAGE.app.whatToBuy.strategy,
            // },
            // {
            //   element: <InvestPage />,
            //   path: PATH_PAGE.app.whatToBuy.invest,
            // },
          ],
        },
        {
          element: <StrategyPage />,
          path: PATH_PAGE.app.strategy,
        },
        {
          element: <InvestPage />,
          path: PATH_PAGE.app.invest,
        },
        {
          path: PATH_PAGE.app.market.root,
          children: [
            {
              element: <MarketPage />,
              path: "",
            },
            {
              element: <AssetSearchPage />,
              path: PATH_PAGE.app.market.search,
            },
            {
              element: <FilterPage />,
              path: PATH_PAGE.app.market.filter.root,
            },
            {
              element: <CreateStockFilterPage />,
              path: PATH_PAGE.app.market.filter.createStockFilter,
            },
            {
              element: <CreateBondFilterPage />,
              path: PATH_PAGE.app.market.filter.createBondFilter,
            },
            {
              element: <FilterReviewPage />,
              path: PATH_PAGE.app.market.filter.filterReview,
            },
            {
              element: <StockDetailsPage />,
              path: PATH_PAGE.app.market.stock,
            },
            // {
            //   element: <StrategyPage />,
            //   path: PATH_PAGE.app.market.strategy,
            // },
            {
              element: <BondDetailsPage />,
              path: PATH_PAGE.app.market.bond,
            },
            {
              element: <CurrencyDetailsPage />,
              path: PATH_PAGE.app.market.currency,
            },
            {
              element: <BuyPage />,
              path: PATH_PAGE.app.market.buy,
            },
            {
              element: <SellPage />,
              path: PATH_PAGE.app.market.sell,
            },
          ],
        },
        {
          path: PATH_PAGE.app.chat.root,
          children: [
            {
              element: <ChatPage />,
              path: "",
            },
            {
              element: <SupportChatPage />,
              path: PATH_PAGE.app.chat.support,
            },
            {
              element: <AIChatPage />,
              path: PATH_PAGE.app.chat.ai,
            },
          ],
        },
        {
          path: PATH_PAGE.app.account.root,
          children: [
            {
              element: <AccountPage />,
              path: "",
            },
            {
              element: <PersonalInfoPage />,
              path: PATH_PAGE.app.account.personal.root,
            },
            {
              element: <SignUserContractPage />,
              path: PATH_PAGE.app.account.signContract,
            },
            {
              element: <ChangeEmailPage />,
              path: PATH_PAGE.app.account.personal.changeEmail,
            },
            {
              element: <VerifyChangedEmailPage />,
              path: PATH_PAGE.app.account.personal.verifyChangedEmail,
            },
            {
              element: <ChangePhonePage />,
              path: PATH_PAGE.app.account.personal.changePhone,
            },
            {
              element: <VerifyChangedPhonePage />,
              path: PATH_PAGE.app.account.personal.verifyChangedPhone,
            },
            {
              element: <SecurityPage />,
              path: PATH_PAGE.app.account.security.root,
            },
            {
              element: <ChangePasswordPage />,
              path: PATH_PAGE.app.account.security.changePassword,
            },
            {
              element: <ChangePINPage />,
              path: PATH_PAGE.app.account.security.changePIN,
            },
            {
              element: <TariffsPage />,
              path: PATH_PAGE.app.account.tariffs,
            },
            {
              element: <DepositPage />,
              path: PATH_PAGE.app.account.deposit,
            },
            {
              element: <WithdrawalPage />,
              path: PATH_PAGE.app.account.withdrawal,
            },
            {
              element: <HistoryPage />,
              path: PATH_PAGE.app.account.history.root,
            },
            {
              element: <HistorySearchPage />,
              path: PATH_PAGE.app.account.history.historySearch,
            },
            {
              element: <BrokerageReportPage />,
              path: PATH_PAGE.app.account.brokerageReport,
            },
            {
              element: <TaxReportPage />,
              path: PATH_PAGE.app.account.taxReport,
            },
          ],
        },
      ],
    },
    { path: "404", element: <Page404 /> },
    { path: "*", element: <Navigate to={PATH_LIST.login} replace /> },
  ]);
}
