import AMD from "../assets/currencies/AMD.svg";
import USD from "../assets/currencies/USD.svg";
import EUR from "../assets/currencies/EUR.svg";
import RUB from "../assets/currencies/RUB.svg";
import AED from "../assets/currencies/AED.svg";
import CNY from "../assets/currencies/CNY.svg";
import BYN from "../assets/currencies/BYN.svg";
import ZAR from "../assets/currencies/ZAR.svg";
import KZT from "../assets/currencies/KZT.svg";
import GBP from "../assets/currencies/GBP.svg";

export const CURRENCY_FLAGS: Record<string, string> = {
  AMD,
  USD,
  EUR,
  RUB,
  AED,
  CNY,
  BYN,
  ZAR,
  KZT,
  GBP,
};

export const G10_CURRENCIES = [
  "USD",
  "EUR",
  "GBP",
  "JPY",
  "AUD",
  "NZD",
  "CAD",
  "CHF",
  "NOK",
  "SEK",
];
