import React, { useState } from "react";
import clipboardCopy from "clipboard-copy";
import "./style.scss";
import CopyIcon from "../../../../assets/CopyIcon.svg";
import CheckIcon from "../../../../assets/icon-check.svg";

const ReplenishmentDetails: React.FC<{
  nameOfDetail: string;
  detail: string;
}> = ({ nameOfDetail, detail }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await clipboardCopy(detail);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1300);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="replenishment-details">
      <p>{nameOfDetail}</p>
      <div className="details-row">
        <span>{detail}</span>
        <button onClick={handleCopyClick}>
          {isCopied == true ? (
            <img className="check-img" src={CheckIcon} alt="check-img" />
          ) : (
            <img src={CopyIcon} alt="Copy to Clipboard Icon" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ReplenishmentDetails;
