import React, { FC } from "react";
import "./style.scss";
import ReactDatePicker from "react-datepicker";
import Calendar from "../../../../assets/Calendar.svg";
import { useTranslation } from "react-i18next";

type PropsType = {
  deadline: "Start" | "End" | "Year";
  selected: any;
  onChange: any;
  report?: boolean;
  withPortal?: boolean;
};

const DatePicker: FC<PropsType> = ({
  deadline,
  selected,
  onChange,
  report = false,
  withPortal = false,
}) => {
  const { t } = useTranslation("app");
  
  if (report == true) {
    return (
      <div className="report-date-picker-container">
        <span>
          {deadline} {deadline !== "Year" && t("date")}
        </span>
        <ReactDatePicker
          calendarClassName={deadline === "Year" ? "calendar-year" : undefined}
          selected={selected}
          className="date-picker"
          showYearPicker={deadline === "Year"}
          dateFormat={deadline === "Year" ? "yyyy" : "yyyy.MM.dd"}
          //@ts-ignore
          onChange={onChange}
          showIcon
          //@ts-ignore
          icon={<img src={Calendar} alt="icon" />}
          withPortal={withPortal}
        />
      </div>
    );
  } else {
    return (
      <div className="date-picker-container">
        <span>{deadline} {t("date")}</span>
        <ReactDatePicker
          selected={selected}
          className="date-picker"
          //@ts-ignore
          onChange={onChange}
          showIcon
          //@ts-ignore
          icon={<img src={Calendar} alt="icon" />}
          // withPortal
        />
      </div>
    );
  }
  return <></>;
};

export default DatePicker;
