import React, { FC, useState } from "react";
import Close from "../../../../assets/Close.svg";
import "./style.scss";
import CheckIcon from "../../../../assets/icon-check.svg";
import { useTranslation } from "react-i18next";

type PropTypeRow = {
  onRowClick: () => void;
  isChecked: boolean;
  title: string;
  info: string;
};

export const PriceTypeRow: FC<PropTypeRow> = ({
  onRowClick,
  isChecked,
  title,
  info,
}) => {
  return (
    <div
      onClick={() => {
        onRowClick();
      }}
      className={`price-type-row ${isChecked ? "checked" : ""}`}
    >
      <div>
        <p>{title}</p>
        <span>{info}</span>
      </div>

      {isChecked && <img src={CheckIcon} alt="CheckIcon" />}
    </div>
  );
};

type PropType = {
  onClose: () => void;
  checked: number;
  setChecked: any;
  list: any[];
};

export const PriceTypeModal: React.FC<PropType> = ({
  onClose,
  checked,
  setChecked,
  list,
}) => {
  const handleRowClick = (index: number) => {
    setChecked(index);
    setTimeout(() => {
      onClose();
    }, 200);
  };

  const { t } = useTranslation("app");

  return (
    <>
      <div onClick={onClose} className="outlet-price-type"></div>

      <div className="price-type">
        <div className="price-type-header">
          <img onClick={onClose} src={Close} alt="close" />
        </div>
        <div className="price-type-main">
          {list.map((item, index) => (
            <PriceTypeRow
              key={index}
              title={t(item.title)}
              info={t(item.info)}
              isChecked={index === checked}
              onRowClick={() => handleRowClick(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};
